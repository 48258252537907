import React from 'react';

import { Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import MapContentContainer from '../Components/maps'

import UserForm from '../Components/users/userForm';
import Form from '../Components/projects/form';
import PlaceForm from '../Components/places/form';
import InstListaForm from '../Components/instListaEspecies/form';
import EspeciesForm from '../Components/especies/form';
import TrapsForm from '../Components/traps/form';
import DocumentsForm from '../Components/documents/form';
import ToraForm from './flora/tora/form';
import ToraSaidaForm from './flora/tora/formSaida';
import LenhaForm from './flora/lenha/form';
import LenhaSaidaForm from './flora/lenha/formSaida';
import RaizesForm from './flora/raizes/form';
import RaizesEdicaoForm from './flora/raizes/formEdicao';
import RaizesSaidaForm from './flora/raizes/formSaida';
import AreaPlantioForm from './flora/areaPlantio/form';
import AuditForm from '../Components/audit/form';
import PalmitoMatrizForm from './flora/palmito/matriz/form';
import PalmitoTalhaoForm from './flora/palmito/talhao/form';
import PalmitoSaidaForm from './flora/palmito/saida/form';
import ViveirosResgateForm from './flora/viveiros/resgate/form';
import ViveirosProducaoForm from './flora/viveiros/producao/form';
import FloraEspeciesForm from './flora/especies/form';
import TelemetriaMonitoramentoForm from './fauna/radio/monitoramentoForm';
import ExclusaoModal from './faunaDetails/exclusaoModal'

export default function CustomModal({ modalFn, content, modalType, alert }) {
  // close modal on escape key
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      modalFn(modalType, true)
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [modalType])
  return (
    <div className="modalBg">
      <div className={`modalBox ${modalType === 'exclusaoCard' ? 'smallSize':''}`}>
        <Grid container>
          <div className="modalHeader">
            {modalType === 'traps' && <Typography>Armadilhas</Typography>}
            {modalType === 'documents' && <Typography>Documentos</Typography>}
            {modalType === 'palmitoMatriz' && <Typography>Matriz de Palmito</Typography>}
            {modalType === 'palmitoTalhao' && <Typography>Talhão</Typography>}
            {modalType === 'palmitoSaida' && <Typography>Saída de Palmito</Typography>}
            {modalType === 'viveirosResgate' && <Typography>Viveiro de Resgate</Typography>}
            {modalType === 'viveirosProducao' && <Typography>Viveiro de Produção</Typography>}
            {modalType === 'floraEspecies' && <Typography>Espécies de Flora</Typography>}
            {modalType === 'radiotalemetria_monitoramento' && <Typography>Monitoramento</Typography>}
            <FontAwesomeIcon icon={faTimes} id="closeIcon" onClick={() => modalFn(modalType, true)} />
          </div>
          <div className="modalBody">
            {alert && alert.type === 'alert-danger' && alert.message &&
                    <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
            }
            {alert && alert.type === 'alert-success' && alert.message &&
                    <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
            }
            {(modalType === 'Captura' || modalType === 'Recebimento' || modalType === 'Ocorrência' || modalType === 'Soltura') && <MapContentContainer coordinates={content} />}
            {(modalType === 'faunaSpot') && <MapContentContainer coordinatesArray={content} />}
            {(modalType === 'mapPolygon') && <MapContentContainer coordinatesArray={content} polygon />}
            {modalType === 'imagem' && <img className="expandedImage" src={content} alt={'zoomImagem'} />}
            {modalType === 'user' && <UserForm closeModal={modalFn} />}
            {modalType === 'project' && <Form closeModal={modalFn} />}
            {modalType === 'places' && <PlaceForm closeModal={modalFn} />}
            {modalType === 'instLista' && <InstListaForm closeModal={modalFn} />}
            {modalType === 'especies' && <EspeciesForm closeModal={modalFn} />}
            {modalType === 'traps' && <TrapsForm closeModal={modalFn} />}
            {modalType === 'documents' && <DocumentsForm closeModal={modalFn} />}
            {modalType === 'palmitoMatriz' && <PalmitoMatrizForm closeModal={modalFn} />}
            {modalType === 'palmitoTalhao' && <PalmitoTalhaoForm closeModal={modalFn} />}
            {modalType === 'palmitoSaida' && <PalmitoSaidaForm closeModal={modalFn} />}
            {modalType === 'viveirosResgate' && <ViveirosResgateForm closeModal={modalFn} />}
            {modalType === 'viveirosProducao' && <ViveirosProducaoForm key="ViveirosProducaoForm" closeModal={modalFn} />}
            {modalType === 'floraEspecies' && <FloraEspeciesForm closeModal={modalFn} />}
            {modalType === 'radiotalemetria_monitoramento' && <TelemetriaMonitoramentoForm closeModal={modalFn} />}
            {modalType === 'exclusaoCard' && <ExclusaoModal numeroFicha={content} closeModal={modalFn} />}
            {modalType === 'tora' && <ToraForm closeModal={modalFn} context={modalType} />}
            {modalType === 'toraSaida' && <ToraSaidaForm closeModal={modalFn} context={modalType} />}
            {modalType === 'lenha' && <LenhaForm closeModal={modalFn} context={modalType} />}
            {modalType === 'lenhaSaida' && <LenhaSaidaForm closeModal={modalFn} context={modalType} />}
            {modalType === 'raizes' && <RaizesForm closeModal={modalFn} context={modalType} />}
            {modalType === 'raizesEdicao' && <RaizesEdicaoForm closeModal={modalFn} context={modalType} />}
            {modalType === 'raizesSaida' && <RaizesSaidaForm closeModal={modalFn} context={modalType} />}
            {modalType === 'areaPlantio' && <AreaPlantioForm closeModal={modalFn} context={modalType} />}
            {modalType === 'audit' && <AuditForm closeModal={modalFn} context={modalType} />}

          </div>
        </Grid>
      </div>
    </div>
  );
}