import { lenhaHandler } from '../handlers/lenhaHandler';
import { lenhaActionTypes } from '../types/lenhaActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const lenhaActions = {
    listLenha,
    paginateLenha,
    createLenha,
    updateLenha,
    deleteLenha,
    listLenhaSaida,
    paginateLenhaSaida,
    createLenhaSaida,
    updateLenhaSaida,
    deleteLenhaSaida,
    calculateLenhaVolume,
    exportLenha
};

function listLenha() {
    return dispatch => {
        dispatch(request());
        lenhaHandler.listLenha()
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: lenhaActionTypes.GET_LENHA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.GET_LENHA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.GET_LENHA_CARD_FAILURE, error } }
}

function paginateLenha(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.paginateLenha(params, page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: lenhaActionTypes.LIST_LENHA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.LIST_LENHA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.LIST_LENHA_CARD_FAILURE, error } }
}

function createLenha(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.createLenha(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateLenha(filters, page, projectId))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: lenhaActionTypes.CREATE_LENHA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.CREATE_LENHA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.CREATE_LENHA_CARD_FAILURE, error } }
}

function updateLenha(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.updateLenha(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: lenhaActionTypes.UPDATE_LENHA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.UPDATE_LENHA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.UPDATE_LENHA_CARD_FAILURE, error } }
}

function deleteLenha(params, page, projectId, cardId, callback) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.deleteLenha(params, page, projectId, cardId)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: lenhaActionTypes.LIST_LENHA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.LIST_LENHA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.LIST_LENHA_CARD_FAILURE, error } }
}

function exportLenha(params, projectId) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.clear())
        lenhaHandler.exportLenha(params, projectId)
        .then(response => {
                if(response === "erro"){
                    dispatch(failure());
                    dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
                }else{
                    dispatch(success());
                    dispatch(alertActions.success('Extração realizada com sucesso'))
                }
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(failure(error));
                dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
        });
    };
    function request() { return { type: lenhaActionTypes.EXPORT_LENHA_REQUEST } }
    function success() { return { type: lenhaActionTypes.EXPORT_LENHA_SUCCESS } }
    function failure() { return { type: lenhaActionTypes.EXPORT_LENHA_FAILURE } }
}

function listLenhaSaida() {
    return dispatch => {
        dispatch(request());
        lenhaHandler.listLenhaSaida()
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: lenhaActionTypes.GET_LENHA_SAIDA_CARD_REQUEST } }
    function success(places) { return { type: lenhaActionTypes.GET_LENHA_SAIDA_CARD_SUCCESS, places } }
    function failure(error) { return { type: lenhaActionTypes.GET_LENHA_SAIDA_CARD_FAILURE, error } }
}

function paginateLenhaSaida(page, projectId) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.paginateLenhaSaida(page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: lenhaActionTypes.LIST_LENHA_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.LIST_LENHA_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.LIST_LENHA_SAIDA_CARD_FAILURE, error } }
}

function createLenhaSaida(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.createLenhaSaida(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateLenhaSaida(filters, page, projectId))
                dispatch(calculateLenhaVolume(projectId))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: lenhaActionTypes.CREATE_LENHA_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.CREATE_LENHA_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.CREATE_LENHA_SAIDA_CARD_FAILURE, error } }
}

function updateLenhaSaida(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.updateLenhaSaida(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                dispatch(calculateLenhaVolume(projectId))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: lenhaActionTypes.UPDATE_LENHA_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.UPDATE_LENHA_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.UPDATE_LENHA_SAIDA_CARD_FAILURE, error } }
}

function deleteLenhaSaida(page, projectId, cardId, callback) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.deleteLenhaSaida(page, projectId, cardId)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                dispatch(calculateLenhaVolume(projectId))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: lenhaActionTypes.LIST_LENHA_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.LIST_LENHA_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.LIST_LENHA_SAIDA_CARD_FAILURE, error } }
}

function calculateLenhaVolume(project) {
    return dispatch => {
        dispatch(request());
        lenhaHandler.calculateLenhaVolume(project)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: lenhaActionTypes.CALCULATE_LENHA_VOLUME_REQUEST } }
    function success(list) { return { type: lenhaActionTypes.CALCULATE_LENHA_VOLUME_SUCCESS, list } }
    function failure(error) { return { type: lenhaActionTypes.CALCULATE_LENHA_VOLUME_FAILURE, error } }
}