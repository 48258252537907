import React, { useState } from 'react';
import { Button, Divider, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, Typography } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import moment from 'moment';

const AccordionCaptura = ({ selectedCard, modalFn, dispatch, params, places, loggedUser, traps, validateForm }) => {
    const validTrap = traps?.some(trap => trap.trapId === selectedCard.armadilha)
    const placeFilter = places && places.filter(place => place._id === selectedCard.localCaptura)
    const validPlace = placeFilter && placeFilter.length <= 0
    const environmentFilter = params && params.environmentType && params.environmentType.filter(environment => environment._id === selectedCard.ambiente)
    const validEnvironment = environmentFilter && environmentFilter.length <= 0
    const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

    const [localManual, setlocalManual] = useState(false);

    return (
        <Grid container spacing={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                        className="inputDefault dateInput"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data de captura"
                        value={selectedCard.formatedDate && selectedCard.formatedDate.dataCaptura ? selectedCard.formatedDate.dataCaptura : null}
                        onChange={(event) => faunaHelpers.handleDate(event, 'dataCaptura', selectedCard.formatedDate, dispatch)}
                        KeyboardButtonProps={{
                            'aria-label': 'Alterar data',
                        }}
                        invalidDateMessage={'Data inválida'}
                        disabled={!writeModuleVerify}
                        error={validateForm && !selectedCard.formatedDate.dataCaptura}
                        helperText={validateForm && !selectedCard.formatedDate.dataCaptura ? 'Campo obrigatório' : ''}
                        required
                    />
                </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12} md={4}>
                <TextField id="manipulador" label="Manipulador" disabled={!writeModuleVerify} className="inputDefault" value={selectedCard.manipulador} onChange={(event) => faunaHelpers.handleInput(event, 'manipulador', dispatch)} />
            </Grid>

            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Armadilha</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.armadilha}
                        onChange={(event) => faunaHelpers.handleTrap(event, traps, selectedCard, dispatch, 'pontoCaptura')}
                    disabled={!writeModuleVerify || selectedCard.localCaptura}
                    >
                        <MenuItem value="">--</MenuItem>
                        <MenuItem value="Busca ativa">Busca ativa</MenuItem>
                        {!validTrap && (selectedCard.armadilha !== "--" || selectedCard.armadilha !== "Busca ativa" || selectedCard.armadilha !== "") && selectedCard.armadilha !== "Busca ativa" &&
                            <MenuItem value={selectedCard.armadilha}>{selectedCard.armadilha}</MenuItem>
                        }
                        {traps?.map(trap => {
                            const trapType = params.trapType.find(item => item._id = trap.trapType)?.trapType;
                            return <MenuItem key={trap._id} value={trap.trapId}>{`${trap.trapId} (${trap.description}) mód. ${trapType ?? ""}`}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Divider className="dividerFull" />

            <Grid item xs={12} md={4}>
                <Checkbox
                    className="faunaCheckBox"
                    checked={localManual}
                    onChange={() => setlocalManual(!localManual)}
                    disabled={!writeModuleVerify || (selectedCard.armadilha && selectedCard.armadilha !== 'Busca ativa')}
                />
                <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                    Deseja inserir o local manualmente?
                </Typography>
            </Grid>
            {localManual &&
                <Grid item xs={12} md={4}>
                    <TextField
                        id="localCapturaManual"
                        label="Local da Captura"
                        className="inputDefault" disabled={!writeModuleVerify}
                        value={selectedCard.localCaptura}
                        onChange={(event) => faunaHelpers.handleInput(event, 'localCaptura', dispatch)}
                    />
                </Grid>
            }
            {!localManual &&
                <Grid item xs={12} md={4}>
                    <FormControl className="inputDefault">
                        <InputLabel id="demo-simple-select-label">Local da Captura</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                            value={selectedCard.localCaptura}
                            onChange={(event) => faunaHelpers.handlePlace(event, 'localCaptura', places, selectedCard, dispatch, 'pontoCaptura')}
                            disabled={!writeModuleVerify || (selectedCard.armadilha && selectedCard.armadilha !== 'Busca ativa')}
                        >
                            <MenuItem value="">--</MenuItem>
                            {validPlace && selectedCard.localCaptura !== '--' &&
                                <MenuItem value={selectedCard.localCaptura}>{selectedCard.localCaptura}</MenuItem>
                            }
                            {places && places.map((place, index) => {
                                return <MenuItem key={index} value={place._id}>{place.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            }

            <Grid item xs={12} md={4}>
                <TextField
                    id="pontoCapturaCoordX"
                    label="Coordenada X"
                    className="inputDefault"
                    disabled={!writeModuleVerify || (selectedCard.localCaptura && !localManual) || (selectedCard.armadilha && selectedCard.armadilha !== 'Busca ativa')}
                    value={selectedCard.pontoCaptura && selectedCard.pontoCaptura.coordX ? selectedCard.pontoCaptura.coordX : ""}
                    onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordX', 'pontoCaptura', selectedCard.pontoCaptura, dispatch)}
                    error={validateForm && !selectedCard.pontoCaptura.coordX}
                    helperText={validateForm && !selectedCard.pontoCaptura.coordX ? 'Campo obrigatório' : ''}
                    required
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    id="pontoCapturaCoordY"
                    label="Coordenada Y"
                    error={validateForm && !selectedCard.pontoCaptura.coordY}
                    className="inputDefault"
                    disabled={!writeModuleVerify || (selectedCard.localCaptura && !localManual) || (selectedCard.armadilha && selectedCard.armadilha !== 'Busca ativa')}
                    value={selectedCard.pontoCaptura && selectedCard.pontoCaptura.coordY ? selectedCard.pontoCaptura.coordY : ""}
                    onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordY', 'pontoCaptura', selectedCard.pontoCaptura, dispatch)}
                    helperText={validateForm && !selectedCard.pontoCaptura.coordY ? 'Campo obrigatório' : ''}
                    required
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField id="pontoCapturaCoordProj" label="Projeção" className="inputDefault" disabled={!writeModuleVerify || (selectedCard.localCaptura && !localManual) || (selectedCard.armadilha && selectedCard.armadilha !== 'Busca ativa')} value={selectedCard.pontoCaptura && selectedCard.pontoCaptura.coordProj ? selectedCard.pontoCaptura.coordProj : ""} onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordProj', 'pontoCaptura', selectedCard.pontoCaptura, dispatch)} />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField id="epe" label="EPE" className="inputDefault" disabled={!writeModuleVerify || (selectedCard.localCaptura && !localManual) || (selectedCard.armadilha && selectedCard.armadilha !== 'Busca ativa')} value={selectedCard.epe} onChange={(event) => faunaHelpers.handleInput(event, 'epe', dispatch)} />
            </Grid>

            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Ambiente</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.ambiente}
                        onChange={(event) => faunaHelpers.handleInput(event, 'ambiente', dispatch)}
                        disabled={(selectedCard.localCaptura && !localManual) || !writeModuleVerify}
                    >
                        {validEnvironment &&
                            <MenuItem value={selectedCard.ambiente}>{selectedCard.ambiente}</MenuItem>
                        }
                        {params && params.environmentType.map((environment, index) => {
                            return <MenuItem key={index} value={environment._id}>{environment.environmentType}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Divider className="dividerFull" />

            <Grid item xs={12} md={12}>
                <TextField id="obsAdicionais" disabled={!writeModuleVerify} label="Observações adicionais" className="inputDefault" multiline rows={1} rowsMax={8} value={selectedCard.obsAdicionais} onChange={(event) => faunaHelpers.handleInput(event, 'obsAdicionais', dispatch)} />
            </Grid>

            {selectedCard.pontoCaptura && selectedCard.pontoCaptura.coordX && selectedCard.pontoCaptura.coordY && selectedCard.pontoCaptura.coordProj && selectedCard.epe &&
                <Grid item xs={12} md={4}>
                    <Button variant="contained" className="btn-primary" onClick={() => modalFn('Captura', selectedCard.pontoCaptura)}>Visualizar no mapa</Button>
                </Grid>
            }
        </Grid>
    )
}

export default AccordionCaptura