// import { actionTypes } from "../types/actionTypes";


const initialState = {};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};