import { lenhaActionTypes } from "../types/lenhaActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: {
    lenha: {},
    lenhaSaida: {}
  },
  lenha: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {
      numeroFicha: '',
      areaDemarcada: '',
      initDate: moment().subtract(7, 'days').format(),
      endDate: moment().format()
    },
    editItem: {
      numeroFicha: '',
      areaDemarcada: '',
      comprimento: '',
      dataMedicao: moment().format(),
      altura1: '',
      altura2: '',
      altura3: '',
      altura4: '',
      altura5: '',
      altura6: '',
      alturaMedia: '',
      largura: '',
      volume: '',
      volumeEsterio: '',
    }
  },
  lenhaSaida: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {
      tipo: '',
      volumetoTal: '',
      initDate: moment().subtract(7, 'days').format(),
      endDate: moment().format()
    },
    editItem: {
      data: moment().format(),
      tipo: '',
      destino: '',
      licenca: '',
      volume: '',
    },
    volumeDisponivel: 0
  }
};

export const lenhaReducer = (state = initialState, action) => {
  switch (action.type) {
    case lenhaActionTypes.GET_LENHA_CARD_REQUEST:
    case lenhaActionTypes.GET_LENHA_CARD_FAILURE:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          lenha: {}
        }
      };
    case lenhaActionTypes.GET_LENHA_CARD_SUCCESS:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          lenha: action.list
        }
      };
    case lenhaActionTypes.UPDATE_LENHA_CARD_REQUEST:
    case lenhaActionTypes.CREATE_LENHA_CARD_REQUEST:
    case lenhaActionTypes.LIST_LENHA_CARD_REQUEST:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          loading: true
        }
      };
    case lenhaActionTypes.CREATE_LENHA_CARD_SUCCESS:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          editItem: initialState.lenha.editItem,
          loading: false
        }
      };
    case lenhaActionTypes.UPDATE_LENHA_CARD_SUCCESS:
    case lenhaActionTypes.LIST_LENHA_CARD_SUCCESS:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.lenha.editItem,
          loading: false
        }
      };
    case lenhaActionTypes.UPDATE_LENHA_CARD_FAILURE:
    case lenhaActionTypes.CREATE_LENHA_CARD_FAILURE:
    case lenhaActionTypes.LIST_LENHA_CARD_FAILURE:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          loading: false
        }
      };

    case lenhaActionTypes.SET_LENHA_FILTER:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          filters: { ...state.lenha.filters, [action.target]: action.value }
        }
      };

    case lenhaActionTypes.SET_LENHA_FILTER_DATE_INIT:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          filters: {
            ...state.lenha.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case lenhaActionTypes.SET_LENHA_FILTER_DATE_END:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          filters: {
            ...state.lenha.filters,
            endDate: moment(action.date).format()
          }
        }
      };

    case lenhaActionTypes.EDIT_LENHA_CARD:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          editItem: action.item
        }
      };
    case lenhaActionTypes.UPDATE_LENHA_CARD_CANCELED:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          editItem: initialState.lenha.editItem,
          loading: false
        }
      };
    case lenhaActionTypes.HANDLE_LENHA_FIELDS:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          editItem: {
            ...state.lenha.editItem,
            [action.target]: action.value
          }
        }
      };
    case lenhaActionTypes.EXPORT_LENHA_REQUEST:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          loading: true
        }
      };
    case lenhaActionTypes.EXPORT_LENHA_SUCCESS:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          loading: false
        }
      };
    case lenhaActionTypes.EXPORT_LENHA_FAILURE:
      return {
        ...state,
        lenha: {
          ...state.lenha,
          loading: false
        }
      };
    // lenha Saida

    case lenhaActionTypes.UPDATE_LENHA_SAIDA_CARD_REQUEST:
    case lenhaActionTypes.CREATE_LENHA_SAIDA_CARD_REQUEST:
    case lenhaActionTypes.LIST_LENHA_SAIDA_CARD_REQUEST:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          loading: true
        }
      };
    case lenhaActionTypes.CREATE_LENHA_SAIDA_CARD_SUCCESS:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          editItem: initialState.lenhaSaida.editItem,
          loading: false
        }
      };
    case lenhaActionTypes.UPDATE_LENHA_SAIDA_CARD_SUCCESS:
    case lenhaActionTypes.LIST_LENHA_SAIDA_CARD_SUCCESS:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.lenhaSaida.editItem,
          loading: false
        }
      };
    case lenhaActionTypes.UPDATE_LENHA_SAIDA_CARD_FAILURE:
    case lenhaActionTypes.CREATE_LENHA_SAIDA_CARD_FAILURE:
    case lenhaActionTypes.LIST_LENHA_SAIDA_CARD_FAILURE:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          loading: false
        }
      };

    case lenhaActionTypes.SET_LENHA_SAIDA_FILTER:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          filters: { ...state.lenhaSaida.filters, [action.target]: action.value }
        }
      };

    case lenhaActionTypes.SET_LENHA_SAIDA_FILTER_DATE_INIT:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          filters: {
            ...state.lenhaSaida.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case lenhaActionTypes.SET_LENHA_SAIDA_FILTER_DATE_END:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          filters: {
            ...state.lenhaSaida.filters,
            endDate: moment(action.date).format()
          }
        }
      };

    case lenhaActionTypes.EDIT_LENHA_SAIDA_CARD:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          editItem: action.item
        }
      };
    case lenhaActionTypes.UPDATE_LENHA_SAIDA_CARD_CANCELED:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          editItem: initialState.lenha.editItem,
          loading: false
        }
      };
    case lenhaActionTypes.HANDLE_LENHA_SAIDA_FIELDS:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          editItem: {
            ...state.lenhaSaida.editItem,
            [action.target]: action.value
          }
        }
      };
    case lenhaActionTypes.CALCULATE_LENHA_VOLUME_SUCCESS:
      return {
        ...state,
        lenhaSaida: {
          ...state.lenhaSaida,
          volumeDisponivel: action.list.availableVolume
        }
      };

    default:
      return state;
  }
};