import React from 'react';
import { Grid, TextField, Checkbox, Divider, Typography, FormControl, InputLabel, Select, MenuItem, InputAdornment } from "@material-ui/core"
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';

const AccordionIdent = ({ selectedCard, classTypes, dispatch, loggedUser }) => {
    const classType = classTypes && classTypes.filter(classe => classe._id === selectedCard.classe)
    const selectedClass = classType && classType.length > 0 ? classType[0].classe : selectedCard.classe
    const selectedOrdem = selectedCard?.ordem ?? ''
    const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

    return (
        <Grid container spacing={3}>
            {(selectedClass === 'Répteis' || selectedClass === 'Anfíbios' || selectedOrdem === 'Squamata' || selectedOrdem === 'Squamata - Anfisbênias' || selectedOrdem === 'Squamata - Serpentes' || selectedOrdem === 'Crocodylia' || selectedOrdem === 'Squamata - Lagartos') &&
                <>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="compFocinhoCloaca"
                            disabled={!writeModuleVerify}
                            label="Comp. Focinho Cloaca"
                            className="inputDefault"
                            value={selectedCard.compFocinhoCloaca}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compFocinhoCloaca', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                </>
            }
            {(selectedClass === 'Anfíbios' || selectedOrdem === 'Squamata' || selectedOrdem === 'Squamata - Anfisbênias' || selectedOrdem === 'Squamata - Serpentes' || selectedOrdem === 'Crocodylia' || selectedOrdem === 'Squamata - Lagartos') &&
                <Grid item xs={12} md={4}>
                    <TextField
                        id="compCranio"
                        disabled={!writeModuleVerify}
                        label="Comp. Crânio"
                        className="inputDefault"
                        value={selectedCard.compCranio}
                        onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compCranio', dispatch)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                    />
                </Grid>
            }
            {(
                selectedClass === 'Anfíbios' ||
                selectedClass === 'Mamíferos'
            ) &&
                <Grid item xs={12} md={4}>
                    <TextField
                        id="compMembroPost"
                        disabled={!writeModuleVerify}
                        label="Comp. Membro Posterior"
                        className="inputDefault"
                        value={selectedCard.compMembroPost}
                        onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compMembroPost', dispatch)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                    />
                </Grid>
            }

            {selectedClass === 'Répteis' &&
                <>
                    {(selectedOrdem === 'Crocodylia' || selectedOrdem === 'Squamata - Lagartos') &&
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="compFocinhoCauda"
                                disabled={!writeModuleVerify}
                                label="Comp. Focinho Cauda"
                                className="inputDefault"
                                value={selectedCard.compFocinhoCauda}
                                onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compFocinhoCauda', dispatch)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                }}
                            />
                        </Grid>
                    }
                    {(selectedOrdem === 'Squamata' || selectedOrdem === 'Squamata - Anfisbênias' || selectedOrdem === 'Squamata - Serpentes') &&
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="compCloacaCauda"
                                disabled={!writeModuleVerify}
                                label="Comp. Cloaca Cauda"
                                className="inputDefault"
                                value={selectedCard.compCloacaCauda}
                                onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compCloacaCauda', dispatch)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                }}
                            />
                        </Grid>
                    }
                    {(selectedOrdem === 'Testudines') &&
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="compCasco"
                                    disabled={!writeModuleVerify}
                                    label="Comp. Casco"
                                    className="inputDefault"
                                    value={selectedCard.compCasco}
                                    onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compCasco', dispatch)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="largCasco"
                                    disabled={!writeModuleVerify}
                                    label="Largura Casaco"
                                    className="inputDefault"
                                    value={selectedCard.largCasco}
                                    onChange={(event) => faunaHelpers.HandleInputNumber(event, 'largCasco', dispatch)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="compplastrao"
                                    disabled={!writeModuleVerify}
                                    label="Comp. Plastrão"
                                    className="inputDefault"
                                    value={selectedCard.compplastrao}
                                    onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compplastrao', dispatch)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="largplastrao"
                                    disabled={!writeModuleVerify}
                                    label="Largura Plastrão"
                                    className="inputDefault"
                                    value={selectedCard.largplastrao}
                                    onChange={(event) => faunaHelpers.HandleInputNumber(event, 'largplastrao', dispatch)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </>
                    }
                </>
            }
            {(
                selectedClass === 'Aves' ||
                selectedClass === 'Mamíferos'
            ) &&
                <Grid item xs={12} md={4}>
                    <TextField
                        id="compCauda"
                        disabled={!writeModuleVerify}
                        label="Comp. Cauda"
                        className="inputDefault"
                        value={selectedCard.compCauda}
                        onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compCauda', dispatch)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                    />
                </Grid>
            }
            {selectedClass === 'Aves' &&
                <>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="compAsa"
                            disabled={!writeModuleVerify}
                            label="Comp. Asa"
                            className="inputDefault"
                            value={selectedCard.compAsa}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compAsa', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="compTarso"
                            disabled={!writeModuleVerify}
                            label="Comp. Tarso"
                            className="inputDefault"
                            value={selectedCard.compTarso}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compTarso', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="alturaBico"
                            disabled={!writeModuleVerify}
                            label="Altura Bico"
                            className="inputDefault"
                            value={selectedCard.alturaBico}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'alturaBico', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="larguraBico"
                            disabled={!writeModuleVerify}
                            label="Largura Bico"
                            className="inputDefault"
                            value={selectedCard.larguraBico}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'larguraBico', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="compBico"
                            disabled={!writeModuleVerify}
                            label="Comp. Bico"
                            className="inputDefault"
                            value={selectedCard.compBico}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compBico', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField id="scorePeitoral" disabled={!writeModuleVerify} label="Score Peitoral" className="inputDefault" value={selectedCard.scorePeitoral} onChange={(event) => faunaHelpers.handleInput(event, 'scorePeitoral', dispatch)} />
                    </Grid>
                </>
            }

            {selectedClass === 'Mamíferos' &&
                <>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="compFocinhoAnus"
                            disabled={!writeModuleVerify}
                            label="Comp. Focinho Ânus"
                            className="inputDefault"
                            value={selectedCard.compFocinhoAnus}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compFocinhoAnus', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="compFocinhoCranio"
                            disabled={!writeModuleVerify}
                            label="Comp. Focinho Crânio"
                            className="inputDefault"
                            value={selectedCard.compFocinhoCranio}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compFocinhoCranio', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    {selectedOrdem !== 'Chiroptera' &&
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="alturaCernelha"
                                disabled={!writeModuleVerify}
                                label="Altura Cernelha"
                                className="inputDefault"
                                value={selectedCard.alturaCernelha}
                                onChange={(event) => faunaHelpers.HandleInputNumber(event, 'alturaCernelha', dispatch)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="alturaOrelha"
                            disabled={!writeModuleVerify}
                            label="Altura Orelha"
                            className="inputDefault"
                            value={selectedCard.alturaOrelha}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'alturaOrelha', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                    {selectedOrdem === 'Chiroptera' &&
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="antebraco"
                                    disabled={!writeModuleVerify}
                                    label="Antebraço"
                                    className="inputDefault"
                                    value={selectedCard.antebraco}
                                    onChange={(event) => faunaHelpers.HandleInputNumber(event, 'antebraco', dispatch)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="envergadura"
                                    disabled={!writeModuleVerify}
                                    label="Envergadura"
                                    className="inputDefault"
                                    value={selectedCard.envergadura}
                                    onChange={(event) => faunaHelpers.HandleInputNumber(event, 'envergadura', dispatch)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </>
                    }
                    {(selectedOrdem === 'Rodentia') &&
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="compPe"
                                    disabled={!writeModuleVerify}
                                    label="Comp. Pé"
                                    className="inputDefault"
                                    value={selectedCard.compPe}
                                    onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compPe', dispatch)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField id="coloracaoVentre" disabled={!writeModuleVerify} label="Coloração Ventre" className="inputDefault" value={selectedCard.coloracaoVentre} onChange={(event) => faunaHelpers.handleInput(event, 'coloracaoVentre', dispatch)} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField id="coloracaoDorso" disabled={!writeModuleVerify} label="Coloração Dorso" className="inputDefault" value={selectedCard.coloracaoDorso} onChange={(event) => faunaHelpers.handleInput(event, 'coloracaoDorso', dispatch)} />
                            </Grid>
                        </>
                    }
                    {selectedOrdem === 'Chiroptera' &&
                        <Grid item xs={12} md={4}>
                            <TextField id="membranaInterfemoral" disabled={!writeModuleVerify} label="Membrana Interfemoral" className="inputDefault" value={selectedCard.membranaInterfemoral} onChange={(event) => faunaHelpers.handleInput(event, 'membranaInterfemoral', dispatch)} />
                        </Grid>
                    }
                    {(selectedOrdem === 'Rodentia') &&
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField id="membranaInterdigitais" disabled={!writeModuleVerify} label="Membrana Interdigitais" className="inputDefault" value={selectedCard.membranaInterdigitais} onChange={(event) => faunaHelpers.handleInput(event, 'membranaInterdigitais', dispatch)} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField id="peloCauda" disabled={!writeModuleVerify} label="Pelo Cauda" className="inputDefault" value={selectedCard.peloCauda} onChange={(event) => faunaHelpers.handleInput(event, 'peloCauda', dispatch)} />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} md={4}>
                        <FormControl className="inputDefault">
                            <InputLabel id="demo-simple-select-label">Prenhez</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                value={selectedCard.prenhez}
                                onChange={(event) => faunaHelpers.handleInput(event, 'prenhez', dispatch)}
                                disabled={!writeModuleVerify}
                            >
                                <MenuItem value="--">--</MenuItem>
                                <MenuItem value="sim">Sim</MenuItem>
                                <MenuItem value="nao">Não</MenuItem>
                                <MenuItem value="amamentacao">Amamentação</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            }
            {(
                selectedClass === 'Aves' ||
                selectedClass === 'Anfíbios' ||
                selectedClass === 'Mamíferos' ||
                selectedOrdem === 'Squamata' ||
                selectedOrdem === 'Squamata - Anfisbênias' ||
                selectedOrdem === 'Squamata - Serpentes'
            ) &&
                <Grid item xs={12} md={4}>
                    <TextField
                        id="compTotal"
                        disabled={!writeModuleVerify}
                        label="Comp. Total"
                        className="inputDefault"
                        value={selectedCard.compTotal}
                        onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compTotal', dispatch)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                        }}
                    />
                </Grid>
            }
            <Grid item xs={12} md={4}>
                <TextField
                    id="peso"
                    disabled={!writeModuleVerify}
                    label="Peso"
                    className="inputDefault"
                    value={selectedCard.peso}
                    onChange={(event) => faunaHelpers.HandleInputNumber(event, 'peso', dispatch)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Faixa Etária</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.faixaEtaria}
                        onChange={(event) => faunaHelpers.handleInput(event, 'faixaEtaria', dispatch)}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem value="--">--</MenuItem>
                        <MenuItem value="jovem">Jovem</MenuItem>
                        <MenuItem value="adulto">Adulto</MenuItem>
                        <MenuItem value="indeterminado">Indeterminado</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.sexo}
                        onChange={(event) => faunaHelpers.handleInput(event, 'sexo', dispatch)}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem value="--">--</MenuItem>
                        <MenuItem value="Macho">Macho</MenuItem>
                        <MenuItem value="Fêmea">Fêmea</MenuItem>
                        <MenuItem value="Indeterminado">Indeterminado</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {selectedClass === 'Peixes' &&
                <>
                    <Grid item xs={12} md={4}>
                        <TextField id="ph" disabled={!writeModuleVerify} label="PH" className="inputDefault" value={selectedCard.ph} onChange={(event) => faunaHelpers.handleInput(event, 'ph', dispatch)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField id="temperatura" disabled={!writeModuleVerify} label="Temperatura" className="inputDefault" value={selectedCard.temperatura} onChange={(event) => faunaHelpers.handleInput(event, 'temperatura', dispatch)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField id="qtd_abertura_branquial" disabled={!writeModuleVerify} label="Qnt. Abertura Branquial" className="inputDefault" value={selectedCard.qtd_abertura_branquial} onChange={(event) => faunaHelpers.handleInput(event, 'qtd_abertura_branquial', dispatch)} />
                    </Grid>
                </>
            }
            {selectedClass === 'Anfíbios' &&
                <>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="compDedoPost"
                            disabled={!writeModuleVerify}
                            label="Compr. dedo maior post."
                            className="inputDefault"
                            value={selectedCard.compDedoPost}
                            onChange={(event) => faunaHelpers.HandleInputNumber(event, 'compDedoPost', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </Grid>
                </>
            }

            <Divider className="dividerFull" />

            {selectedClass === 'Peixes' &&
                <>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.escamas}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.escamas, 'escamas', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Possui escamas
                        </Typography>
                    </Grid>
                    {selectedCard.escamas &&
                        <Grid item xs={12} md={4}>
                            <TextField id="escamas_tam" disabled={!writeModuleVerify} label="Tamanho das escamas" className="inputDefault" value={selectedCard.escamas_tam} onChange={(event) => faunaHelpers.handleInput(event, 'escamas_tam', dispatch)} />
                        </Grid>
                    }
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.linha_lateral_dividida}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.linha_lateral_dividida, 'linha_lateral_dividida', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Linha lateral dividida
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.linha_lateral_inteira}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.linha_lateral_inteira, 'linha_lateral_inteira', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Linha lateral inteira
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.opeculo}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.opeculo, 'opeculo', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Opéculo
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.barbilhao_maxilar}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.barbilhao_maxilar, 'barbilhao_maxilar', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Barbilhão maxilar
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.barbilhao_mental}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.barbilhao_mental, 'barbilhao_mental', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Barbilhão mental
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.barbilhao_pos_mental}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.barbilhao_pos_mental, 'barbilhao_pos_mental', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Barbilhão pós mental
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.boca_terminal}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.boca_terminal, 'boca_terminal', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Boca terminal
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.boca_ventral}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.boca_ventral, 'boca_ventral', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Boca ventral
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.nadadeira_dorsal}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.nadadeira_dorsal, 'nadadeira_dorsal', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Nadadeira dorsal
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.nadadeira_ventral}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.nadadeira_ventral, 'nadadeira_ventral', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Nadadeira ventral
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.nadadeira_peitoral}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.nadadeira_peitoral, 'nadadeira_peitoral', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Nadadeira peitoral
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.nadadeira_anal}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.nadadeira_anal, 'nadadeira_anal', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Nadadeira anal
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.nadadeira_adiposa}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.nadadeira_adiposa, 'nadadeira_adiposa', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Nadadeira adiposa
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.nadadeira_caudal_div}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.nadadeira_caudal_div, 'nadadeira_caudal_div', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Nadadeira caudal dividida
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className="faunaCheckBox"
                            checked={selectedCard.nadadeira_caudal_int}
                            onChange={() => faunaHelpers.handleRadios(selectedCard.nadadeira_caudal_int, 'nadadeira_caudal_int', dispatch)}
                            disabled={!writeModuleVerify}
                        />
                        <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                            Nadadeira caudal inteira
                        </Typography>
                    </Grid>
                </>
            }

            <Divider className="dividerFull" />

            {selectedOrdem === 'Chiroptera' &&
                <>
                    <Grid item xs={12} md={4}>
                        <TextField id="incisivos" disabled={!writeModuleVerify} label="Incisivos" className="inputDefault" value={selectedCard.denticao && selectedCard.denticao.incisivos ? selectedCard.denticao.incisivos : ""} onChange={(event) => faunaHelpers.handleDenticao(event, 'incisivos', selectedCard.denticao, dispatch)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField id="caninos" disabled={!writeModuleVerify} label="Caninos" className="inputDefault" value={selectedCard.denticao && selectedCard.denticao.caninos ? selectedCard.denticao.caninos : ""} onChange={(event) => faunaHelpers.handleDenticao(event, 'caninos', selectedCard.denticao, dispatch)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField id="preMolares" disabled={!writeModuleVerify} label="Pré-molares" className="inputDefault" value={selectedCard.denticao && selectedCard.denticao.preMolares ? selectedCard.denticao.preMolares : ""} onChange={(event) => faunaHelpers.handleDenticao(event, 'preMolares', selectedCard.denticao, dispatch)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField id="molares" disabled={!writeModuleVerify} label="Molares" className="inputDefault" value={selectedCard.denticao && selectedCard.denticao.molares ? selectedCard.denticao.molares : ""} onChange={(event) => faunaHelpers.handleDenticao(event, 'molares', selectedCard.denticao, dispatch)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField id="totalDentes" disabled={!writeModuleVerify} label="Total de Dentes" className="inputDefault" value={selectedCard.denticao && selectedCard.denticao.totalDentes ? selectedCard.denticao.totalDentes : ""} onChange={(event) => faunaHelpers.handleDenticao(event, 'totalDentes', selectedCard.denticao, dispatch)} />
                    </Grid>
                </>
            }
        </Grid>
    )
}

export default AccordionIdent