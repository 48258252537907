export const especiesActionTypes = {

    GET_ESPECIES_REQUEST: "GET_ESPECIES_REQUEST",
    GET_ESPECIES_SUCCESS: "GET_ESPECIES_SUCCESS",
    GET_ESPECIES_FAILURE: "GET_ESPECIES_FAILURE",

    CREATE_ESPECIES_REQUEST: "CREATE_ESPECIES_REQUEST",
    CREATE_ESPECIES_SUCCESS: "CREATE_ESPECIES_SUCCESS",
    CREATE_ESPECIES_FAILURE: "CREATE_ESPECIES_FAILURE",

    UPDATE_ESPECIES_REQUEST: "UPDATE_ESPECIES_REQUEST",
    UPDATE_ESPECIES_SUCCESS: "UPDATE_ESPECIES_SUCCESS",
    UPDATE_ESPECIES_FAILURE: "UPDATE_ESPECIES_FAILURE",
    UPDATE_ESPECIES_CANCELED: "UPDATE_ESPECIES_CANCELED",

    LIST_ESPECIES_REQUEST: "LIST_ESPECIES_REQUEST",
    LIST_ESPECIES_SUCCESS: "LIST_ESPECIES_SUCCESS",
    LIST_ESPECIES_FAILURE: "LIST_ESPECIES_FAILURE",

    HANDLE_INPUT_ESPECIES: "HANDLE_INPUT_ESPECIES",
    HANDLE_RADIOS_ESPECIES: "HANDLE_RADIOS_ESPECIES",
    HANDLE_ESPECIES: "HANDLE_ESPECIES",
    
    SET_ESPECIES_FILTER: "SET_ESPECIES_FILTER",
    EDIT_ESPECIES: "EDIT_ESPECIES",
};

