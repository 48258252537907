import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Divider, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Header from '../Components/headerAlter';
import AccordionBasic from '../Components/faunaDetails/accordionBasico';
import AccordionIdent from '../Components/faunaDetails/accordionIdentifica';
import AccordionCaracteristicas from '../Components/faunaDetails/accordionCaracteristicas';
import AccordionRecebimento from '../Components/faunaDetails/accordionRecebimento';
import AccordionCaptura from '../Components/faunaDetails/accordionCaptura';
import AccordionOcorrencia from '../Components/faunaDetails/accordionOcorrencia';
import AccordionDescricaoOcorrencia from '../Components/faunaDetails/accordionOcorrenciaDescricao';
import AccordionSoltura from '../Components/faunaDetails/accordionSoltura';
import AccordionImagens from '../Components/faunaDetails/accordionImagens';
import AccordionDocumentos from '../Components/faunaDetails/accordionDocumentos';
import { Alert } from '@material-ui/lab';

import CustomModal from '../Components/customModal';

import { history } from '../helpers/history';
import { helpers } from '../helpers/helpers';
import { especiesHelpers } from '../helpers/especies/especiesHelpers'
import { placesHelpers } from '../helpers/places/placesHelpers';
import { documentsHelpers } from '../helpers/documents/documentsHelpers';
import { faunaHelpers } from '../helpers/fauna/faunaHelpers';
import { useParams } from "react-router-dom";
import { trapsHelpers } from '../helpers/traps/trapsHelpers';
import { faunaReducer } from '../reducers/faunaReducer';

function FaunaDetails(props) {

  const { idFicha } = useParams()

  const { fauna, dispatch, especies, paramsReducer, placesReducer, documentsReducer, projectsReducer, loggedUser, trapsReducer, alert, login } = props
  const { selectedCard, selectOptions } = fauna
  const selectedProjectObj = projectsReducer.listProjects.filter(project => project.projectId === projectsReducer.selectedProject)
  const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write


  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(true);
  const [panel3, setPanel3] = useState(true);
  const [panel4, setPanel4] = useState(true);
  const [panel5, setPanel5] = useState(true);
  const [modal, setModal] = useState({ showModal: false, modalType: undefined })
  const [validateForm, setValidateForm] = useState(false)

  useEffect(() => {
    if (projectsReducer.selectedProject !== '999') {
      dispatch(faunaHelpers.handleInput(projectsReducer.selectedProject, "projectId", dispatch))
    }
    if (!fauna.selectedCard.numeroFicha && idFicha) {
      dispatch(faunaHelpers.getById({
        numeroFicha: idFicha,
      }, 1, projectsReducer.selectedProject))
    }

    faunaHelpers.trimFields(selectedCard, dispatch)

    dispatch(helpers.saveLocation(history.location.pathname));
    dispatch(especiesHelpers.list(null, 0));
    dispatch(helpers.listParams(null, null, projectsReducer.selectedProject, 'faunaClass', "list"));
    dispatch(helpers.listParams(null, null, projectsReducer.selectedProject, 'trapType', "list"));
    dispatch(helpers.listParams(null, null, projectsReducer.selectedProject, 'environmentType', "list"));
    dispatch(helpers.listParams(null, null, projectsReducer.selectedProject, 'ringColors', "list"));
    dispatch(placesHelpers.listPlaces(projectsReducer.selectedProject));
    dispatch(trapsHelpers.list(projectsReducer.selectedProject));
    dispatch(documentsHelpers.list());

  }, [dispatch, fauna.selectedCard.numeroFicha, idFicha, projectsReducer.selectedProject])

  const handleAccordion = (accordion) => {
    if (accordion === 'panel1') setPanel1(!panel1)
    else if (accordion === 'panel2') setPanel2(!panel2)
    else if (accordion === 'panel3') setPanel3(!panel3)
    else if (accordion === 'panel4') setPanel4(!panel4)
    else if (accordion === 'panel5') setPanel5(!panel5)
  }

  const handleModal = (modalType, content) => {
    setModal({ showModal: !modal.showModal, modalType: modalType, content: content })
  }

  const formValidator = () => {
    const { selectedCard } = fauna
    const { nomeCientifico, numeroFicha, cardType, dataCaptura, dataRecebimento, dataObs, pontoCaptura, pontoObs } = selectedCard
    const dataTemp = cardType === 'Captura' || cardType === 'Recaptura' ? dataCaptura : cardType === 'Recebimento' ? dataRecebimento : cardType === 'Ocorrência' ? dataObs : false
    const coordenadasCaptura = (cardType === 'Captura' || cardType === 'Recaptura') && pontoCaptura.coordX !== '' && pontoCaptura.coordY !== '' ? true : false
    const coordenadasOcorrencia = (cardType === 'Ocorrência') && pontoObs.coordX !== '' && pontoObs.coordY !== '' ? true : false
    const validadorCoords = ((cardType === 'Captura' || cardType === 'Recaptura') && coordenadasCaptura) ? true : ((cardType === 'Ocorrência' && coordenadasOcorrencia) || cardType === 'Recebimento') ? true : false
    const validate = !!(nomeCientifico && numeroFicha && dataTemp && validadorCoords)

    setValidateForm(true)
    if (validate === false) return

    if (selectedCard._id && selectedCard._id !== '') {
      dispatch(faunaHelpers.update(selectedCard, 1))
    } else {
      dispatch(faunaHelpers.create(selectedCard, 1))
    }
    window.scrollTo(0, 0)

  }

  return (
    <div className='mainContainer'>
      <Header title={selectedCard._id ? `FICHA DETALHES` : 'NOVA FICHA'} />
      {fauna.selectedCard &&
        <div className='basicContainer'>
          {alert && alert.type && alert.message &&
            <>
              {alert.type === 'alert-danger' &&
                <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
              }
              {alert.type === 'alert-success' &&
                <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
              }
            </>
          }
          <AccordionBasic
            selectedCard={selectedCard}
            selectOptions={selectOptions}
            selectProject={selectedProjectObj}
            classTypes={paramsReducer.listOptions.faunaClass}
            dispatch={dispatch}
            loggedUser={loggedUser}
            validateForm={validateForm}
            ringColors={paramsReducer.listOptions.ringColors}
            projects={projectsReducer}
            login={login}
          />

          <Accordion className='basicAccordion' expanded={panel1} onChange={() => handleAccordion('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
              <Typography>Identificação</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionIdent selectedCard={selectedCard} selectProject={selectedProjectObj} listEspecies={especies.listEspecies} classTypes={paramsReducer.listOptions.faunaClass} dispatch={dispatch} loggedUser={loggedUser} validateForm={validateForm} projects={projectsReducer} />
            </AccordionDetails>
          </Accordion>
          {selectedCard.classe &&
            <Accordion className='basicAccordion' expanded={panel2} onChange={() => handleAccordion('panel2')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
                <Typography>Características Biométricas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionCaracteristicas selectedCard={selectedCard} classTypes={paramsReducer.listOptions.faunaClass} dispatch={dispatch} loggedUser={loggedUser} />
              </AccordionDetails>
            </Accordion>
          }
          {selectedCard.cardType &&
            <Accordion className='basicAccordion' expanded={panel3} onChange={() => handleAccordion('panel3')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
                {selectedCard.cardType === 'Captura' && <Typography>Captura</Typography>}
                {selectedCard.cardType === 'Recaptura' && <Typography>Recaptura</Typography>}
                {selectedCard.cardType === 'Ocorrência' && <Typography>Local da Ocorrência</Typography>}
                {selectedCard.cardType === 'Recebimento' && <Typography>Recebimento</Typography>}
              </AccordionSummary>
              <AccordionDetails>
                {(selectedCard.cardType === 'Captura' || selectedCard.cardType === 'Recaptura') && <AccordionCaptura selectedCard={selectedCard} modalFn={handleModal} dispatch={dispatch} params={paramsReducer.listOptions} places={placesReducer.listPlaces} traps={trapsReducer.listTraps} loggedUser={loggedUser} validateForm={validateForm} />}
                {selectedCard.cardType === 'Ocorrência' && <AccordionOcorrencia selectedCard={selectedCard} dispatch={dispatch} params={paramsReducer.listOptions} places={placesReducer.listPlaces} loggedUser={loggedUser} validateForm={validateForm} />}
                {selectedCard.cardType === 'Recebimento' && <AccordionRecebimento selectedCard={selectedCard} dispatch={dispatch} params={paramsReducer.listOptions} documents={documentsReducer.listDocuments} loggedUser={loggedUser} validateForm={validateForm} />}
              </AccordionDetails>
            </Accordion>
          }

          {selectedCard.cardType && selectedCard.cardType === 'Ocorrência' &&
            <Accordion className='basicAccordion' expanded={panel4} onChange={() => handleAccordion('panel4')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
                <Typography>Descriçao da Ocorrência</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionDescricaoOcorrencia selectedCard={selectedCard} modalFn={handleModal} dispatch={dispatch} selectOptions={fauna.selectOptions} loggedUser={loggedUser} />
              </AccordionDetails>
            </Accordion>
          }

          {selectedCard.cardType && selectedCard.cardType !== 'Ocorrência' &&
            <Accordion className='basicAccordion' expanded={panel4} onChange={() => handleAccordion('panel4')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
                <Typography>Soltura</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionSoltura selectedCard={selectedCard} modalFn={handleModal} dispatch={dispatch} places={placesReducer.listPlaces} loggedUser={loggedUser} />
              </AccordionDetails>
            </Accordion>
          }
          {selectedCard._id &&
            <Accordion className='basicAccordion' expanded={panel5} onChange={() => handleAccordion('panel5')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
                <Typography>Imagens</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionImagens selectedCard={selectedCard} modalFn={handleModal} loggedUser={loggedUser} />
              </AccordionDetails>
            </Accordion>
          }
          {!!selectedCard.numeroFicha &&
            <Accordion className='basicAccordion' expanded={panel5} onChange={() => handleAccordion('panel5')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
                <Typography>Documentos</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionDocumentos selectedCard={selectedCard} modalFn={handleModal} {...props} loggedUser={loggedUser} />
              </AccordionDetails>
            </Accordion>
          }

          <Divider className="dividerFull" />
          {writeModuleVerify &&
            <Grid container spacing={3}>
              <Grid item xs={6} md={2}>
                <Button variant="contained" className="btn-primary" onClick={() => formValidator()}>
                  SALVAR
                </Button>
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={6} md={2}>
                <Button variant="contained" className="btn-secondary" onClick={() => handleModal("exclusaoCard", selectedCard.numeroFicha)}>
                  EXCLUIR
                </Button>
              </Grid>
            </Grid>
          }
        </div>}
      {modal.showModal &&
        <CustomModal modalFn={handleModal} content={modal.content} modalType={modal.modalType} />
      }
    </div>
  )
}

const mapStateToProps = store => ({
  alert: store.alertReducer,
  fauna: store.faunaReducer,
  especies: store.especiesReducer,
  paramsReducer: store.paramsReducer,
  placesReducer: store.placesReducer,
  documentsReducer: store.documentsReducer,
  projectsReducer: store.projectsReducer,
  loggedUser: store.loginReducer.loggedUser,
  trapsReducer: store.trapsReducer,
  login: store.loginReducer
});

export default connect(mapStateToProps)(FaunaDetails);