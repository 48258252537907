import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'
import { especiesHelpers } from '../../helpers/especies/especiesHelpers';

class EspeciesForm extends Component {

    constructor(props){
        super(props)
        this.state = {
        }
    }

    render(){
        const { dispatch, especiesReducer, closeModal, paramsReducer } = this.props
        const { editItem } = especiesReducer

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Classe</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.classe} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'classe', 'editEspecies', dispatch)}>
                                    {paramsReducer && paramsReducer.listOptions && paramsReducer.listOptions.faunaClass && paramsReducer.listOptions.faunaClass.map((item, index) => <MenuItem key={index} value={item.classe}>{item.classe}</MenuItem> )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="ordem" label="Ordem" className="inputDefault" value={editItem.ordem} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'ordem', 'editEspecies', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="familia" label="Família" className="inputDefault" value={editItem.familia} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'familia', 'editEspecies', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="subFamilia" label="Sub-Família" className="inputDefault" value={editItem.subFamilia} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'subFamilia', 'editEspecies', dispatch)}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={6}>
                            <TextField id="genero" label="Gênero" className="inputDefault" value={editItem.genero} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'genero', 'editEspecies', dispatch)}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={6}>
                            <TextField id="nomeCientifico" label="Nome Científico" className="inputDefault" value={editItem.nomeCientifico} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'nomeCientifico', 'editEspecies', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="nomePopular" label="Nome Popular" className="inputDefault" value={editItem.nomePopular} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'nomePopular', 'editEspecies', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="especie" label="Espécie" className="inputDefault" value={editItem.especie} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'especie', 'editEspecies', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="subEspecie" label="Sub-Espécie" className="inputDefault" value={editItem.subEspecie} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'subEspecie', 'editEspecies', dispatch)}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.status} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'status', 'editEspecies', dispatch)}>
                                    <MenuItem key='ativa' value={true}>{'Ativa'}</MenuItem>
                                    <MenuItem key='inativa' value={false}>{'Inativa'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        
                        
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-primary" onClick={editItem._id && editItem._id !== '' ? () => dispatch(especiesHelpers.update(editItem, 1, closeModal)) : () => dispatch(especiesHelpers.create(editItem, 1, closeModal))}>
                                SALVAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    especiesReducer: store.especiesReducer,
    paramsReducer: store.paramsReducer,
});

export default connect(mapStateToProps)(EspeciesForm);