import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  Grid,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { Pagination, Alert } from '@material-ui/lab';
import Header from '../../../Components/headerAlter'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';

import { history } from '../../../helpers/history'
import { helpers } from '../../../helpers/helpers'
import { areaMonitoramentoHelpers } from '../../../helpers/flora/areaMonitoramento/helpers'
import { placesHelpers } from '../../../helpers/places/placesHelpers';
import { floraEspeciesHelpers } from '../../../helpers/flora/especies/helpers';

function AreaMonitoramentoDetalhes(props) {

  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(true);
  const [panel3, setPanel3] = useState(true);
  const [addItem, setAddItem] = useState(false);
  const [addMonitoramento, setaddMonitoramento] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);
  const { areaMonitoramentoReducer, dispatch, alert, pageContext, pageTitle, projectsReducer, placesReducer, floraEspeciesReducer } = props
  const { listPlaces } = placesReducer
  const { listDocuments } = floraEspeciesReducer
  const { areaMonitoramento } = areaMonitoramentoReducer
  const { newItem, editItem, monitoramentos, newMonitoramento, filters, documents } = areaMonitoramento



  useEffect(() => {
    const { dispatch, areaMonitoramentoReducer, projectsReducer } = props
    dispatch(helpers.saveLocation(history.location.pathname));
    // dispatch(areaMonitoramentoHelpers.paginateAreaMonitoramento(areaMonitoramentoReducer.areaMonitoramento.filters, areaMonitoramentoReducer.areaMonitoramento.documents.page, projectsReducer.selectedProject))
    dispatch(placesHelpers.listPlaces(projectsReducer.selectedProject, '2'));
    dispatch(floraEspeciesHelpers.list());
    if (areaMonitoramentoReducer.areaMonitoramento.editItem._id && areaMonitoramentoReducer.areaMonitoramento.editItem._id !== '') {
      setPanel1(false)
    }
  }, [])

  const handleAccordion = (accordion) => {
    if (accordion === 'panel1') setPanel1(!panel1)
    else if (accordion === 'panel2') setPanel2(!panel2)
    else if (accordion === 'panel3') setPanel3(!panel3)
    // else if (accordion === 'panel4') setPanel4(!panel4)
    // else if (accordion === 'panel5') setPanel5(!panel5)
  }

  const formValidator = () => {
    const { areaMonitoramentoReducer } = props
    const { areaMonitoramento } = areaMonitoramentoReducer
    const { editItem } = areaMonitoramento
    const { codigoDaArea, coord } = editItem

    if (codigoDaArea !== '' && coord !== [] && coord.length >= 3 &&
      coord[0].coordenadaX !== '' && coord[0].coordenadaY !== '' && coord[0].EPE !== ''
      && coord[1].coordenadaX !== '' && coord[1].coordenadaY !== '' && coord[1].EPE !== ''
      && coord[2].coordenadaX !== '' && coord[2].coordenadaY !== '' && coord[2].EPE !== '') {
      console.log('passouu')
      return true
    } else {
      console.log('não passouu')
      return false
    }
  }

  const submitForm = () => {
    const { areaMonitoramentoReducer, dispatch, closeModal, projectsReducer } = props
    const { areaMonitoramento } = areaMonitoramentoReducer
    const { editItem, filters } = areaMonitoramento
    setFormSubmited(true)

    if (formValidator() === false) return
    if (editItem._id && editItem._id !== '') {
      dispatch(areaMonitoramentoHelpers.updateAreaMonitoramento(editItem, 1, null, projectsReducer.selectedProject, filters))
    }
    else {
      dispatch(areaMonitoramentoHelpers.createAreaMonitoramento(editItem, 1, null, projectsReducer.selectedProject, filters))
    }
  }

  const cancelAddItem = () => {
    const { dispatch } = props
    if (addItem) dispatch(areaMonitoramentoHelpers.clearNewItem())
    setAddItem(!addItem)
  }

  const editedItem = (item) => {
    const { dispatch } = props
    dispatch(areaMonitoramentoHelpers.editItem(item))
    setAddItem(true)
  }

  const addNewItem = () => {
    const { dispatch, areaMonitoramentoReducer } = props
    const { areaMonitoramento } = areaMonitoramentoReducer
    const { editItem, newItem } = areaMonitoramento
    dispatch(areaMonitoramentoHelpers.addNewItem(editItem, newItem, dispatch))
    setAddItem(false)
  }

  const setMonitoramento = (item) => {
    const { dispatch } = props
    dispatch(areaMonitoramentoHelpers.addMonitoramento(item))
    setPanel2(false)
  }
  const handleNewMonitoramento = () => {
    const { dispatch } = props
    dispatch(areaMonitoramentoHelpers.clearMonitoramento())
    setaddMonitoramento(!addMonitoramento)
  }

  const addNewMonitoramento = () => {
    const { dispatch, areaMonitoramentoReducer } = props
    const { areaMonitoramento } = areaMonitoramentoReducer
    const { monitoramentos, newMonitoramento } = areaMonitoramento
    dispatch(areaMonitoramentoHelpers.addNewMonitoramento(editItem, monitoramentos, newMonitoramento, dispatch))
    setaddMonitoramento(false)
  }

  const editedMonitoramento = (item) => {
    const { dispatch } = props
    dispatch(areaMonitoramentoHelpers.editedMonitoramento(item))
    setaddMonitoramento(true)
  }


  const validAreaDemarcada = listPlaces?.some(item => item.name === newItem.areaDemarcada)

  return (
    <div className='mainContainer'>
      <Header title={pageTitle} />
      <div className='basicContainer'>
        {alert && alert.type === 'alert-danger' && alert.message &&
          <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
        }
        {alert && alert.type === 'alert-success' && alert.message &&
          <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
        }
        <div className='basicInformation box-content-primary'>
          <Grid container spacing={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="codigoDaArea"
                  label="Código da área"
                  className="inputDefault"
                  value={editItem.codigoDaArea}
                  autoComplete='off'
                  onChange={(event) => areaMonitoramentoHelpers.handleInput(event, 'codigoDaArea', 'areaMonitoramento', dispatch)}
                  error={formSubmited && editItem.codigoDaArea === ''}
                  helperText={formSubmited && editItem.codigoDaArea === '' ? 'Campo obrigatório' : ''}
                  disabled={(editItem._id && editItem._id !== '') ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl className="inputDefault" required error={formSubmited && editItem.tipo === ''}>
                  <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select"
                    value={editItem.tipo}
                    onChange={(event) => areaMonitoramentoHelpers.handleInput(event, 'tipo', 'areaMonitoramento', dispatch)}
                  >
                    <MenuItem key={'Arbóreas'} selected value={'Arbóreas'}>{'Arbóreas'}</MenuItem>
                    <MenuItem key={'Epífitas'} value={'Epífitas'}>{'Epífitas'}</MenuItem>
                    <MenuItem key={'Piezômetro'} value={'Piezômetro'}>{'Piezômetro'}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={4}>
                  <KeyboardDatePicker
                    className="inputDefault dateInput"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Data de criação"
                    value={editItem.dataCriacao}
                    onChange={(event) => areaMonitoramentoHelpers.handleInput(event, 'dataCriacao', 'areaMonitoramento', dispatch)}
                    KeyboardButtonProps={{
                      'aria-label': 'Alterar data',
                    }}
                    invalidDateMessage={'Data inválida'}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </div>
        <Accordion className='basicAccordion' expanded={panel1} onChange={() => handleAccordion('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
            <Typography>Coordenadas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {editItem?.coord.map((position, index) => {
                return (
                  <>
                    <Grid item xs={12} md={4} key={`${index}-coordx`}>
                      <TextField
                        label="Coordenada X"
                        className="inputDefault"
                        value={position.coordenadaX}
                        autoComplete='off'
                        onChange={(event) => areaMonitoramentoHelpers.handlePlaces(editItem.coord, index, 'coordenadaX', event.target.value, 'areaMonitoramento', areaMonitoramentoHelpers.getArea, dispatch)}
                        error={formSubmited && index < 3 && position.coordenadaX === ''}
                        helperText={formSubmited && index < 3 && position.coordenadaX === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} key={`${index}-coordy`}>
                      <TextField
                        label="Coordenada Y"
                        className="inputDefault"
                        value={position.coordenadaY}
                        autoComplete='off'
                        onChange={(event) => areaMonitoramentoHelpers.handlePlaces(editItem.coord, index, 'coordenadaY', event.target.value, 'areaMonitoramento', areaMonitoramentoHelpers.getArea, dispatch)}
                        error={formSubmited && index < 3 && position.coordenadaY === ''}
                        helperText={formSubmited && index < 3 && position.coordenadaY === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} key={`${index}-epe`}>
                      <TextField
                        label="EPE"
                        className="inputDefault"
                        value={position.EPE}
                        autoComplete='off'
                        onChange={(event) => areaMonitoramentoHelpers.handlePlaces(editItem.coord, index, 'EPE', event.target.value, 'areaMonitoramento', areaMonitoramentoHelpers.getArea, dispatch)}
                        error={formSubmited && index < 3 && position.EPE === ''}
                        helperText={formSubmited && index < 3 && position.EPE === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                  </>
                )
              })}
              <Divider className="dividerFull" />
              <Grid item xs={12} md={2}>
                <Button variant="contained" className="btn-secondary" onClick={() => areaMonitoramentoHelpers.addNewPlace(editItem.coord, 'coord', 'areaMonitoramento', dispatch)}>
                  Adicionar...
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {
          editItem._id && editItem._id !== '' &&

          <Accordion className='basicAccordion' expanded={panel2} onChange={() => handleAccordion('panel2')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
              <Typography>Indivíduos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                {addItem &&
                  <Grid item xs={12} md={12}>
                    <div className='basicInformation box-content-primary'>
                      <Grid container spacing={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="numero"
                              label="Número da ficha"
                              className="inputDefault"
                              value={newItem.numero}
                              autoComplete='off'
                              onChange={(event) => areaMonitoramentoHelpers.handleNewItemInput(event, 'numero', 'areaMonitoramento', dispatch)}
                            // error={formSubmited && newItem.numero === ''}
                            // helperText={formSubmited && newItem.numero === '' ? 'Campo obrigatório' : ''}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault" required error={formSubmited && newItem.areaDemarcada === ''}>
                              <InputLabel id="demo-simple-select-label">Área demarcada</InputLabel>
                              <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                value={newItem.areaDemarcada}
                                onChange={(event) => areaMonitoramentoHelpers.handleNewItemInput(event, 'areaDemarcada', 'areaMonitoramento', dispatch)}
                              >
                                <MenuItem selected value={''}>{'--'}</MenuItem>
                                {!validAreaDemarcada && newItem.areaDemarcada !== "--" &&
                                  <MenuItem value={newItem.areaDemarcada}>{newItem.areaDemarcada}</MenuItem>
                                }
                                {listPlaces.map((item, index) => {
                                  return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Divider className="dividerFull" />
                          <Grid item xs={12} md={8}>
                            <FormControl className="inputDefault" required error={formSubmited && newItem.nomeCientifico === ''}>
                              <InputLabel id="demo-simple-select-label">Espécie</InputLabel>
                              <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                value={newItem.nomeCientifico}
                                onChange={(event) => areaMonitoramentoHelpers.handleNewItemInput(event, 'nomeCientifico', 'areaMonitoramento', dispatch)}
                              >
                                <MenuItem selected value={''}>{'--'}</MenuItem>
                                {listDocuments?.especies.map((item, index) => {
                                  return <MenuItem key={item._id} value={item.nomeCientifico}>{item.nomeCientifico}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="nomePopular"
                              label="Nome popular"
                              className="inputDefault"
                              value={newItem.nomePopular}
                              autoComplete='off'
                              onChange={(event) => areaMonitoramentoHelpers.handleNewItemInput(event, 'nomePopular', 'areaMonitoramento', dispatch)}
                            // error={formSubmited && newItem.nomePopular === ''}
                            // helperText={formSubmited && newItem.nomePopular === '' ? 'Campo obrigatório' : ''}
                            />
                          </Grid>
                          <Divider className="dividerFull" />
                          <Grid item xs={12} md={3}>
                            <Button
                              variant="contained"
                              className="btn-secondary"
                              onClick={() => addNewItem()}
                            >
                              {newItem._id && newItem._id !== '' ? 'Salvar' : 'Adicionar'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                }
                <Divider className="dividerFull" />
                <Grid item xs={12} md={4}>
                  <Button variant="contained" className="btn-secondary" onClick={() => cancelAddItem()}>
                    {!addItem ? 'Adicionar Indivíduo' : 'Cancelar'}
                  </Button>
                </Grid>
                <Divider className="dividerFull" />
                {editItem.individuos.length > 0 &&
                  <Grid item xs={12} md={12}>
                    <TableContainer className="customTable paramsTable">
                      <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                        <TableHead className="tableHead">
                          <Grid component={'tr'} container className="parametersTable">
                            <Grid component={'th'} item xs={12} md={2}>Número</Grid>
                            <Grid component={'th'} item xs={12} md={3}>Nome científico</Grid>
                            <Grid component={'th'} item xs={12} md={2}>Nome popular</Grid>
                            <Grid component={'th'} item xs={12} md={2}>Área demarcada</Grid>
                            <Grid component={'th'} item xs={12} md={1}>Morte</Grid>
                            <Grid component={'th'} item xs={12} md={2}></Grid>
                          </Grid>
                        </TableHead>
                        <TableBody>
                          {editItem?.individuos.map((item, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <Grid component={'tr'} container className="parametersTable multiLine" key={index} id={labelId}>
                                <Grid component={'td'} item xs={12} md={2}>{item?.numero}</Grid>
                                <Grid component={'td'} item xs={12} md={3}>{item?.nomeCientifico}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>{item?.nomePopular}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>{item?.areaDemarcada}</Grid>
                                <Grid component={'td'} item xs={12} md={1}>{item?.morte ?? 'Não'}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>
                                  <Button
                                    variant="contained"
                                    className="btn-secondary btn-innerTable btn-small"
                                    onClick={() => dispatch(() => setMonitoramento(item))}
                                  >
                                    Monitoramentos
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="btn-secondary btn-innerTable btn-small"
                                    onClick={() => dispatch(() => editedItem(item))}
                                  >
                                    Editar
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                }
                {editItem.individuos.length === 0 &&
                  <Grid item xs={12} md={12}>
                    <Alert className="alert alert-warning" severity="warning">Ficha sem indivíduos cadastrados</Alert>
                  </Grid>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        }
        {
          editItem._id && editItem._id !== '' && monitoramentos._id && monitoramentos._id !== '' &&

          <Accordion className='basicAccordion' expanded={panel3} onChange={() => handleAccordion('panel3')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
              <Typography>Monitoramentos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <div className='basicInformation box-content-primary'>
                    <Grid container spacing={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="numero"
                            label="Número"
                            className="inputDefault"
                            value={monitoramentos.numero}
                            autoComplete='off'
                            onChange={(event) => areaMonitoramentoHelpers.handleInput(event, 'numero', 'areaMonitoramento', dispatch)}
                            error={formSubmited && monitoramentos.numero === ''}
                            helperText={formSubmited && monitoramentos.numero === '' ? 'Campo obrigatório' : ''}
                            disabled={(monitoramentos._id && monitoramentos._id !== '') ? true : false}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nomeCientifico"
                            label="Nome científico"
                            className="inputDefault"
                            value={monitoramentos.nomeCientifico}
                            autoComplete='off'
                            onChange={(event) => areaMonitoramentoHelpers.handleInput(event, 'nomeCientifico', 'areaMonitoramento', dispatch)}
                            error={formSubmited && monitoramentos.nomeCientifico === ''}
                            helperText={formSubmited && monitoramentos.nomeCientifico === '' ? 'Campo obrigatório' : ''}
                            disabled={(monitoramentos._id && monitoramentos._id !== '') ? true : false}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nomePopular"
                            label="Nome popular"
                            className="inputDefault"
                            value={monitoramentos.nomePopular}
                            autoComplete='off'
                            onChange={(event) => areaMonitoramentoHelpers.handleInput(event, 'nomePopular', 'areaMonitoramento', dispatch)}
                            error={formSubmited && monitoramentos.nomePopular === ''}
                            helperText={formSubmited && monitoramentos.nomePopular === '' ? 'Campo obrigatório' : ''}
                            disabled={(monitoramentos._id && monitoramentos._id !== '') ? true : false}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="areaDemarcada"
                            label="Área demarcada"
                            className="inputDefault"
                            value={monitoramentos.areaDemarcada}
                            autoComplete='off'
                            onChange={(event) => areaMonitoramentoHelpers.handleInput(event, 'areaDemarcada', 'areaMonitoramento', dispatch)}
                            error={formSubmited && monitoramentos.areaDemarcada === ''}
                            helperText={formSubmited && monitoramentos.areaDemarcada === '' ? 'Campo obrigatório' : ''}
                            disabled={(monitoramentos._id && monitoramentos._id !== '') ? true : false}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                {addMonitoramento &&
                  <Grid item xs={12} md={12}>
                    <div className='basicInformation box-content-primary'>
                      <Grid container spacing={3}>
                        <Grid container spacing={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={4}>
                              <KeyboardDatePicker
                                className="inputDefault dateInput"
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Data de monitoramento"
                                value={newMonitoramento.dataDeMonitoramento}
                                onChange={(event) => areaMonitoramentoHelpers.handleNewMonitoramento(event, 'dataDeMonitoramento', 'areaMonitoramento', dispatch)}
                                KeyboardButtonProps={{
                                  'aria-label': 'Alterar data',
                                }}
                                invalidDateMessage={'Data inválida'}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="DPA"
                              label="DPA"
                              className="inputDefault"
                              value={newMonitoramento.DPA}
                              autoComplete='off'
                              onChange={(event) => areaMonitoramentoHelpers.handleNewMonitoramento(event, 'DPA', 'areaMonitoramento', dispatch)}
                            // error={formSubmited && newItem.DPA === ''}
                            // helperText={formSubmited && newItem.DPA === '' ? 'Campo obrigatório' : ''}
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <TextField
                              id="altura"
                              label="Altura"
                              className="inputDefault"
                              value={newMonitoramento.altura}
                              autoComplete='off'
                              onChange={(event) => areaMonitoramentoHelpers.handleNewMonitoramento(event, 'altura', 'areaMonitoramento', dispatch)}
                            // error={formSubmited && newItem.altura === ''}
                            // helperText={formSubmited && newItem.altura === '' ? 'Campo obrigatório' : ''}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControlLabel control={
                              <Checkbox
                                checked={newMonitoramento.morte}
                                onChange={() => areaMonitoramentoHelpers.handleNewMonitoramento(newMonitoramento.morte, 'morte', 'areaMonitoramento', dispatch)}
                                name="morte"
                                className='inputCheckbox'
                              />
                            }
                              label="Morte?"
                            />
                          </Grid>
                          <Divider className="dividerFull" />
                          <Grid item xs={12} md={3}>
                            <Button
                              variant="contained"
                              className="btn-secondary"
                              onClick={() => addNewMonitoramento()}
                            >
                              {newMonitoramento._id && newMonitoramento._id !== '' ? 'Salvar' : 'Adicionar'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                }
                <Divider className="dividerFull" />
                <Grid item xs={12} md={4}>
                  <Button variant="contained" className="btn-secondary" onClick={() => handleNewMonitoramento()}>
                    {!addMonitoramento ? 'Adicionar Monitoramento' : 'Cancelar'}
                  </Button>
                </Grid>
                <Divider className="dividerFull" />
                {monitoramentos.monitoramento.length > 0 &&
                  <Grid item xs={12} md={12}>
                    <TableContainer className="customTable paramsTable">
                      <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                        <TableHead className="tableHead">
                          <Grid component={'tr'} container className="parametersTable">
                            <Grid component={'th'} item xs={12} md={3}>Data de Monitoramento</Grid>
                            <Grid component={'th'} item xs={12} md={3}>DPA</Grid>
                            <Grid component={'th'} item xs={12} md={2}>Altura</Grid>
                            <Grid component={'th'} item xs={12} md={2}>Morte</Grid>
                            <Grid component={'th'} item xs={12} md={2}></Grid>
                          </Grid>
                        </TableHead>
                        <TableBody>
                          {monitoramentos?.monitoramento.map((item, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <Grid component={'tr'} container className="parametersTable multiLine" key={index} id={labelId}>
                                <Grid component={'td'} item xs={12} md={3}>{item?.dataDeMonitoramento ? moment(item?.dataDeMonitoramento).format('DD/MM/YYYY') : ''}</Grid>
                                <Grid component={'td'} item xs={12} md={3}>{item?.DPA}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>{item?.altura}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>{item.morte && item.morte === true ? 'Sim' : 'Não'}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>
                                  <Button
                                    variant="contained"
                                    className="btn-secondary btn-innerTable btn-small"
                                    onClick={() => dispatch(() => editedMonitoramento(item))}
                                  >
                                    Editar
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                }
                {monitoramentos.monitoramento.length === 0 &&
                  <Grid item xs={12} md={12}>
                    <Alert className="alert alert-warning" severity="warning">Indivíduo sem monitoramentos cadastrado</Alert>
                  </Grid>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        }
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >

          <Divider className="dividerFull" />
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            <Box padding={10}>
              <Button variant="contained"
                className="btn-primary"
                component="label"
                startIcon={<SaveIcon />}
                onClick={() => submitForm()}> Salvar Ficha </Button>
            </Box>
          </Box>
          {editItem._id && editItem._id !== '' &&
            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
              <Box padding={10}>
                <Button variant="contained"
                  className="btn-secondary"
                  component="label"
                  startIcon={<DeleteForeverIcon />}
                  onClick={(event) => areaMonitoramentoHelpers.deleteMonitoramento(filters, documents.page, projectsReducer.selectedProject, editItem._id, dispatch)}
                >
                  Excluir
                </Button>
              </Box>
            </Box>
          }
        </Grid>
        <Divider className="dividerFull" />
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  areaMonitoramentoReducer: store.areaMonitoramentoReducer,
  projectsReducer: store.projectsReducer,
  paramsReducer: store.paramsReducer,
  placesReducer: store.placesReducer,
  floraEspeciesReducer: store.floraEspeciesReducer,
  auth: store.loginReducer.auth,
  alert: store.alertReducer
});

export default connect(mapStateToProps)(AreaMonitoramentoDetalhes);