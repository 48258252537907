import { documentsActionTypes } from "../types/documentsActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: null,
  loading: false,
  documents: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0
  },
  filters: {
    trapId: ''
  },
  options:{
    projecao: ['Geográfica', 'UTM - 20 S', 'UTM - 21 S', 'UTM - 22 S', 'UTM - 23 S', 'UTM - 24 S', 'UTM - 20 N', 'UTM - 21 N', 'UTM - 22 N']
  },
  editItem: {
    project: "", 
    documentType: "", 
    gaiaCode: "", 
    originalCode: "",
    orgEmissor: "",
    addDate: moment().format(),
    endDate: moment().format(),
    endDateAlert: moment().subtract(1, 'months').format(),
    filePlace: "",
    description: "",
    status: true,
    files: []
  }
};

export const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentsActionTypes.GET_DOCUMENTS_REQUEST:
      return { ...state, listDocuments: state.listDocuments, loading: true };
    case documentsActionTypes.GET_DOCUMENTS_SUCCESS:
      return { ...state, listDocuments: action.places, loading: false };
    case documentsActionTypes.GET_DOCUMENTS_FAILURE:
      return { ...state, listDocuments: state.listDocuments, loading: false };

    case documentsActionTypes.CREATE_DOCUMENTS_REQUEST:
    case documentsActionTypes.UPDATE_DOCUMENTS_REQUEST:
    case documentsActionTypes.LIST_DOCUMENTS_REQUEST:
    case documentsActionTypes.DELETE_DOCUMENTS_REQUEST:
      
      return {
        ...state,
        loading: true
      };
      case documentsActionTypes.CREATE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        editItem: action.list,
        loading: false
      };
    case documentsActionTypes.UPDATE_DOCUMENTS_SUCCESS:
    case documentsActionTypes.LIST_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: {list: action.list.docs, total: action.list.total, limit: action.list.limit, page: Number(action.list.page), pagesTotal: action.list.pages},
        editItem: initialState.editItem,
        loading: false
      };
    case documentsActionTypes.UPDATE_DOCUMENTS_CANCELED:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case documentsActionTypes.CREATE_DOCUMENTS_FAILURE:
    case documentsActionTypes.UPDATE_DOCUMENTS_FAILURE:
    case documentsActionTypes.LIST_DOCUMENTS_FAILURE:
    case documentsActionTypes.DELETE_DOCUMENTS_FAILURE:  
      return {
        ...state,
        loading: false
      };
    case documentsActionTypes.EDIT_DOCUMENTS:
      return { ...state, editItem: action.places };
    case documentsActionTypes.HANDLE_DOCUMENTS:
    case documentsActionTypes.HANDLE_RADIOS_DOCUMENTS:
    case documentsActionTypes.HANDLE_INPUT_DOCUMENTS:
      return {
        ...state,
        editItem: {...state.editItem, [action.target]: action.value}
      };
    case documentsActionTypes.SET_DOCUMENTS_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
      case documentsActionTypes.UPLOAD_DOCUMENTS_SUCCESS:
      case documentsActionTypes.DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        editItem: action.list,
        loading: false
      }
    
    default:
      return state;
  }
};