import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core"
import { viveirosHelpers } from '../../../../helpers/flora/viveiros/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';

class ViveirosResgateForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formSubmited: false
    }
  }

  formValidator = () => {
    const { viveirosReducer } = this.props
    const { editItem } = viveirosReducer
    const { viveirosResgate } = editItem
    const { numeroLote, addDate, nomeCientifico, nomePopular, quantidadeResgatada } = viveirosResgate

    if (numeroLote !== '' && addDate !== null && nomeCientifico !== '' && nomePopular !== '' && quantidadeResgatada > 0) {
      return true
    } else {
      return false
    }
  }

  submitForm = () => {
    const { viveirosReducer, dispatch, closeModal, projectsReducer } = this.props
    const { editItem } = viveirosReducer
    const { viveirosResgate } = editItem
    this.setState({ formSubmited: true })

    if (this.formValidator() === false) return

    if (viveirosResgate._id && viveirosResgate._id !== '') {
      dispatch(viveirosHelpers.updateResgate(viveirosResgate, viveirosReducer.documents.page, closeModal, viveirosReducer.filters, projectsReducer.selectedProject))
    } else {
      dispatch(viveirosHelpers.createResgate(viveirosResgate, viveirosReducer.documents.page, closeModal, viveirosReducer.filters, projectsReducer.selectedProject))
    }
  }

  render() {
    const { dispatch, viveirosReducer, placesReducer, floraEspeciesReducer } = this.props
    const { editItem, options } = viveirosReducer
    const { viveirosResgate } = editItem
    const { formSubmited } = this.state

    return (
      <>
        <Typography variant="h5" component="h5" className="filterTitle">
          {viveirosResgate._id && viveirosResgate._id !== '' ? 'Editar' : 'Adicionar novo'}
        </Typography>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <TextField
                id="numeroLote"
                label="Número do Lote"
                className="inputDefault"
                disabled={viveirosResgate && viveirosResgate._id}
                value={viveirosResgate.numeroLote}
                autoComplete='off'
                onChange={(event) => viveirosHelpers.handleInput(event, 'numeroLote', 'viveirosResgate', dispatch)}
                error={formSubmited && viveirosResgate.numeroLote === ''}
                helperText={formSubmited && viveirosResgate.numeroLote === '' ? 'Campo obrigatório' : ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl className="inputDefault">
                <InputLabel id="demo-simple-select-label">Nome Científico</InputLabel>
                <Select
                  labelId="nomeCientifico-select-label"
                  id="nomeCientifico-select"
                  value={viveirosResgate.nomeCientifico}
                  autoComplete='off'
                  onChange={(event) => viveirosHelpers.handleEspecie(event.target.value, floraEspeciesReducer.listDocuments.especies, 'viveirosResgate', dispatch)}
                  error={formSubmited && viveirosResgate.nomeCientifico === ''}
                  helperText={formSubmited && viveirosResgate.nomeCientifico === '' ? 'Campo obrigatório' : ''}
                >
                  <MenuItem key='default' value={''}>Selecione...</MenuItem>
                  {floraEspeciesReducer.listDocuments.especies.map((item, index) => {
                    return <MenuItem key={index} value={item.nomeCientifico}>{item.nomeCientifico}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="nomePopular"
                label="Nome Popular"
                className="inputDefault"
                value={viveirosResgate.nomePopular}
                autoComplete='off'
                disabled={true}
                error={viveirosResgate.nomeCientifico !== '' && viveirosResgate.nomePopular === ''}
                helperText={viveirosResgate.nomeCientifico !== '' && viveirosResgate.nomePopular === '' ? 'Campo obrigatório' : ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="quantidadeResgatada"
                label="Quantidade Resgatada"
                className="inputDefault"
                value={viveirosResgate.quantidadeResgatada}
                autoComplete='off'
                onChange={(event) => viveirosHelpers.handleInput(event, 'quantidadeResgatada', 'viveirosResgate', dispatch, viveirosHelpers.updateQnt)}
                error={formSubmited && viveirosResgate.quantidadeResgatada === ''}
                helperText={formSubmited && viveirosResgate.quantidadeResgatada === '' ? 'Campo obrigatório' : ''}
                type="number"
              />
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} md={3}>
                <KeyboardDatePicker
                  className="inputDefault dateInput"
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data de entrada"
                  value={viveirosResgate.addDate}
                  onChange={(event) => viveirosHelpers.handleInput(event, 'addDate', 'viveirosResgate', dispatch)}
                  KeyboardButtonProps={{
                    'aria-label': 'Alterar data',
                  }}
                  invalidDateMessage={'Data inválida'}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12} md={3}>
              <FormControl className="inputDefault">
                <InputLabel id="demo-simple-select-label">Local de Origem</InputLabel>
                <Select
                  labelId="localOrigemTipo-select-label"
                  id="localOrigemTipo-select"
                  value={viveirosResgate.localOrigemTipo}
                  autoComplete='off'
                  onChange={(event) => viveirosHelpers.handleInput(event, 'localOrigemTipo', 'viveirosResgate', dispatch)}
                  error={formSubmited && viveirosResgate.localOrigemTipo === ''}
                  helperText={formSubmited && viveirosResgate.localOrigemTipo === '' ? 'Campo obrigatório' : ''}
                >
                  <MenuItem key='default' value={''}>Selecione...</MenuItem>
                  {options.localOrigemTipo.map((item, index) => {
                    return <MenuItem key={index} value={item.type}>{item.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            {viveirosResgate.localOrigemTipo && viveirosResgate.localOrigemTipo === 'serrapilheira' &&
              <Grid item xs={12} md={3}>
                <TextField
                  id="localOrigemId"
                  label="Número Serrapilheira"
                  className="inputDefault"
                  value={viveirosResgate.localOrigemId}
                  autoComplete='off'
                  onChange={(event) => viveirosHelpers.handleInput(event, 'localOrigemId', 'viveirosResgate', dispatch)}
                  error={formSubmited && viveirosResgate.localOrigemId === ''}
                  helperText={formSubmited && viveirosResgate.localOrigemId === '' ? 'Campo obrigatório' : ''}
                />
              </Grid>
            }
            {viveirosResgate.localOrigemTipo && viveirosResgate.localOrigemTipo === 'areaDemarcada' &&
              <Grid item xs={12} md={3}>
                <FormControl className="inputDefault">
                  <InputLabel id="demo-simple-select-label">Área Demarcada</InputLabel>
                  <Select
                    labelId="areaDemarcada-select-label"
                    id="areaDemarcada-select"
                    value={viveirosResgate.localOrigemId}
                    autoComplete='off'
                    onChange={(event) => viveirosHelpers.handleInput(event, 'localOrigemId', 'viveirosResgate', dispatch)}
                  >
                    <MenuItem key='default' value={''}>Selecione...</MenuItem>
                    {placesReducer.listPlaces.map((mapped, index) => {
                      if (mapped.placeType === '2') {
                        return <MenuItem key={index} value={mapped.name}>{mapped.name}</MenuItem>
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid item xs={12} md={3}>
              <TextField
                id="quantidadeViveiro"
                label="Quantidade em viveiro"
                className="inputDefault"
                value={viveirosResgate.quantidadeViveiro}
                disabled={true}
                autoComplete='off'
              />
            </Grid>
            <Divider className="dividerFull" />
            <Grid item xs={12} md={12} >
              <Typography variant="h6" component="h6" className="filterTitle">Saídas</Typography>
            </Grid>
            <Divider className="dividerFull" />
            {viveirosResgate.saidas && viveirosResgate.saidas.map((item, index) => {
              return (
                <>
                  <Grid container spacing={3} className="box-content-primary center blocked-itens" md={11}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={12} md={3}>
                        <KeyboardDatePicker
                          className="inputDefault dateInput"
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          label="Data de saída"
                          value={item.dataSaida}
                          onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'dataSaida', event, 'saidas', 'viveirosResgate', dispatch)}
                          KeyboardButtonProps={{
                            'aria-label': 'Alterar data',
                          }}
                          invalidDateMessage={'Data inválida'}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={12} md={3}>
                      <FormControl className="inputDefault">
                        <InputLabel id="demo-simple-select-label">Óbito</InputLabel>
                        <Select
                          labelId="obito-select-label"
                          id="obito-select"
                          value={item.obito}
                          autoComplete='off'
                          onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'obito', event.target.value, 'saidas', 'viveirosResgate', dispatch, undefined, 'string')}
                          error={formSubmited && item.obito === ''}
                          helperText={formSubmited && item.obito === '' ? 'Campo obrigatório' : ''}
                        >
                          <MenuItem key='default' value={null}>Selecione...</MenuItem>
                          <MenuItem key='true' value={true}>Sim</MenuItem>
                          <MenuItem key='false' value={false}>Não</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Quantidade"
                        className="inputDefault"
                        value={item.quantidadeSaida}
                        autoComplete='off'
                        onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'quantidadeSaida', event.target.value, 'saidas', 'viveirosResgate', dispatch, viveirosHelpers.updateQnt)}
                        type="number"
                        InputLabelProps={{ shrink: true, }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl className="inputDefault">
                        <InputLabel id="demo-simple-select-label">Área de Plantio</InputLabel>
                        <Select
                          labelId="areaPlantio-select-label"
                          id="areaPlantio-select"
                          value={item.areaPlantio}
                          autoComplete='off'
                          disabled={item.obito === true}
                          onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'areaPlantio', event.target.value, 'saidas', 'viveirosResgate', dispatch, undefined, 'string')}
                          error={formSubmited && item.areaPlantio === ''}
                          helperText={formSubmited && item.areaPlantio === '' ? 'Campo obrigatório' : ''}
                        >
                          <MenuItem key='default' value={''}>Selecione...</MenuItem>
                          {options.areaPlantio.map((mapped, index) => {
                            return <MenuItem key={index} value={mapped.type}>{mapped.label}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {item.areaPlantio && item.areaPlantio === 'areaDemarcada' &&
                      <Grid item xs={12} md={3}>
                        <FormControl className="inputDefault">
                          <InputLabel id="demo-simple-select-label">Área Demarcada</InputLabel>
                          <Select
                            labelId="areaDemarcada-select-label"
                            id="areaDemarcada-select"
                            value={item.areaDemarcada}
                            autoComplete='off'
                            onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'areaDemarcada', event.target.value, 'saidas', 'viveirosResgate', dispatch, undefined, 'string')}
                            error={formSubmited && item.areaDemarcada === ''}
                            helperText={formSubmited && item.areaDemarcada === '' ? 'Campo obrigatório' : ''}
                          >
                            <MenuItem key='default' value={''}>Selecione...</MenuItem>
                            {placesReducer.listPlaces.map((mapped, index) => {
                              if (mapped.placeType === '2') {
                                return <MenuItem key={index} value={mapped.name}>{mapped.name}</MenuItem>
                              }
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    }
                    {item.areaPlantio && item.areaPlantio === 'localConhecido' &&
                      <Grid item xs={12} md={3}>
                        <FormControl className="inputDefault">
                          <InputLabel id="demo-simple-select-label">Local Conhecido</InputLabel>
                          <Select
                            labelId="localConhecido-select-label"
                            id="localConhecido-select"
                            value={item.localConhecido}
                            autoComplete='off'
                            onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'localConhecido', event.target.value, 'saidas', 'viveirosResgate', dispatch, undefined, 'string')}
                            error={formSubmited && item.localConhecido === ''}
                            helperText={formSubmited && item.localConhecido === '' ? 'Campo obrigatório' : ''}
                          >
                            <MenuItem key='default' value={''}>Selecione...</MenuItem>
                            {placesReducer.listPlaces.map((mapped, index) => {
                              if (mapped.placeType === '1') {
                                return <MenuItem key={index} value={mapped.name}>{mapped.name}</MenuItem>
                              }
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    }
                    {item.areaPlantio && item.areaPlantio === 'coordenadas' &&
                      <>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="coordX"
                            label="Coordenada X"
                            className="inputDefault"
                            value={item.coordX}
                            autoComplete='off'
                            onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'coordX', event.target.value, 'saidas', 'viveirosResgate', dispatch, undefined, 'string')}
                            error={formSubmited && viveirosResgate.coordX === ''}
                            helperText={formSubmited && viveirosResgate.coordX === '' ? 'Campo obrigatório' : ''}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="coordY"
                            label="Coordenada Y"
                            className="inputDefault"
                            value={item.coordY}
                            autoComplete='off'
                            onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'coordY', event.target.value, 'saidas', 'viveirosResgate', dispatch, undefined, 'string')}
                            error={formSubmited && viveirosResgate.coordY === ''}
                            helperText={formSubmited && viveirosResgate.coordY === '' ? 'Campo obrigatório' : ''}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="projecao"
                            label="Projeção"
                            className="inputDefault"
                            value={item.projecao}
                            autoComplete='off'
                            onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'projecao', event.target.value, 'saidas', 'viveirosResgate', dispatch, undefined, 'string')}
                            error={formSubmited && viveirosResgate.projecao === ''}
                            helperText={formSubmited && viveirosResgate.projecao === '' ? 'Campo obrigatório' : ''}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="epe"
                            label="EPE"
                            className="inputDefault"
                            value={item.epe}
                            autoComplete='off'
                            onChange={(event) => viveirosHelpers.handleObjs(viveirosResgate, index, 'epe', event.target.value, 'saidas', 'viveirosResgate', dispatch, undefined, 'string')}
                            error={formSubmited && viveirosResgate.epe === ''}
                            helperText={formSubmited && viveirosResgate.epe === '' ? 'Campo obrigatório' : ''}
                          />
                        </Grid>
                      </>
                    }
                  </Grid>
                </>
              )
            })}
            <Divider className="dividerFull" />
            <Grid item xs={12} md={3}>
              <Button variant="contained" className="btn-secondary" onClick={() => viveirosHelpers.addNewObjs(viveirosResgate.saidas, 'saidas', 'viveirosResgate', dispatch)}>
                Adicionar Saída
              </Button>
            </Grid>
            <Divider className="dividerFull" />
            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
              <Box padding={10}>
                <Button variant="contained"
                  className="btn-primary"
                  component="label"
                  startIcon={<SaveIcon />}
                  onClick={() => this.submitForm()}> Salvar Ficha
                </Button>
              </Box>
            </Box>
            <Divider className="dividerFull" />
          </Grid>
        </form>
      </>
    )
  }
}

const mapStateToProps = store => ({
  viveirosReducer: store.viveirosReducer,
  placesReducer: store.placesReducer,
  floraEspeciesReducer: store.floraEspeciesReducer,
  projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(ViveirosResgateForm);