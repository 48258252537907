import { trapsActionTypes } from "../types/trapsActionTypes";

const initialState = {
  listTraps: null,
  loading: false,
  traps: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0
  },
  filters: {
    trapId: ''
  },
  options:{
    projecao: ['Geográfica', 'UTM - 20 S', 'UTM - 21 S', 'UTM - 22 S', 'UTM - 23 S', 'UTM - 24 S', 'UTM - 20 N', 'UTM - 21 N', 'UTM - 22 N']
  },
  editItem: {
    trapType: "", 
    trapId: "", 
    description: "", 
    availableProjects: [],
    trapSize: {
        altura: "",
        largura: "",
        comprimento: ""
    },
    status: true,
    position: []
  }
};

export const trapsReducer = (state = initialState, action) => {
  switch (action.type) {
    case trapsActionTypes.GET_TRAPS_REQUEST:
      return { ...state, listTraps: state.listTraps, loading: true };
    case trapsActionTypes.GET_TRAPS_SUCCESS:
      return { ...state, listTraps: action.places, loading: false };
    case trapsActionTypes.GET_TRAPS_FAILURE:
      return { ...state, listTraps: state.listTraps, loading: false };

    case trapsActionTypes.CREATE_TRAPS_REQUEST:
    case trapsActionTypes.UPDATE_TRAPS_REQUEST:
    case trapsActionTypes.LIST_TRAPS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case trapsActionTypes.CREATE_TRAPS_SUCCESS:
    case trapsActionTypes.UPDATE_TRAPS_SUCCESS:
    case trapsActionTypes.LIST_TRAPS_SUCCESS:
      return {
        ...state,
        traps: {list: action.list.docs, total: action.list.total, limit: action.list.limit, page: Number(action.list.page), pagesTotal: action.list.pages},
        editItem: initialState.editItem,
        loading: false
      };
    case trapsActionTypes.UPDATE_TRAPS_CANCELED:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case trapsActionTypes.CREATE_TRAPS_FAILURE:
    case trapsActionTypes.UPDATE_TRAPS_FAILURE:
    case trapsActionTypes.LIST_TRAPS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case trapsActionTypes.EDIT_TRAPS:
      return { ...state, editItem: action.places };
    case trapsActionTypes.HANDLE_TRAPS:
    case trapsActionTypes.HANDLE_RADIOS_TRAPS:
    case trapsActionTypes.HANDLE_INPUT_TRAPS:
      return {
        ...state,
        editItem: {...state.editItem, [action.target]: action.value}
      };
    case trapsActionTypes.SET_TRAPS_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
    default:
      return state;
  }
};