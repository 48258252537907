import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableContainer, Table, TableBody, Button, Divider } from '@material-ui/core'
import { Pagination, Alert } from '@material-ui/lab';
import Header from '../Components/headerAlter'
import Filter from '../Components/users/userFilter'
import CustomModal from '../Components/customModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { history } from '../helpers/history'
import { helpers } from '../helpers/helpers'


class Users extends Component {

	constructor(props){
		super(props);
		this.state = {
			modal: {showModal: false, modalType: undefined}
		}
	}

	componentDidMount(){
		const { dispatch } = this.props
		dispatch(helpers.saveLocation(history.location.pathname));
		dispatch(helpers.paginateUsers(undefined, this.props.usersReducer.listUsers.page))
		
	}

	handleModal = (modalType, clear) => {
        const { modal } = this.state
		const { dispatch } = this.props
        this.setState({modal:{ showModal: !modal.showModal, modalType: modalType}})
		if(clear){
			dispatch(helpers.editUser(false))
		}
    }

	render(){
		const { usersReducer, dispatch, alert } = this.props
		const { modal } = this.state
		const { listUsers, editUser } = usersReducer
		const { page, pagesTotal } = listUsers
		return (
			<div className='mainContainer'>
				<Header title='USUÁRIOS'/>
				<div className='basicContainer'>
					{alert && alert.type === 'alert-danger' && alert.message && 
							<Alert className="alert alert-error" severity="error">{alert.message}</Alert>
					}
					{alert && alert.type === 'alert-success' && alert.message && 
							<Alert className="alert alert-success" severity="success">{alert.message}</Alert>
					}
					<Filter handleModal={this.handleModal}/>
					{listUsers && listUsers.users &&
						<Grid container>
							<TableContainer className="customTable paramsTable">
								<Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
									<TableHead className="tableHead">
										<Grid component={'tr'} container className="parametersTable">
											<Grid component={'th'} item xs={12} md={5}>Nome</Grid>
											<Grid component={'th'} item xs={12} md={4}>Email</Grid>
											<Grid component={'th'} item xs={12} md={2}>Status</Grid>
											<Grid component={'th'} item xs={12} md={1}></Grid>
										</Grid>
									</TableHead>
									<TableBody>
										{listUsers.users.map((user, index) => {
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<Grid component={'tr'} container className="parametersTable" key={index} id={labelId}>
												<Grid component={'td'} item xs={12} md={5}>{user.nome}</Grid>
												<Grid component={'td'} item xs={12} md={4}>
													{user.email}
												</Grid>
												<Grid component={'td'} item xs={12} md={2}>{user.status ? 'Ativo' : 'Inativo' }</Grid>
												<Grid component={'td'} item xs={12} md={1}>
													<Button 
														variant="contained" 
														className="btn-secondary btn-innerTable" 
														onClick={() => dispatch(helpers.editUser(user, this.handleModal))}
														disabled={(editUser._id) ? true : false}
													>
														<FontAwesomeIcon icon={faEdit} />
													</Button>
												</Grid>
											</Grid>
										);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<Divider className="dividerFull"/>
							<Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, undefined, page, 'users'))} />
						</Grid>
					}
				</div>
				{modal.showModal &&  
                    <CustomModal modalFn={this.handleModal} modalType={modal.modalType}/>
                }
			</div>
		)
	}
}

const mapStateToProps = store => ({
	usersReducer: store.usersReducer,
	auth: store.loginReducer.auth,
	alert: store.alertReducer
  });
  
export default connect(mapStateToProps)(Users);