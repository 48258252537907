import React, { Component } from 'react';
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet'

import utmToLatLng from '../helpers/coordConverter'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@material-ui/core';




export class MapContentContainer extends Component {

  displayMarkers = (coordinates) => {
    if (!coordinates) return
    return coordinates.map((store, index) => {
      return <Marker key={index} position={[store.latitude, store.longitude]}>
        <Popup>
        
        
          <Link href={`/fauna/ficha/detalhes/${store?.item?.numeroFicha ?? ''}`} target="_blank">
            {store.item.numeroFicha} <FontAwesomeIcon icon={faExternalLinkAlt } />
          </Link>
          
        </Popup>
      </Marker>
    })
  }

  render() {
    const polygonOptions = { color: 'purple' }

    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow
    });
  
    L.Marker.prototype.options.icon = DefaultIcon;  

    const { coordinates, coordinatesArray } = this.props
    const convertedCoords = []
    if (coordinatesArray) {
      coordinatesArray.map(item => {
        const result = utmToLatLng(item.coordProj, item.coordX, item.coordY, false, item)
        convertedCoords.push(result)
      })
    } else {
      convertedCoords.push(utmToLatLng(coordinates.coordProj, coordinates.coordX, coordinates.coordY, false))
    }
    
    const polygonPositions = convertedCoords.map((item)=>{
      const toReturn = []
      toReturn[0] = item.latitude
      toReturn[1] = item.longitude
      return toReturn
      })

    return (
      
      <MapContainer center={[convertedCoords[0].latitude, convertedCoords[0].longitude]} zoom={15} scrollWheelZoom={false} style={{ width: "100%", height: "500px" }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
          {}
        {!this.props.polygon && 
        <>
          {this.displayMarkers(convertedCoords)}
        </>
        
          
        }
        {this.props.polygon && 
          <Polygon pathOptions={polygonOptions} positions={polygonPositions} />
        }
      </MapContainer>

    );
  }
}

export default MapContentContainer;