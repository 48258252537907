import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core"
import { raizesHelpers } from '../../../helpers/flora/raizes/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class RaizesForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    formValidator = () => {
        const { raizesReducer } = this.props
        const { raizes } = raizesReducer
        const { editItem } = raizes
        const { newItem } = editItem
        const { areaDemarcada, dataMedicao, volume } = newItem

        if (dataMedicao !== null && areaDemarcada !== '' && volume !== '') {
            return true
        } else {
            return false
        }
    }

    submitForm = () => {
        const { raizesReducer, dispatch, closeModal, projectsReducer } = this.props
        const { raizes } = raizesReducer
        const { editItem, filters } = raizes
        this.setState({ formSubmited: true })

        if (this.formValidator() === false) return

        dispatch(raizesHelpers.createRaizes(editItem.newItem, 1, closeModal, projectsReducer.selectedProject, filters))
    }

    render() {
        const { dispatch, raizesReducer, projectsReducer, closeModal, placesReducer } = this.props
        const { raizes } = raizesReducer
        const { listPlaces } = placesReducer
        const { editItem, filters, documents } = raizes
        const { formSubmited } = this.state

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault" required error={formSubmited && editItem.newItem.areaDemarcada === ''}>
                                <InputLabel id="demo-simple-select-label">Área Demarcada</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={editItem.newItem.areaDemarcada}
                                    onChange={(event) => raizesHelpers.handleInput(event, 'areaDemarcada', 'raizes', dispatch)}
                                >
                                    <MenuItem selected value={''}>{'--'}</MenuItem>
                                    {listPlaces.map((item, index) => {
                                        return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                {formSubmited && editItem.newItem.areaDemarcada === '' && <FormHelperText>Campo obrigatório</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de cadastro"
                                    value={editItem.newItem.dataMedicao}
                                    onChange={(event) => raizesHelpers.handleInput(event, 'dataMedicao', 'raizes', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="volume"
                                label="Quantidade"
                                className="inputDefault"
                                value={editItem.newItem.volume}
                                autoComplete='off'
                                onChange={(event) => raizesHelpers.handleInput(event, 'volume', 'raizes', dispatch)}
                                error={formSubmited && editItem.newItem.volume === ''}
                                helperText={formSubmited && editItem.newItem.volume === '' ? 'Campo obrigatório' : ''}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Divider className="dividerFull" />
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained"
                                    className="btn-primary"
                                    component="label"
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        <Divider className="dividerFull" />
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    raizesReducer: store.raizesReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
    placesReducer: store.placesReducer,
});

export default connect(mapStateToProps)(RaizesForm);