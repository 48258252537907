import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'

class UserFilter extends Component {

    render(){
        const { dispatch, usersReducer } = this.props
        const { filters } = usersReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="name" label="Nome" className="inputDefault" value={filters.users_Nome} onChange={(event) => dispatch(helpers.handleChangeFilter(event, "nome", "users"))}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="email" label="E-mail" className="inputDefault" value={filters.users_Email} onChange={(event) => dispatch(helpers.handleChangeFilter(event, "email", "users"))}/>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('user', true)}>
                                NOVO USUÁRIO
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={8}/>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(helpers.paginateUsers(filters, 1))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    usersReducer: store.usersReducer,
});

export default connect(mapStateToProps)(UserFilter);