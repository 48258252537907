import { palmitoActionTypes } from "../types/palmitoActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: {
    matriz: {},
    talhao: {},
    saida: {}
  },
  loading: false,
  documents: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0
  },
  filters: {
    numeroMatriz: '',
    dataCadastro: null,
    dataSaida: null,
    obito: null,
    tipo: '',
    tipoAmbiente: '',
    iluminacao: '',
  },
  options:{
    projecao: ['Geográfica', 'UTM - 20 S', 'UTM - 21 S', 'UTM - 22 S', 'UTM - 23 S', 'UTM - 24 S', 'UTM - 20 N', 'UTM - 21 N', 'UTM - 22 N'],
    tipo: ['pomar', 'natural'],
    saida: {
      tipo: ['semente', 'muda'],
      tipoSaida: ['doação', 'plantio'],
      localPlantio: ['área demarcada', 'talhão']
    },
    tipoAmbiente: ['alagado', 'parcialmente alagado', 'seco'],
    iluminacao: ['muita luz', 'média luz', 'pouca luz'],
  },
  editItem: {
    matriz:{
      numeroMatriz: '',
      dataCadastro: moment().format(),
      coordX: '',
      coordY: '',
      projecao: '23',
      EPE: '',
      obito: false,
      dataObito: null,
      coleta: [
        {
          dataColeta: moment().format(),
          qntCachos: 0,
          kgColetado: 0,
          sementesColetadas: 0,
          qntPlantadas: 0,
          qntBoiou: 0,
          qntGerminada: 0,
          qntDescartada: 0,
          taxaGerminacao: 0
        }
      ],
      status: true
    },
    talhao:{
      numeroTalhao: '', 
      dataCadastro: moment().format(),
      tipo: '', 
      tipoAmbiente: '', 
      iluminacao: '',
      area: [
          {
              coordX: '', 
              coordY: '', 
              EPE: '',
              projecao: '23'
          }
      ],
      areaTotal: 0,
      monitoramento: [
          {
              dataMonitoramento: moment().format(),
              qntMudasAte50: 0, 
              qntMudasAte100: 0, 
              qntMudasAte150: 0, 
              qntMudasAcima150: 0, 
              observacoes: '', 
          }
      ],
      status: true
    },
    saida: {
      numeroSaida: '', 
      dataSaida: moment().format(),
      quantidade: 0, 
      tipo: null,
      matriz: null,
      tipoSaida: null,
      localDestino: null, 
      licenca: null, 
      localPlantio: null, 
      talhao: null, 
      areaDemarcada: null, 
      status: true
    }
  }
};

export const palmitoReducer = (state = initialState, action) => {
  switch (action.type) {
    case palmitoActionTypes.GET_PALMITO_MATRIZ_REQUEST:
      return { ...state, listDocuments: {...state.listDocuments, matriz: initialState.listDocuments.matriz, loading: true}};
    case palmitoActionTypes.GET_PALMITO_TALHAO_REQUEST:
      return { ...state, listDocuments: {...state.listDocuments, talhao: initialState.listDocuments.talhao, loading: true}};
    case palmitoActionTypes.GET_PALMITO_SAIDA_REQUEST:
      return { ...state, listDocuments: {...state.listDocuments, saida: initialState.listDocuments.saida, loading: true}};
    case palmitoActionTypes.GET_PALMITO_MATRIZ_SUCCESS:
      return { ...state, listDocuments: {...state.listDocuments, matriz: action.places, loading: false }};
    case palmitoActionTypes.GET_PALMITO_TALHAO_SUCCESS:
      return { ...state, listDocuments: {...state.listDocuments, talhao: action.places, loading: false }};
    case palmitoActionTypes.GET_PALMITO_SAIDA_SUCCESS:
      return { ...state, listDocuments: {...state.listDocuments, saida: action.places, loading: false }};
    case palmitoActionTypes.GET_PALMITO_MATRIZ_FAILURE:
      return { ...state, listDocuments: {...state.listDocuments, matriz: initialState.listDocuments.matriz, loading: false }};
    case palmitoActionTypes.GET_PALMITO_TALHAO_FAILURE:
      return { ...state, listDocuments: {...state.listDocuments, talhao: initialState.listDocuments.talhao, loading: false }};
    case palmitoActionTypes.GET_PALMITO_SAIDA_FAILURE:
      return { ...state, listDocuments: {...state.listDocuments, saida: initialState.listDocuments.saida, loading: false }};

    case palmitoActionTypes.CREATE_PALMITO_MATRIZ_REQUEST:
    case palmitoActionTypes.CREATE_PALMITO_TALHAO_REQUEST:
    case palmitoActionTypes.CREATE_PALMITO_SAIDA_REQUEST:
    case palmitoActionTypes.UPDATE_PALMITO_MATRIZ_REQUEST:
    case palmitoActionTypes.UPDATE_PALMITO_TALHAO_REQUEST:
    case palmitoActionTypes.UPDATE_PALMITO_SAIDA_REQUEST:
    case palmitoActionTypes.LIST_PALMITO_MATRIZ_REQUEST:
    case palmitoActionTypes.LIST_PALMITO_TALHAO_REQUEST:
    case palmitoActionTypes.LIST_PALMITO_SAIDA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case palmitoActionTypes.CREATE_PALMITO_MATRIZ_SUCCESS:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case palmitoActionTypes.CREATE_PALMITO_TALHAO_SUCCESS:
    case palmitoActionTypes.CREATE_PALMITO_SAIDA_SUCCESS:
    case palmitoActionTypes.UPDATE_PALMITO_TALHAO_SUCCESS:
    case palmitoActionTypes.UPDATE_PALMITO_SAIDA_SUCCESS:
    case palmitoActionTypes.UPDATE_PALMITO_MATRIZ_SUCCESS:
    case palmitoActionTypes.LIST_PALMITO_MATRIZ_SUCCESS:
    case palmitoActionTypes.LIST_PALMITO_TALHAO_SUCCESS:
    case palmitoActionTypes.LIST_PALMITO_SAIDA_SUCCESS:
      return {
        ...state,
        documents: {list: action.list.docs, total: action.list.total, limit: action.list.limit, page: Number(action.list.page), pagesTotal: action.list.pages},
        editItem: initialState.editItem,
        loading: false
      };
    case palmitoActionTypes.UPDATE_PALMITO_CANCELED:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case palmitoActionTypes.CREATE_PALMITO_MATRIZ_FAILURE:
    case palmitoActionTypes.CREATE_PALMITO_TALHAO_FAILURE:
    case palmitoActionTypes.CREATE_PALMITO_SAIDA_FAILURE:
    case palmitoActionTypes.UPDATE_PALMITO_TALHAO_FAILURE:
    case palmitoActionTypes.UPDATE_PALMITO_MATRIZ_FAILURE:
    case palmitoActionTypes.UPDATE_PALMITO_SAIDA_FAILURE:
    case palmitoActionTypes.LIST_PALMITO_MATRIZ_FAILURE:
    case palmitoActionTypes.LIST_PALMITO_TALHAO_FAILURE:
    case palmitoActionTypes.LIST_PALMITO_SAIDA_FAILURE:
      return {
        ...state,
        loading: false
      };
    case palmitoActionTypes.EDIT_PALMITO:
      return { 
        ...state, 
        editItem: { ...state.editItem, [action.context]: action.places}
      };
    case palmitoActionTypes.HANDLE_PALMITO:
    case palmitoActionTypes.HANDLE_RADIOS_PALMITO:
    case palmitoActionTypes.HANDLE_INPUT_PALMITO:
      return {
        ...state,
        editItem: {
          ...state.editItem, 
          [action.context]: { 
            ...state.editItem[action.context],
            [action.target]: action.value
          } 
        }
      };
    case palmitoActionTypes.SET_PALMITO_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
    case palmitoActionTypes.SET_FILTER_DATE:
      return { ...state, filters: {...state.filters, [action.target]: moment(action.date).format()} };
    default:
      return state;
  }
};