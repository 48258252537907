import { especiesActions } from "../../actions/especiesActions"
import { especiesActionTypes } from "../../types/especiesActionTypes"

export const especiesHelpers = {
    paginate,
	list,
	edit,
	update,
	create,
	handleChangeFilter,
	getOne
}
function getOne(codigo){
	return dispatch => {
		dispatch(especiesActions.getOne(codigo))
	}
}
function list(name, qnt){
	return dispatch => {
		dispatch(especiesActions.list(name, qnt))
	}
}

function paginate(parameters, page){
	return dispatch => {
		dispatch(especiesActions.paginate(parameters, page))
	}
}

function edit(places, callback) {
	if(places){
		if(callback){ callback('especies') }
		return { type: especiesActionTypes.EDIT_ESPECIES, places }
	}else{
		return { type: especiesActionTypes.UPDATE_ESPECIES_CANCELED }
	}
}

function update(parameters, page, callback){
	return dispatch => {
		dispatch(especiesActions.update(parameters, page, callback))
	}
}

function create(parameters, page, callback){
	return dispatch => {
		dispatch(especiesActions.create(parameters, page, callback))
	}
}

function handleChangeFilter(event, target) {
	const value = event.target.value

	return dispatch => {
		dispatch(setFilter(value, target))
	}
}

function setFilter(value, target) {return { type: especiesActionTypes.SET_ESPECIES_FILTER, value, target }}