import { documentsHandler } from '../handlers/documentsHandler';
import { documentsActionTypes } from '../types/documentsActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const documentsActions = {
    list,
    paginate,
    create,
    update,
    upload,
    deleteItem
  };

function list() {
    return dispatch => {
        dispatch(request());
        documentsHandler.list()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error("Erro ao listar o arquivo. Tente novamente"));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar. Tente Novamente."));
        });
    };
    function request() { return { type: documentsActionTypes.GET_DOCUMENTS_REQUEST } }
    function success(places) { return { type: documentsActionTypes.GET_DOCUMENTS_SUCCESS, places } }
    function failure(error) { return { type: documentsActionTypes.GET_DOCUMENTS_FAILURE, error } }
}

function paginate(params, page) {
    return dispatch => {
        dispatch(request());
        documentsHandler.paginate(params, page)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: documentsActionTypes.LIST_DOCUMENTS_REQUEST } }
    function success(list) { return { type: documentsActionTypes.LIST_DOCUMENTS_SUCCESS, list } }
    function failure(error) { return { type: documentsActionTypes.LIST_DOCUMENTS_FAILURE, error } }
}

function create(params, page, callback) {
    return dispatch => {
        dispatch(request());
        documentsHandler.create(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: documentsActionTypes.CREATE_DOCUMENTS_REQUEST } }
    function success(list) { return { type: documentsActionTypes.CREATE_DOCUMENTS_SUCCESS, list } }
    function failure(error) { return { type: documentsActionTypes.CREATE_DOCUMENTS_FAILURE, error } }
}

function update(params, page, callback) {
    return dispatch => {
        dispatch(request());
        documentsHandler.update(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: documentsActionTypes.UPDATE_DOCUMENTS_REQUEST } }
    function success(list) { return { type: documentsActionTypes.UPDATE_DOCUMENTS_SUCCESS, list } }
    function failure(error) { return { type: documentsActionTypes.UPDATE_DOCUMENTS_FAILURE, error } }
}

function deleteItem(params, page, callback) {
    return dispatch => {
        dispatch(request());
        documentsHandler.deleteItem(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error("Erro ao deletar o arquivo. Tente novamente"));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Erro ao deletar o arquivo. Tente novamente"));
        });
    }
    function request() { return { type: documentsActionTypes.DELETE_DOCUMENTS_REQUEST } }
    function success(list) { return { type: documentsActionTypes.DELETE_DOCUMENTS_SUCCESS, list } }
    function failure(error) { return { type: documentsActionTypes.DELETE_DOCUMENTS_FAILURE, error } }
}

function upload(file, fileName, id) {
    return dispatch => {
        dispatch(request());
        documentsHandler.upload(file, fileName, id)
        .then(response => {
                dispatch(success(response));
                // if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error("Erro ao enviar o arquivo. Tente novamente"));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Erro ao enviar o arquivo. Tente novamente"));
        });
    };
    function request() { return { type: documentsActionTypes.UPLOAD_DOCUMENTS_REQUEST } }
    function success(list) { return { type: documentsActionTypes.UPLOAD_DOCUMENTS_SUCCESS, list } }
    function failure(error) { return { type: documentsActionTypes.UPLOAD_DOCUMENTS_FAILURE, error } }
}