import { documentsActions } from "../../actions/documentsActions"
import { documentsActionTypes } from "../../types/documentsActionTypes"

export const documentsHelpers = {
    paginate,
	list,
	edit,
	deleteItem,
	update,
	create,
	handleChangeFilter,
	handleTrapSize,
	handlePosition,
	addNewPosition,
	uploadFile
}

function paginate(parameters, page){
	return dispatch => {
		dispatch(documentsActions.paginate(parameters, page))
	}
}

function list(){
	return dispatch => {
		dispatch(documentsActions.list())
	}
}

function edit(places, callback) {
	if(places){
		if(callback){ callback('documents') }
		return { type: documentsActionTypes.EDIT_DOCUMENTS, places }
	}else{
		return { type: documentsActionTypes.UPDATE_DOCUMENTS_CANCELED }
	}
}

function deleteItem(parameters) {
	return dispatch => {
		dispatch(documentsActions.deleteItem(parameters))
	}
}

function update(parameters, page, callback){
	return dispatch => {
		dispatch(documentsActions.update(parameters, page, callback))
	}
}

function create(parameters, page, callback){
	return dispatch => {
		dispatch(documentsActions.create(parameters, page, callback))
	}
}

function handleChangeFilter(event, target) {
	const value = event.target.value

	return dispatch => {
		dispatch(setFilter(value, target))
	}
}

function setFilter(value, target) {return { type: documentsActionTypes.SET_DOCUMENTS_FILTER, value, target }}

function handleTrapSize(myValue, target, trapSize, dispatch) {
	let value = Number(myValue.target.value)
	let trapSizeObj = trapSize
	trapSizeObj[target] = value
	return dispatch(saveStore(trapSizeObj, 'trapSize', documentsActionTypes.HANDLE_INPUT_DOCUMENTS));

}

function saveStore(value, target, context) { return { type: context, value, target } }

function handlePosition(module, index, target, myValue, dispatch) {

	let newObj = module;

	newObj[index][target] = myValue

	if(target === 'status' && myValue === false) {
		newObj[index]['removeDate'] = new Date()
	}else{
		newObj[index]['removeDate'] = ''
	}

	return dispatch(saveStore(newObj, 'position', documentsActionTypes.HANDLE_INPUT_DOCUMENTS));
}

function addNewPosition(module, dispatch) {
	let newObj = module;
	const emptyModule = {addDate: new Date(), coordX: "", coordY: "", EPE: "", projecao: "--", status: true, removeDate: ""}

	newObj.unshift(emptyModule)

	return dispatch(saveStore(newObj, 'position', documentsActionTypes.HANDLE_INPUT_DOCUMENTS));
}

function uploadFile(event, id, dispatch) {
	const file = event.target.files[0]
	const fileName = event.target.files[0].name

	return dispatch(documentsActions.upload(file, fileName, id))
}