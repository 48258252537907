import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, InputLabel, Select, FormControl, MenuItem, Divider } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers'

import FilterDate from './filterDate'


class Filter extends Component {

    render(){
        const { dispatch, fauna, projects, writeModuleVerify } = this.props

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    displayEmpty
                                    value={fauna.filters.cardType}
                                    onChange={(event) => dispatch(helpers.handleChangeFilter(event, "cardType", "fauna"))}
                                >
                                    <MenuItem selected value={'todos'}>{'todos'}</MenuItem>
                                {fauna.selectOptions.cardTypeFilter.map((type, index) => {
                                    return <MenuItem key={index} value={type}>{type}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Classe</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                value={fauna.filters.classType}
                                onChange={(event) => dispatch(helpers.handleChangeFilter(event, "classType", "fauna"))}
                                >
                                {fauna.selectOptions.classType.map((type, index) => {
                                    if(type === "Todos"){
                                        return <MenuItem key='0' value={false}>{type}</MenuItem>
                                    }else{
                                        return <MenuItem key={index} value={type}>{type}</MenuItem>
                                    }
                                })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField id="numFicha" label="Nº da Ficha" className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "numeroFicha", "fauna"))} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField id="numAnimal" label="Nº do Animal" className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "numeroAnimal", "fauna"))} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField id="nomeCientifico" label="Nome Científico" className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "nomeCientifico", "fauna"))} />
                        </Grid>
                        <FilterDate faunaProps={fauna.filters} dispatch={dispatch}/>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            {writeModuleVerify && 
                                <Button variant="contained" className="btn-secondary" onClick={() => dispatch(faunaHelpers.faunaDetails())}>
                                    NOVA FICHA
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12} md={3}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" download onClick={() => dispatch(faunaHelpers.exportData(fauna.filters, projects.selectedProject))}>
                                EXPORTAR
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(faunaHelpers.listFauna(fauna.filters, 1, projects.selectedProject ))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    fauna: store.faunaReducer,
    projects: store.projectsReducer
});

export default connect(mapStateToProps)(Filter);