import { usersReducer } from './usersReducer';
import { loginReducer } from './loginReducer';
import { alertReducer } from './alertReducer';
import { locationReducer } from './locationReducer';
import { faunaReducer } from './faunaReducer';
import { paramsReducer } from './paramsReducer';
import { projectsReducer } from './projectsReducer';
import { placesReducer } from './placesReducer';
import { instListaEspeciesReducer } from './instListaEspeciesReducer';
import { especiesReducer } from './especiesReducer';
import { trapsReducer } from './trapsReducer';
import { documentsReducer } from './documentsReducer';
import { palmitoReducer } from './palmitoReducer';
import { viveirosReducer } from './viveirosReducer';
import { toraReducer } from './toraReducer';
import { lenhaReducer } from './lenhaReducer';
import { raizesReducer } from './raizesReducer';
import { areaPlantioReducer } from './areaPlantioReducer';
import { areaMonitoramentoReducer } from './areaMonitoramentoReducer';
import { auditReducer } from './auditReducer';
import { floraEspeciesReducer } from './floraEspeciesReducer';
import { commonReducer } from './commonReducer';
import { combineReducers } from 'redux';

export const Reducers = combineReducers({
  loginReducer: loginReducer,
  usersReducer: usersReducer,
  auditReducer: auditReducer,
  documentsReducer: documentsReducer,
  palmitoReducer: palmitoReducer,
  viveirosReducer: viveirosReducer,
  raizesReducer: raizesReducer,
  areaPlantioReducer: areaPlantioReducer,
  areaMonitoramentoReducer: areaMonitoramentoReducer,
  lenhaReducer: lenhaReducer,
  toraReducer: toraReducer,
  floraEspeciesReducer: floraEspeciesReducer,
  trapsReducer: trapsReducer,
  especiesReducer: especiesReducer,
  instListaEspeciesReducer: instListaEspeciesReducer,
  placesReducer: placesReducer,
  projectsReducer: projectsReducer,
  faunaReducer: faunaReducer,
  paramsReducer: paramsReducer,
  locationReducer: locationReducer,
  alertReducer: alertReducer,
  commonReducer: commonReducer,
});