import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider} from "@material-ui/core"
import { documentsHelpers} from '../../helpers/documents/documentsHelpers'

class filter extends Component {

    render(){
        const { dispatch, documentsReducer } = this.props
        const { filters } = documentsReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="gaiaCode" label="Código Gaia" className="inputDefault" value={filters.gaiaCode} onChange={(event) => dispatch(documentsHelpers.handleChangeFilter(event, "gaiaCode"))}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('documents', true)}>
                                NOVO DOCUMENTO
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(documentsHelpers.paginate(filters, 1))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    documentsReducer: store.documentsReducer,
    paramsReducer: store.paramsReducer,
});

export default connect(mapStateToProps)(filter);