import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core";
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import { helpers } from '../../helpers/helpers';

const AccordionBasic = ({ selectedCard, selectOptions, selectProject, classTypes, dispatch, loggedUser, validateForm, ringColors, projects, login }) => {
  const validRing = ringColors?.some(ring => ring.color === selectedCard.corAnilha)
  const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

  
  const handleInputWithUpdate = async (event, target) => {
    await faunaHelpers.handleInput(event, target, dispatch)
    if(target === 'cardType'){
      handleCardNumber(false, event.target.value, false)
    }else if(target === 'projectId'){
      handleCardNumber(false, false, event.target.value)
    } else{
      handleCardNumber(false, event.target.value)
    }
  }

  const handleCardNumber = (event, cardType, projectId) => faunaHelpers.handleCardNumber(event ? event : selectedCard.numeroFicha, projects, selectedCard, classTypes, dispatch, cardType, false, projectId);

  return (
    <div className='basicInformation box-content-primary'>
      <Grid container spacing={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              id="numeroFicha"
              label="Nº da Ficha"
              className="inputDefault inputBold"
              value={selectedCard.numeroFicha}
              disabled={!!(selectedCard._id && selectedCard._id !== "") || !writeModuleVerify ? true : false}
              onChange={(event) => handleCardNumber(event.target.value)}
              error={validateForm && selectedCard.numeroFicha === ''}
              helperText={validateForm && selectedCard.numeroFicha === '' ? 'Campo obrigatório' : ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl className="inputDefault">
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select"
                value={selectedCard.cardType}
                onChange={(event) => handleInputWithUpdate(event, 'cardType')}
                disabled={!!(selectedCard._id && selectedCard._id !== "") || (selectedCard?.cardType === 'Recaptura') || !writeModuleVerify ? true : false}
              >
                {(selectedCard?.cardType === 'Recaptura' && selectedCard?.numeroAnimal !== "") &&
                  <MenuItem key={'Recaptura'} value={'Recaptura'}>{'Recaptura'}</MenuItem>
                }
                {selectOptions.cardType.map((type, index) => {
                  return <MenuItem key={index} value={type}>{type}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl className="inputDefault">
              <InputLabel id="demo-simple-select-label">Projeto</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select"
                value={selectedCard.projectId}
                onChange={(event) => handleInputWithUpdate(event, 'projectId', dispatch)}
                disabled={(!(helpers.isSuperUser(login)) || selectProject[0]?.projectId)}
              >
                <MenuItem value="">--</MenuItem>
                {projects.listProjects?.map(project => {
                  return <MenuItem key={project._id} value={project.projectId}>{project.projectName}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Divider className="dividerFull" />
          <Grid item xs={12} md={4}>
            <TextField
              id="numeroAnimal"
              label="Nº do Animal"
              className="inputDefault"
              value={selectedCard.numeroAnimal}
              onChange={(event) => faunaHelpers.handleInput(event, 'numeroAnimal', dispatch)}
              disabled={(selectedCard._id && selectedCard._id !== "") || selectedCard.numeroCaptura || !writeModuleVerify ? true : false || selectedCard.marcacao === 'Sem marcação'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl className="inputDefault">
              <InputLabel id="demo-simple-select-label">Marcação</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCard.marcacao}
                onChange={(event) => faunaHelpers.handleMarcacao(event, 'marcacao', dispatch)}
                disabled={((selectedCard?.cardType === "Recaptura" && selectedCard?.isRecaptura) || !writeModuleVerify) ? true : false}
              >
                {selectOptions.marcacaoType.map((type, index) => {
                  return <MenuItem key={index} value={type}>{type}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          {selectedCard?.cardType === 'Recaptura' &&
            <Grid item xs={12} md={4}>
              <TextField
                id="numeroCaptura"
                label="Numero da ficha original"
                className="inputDefault"
                value={selectedCard.numeroCaptura}
                disabled={selectedCard?.isRecaptura}
                onChange={(event) => faunaHelpers.handleInput(event, 'numeroCaptura', dispatch)}
              />
            </Grid>
          }
          {selectedCard.marcacao && selectedCard.marcacao === "Anilha" &&
            <Grid item xs={12} md={4}>
              <FormControl className="inputDefault">
                <InputLabel id="demo-simple-select-label">Cor da anilha</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                  value={selectedCard.corAnilha}
                  onChange={(event) => faunaHelpers.handleInput(event, 'corAnilha', dispatch)}
                  disabled={((selectedCard?.cardType === "Recaptura" && selectedCard?.isRecaptura) || !writeModuleVerify) ? true : false}
                >
                  <MenuItem value="">--</MenuItem>

                  {!validRing && selectedCard.corAnilha !== "--" &&
                    <MenuItem value={selectedCard.corAnilha}>{selectedCard.corAnilha}</MenuItem>
                  }
                  {ringColors?.map(ring => {
                    return <MenuItem key={ring._id} value={ring.color}>{ring.color}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
          }
          {selectedCard.classe && selectedCard.classe === "Peixes" &&
            <Grid item xs={12} md={4}>
              <TextField
                id="corMarcacao"
                label="Cor da marcação"
                className="inputDefault"
                value={selectedCard.corMarcacao}
                onChange={(event) => faunaHelpers.handleInput(event, 'corMarcacao', dispatch)}
                disabled={((selectedCard?.cardType === "Recaptura" && selectedCard?.isRecaptura) || !writeModuleVerify) ? true : false}
              />
            </Grid>
          }
          <Grid item xs={12} md={4}>
            <TextField
              id="radiotransmissor"
              label="Radiotransmissor"
              className="inputDefault"
              value={selectedCard.radiotransmissor}
              onChange={(event) => faunaHelpers.handleInput(event, 'radiotransmissor', dispatch)}
              disabled={((selectedCard?.cardType === "Recaptura" && selectedCard?.isRecaptura) || !writeModuleVerify) ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="frequencia"
              label="Frequência"
              className="inputDefault"
              value={selectedCard.frequencia}
              onChange={(event) => faunaHelpers.handleInput(event, 'frequencia', dispatch)}
              disabled={((selectedCard?.cardType === "Recaptura" && selectedCard?.isRecaptura) || !writeModuleVerify) ? true : false}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default AccordionBasic