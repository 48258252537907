import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, InputAdornment, InputLabel, FormControl, Select, MenuItem, FormHelperText } from "@material-ui/core"
import { lenhaHelpers } from '../../../helpers/flora/lenha/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class LenhaSaidaForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    formValidator = () => {
        const { lenhaReducer } = this.props
        const { lenhaSaida } = lenhaReducer
        const { editItem } = lenhaSaida
        const { data, volume, tipo } = editItem

        if (data !== null && volume !== '' && tipo !== '') {
            return true
        } else {
            return false
        }
    }

    submitForm = () => {
        const { lenhaReducer, dispatch, closeModal, projectsReducer } = this.props
        const { lenhaSaida } = lenhaReducer
        const { editItem, filters } = lenhaSaida
        this.setState({ formSubmited: true })

        if (this.formValidator() === false) return

        if (editItem._id && editItem._id !== '') {
            dispatch(lenhaHelpers.updateLenhaSaida(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        } else {
            dispatch(lenhaHelpers.createLenhaSaida(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        }
    }

    render() {
        const { dispatch, lenhaReducer, projectsReducer, closeModal } = this.props
        const { lenhaSaida } = lenhaReducer
        const { editItem, filters, documents, volumeDisponivel } = lenhaSaida
        const { formSubmited } = this.state

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data"
                                    value={editItem.data}
                                    onChange={(event) => lenhaHelpers.handleSaidaInput(event, 'data', 'lenhaSaida', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault" required error={formSubmited && editItem.tipo === ''}>
                                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={editItem.tipo}
                                    onChange={(event) => lenhaHelpers.handleSaidaInput(event, 'tipo', 'lenhaSaida', dispatch)}
                                >
                                    <MenuItem selected value={''}>{'--'}</MenuItem>
                                    <MenuItem selected value={'Saída'}>{'Saída'}</MenuItem>
                                    <MenuItem selected value={'Uso interno'}>{'Uso interno'}</MenuItem>
                                    <MenuItem selected value={'Deterioração'}>{'Deterioração'}</MenuItem>
                                </Select>
                                {formSubmited && editItem.tipo === '' && <FormHelperText>Campo obrigatório</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="destino"
                                label="Destino"
                                className="inputDefault"
                                value={editItem.destino}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.handleSaidaInput(event, 'destino', 'lenha', dispatch)}
                                error={formSubmited && editItem.destino === ''}
                                helperText={formSubmited && editItem.destino === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="licenca"
                                label="Licença"
                                className="inputDefault"
                                value={editItem.licenca}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.handleSaidaInput(event, 'licenca', 'lenha', dispatch)}
                                error={formSubmited && editItem.licenca === ''}
                                helperText={formSubmited && editItem.licenca === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="volume"
                                label="Volume de Saída"
                                className="inputDefault"
                                value={editItem.volume}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleSaidaInputNumber(event, 'volume', 'lenhaSaida', dispatch)}
                                error={(parseFloat(editItem.volume) > volumeDisponivel) || (formSubmited && editItem.volume === '')}
                                helperText={parseFloat(editItem.volume) > volumeDisponivel ? 'O Volume deve ser menor ou igual ao volume disponível.' : formSubmited && editItem.volume === '' ? 'Campo obrigatório' : ''}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="volumeDisponivel"
                                label="Volume Total Disponível"
                                className="inputDefault"
                                value={volumeDisponivel}
                                autoComplete='off'
                                disabled={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Divider className="dividerFull" />
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained"
                                    className="btn-primary"
                                    component="label"
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        {editItem._id && editItem._id !== '' &&
                            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                                <Box padding={10}>
                                    <Button variant="contained"
                                        className="btn-secondary"
                                        component="label"
                                        startIcon={<DeleteForeverIcon />}
                                        onClick={(event) => lenhaHelpers.deleteLenhaSaida(documents.page, projectsReducer.selectedProject, editItem._id, closeModal, dispatch)}> Excluir </Button>
                                </Box>
                            </Box>
                        }
                        <Divider className="dividerFull" />
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    lenhaReducer: store.lenhaReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
    placesReducer: store.placesReducer,
});

export default connect(mapStateToProps)(LenhaSaidaForm);