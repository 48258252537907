export const floraEspeciesActionTypes = {

    // Viveiros geral

    HANDLE_INPUT_FLORA_ESPECIES: "HANDLE_INPUT_FLORA_ESPECIES",
    HANDLE_RADIOS_FLORA_ESPECIES: "HANDLE_RADIOS_FLORA_ESPECIES",
    HANDLE_FLORA_ESPECIES: "HANDLE_FLORA_ESPECIES",
    SET_FILTER_DATE_INIT: "SET_FILTER_DATE_INIT",
    SET_FILTER_DATE_END: "SET_FILTER_DATE_END",
    
    SET_FLORA_ESPECIES_FILTER: "SET_FLORA_ESPECIES_FILTER",
    EDIT_FLORA_ESPECIES: "EDIT_FLORA_ESPECIES",
    DELETE_FLORA_ESPECIES: "DELETE_FLORA_ESPECIES",
    UPDATE_FLORA_ESPECIES_CANCELED: "UPDATE_FLORA_ESPECIES_CANCELED",
    DELETE_FLORA_ESPECIES_CANCELED: "DELETE_FLORA_ESPECIES_CANCELED",

    // Viveiros RESGATE

    GET_FLORA_ESPECIES_REQUEST: "GET_FLORA_ESPECIES_REQUEST",
    GET_FLORA_ESPECIES_SUCCESS: "GET_FLORA_ESPECIES_SUCCESS",
    GET_FLORA_ESPECIES_FAILURE: "GET_FLORA_ESPECIES_FAILURE",

    CREATE_FLORA_ESPECIES_REQUEST: "CREATE_FLORA_ESPECIES_REQUEST",
    CREATE_FLORA_ESPECIES_SUCCESS: "CREATE_FLORA_ESPECIES_SUCCESS",
    CREATE_FLORA_ESPECIES_FAILURE: "CREATE_FLORA_ESPECIES_FAILURE",

    UPDATE_FLORA_ESPECIES_REQUEST: "UPDATE_FLORA_ESPECIES_REQUEST",
    UPDATE_FLORA_ESPECIES_SUCCESS: "UPDATE_FLORA_ESPECIES_SUCCESS",
    UPDATE_FLORA_ESPECIES_FAILURE: "UPDATE_FLORA_ESPECIES_FAILURE",

    LIST_FLORA_ESPECIES_REQUEST: "LIST_FLORA_ESPECIES_REQUEST",
    LIST_FLORA_ESPECIES_SUCCESS: "LIST_FLORA_ESPECIES_SUCCESS",
    LIST_FLORA_ESPECIES_FAILURE: "LIST_FLORA_ESPECIES_FAILURE",

    EXPORT_FLORA_ESPECIES_REQUEST: "EXPORT_FLORA_ESPECIES_REQUEST",
    EXPORT_FLORA_ESPECIES_SUCCESS: "EXPORT_FLORA_ESPECIES_SUCCESS",
    EXPORT_FLORA_ESPECIES_FAILURE: "EXPORT_FLORA_ESPECIES_FAILURE",

};