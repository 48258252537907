import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableContainer, Table, TableBody, Button, Divider } from '@material-ui/core'
import { Pagination, Alert } from '@material-ui/lab';
import Header from '../../Components/headerAlter'
import Filter from '../../Components/audit/filter'
import CustomModal from '../../Components/customModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { tz } from 'moment-timezone';

import { history } from '../../helpers/history'
import { helpers } from '../../helpers/helpers'
import { auditHelpers } from '../../helpers/audit/helpers'
import { placesHelpers } from '../../helpers/places/placesHelpers';

class Audit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: { showModal: false, modalType: undefined }
    }
  }

  componentDidMount() {
    const { dispatch, auditReducer, projectsReducer } = this.props
    dispatch(helpers.saveLocation(history.location.pathname));
    dispatch(auditHelpers.paginateAudit(auditReducer.audit.filters, auditReducer.audit.documents.page, projectsReducer.selectedProject))
    dispatch(placesHelpers.listPlaces(projectsReducer.selectedProject, '2'));
  }

  handleModal = (clear) => {
    const { modal } = this.state
    const { dispatch } = this.props
    this.setState({ modal: { showModal: !modal.showModal, modalType: 'audit' } })
    if (clear) {
      dispatch(auditHelpers.editCard('audit', false))
    }
  }

  translateType = (type) => {
    switch (type) {
      case 'create': return 'Criado'
      case 'update': return 'Atualizado'
      case 'delete': return 'Deletado'
      case 'login': return 'Acesso'
      default: return type
    }
  }

  translateModule = (module) => {
    switch (module) {
      case 'users': return 'Usuários'
      case 'projects': return 'Projetos'
      case 'fauna': return 'Fauna'
      case 'login': return 'Acesso'
      default: return module
    }
  }


  render() {
    const { auditReducer, dispatch, alert, pageContext, pageTitle, projectsReducer } = this.props
    const { modal } = this.state
    const { audit, editItem } = auditReducer
    const { documents, filters } = audit
    const { page, pagesTotal } = documents
    return (
      <div className='mainContainer'>
        <Header title={pageTitle} />
        <div className='basicContainer'>
          {alert && alert.type === 'alert-danger' && alert.message &&
            <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
          }
          {alert && alert.type === 'alert-success' && alert.message &&
            <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
          }
          <Filter context={'audit'} handleModal={this.handleModal} />
          {documents && documents.list && documents.list.length > 0 &&
            <Grid container>
              <TableContainer className="customTable paramsTable">
                <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <TableHead className="tableHead">
                    <Grid component={'tr'} container className="parametersTable">
                      <Grid component={'th'} item xs={12} md={2}>Data</Grid>
                      <Grid component={'th'} item xs={12} md={3}>Usuário</Grid>
                      <Grid component={'th'} item xs={12} md={3}>Email</Grid>
                      <Grid component={'th'} item xs={12} md={2}>Modulo</Grid>
                      <Grid component={'th'} item xs={12} md={1}>Tipo</Grid>
                      <Grid component={'th'} item xs={12} md={1}></Grid>
                    </Grid>
                  </TableHead>
                  <TableBody>
                    {documents.list.map((item, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Grid component={'tr'} container className="parametersTable" key={index} id={labelId}>
                          <Grid component={'td'} item xs={12} md={2}>{item?.createdAt ? moment(item?.createdAt).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm') : ''}</Grid>
                          <Grid component={'td'} item xs={12} md={3}>{item?.user}</Grid>
                          <Grid component={'td'} item xs={12} md={3}>{item?.email}</Grid>
                          <Grid component={'td'} item xs={12} md={2}>{item?.module ? this.translateModule(item.module) : ''}</Grid>
                          <Grid component={'td'} item xs={12} md={1}>{item?.type ? this.translateType(item.type) : ''}</Grid>
                          <Grid component={'td'} item xs={12} md={1}>
                            <Button
                              variant="contained"
                              className="btn-secondary btn-innerTable"
                              onClick={() => dispatch(auditHelpers.editCard('audit', item, this.handleModal))}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider className="dividerFull" />
              <Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, filters, page, 'audit', projectsReducer.selectedProject))} />
            </Grid>
          }
          {documents && documents.list && documents.list.length === 0 &&
            <Alert className="alert alert-warning" severity="warning">Não foram encontrados resultados com os filtros aplicados.</Alert>
          }
        </div>
        {modal.showModal &&
          <CustomModal modalFn={this.handleModal} modalType={modal.modalType} />
        }
      </div>
    )
  }
}

const mapStateToProps = store => ({
  auditReducer: store.auditReducer,
  projectsReducer: store.projectsReducer,
  paramsReducer: store.paramsReducer,
  auth: store.loginReducer.auth,
  alert: store.alertReducer
});

export default connect(mapStateToProps)(Audit);