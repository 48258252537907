import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core"
import { auditHelpers } from '../../helpers/audit/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import { tz } from 'moment-timezone';

class AuditForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    translateType = (type) => {
        switch (type) {
            case 'create': return 'Criado'
            case 'update': return 'Atualizado'
            case 'delete': return 'Deletado'
            case 'login': return 'Acesso'
            default: return type
        }
    }

    translateModule = (module) => {
        switch (module) {
            case 'users': return 'Usuários'
            case 'projects': return 'Projetos'
            case 'fauna': return 'Fauna'
            case 'login': return 'Acesso'
            default: return module
        }
    }

    render() {
        const { dispatch, auditReducer, closeModal } = this.props
        const { audit } = auditReducer
        const { editItem } = audit
        const { formSubmited } = this.state

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy HH:mm"
                                    margin="normal"
                                    label="Data"
                                    value={editItem?.createdAt ? moment(editItem?.createdAt).tz('America/Sao_Paulo') : null}
                                    disabled={true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="user"
                                label="Usuário"
                                className="inputDefault"
                                value={editItem?.user}
                                autoComplete='off'
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="email"
                                label="Email"
                                className="inputDefault"
                                value={editItem?.email}
                                autoComplete='off'
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="type"
                                label="Tipo"
                                className="inputDefault"
                                value={editItem?.type ? this.translateType(editItem?.type) : ''}
                                autoComplete='off'
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="module"
                                label="Módulo"
                                className="inputDefault"
                                value={editItem?.module ? this.translateModule(editItem?.module) : ''}
                                autoComplete='off'
                                disabled={true}
                            />
                        </Grid>

                        <Divider className="dividerFull" />

                        <Grid item xs={12} md={12}>
                            <TextField
                                id="obsAdicionais"
                                label="Detalhes"
                                className="inputDefault"
                                multiline
                                minRows={2}
                                maxRows={20}
                                value={editItem?.document && editItem?.document !== null && editItem?.document.length > 0 ? JSON.stringify(editItem?.document[0]): ''}
                            />
                        </Grid>

                        <Divider className="dividerFull" />


                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    auditReducer: store.auditReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
    placesReducer: store.placesReducer,
});

export default connect(mapStateToProps)(AuditForm);