import React from 'react';
import { Grid } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';  
import { areaMonitoramentoHelpers } from '../../../helpers/flora/areaMonitoramento/helpers'

const FilterDate = ({ filterProps, context, dispatch}) => {
    
    const handleDateChangeInit = (date) => {
        dispatch(areaMonitoramentoHelpers.handleDateChangeInit(new Date(date), context));
    };
    
    const handleDateChangeEnd = (date) => {
        dispatch(areaMonitoramentoHelpers.handleDateChangeEnd(new Date(date), context));
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} md={4}>
                <KeyboardDatePicker
                    className="inputDefault"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="De"
                    value={filterProps.initDate}
                    onChange={handleDateChangeInit}
                    KeyboardButtonProps={{
                        'aria-label': 'Alterar data',
                    }}
                    invalidDateMessage={'Data inválida'}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <KeyboardDatePicker
                    className="inputDefault"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="à"
                    value={filterProps.endDate}
                    onChange={handleDateChangeEnd}
                    KeyboardButtonProps={{
                        'aria-label': 'Alterar data',
                    }}
                    invalidDateMessage={'Data inválida'}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default FilterDate