import { actionTypes } from "../types/actionTypes";


const initialState = {
  listPlaces: null,
  loading: false,
  places: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0
  },
  filters: {
    name: ''
  },
  options:{
    status: ['Demarcadas', 'Retirada de mudas', 'Roçado', 'Área Suprimida', 'Área Limpa', 'Outras áreas']
  },
  editPlace: {
    projectId: "",
    name: "",
    ambiente: "",
    status: true,
    places: [
      {
        coodX: "",
        coodY: "",
        EPE: ""
      }
    ],
    placeType: 1,
    umidadeDoAmbiente: [
      {description: "Seco", status: false},
      {description: "Úmido'", status: false},
      {description: "Super Úmido", status: false}
    ],
    sombreamentoDoAmbiente: [
      {description: "Sombra", status: false},
      {description: "Meia sombra", status: false},
      {description: "Luz intensa", status: false}
    ],
    tratamentoPreliminar: [
      {description: "Cipó", status: false},
      {description: "Taquara", status: false},
      {description: "Gramíneas", status: false}
    ],
    tipologiaVegetal: [
      {description: "Mata - Inicial", status: false},
      {description: "Mata - Avançado", status: false},
      {description: "Mata - Médio", status: false},
      {description: "Veg. Pioneira", status: false},
      {description: "Solo exposto", status: false}
    ],
    placeStatus: "",
    area: "",
  }
};

export const placesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PLACES_REQUEST:
      return { ...state, listPlaces: state.listPlaces, loading: true };
    case actionTypes.GET_PLACES_SUCCESS:
      return { ...state, listPlaces: action.places, loading: false };
    case actionTypes.GET_PLACES_FAILURE:
      return { ...state, listPlaces: state.listFauna, loading: false };

    case actionTypes.CREATE_PLACES_REQUEST:
    case actionTypes.UPDATE_PLACES_REQUEST:
    case actionTypes.LIST_PLACES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_PLACES_SUCCESS:
    case actionTypes.UPDATE_PLACES_SUCCESS:
    case actionTypes.LIST_PLACES_SUCCESS:
      return {
        ...state,
        places: {list: action.listPlaces.docs, total: action.listPlaces.total, limit: action.listPlaces.limit, page: Number(action.listPlaces.page), pagesTotal: action.listPlaces.pages},
        editPlace: initialState.editPlace,
        loading: false
      };
    case actionTypes.UPDATE_PLACES_CANCELED:
      return {
        ...state,
        editPlace: initialState.editPlace,
        loading: false
      };
    case actionTypes.CREATE_PLACES_FAILURE:
    case actionTypes.UPDATE_PLACES_FAILURE:
    case actionTypes.LIST_PLACES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case actionTypes.EDIT_PLACE:
      return { ...state, editPlace: action.places };
    case actionTypes.HANDLE_PLACES:
    case actionTypes.HANDLE_RADIOS_PLACES:
    case actionTypes.HANDLE_INPUT_PLACE:
      return {
        ...state,
        editPlace: {...state.editPlace, [action.target]: action.value}
      };
    case actionTypes.HANDLE_MODULES_PLACE:
      return {
        ...state,
        editPlace: {...state.editPlace, modules: action.value}
      };
    case actionTypes.HANDLE_GEODATA_PLACE:
      return {
        ...state,
        editPlace: {...state.editPlace, geoData: {...state.editPlace.geoData, [action.target]: action.value}}
      };
    case actionTypes.SET_PLACES_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
    default:
      return state;
  }
};