import { actionTypes } from "../types/actionTypes";

const initialState = {
  listOptions: {
    ringColors: [],
    trapType: [],
    environmentType: [],
    caracFlora: [],
    orgEmissor: [],
    documentsType: [],
    faunaClass: []
  },
  listParams: [],
  loading: false,
  page: 1,
  pagesTotal: 1,
  fields: {
    _id: null,
    color: '',
    trapType: '',
    environmenttype: '',
    modulo: '',
    short: '',
    description: '',
    name: '',
    classe: '',
    status: true
  },
  selectOptions: {
    status: [{label: 'ativo', value: true}, {label: 'inativo', value: false}],
  }
};

export const paramsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_OPTIONS_PARAMS_SUCCESS:
      return { ...state, listOptions: {...state.listOptions, [action.route]: action.list}, loading: false };
    case actionTypes.UPDATE_PARAMS_REQUEST:
    case actionTypes.CREATE_PARAMS_REQUEST:
    case actionTypes.LIST_PARAMS_REQUEST:
      return { ...state, listParams: state.listParams, loading: true, fields: initialState.fields };
    case actionTypes.UPDATE_PARAMS_SUCCESS:
    case actionTypes.CREATE_PARAMS_SUCCESS:
    case actionTypes.LIST_PARAMS_SUCCESS:
      return { ...state, listParams: action.list.docs ? action.list.docs : action.list, page: Number(action.list.page), pagesTotal: action.list.pages, loading: false, fields: initialState.fields };
    case actionTypes.UPDATE_PARAMS_FAILURE:
    case actionTypes.CREATE_PARAMS_FAILURE:
    case actionTypes.LIST_PARAMS_FAILURE:
      return { ...state, listParams: state.listParams, loading: false };
    case actionTypes.SET_PARAMS_FIELD:
      return { ...state, loading: false, fields: {...state.fields, [action.target]: action.value} };
    case actionTypes.EDIT_PARAMS:
      return { ...state, fields: action.row, loading: false}
    default:
      return state;
  }
};