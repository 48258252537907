import { trapsActions } from "../../actions/trapsActions"
import { trapsActionTypes } from "../../types/trapsActionTypes"

export const trapsHelpers = {
	list,
    paginate,
	edit,
	update,
	create,
	handleChangeFilter,
	handleTrapSize,
	handlePosition,
	addNewPosition
}

function list(projectId){
	return dispatch => {
		dispatch(trapsActions.list(projectId))
	}
}

function paginate(parameters, projectId, page){
	return dispatch => {
		dispatch(trapsActions.paginate(parameters, projectId, page))
	}
}

function edit(places, callback) {
	if(places){
		if(callback){ callback('traps') }
		return { type: trapsActionTypes.EDIT_TRAPS, places }
	}else{
		return { type: trapsActionTypes.UPDATE_TRAPS_CANCELED }
	}
}

function update(parameters, page, callback){
	return dispatch => {
		dispatch(trapsActions.update(parameters, page, callback))
	}
}

function create(parameters, page, projectId, callback){
	const trapData = {...parameters, project: projectId}
	return dispatch => {
		dispatch(trapsActions.create(trapData, page, callback))
	}
}

function handleChangeFilter(event, target) {
	const value = event.target.value

	return dispatch => {
		dispatch(setFilter(value, target))
	}
}

function setFilter(value, target) {return { type: trapsActionTypes.SET_TRAPS_FILTER, value, target }}

function handleTrapSize(myValue, target, trapSize, dispatch) {
	let value = Number(myValue.target.value)
	let trapSizeObj = trapSize
	trapSizeObj[target] = value
	return dispatch(saveStore(trapSizeObj, 'trapSize', trapsActionTypes.HANDLE_INPUT_TRAPS));

}

function saveStore(value, target, context) { return { type: context, value, target } }

function handlePosition(module, index, target, myValue, dispatch) {

	let newObj = module;

	newObj[index][target] = myValue

	if(target === 'status' && myValue === false) {
		newObj[index]['removeDate'] = new Date()
	}else{
		newObj[index]['removeDate'] = ''
	}

	return dispatch(saveStore(newObj, 'position', trapsActionTypes.HANDLE_INPUT_TRAPS));
}

function addNewPosition(module, dispatch) {
	let newObj = module;
	const emptyModule = {addDate: new Date(), coordX: "", coordY: "", EPE: "", projecao: "--", status: true, removeDate: ""}

	newObj.unshift(emptyModule)

	return dispatch(saveStore(newObj, 'position', trapsActionTypes.HANDLE_INPUT_TRAPS));
}