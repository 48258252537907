import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableRow, TableCell, Checkbox, TableContainer, Table, TableBody, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import Header from '../Components/headerAlter';
import Filter from '../Components/fauna/filters';
import CustomModal from '../Components/customModal';
import Loading from '../Components/loading'

import { history } from '../helpers/history';
import { helpers } from '../helpers/helpers';
import { faunaHelpers } from '../helpers/fauna/faunaHelpers';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCaretUp } from '@fortawesome/free-solid-svg-icons'
// import { especiesHelpers } from '../helpers/especies/especiesHelpers';


class Fauna extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: { showModal: false, modalType: undefined, content: undefined },
        };
    }

    componentDidMount() {
        const { dispatch, fauna, projects } = this.props
        dispatch(helpers.saveLocation(history.location.pathname));

        dispatch(faunaHelpers.listFauna(fauna.filters, fauna.page, projects.selectedProject));
        // dispatch(especiesHelpers.list())
        dispatch(faunaHelpers.clearFaunaCard());
    }

    handleModal = (modalType, content) => {
        const { modal } = this.state
        this.setState({ modal: { showModal: !modal.showModal, modalType: modalType, content: content } })
    }

    handleOrderBy = async (value) => {
        const { fauna, dispatch, projects } = this.props
        if (fauna.filters.orderBy === value) return
        await dispatch(faunaHelpers.setFaunaFilter(value, 'orderBy'));
        const $filters = fauna.filters
        $filters.orderBy = value
        dispatch(faunaHelpers.listFauna($filters, fauna.page, projects.selectedProject));
    }

    render() {

        const { dispatch, fauna, projects, alert, loggedUser } = this.props
        const { listFauna, page, pagesTotal } = fauna
        const { selectedProject } = projects
        const { modal } = this.state

        const hasCardList = listFauna && listFauna.length > 0
        const hasCardType = hasCardList && listFauna[0].cardType
        const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

        return (
            <div className='mainContainer'>
                <Loading />
                <Header title='FAUNA' />
                <div className='basicContainer'>
                    {alert && alert.type && alert.message &&
                        <>
                            {alert.type === 'alert-danger' &&
                                <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
                            }
                            {alert.type === 'alert-success' &&
                                <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
                            }
                        </>
                    }
                    <Filter writeModuleVerify={writeModuleVerify} />
                    {hasCardList &&
                        <>
                            <Grid container spacing={3} className="mapBox">
                                <Grid item xs={12} md={9}></Grid>
                                <Grid item xs={12} md={3}>
                                    <Button variant="contained" className="btn-secondary" download onClick={() => faunaHelpers.plotOnMap(listFauna, this.handleModal)}>
                                        MAPA
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <TableContainer className="customTable">
                                    <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                                        <TableHead className="tableHead">
                                            <TableRow>
                                                <TableCell key='numeroFicha' align='right' padding='normal' onClick={() => this.handleOrderBy('numeroFicha')}>Ficha {fauna?.filters?.orderBy === 'numeroFicha' && <FontAwesomeIcon icon={faCaretUp} />}</TableCell>
                                                <TableCell key='numeroAnimal' align='right' padding='normal' onClick={() => this.handleOrderBy('numeroAnimal')}>Nº do Animal {fauna?.filters?.orderBy === 'numeroAnimal' && <FontAwesomeIcon icon={faCaretUp} />}</TableCell>
                                                <TableCell key='cardType' align='right' padding='normal' onClick={() => this.handleOrderBy('cardType')}>Tipo {fauna?.filters?.orderBy === 'cardType' && <FontAwesomeIcon icon={faCaretUp} />}</TableCell>
                                                <TableCell key='dataCriacao' align='right' padding='normal' onClick={() => this.handleOrderBy('dataCriacao')}>Data criação {fauna?.filters?.orderBy === 'dataCriacao' && <FontAwesomeIcon icon={faCaretUp} />}</TableCell>
                                                <TableCell key='nomeCientifico' align='right' padding='normal' onClick={() => this.handleOrderBy('nomeCientifico')}>Nome Científico {fauna?.filters?.orderBy === 'nomeCientifico' && <FontAwesomeIcon icon={faCaretUp} />}</TableCell>
                                                <TableCell padding="none" />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listFauna.map((row, index) => {
                                                const isItemSelected = false;
                                                // const isItemSelected = isSelected(row.name);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" id={labelId} scope="row"><b>{row.numeroFicha}</b></TableCell>
                                                        <TableCell align="right">{row.numeroAnimal ?? ''}</TableCell>
                                                        <TableCell align="right">{row.cardType}</TableCell>
                                                        <TableCell align="right">
                                                            {(row.cardType === "Captura" || row.cardType === "Recaptura") &&
                                                                moment(row?.formatedDate?.dataCaptura).format('DD/MM/YYYY')
                                                            }
                                                            {row.cardType === "Ocorrência" &&
                                                                moment(row?.formatedDate?.dataObs).format('DD/MM/YYYY')
                                                            }
                                                            {row.cardType === "Recebimento" &&
                                                                moment(row?.formatedDate?.dataRecebimento).format('DD/MM/YYYY')
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">{row.nomeCientifico}</TableCell>
                                                        <TableCell>
                                                            {(row.cardType === "Captura" || row.cardType === "Recebimento") &&
                                                                <Button
                                                                    variant="contained"
                                                                    className="btn-secondary btn-innerTable btn-small"
                                                                    onClick={() => dispatch(faunaHelpers.addRecapture(row))}
                                                                    disabled={!writeModuleVerify}
                                                                >
                                                                    Recaptura
                                                                </Button>
                                                            }
                                                            <Button
                                                                variant="contained"
                                                                className="btn-secondary btn-innerTable btn-small"
                                                                onClick={() => dispatch(faunaHelpers.faunaDetails(row))}
                                                            >
                                                                {writeModuleVerify ? "Editar" : "Visualizar"}
                                                                {writeModuleVerify && <FontAwesomeIcon icon={faEdit} />}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, fauna.filters, page, 'fauna', selectedProject))} />
                            </Grid>
                        </>
                    }
                    {!hasCardList &&
                        <Alert className="alert alert-warning" severity="warning">Não foram encontrados resultados com os filtros aplicados.</Alert>
                    }
                </div>
                {modal.showModal &&
                    <CustomModal modalFn={this.handleModal} content={modal.content} modalType={modal.modalType} />
                }
            </div>
        )
    }
}

const mapStateToProps = store => ({
    alert: store.alertReducer,
    fauna: store.faunaReducer,
    projects: store.projectsReducer,
    loggedUser: store.loginReducer.loggedUser
});

export default connect(mapStateToProps)(Fauna);