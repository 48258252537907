import { floraEspeciesActionTypes } from "../types/floraEspeciesActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: {
    especies: []
  },
  loading: false,
  documents: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0,
  },
  filters: {
    nomeCientifico: '',
    nomePopular: ''
  },
  options:{
    localOrigemTipo: [{type: "areaDemarcada", label: "Área Demarcada"}, {type: "serrapilheira", label: "Serrapilheira"}],
    areaPlantio: [{type: "areaDemarcada", label: "Área Demarcada"}, {type: "localConhecido", label: "Local Conhecido"}, {type: "coordenadas", label: "Coordenadas"}],
    producaoType: [{type: "obito", label: "Óbito"}, {type: "doacao", label: "Doação"}, {type: "plantio", label: "Plantio"}],
  },
  editItem: {
    nomeCientifico:'', 
    nomePopular:'',
    ordem:'',
    familia:'', 
    subFamilia:'', 
    genero:'', 
    especie:'', 
    subEspecie:'',
  }
};

export const floraEspeciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case floraEspeciesActionTypes.GET_FLORA_ESPECIES_REQUEST:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments,
          especies: initialState.listDocuments.especies, 
          loading: true
        }
      };
    case floraEspeciesActionTypes.GET_FLORA_ESPECIES_SUCCESS:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments, 
          especies: action.places, 
          loading: false 
        }
      };
    case floraEspeciesActionTypes.GET_FLORA_ESPECIES_FAILURE:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments, 
          especies: initialState.listDocuments.especies, 
          loading: false 
        }
      };
    
    case floraEspeciesActionTypes.CREATE_FLORA_ESPECIES_REQUEST:
    case floraEspeciesActionTypes.UPDATE_FLORA_ESPECIES_REQUEST:
    case floraEspeciesActionTypes.LIST_FLORA_ESPECIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case floraEspeciesActionTypes.CREATE_FLORA_ESPECIES_SUCCESS:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case floraEspeciesActionTypes.UPDATE_FLORA_ESPECIES_SUCCESS:
    case floraEspeciesActionTypes.LIST_FLORA_ESPECIES_SUCCESS:
      return {
        ...state,
        documents: {
          list: action.list.docs, 
          total: action.list.total, 
          limit: action.list.limit, 
          page: Number(action.list.page), 
          pagesTotal: action.list.pages,
          qntCounter: Number(action.list.qntCounter)
        },
        editItem: initialState.editItem,
        loading: false
      };
    case floraEspeciesActionTypes.UPDATE_FLORA_ESPECIES_CANCELED:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case floraEspeciesActionTypes.CREATE_FLORA_ESPECIES_FAILURE:
    case floraEspeciesActionTypes.UPDATE_FLORA_ESPECIES_FAILURE:
    case floraEspeciesActionTypes.LIST_FLORA_ESPECIES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case floraEspeciesActionTypes.EDIT_FLORA_ESPECIES:
      return { 
        ...state, 
        editItem: action.item
      };
    case floraEspeciesActionTypes.HANDLE_FLORA_ESPECIES:
    case floraEspeciesActionTypes.HANDLE_RADIOS_FLORA_ESPECIES:
    case floraEspeciesActionTypes.HANDLE_INPUT_FLORA_ESPECIES:
      return {
        ...state,
        editItem: {
          ...state.editItem, [action.target]: action.value
        }
      };
    case floraEspeciesActionTypes.SET_FLORA_ESPECIES_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
    case floraEspeciesActionTypes.SET_FILTER_DATE_INIT:
      return { 
        ...state, 
        filters: {
          ...state.filters, 
          initDate: moment(action.date).format()
        } 
      };
    case floraEspeciesActionTypes.SET_FILTER_DATE_END:
      return { 
        ...state, 
        filters: {
          ...state.filters, 
          endDate: moment(action.date).format()
        }
      };
    case floraEspeciesActionTypes.EXPORT_FLORA_ESPECIES_REQUEST:
      return { ...state, loading: true };
    case floraEspeciesActionTypes.EXPORT_FLORA_ESPECIES_SUCCESS:
    case floraEspeciesActionTypes.EXPORT_FLORA_ESPECIES_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};