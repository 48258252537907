import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider } from "@material-ui/core"

import { areaPlantioHelpers } from '../../../helpers/flora/areaPlantio/helpers';

class filter extends Component {

  render() {
    const { dispatch, areaPlantioReducer, context, projectsReducer } = this.props
    const { areaPlantio } = areaPlantioReducer
    const { filters } = areaPlantio

    return (
      <>
        <div className="filter">
          <Typography variant="h5" component="h5" className="filterTitle">
            Pesquisar
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="codigoDaArea"
                    label="Código da área"
                    className="inputDefault"
                    value={filters.codigoDaArea}
                    onChange={(event) => dispatch(areaPlantioHelpers.handleChangeFilter(event, "codigoDaArea", context))}
                  />
                </Grid>
              </>
              <Divider className="dividerFull" />
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  // onClick={() => this.props.handleModal(context, true)}
                  onClick={() => dispatch(areaPlantioHelpers.editCard('areaPlantioEdicao', null, areaPlantioHelpers.areaPlantio))}
                >
                  ADICIONAR ÁREA DE PLANTIO
                </Button>
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                {/* <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => dispatch(areaPlantioHelpers.areaPlantioSaidas())}
                >
                  SAÍDAS
                </Button> */}
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={
                    () => {
                      if (context === 'areaPlantio') {
                        dispatch(areaPlantioHelpers.paginateAreaPlantio(filters, 1, projectsReducer.selectedProject))
                      } else {
                        // dispatch(areaPlantioHelpers.paginateProducao(filters, 1))
                      }
                    }
                  }
                >
                  PESQUISAR
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        {/* <div id="qntCounter">
          <p>
            {`Quantidade de exemplares no viveiro: `}
            <span>{documents.qntCounter ? documents.qntCounter : '0' }</span>
          </p>
        </div> */}
      </>
    )
  }
}

const mapStateToProps = store => ({
  areaPlantioReducer: store.areaPlantioReducer,
  paramsReducer: store.paramsReducer,
  projectsReducer: store.projectsReducer
});

export default connect(mapStateToProps)(filter);