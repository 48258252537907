export const instListaActionTypes = {

    GET_INSTLISTA_REQUEST: "GET_INSTLISTA_REQUEST",
    GET_INSTLISTA_SUCCESS: "GET_INSTLISTA_SUCCESS",
    GET_INSTLISTA_FAILURE: "GET_INSTLISTA_FAILURE",

    CREATE_INSTLISTA_REQUEST: "CREATE_INSTLISTA_REQUEST",
    CREATE_INSTLISTA_SUCCESS: "CREATE_INSTLISTA_SUCCESS",
    CREATE_INSTLISTA_FAILURE: "CREATE_INSTLISTA_FAILURE",

    UPDATE_INSTLISTA_REQUEST: "UPDATE_INSTLISTA_REQUEST",
    UPDATE_INSTLISTA_SUCCESS: "UPDATE_INSTLISTA_SUCCESS",
    UPDATE_INSTLISTA_FAILURE: "UPDATE_INSTLISTA_FAILURE",
    UPDATE_INSTLISTA_CANCELED: "UPDATE_INSTLISTA_CANCELED",

    LIST_INSTLISTA_REQUEST: "LIST_INSTLISTA_REQUEST",
    LIST_INSTLISTA_SUCCESS: "LIST_INSTLISTA_SUCCESS",
    LIST_INSTLISTA_FAILURE: "LIST_INSTLISTA_FAILURE",

    HANDLE_INPUT_INSTLISTA: "HANDLE_INPUT_INSTLISTA",
    HANDLE_RADIOS_INSTLISTA: "HANDLE_RADIOS_INSTLISTA",
    HANDLE_INSTLISTA: "HANDLE_INSTLISTA",
    
    SET_INSTLISTA_FILTER: "SET_INSTLISTA_FILTER",
    EDIT_INSTLISTA: "EDIT_INSTLISTA",
};

