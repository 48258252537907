import { toraHandler } from '../handlers/toraHandler';
import { toraActionTypes } from '../types/toraActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const toraActions = {
    listTora,
    paginateTora,
    createTora,
    updateTora,
    deleteTora,
    listToraSaida,
    paginateToraSaida,
    createToraSaida,
    updateToraSaida,
    deleteToraSaida,
    exportTora
};

function listTora(findParams) {
    return dispatch => {
        dispatch(request());
        toraHandler.listTora(findParams)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: toraActionTypes.GET_TORA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.GET_TORA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.GET_TORA_CARD_FAILURE, error } }
}

function paginateTora(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        toraHandler.paginateTora(params, page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: toraActionTypes.LIST_TORA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.LIST_TORA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.LIST_TORA_CARD_FAILURE, error } }
}

function createTora(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        toraHandler.createTora(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateTora(filters, page, projectId))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: toraActionTypes.CREATE_TORA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.CREATE_TORA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.CREATE_TORA_CARD_FAILURE, error } }
}

function updateTora(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        toraHandler.updateTora(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: toraActionTypes.UPDATE_TORA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.UPDATE_TORA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.UPDATE_TORA_CARD_FAILURE, error } }
}

function deleteTora(params, page, projectId, cardId, callback) {
    return dispatch => {
        dispatch(request());
        toraHandler.deleteTora(params, page, projectId, cardId)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: toraActionTypes.LIST_TORA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.LIST_TORA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.LIST_TORA_CARD_FAILURE, error } }
}

function exportTora(params, projectId) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.clear())
        toraHandler.exportTora(params, projectId)
        .then(response => {
                if(response === "erro"){
                    dispatch(failure());
                    dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
                }else{
                    dispatch(success());
                    dispatch(alertActions.success('Extração realizada com sucesso'))
                }
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(failure(error));
                dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
        });
    };
    function request() { return { type: toraActionTypes.EXPORT_TORA_REQUEST } }
    function success() { return { type: toraActionTypes.EXPORT_TORA_SUCCESS } }
    function failure() { return { type: toraActionTypes.EXPORT_TORA_FAILURE } }
}

function listToraSaida() {
    return dispatch => {
        dispatch(request());
        toraHandler.listToraSaida()
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: toraActionTypes.GET_TORA_SAIDA_CARD_REQUEST } }
    function success(places) { return { type: toraActionTypes.GET_TORA_SAIDA_CARD_SUCCESS, places } }
    function failure(error) { return { type: toraActionTypes.GET_TORA_SAIDA_CARD_FAILURE, error } }
}

function paginateToraSaida(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        toraHandler.paginateToraSaida(params, page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: toraActionTypes.LIST_TORA_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.LIST_TORA_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.LIST_TORA_SAIDA_CARD_FAILURE, error } }
}

function createToraSaida(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        toraHandler.createToraSaida(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateToraSaida(filters, page, projectId))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: toraActionTypes.CREATE_TORA_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.CREATE_TORA_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.CREATE_TORA_SAIDA_CARD_FAILURE, error } }
}

function updateToraSaida(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        toraHandler.updateToraSaida(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: toraActionTypes.UPDATE_TORA_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.UPDATE_TORA_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.UPDATE_TORA_SAIDA_CARD_FAILURE, error } }
}

function deleteToraSaida(params, page, projectId, cardId, callback) {
    return dispatch => {
        dispatch(request());
        toraHandler.deleteToraSaida(params, page, projectId, cardId)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: toraActionTypes.LIST_TORA_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: toraActionTypes.LIST_TORA_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: toraActionTypes.LIST_TORA_SAIDA_CARD_FAILURE, error } }
}
