import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, FormControl, InputLabel, Select, MenuItem, } from "@material-ui/core"
import { palmitoHelpers } from '../../../helpers/flora/palmito/helpers'

import FilterDate from './filterDate'

class filter extends Component {

    render(){
        const { dispatch, palmitoReducer, context, projectsReducer } = this.props
        const { filters, options } = palmitoReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        {context === 'matriz' && 
                            <Grid item xs={12} md={6}>
                                <TextField id="numeroMatriz" label="Número da Matriz" className="inputDefault" value={filters.numeroMatriz} onChange={(event) => dispatch(palmitoHelpers.handleChangeFilter(event, "numeroMatriz"))}/>
                            </Grid>
                        }
                        {context === 'talhao' && 
                            <Grid item xs={12} md={6}>
                                <TextField id="numeroTalhao" label="Número do Talhão" className="inputDefault" value={filters.numeroTalhao} onChange={(event) => dispatch(palmitoHelpers.handleChangeFilter(event, "numeroTalhao"))}/>
                            </Grid>
                        }
                        {context === 'saida' && 
                            <Grid item xs={12} md={6}>
                                <TextField id="numeroSaida" label="Número da Saída" className="inputDefault" value={filters.numeroSaida} onChange={(event) => dispatch(palmitoHelpers.handleChangeFilter(event, "numeroSaida"))}/>
                            </Grid>
                        }
                        <FilterDate filterProps={filters} dispatch={dispatch} context={context}/>
                        {context === 'talhao' && 
                            <>
                                <Grid item xs={12} md={4}>
                                    <FormControl className="inputDefault">
                                        <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                            value={filters.tipo}
                                            onChange={(event) => dispatch(palmitoHelpers.handleChangeFilter(event, "tipo"))}
                                        >
                                            <MenuItem value={''}>Selecione...</MenuItem>
                                        {options.tipo.map(type => {
                                            return <MenuItem value={type}>{type}</MenuItem>
                                        })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl className="inputDefault">
                                        <InputLabel id="demo-simple-select-label">Tipo de Ambiente</InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                            value={filters.tipoAmbiente}
                                            onChange={(event) => dispatch(palmitoHelpers.handleChangeFilter(event, "tipoAmbiente"))}
                                        >
                                            <MenuItem value={''}>Selecione...</MenuItem>
                                        {options.tipoAmbiente.map(type => {
                                            return <MenuItem value={type}>{type}</MenuItem>
                                        })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl className="inputDefault">
                                        <InputLabel id="demo-simple-select-label">Iluminação</InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                            value={filters.iluminacao}
                                            onChange={(event) => dispatch(palmitoHelpers.handleChangeFilter(event, "iluminacao"))}
                                        >
                                            <MenuItem value={''}>Selecione...</MenuItem>
                                        {options.iluminacao.map(type => {
                                            return <MenuItem value={type}>{type}</MenuItem>
                                        })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </> 
                        }
                        {context === 'saida' && 
                            <>
                                <Grid item xs={12} md={6}>
                                    <FormControl className="inputDefault">
                                        <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                            value={filters.tipo}
                                            onChange={(event) => dispatch(palmitoHelpers.handleChangeFilter(event, "tipo"))}
                                        >
                                            <MenuItem value={''}>Selecione...</MenuItem>
                                        {options.saida.tipo.map(type => {
                                            return <MenuItem value={type}>{type}</MenuItem>
                                        })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </> 
                        }
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            {context === 'matriz' && 
                                <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('palmitoMatriz', true)}>
                                    NOVA MATRIZ
                                </Button>
                            }
                            {context === 'talhao' && 
                                <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('palmitoTalhao', true)}>
                                    NOVO TALHÃO
                                </Button>
                            }
                            {context === 'saida' && 
                                <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('palmitoSaida', true)}>
                                    NOVA SAÍDA
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(palmitoHelpers.paginatePalmito(filters, 1, context, projectsReducer.selectedProject))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    palmitoReducer: store.palmitoReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(filter);