export const raizesActionTypes = {

    // TORA

    GET_RAIZES_CARD_REQUEST: "GET_RAIZES_CARD_REQUEST",
    GET_RAIZES_CARD_SUCCESS: "GET_RAIZES_CARD_SUCCESS",
    GET_RAIZES_CARD_FAILURE: "GET_RAIZES_CARD_FAILURE",

    LIST_RAIZES_CARD_REQUEST: "LIST_RAIZES_CARD_REQUEST",
    LIST_RAIZES_CARD_SUCCESS: "LIST_RAIZES_CARD_SUCCESS",
    LIST_RAIZES_CARD_FAILURE: "LIST_RAIZES_CARD_FAILURE",

    SET_RAIZES_FILTER: "SET_RAIZES_FILTER",

    SET_RAIZES_FILTER_DATE_INIT: "SET_RAIZES_FILTER_DATE_INIT",
    SET_RAIZES_FILTER_DATE_END: "SET_RAIZES_FILTER_DATE_END",

    EDIT_RAIZES_CARD: "EDIT_RAIZES_CARD",
    UPDATE_RAIZES_CARD_CANCELED: "UPDATE_RAIZES_CARD_CANCELED",
    HANDLE_RAIZES_FIELDS: "HANDLE_RAIZES_FIELDS",
    HANDLE_RAIZES_TOTAL_VOLUME: "HANDLE_RAIZES_TOTAL_VOLUME",

    CREATE_RAIZES_CARD_REQUEST: "CREATE_RAIZES_CARD_REQUEST",
    CREATE_RAIZES_CARD_SUCCESS: "CREATE_RAIZES_CARD_SUCCESS",
    CREATE_RAIZES_CARD_FAILURE: "CREATE_RAIZES_CARD_FAILURE",

    UPDATE_RAIZES_CARD_REQUEST: "UPDATE_RAIZES_CARD_REQUEST",
    UPDATE_RAIZES_CARD_SUCCESS: "UPDATE_RAIZES_CARD_SUCCESS",
    UPDATE_RAIZES_CARD_FAILURE: "UPDATE_RAIZES_CARD_FAILURE",

    UPDATE_RAIZES_INDIVIDUOS: "UPDATE_RAIZES_INDIVIDUOS",

    // LENHA SAIDA

    GET_RAIZES_SAIDA_CARD_REQUEST: "GET_RAIZES_SAIDA_CARD_REQUEST",
    GET_RAIZES_SAIDA_CARD_SUCCESS: "GET_RAIZES_SAIDA_CARD_SUCCESS",
    GET_RAIZES_SAIDA_CARD_FAILURE: "GET_RAIZES_SAIDA_CARD_FAILURE",

    LIST_RAIZES_SAIDA_CARD_REQUEST: "LIST_RAIZES_SAIDA_CARD_REQUEST",
    LIST_RAIZES_SAIDA_CARD_SUCCESS: "LIST_RAIZES_SAIDA_CARD_SUCCESS",
    LIST_RAIZES_SAIDA_CARD_FAILURE: "LIST_RAIZES_SAIDA_CARD_FAILURE",

    CREATE_RAIZES_SAIDA_CARD_REQUEST: "CREATE_RAIZES_SAIDA_CARD_REQUEST",
    CREATE_RAIZES_SAIDA_CARD_SUCCESS: "CREATE_RAIZES_SAIDA_CARD_SUCCESS",
    CREATE_RAIZES_SAIDA_CARD_FAILURE: "CREATE_RAIZES_SAIDA_CARD_FAILURE",

    UPDATE_RAIZES_SAIDA_CARD_REQUEST: "UPDATE_RAIZES_SAIDA_CARD_REQUEST",
    UPDATE_RAIZES_SAIDA_CARD_SUCCESS: "UPDATE_RAIZES_SAIDA_CARD_SUCCESS",
    UPDATE_RAIZES_SAIDA_CARD_FAILURE: "UPDATE_RAIZES_SAIDA_CARD_FAILURE",

    SET_RAIZES_SAIDA_FILTER: "SET_RAIZES_SAIDA_FILTER",

    SET_RAIZES_SAIDA_FILTER_DATE_INIT: "SET_RAIZES_SAIDA_FILTER_DATE_INIT",
    SET_RAIZES_SAIDA_FILTER_DATE_END: "SET_RAIZES_SAIDA_FILTER_DATE_END",

    EDIT_RAIZES_SAIDA_CARD: "EDIT_RAIZES_SAIDA_CARD",
    UPDATE_RAIZES_SAIDA_CARD_CANCELED: "UPDATE_RAIZES_SAIDA_CARD_CANCELED",
    HANDLE_RAIZES_SAIDA_FIELDS: "HANDLE_RAIZES_SAIDA_FIELDS",

};