export const palmitoActionTypes = {

    // Palmito geral

    HANDLE_INPUT_PALMITO: "HANDLE_INPUT_PALMITO",
    HANDLE_RADIOS_PALMITO: "HANDLE_RADIOS_PALMITO",
    HANDLE_PALMITO: "HANDLE_PALMITO",
    SET_FILTER_DATE: "SET_FILTER_DATE",
    
    SET_PALMITO_FILTER: "SET_PALMITO_FILTER",
    EDIT_PALMITO: "EDIT_PALMITO",
    DELETE_PALMITO: "DELETE_PALMITO",
    UPDATE_PALMITO_CANCELED: "UPDATE_PALMITO_CANCELED",
    DELETE_PALMITO_CANCELED: "DELETE_PALMITO_CANCELED",

    // Palmito Matriz

    GET_PALMITO_MATRIZ_REQUEST: "GET_PALMITO_MATRIZ_REQUEST",
    GET_PALMITO_MATRIZ_SUCCESS: "GET_PALMITO_MATRIZ_SUCCESS",
    GET_PALMITO_MATRIZ_FAILURE: "GET_PALMITO_MATRIZ_FAILURE",

    CREATE_PALMITO_MATRIZ_REQUEST: "CREATE_PALMITO_MATRIZ_REQUEST",
    CREATE_PALMITO_MATRIZ_SUCCESS: "CREATE_PALMITO_MATRIZ_SUCCESS",
    CREATE_PALMITO_MATRIZ_FAILURE: "CREATE_PALMITO_MATRIZ_FAILURE",

    UPDATE_PALMITO_MATRIZ_REQUEST: "UPDATE_PALMITO_MATRIZ_REQUEST",
    UPDATE_PALMITO_MATRIZ_SUCCESS: "UPDATE_PALMITO_MATRIZ_SUCCESS",
    UPDATE_PALMITO_MATRIZ_FAILURE: "UPDATE_PALMITO_MATRIZ_FAILURE",

    LIST_PALMITO_MATRIZ_REQUEST: "LIST_PALMITO_MATRIZ_REQUEST",
    LIST_PALMITO_MATRIZ_SUCCESS: "LIST_PALMITO_MATRIZ_SUCCESS",
    LIST_PALMITO_MATRIZ_FAILURE: "LIST_PALMITO_MATRIZ_FAILURE",

    // Palmito Talhao

    GET_PALMITO_TALHAO_REQUEST: "GET_PALMITO_TALHAO_REQUEST",
    GET_PALMITO_TALHAO_SUCCESS: "GET_PALMITO_TALHAO_SUCCESS",
    GET_PALMITO_TALHAO_FAILURE: "GET_PALMITO_TALHAO_FAILURE",

    CREATE_PALMITO_TALHAO_REQUEST: "CREATE_PALMITO_TALHAO_REQUEST",
    CREATE_PALMITO_TALHAO_SUCCESS: "CREATE_PALMITO_TALHAO_SUCCESS",
    CREATE_PALMITO_TALHAO_FAILURE: "CREATE_PALMITO_TALHAO_FAILURE",

    UPDATE_PALMITO_TALHAO_REQUEST: "UPDATE_PALMITO_TALHAO_REQUEST",
    UPDATE_PALMITO_TALHAO_SUCCESS: "UPDATE_PALMITO_TALHAO_SUCCESS",
    UPDATE_PALMITO_TALHAO_FAILURE: "UPDATE_PALMITO_TALHAO_FAILURE",

    LIST_PALMITO_TALHAO_REQUEST: "LIST_PALMITO_TALHAO_REQUEST",
    LIST_PALMITO_TALHAO_SUCCESS: "LIST_PALMITO_TALHAO_SUCCESS",
    LIST_PALMITO_TALHAO_FAILURE: "LIST_PALMITO_TALHAO_FAILURE",

    // Palmito Saida

    GET_PALMITO_SAIDA_REQUEST: "GET_PALMITO_SAIDA_REQUEST",
    GET_PALMITO_SAIDA_SUCCESS: "GET_PALMITO_SAIDA_SUCCESS",
    GET_PALMITO_SAIDA_FAILURE: "GET_PALMITO_SAIDA_FAILURE",

    CREATE_PALMITO_SAIDA_REQUEST: "CREATE_PALMITO_SAIDA_REQUEST",
    CREATE_PALMITO_SAIDA_SUCCESS: "CREATE_PALMITO_SAIDA_SUCCESS",
    CREATE_PALMITO_SAIDA_FAILURE: "CREATE_PALMITO_SAIDA_FAILURE",

    UPDATE_PALMITO_SAIDA_REQUEST: "UPDATE_PALMITO_SAIDA_REQUEST",
    UPDATE_PALMITO_SAIDA_SUCCESS: "UPDATE_PALMITO_SAIDA_SUCCESS",
    UPDATE_PALMITO_SAIDA_FAILURE: "UPDATE_PALMITO_SAIDA_FAILURE",

    LIST_PALMITO_SAIDA_REQUEST: "LIST_PALMITO_SAIDA_REQUEST",
    LIST_PALMITO_SAIDA_SUCCESS: "LIST_PALMITO_SAIDA_SUCCESS",
    LIST_PALMITO_SAIDA_FAILURE: "LIST_PALMITO_SAIDA_FAILURE",
};