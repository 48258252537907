import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider } from "@material-ui/core"
import { instListaHelpers} from '../../helpers/instListaEspecies/instListaHelpers'

class filter extends Component {

    render(){
        const { dispatch, instListaEspeciesReducer } = this.props
        const { filters } = instListaEspeciesReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="name" label="Instituição" className="inputDefault" value={filters.name} onChange={(event) => dispatch(instListaHelpers.handleChangeFilter(event, "name"))}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="nickname" label="Sigla" className="inputDefault" value={filters.nickname} onChange={(event) => dispatch(instListaHelpers.handleChangeFilter(event, "nickname"))}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('instLista', true)}>
                                NOVA INSTITUIÇÃO
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(instListaHelpers.paginate(filters, 1))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    instListaEspeciesReducer: store.instListaEspeciesReducer,
});

export default connect(mapStateToProps)(filter);