import { toraActionTypes } from "../types/toraActionTypes";
import moment from "moment"
import _ from "lodash";

const initialState = {
  listDocuments: {
    tora: [],
    toraSaida: {},
    lenha: {},
    lenhaSaida: {}
  },
  tora: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {
      numeroFicha: '',
      areaDemarcada: '',
      initDate: moment().subtract(7, 'days').format(),
      endDate: moment().format()
    },
    editItem: {
      numeroFicha: '',
      areaDemarcada: '',
      comprimento: '',
      dataMedicao: moment().format(),
      diametro1: '',
      diametro2: '',
      diametro3: '',
      diametroMedio: '',
      volume: '',
    }
  },
  toraSaida: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {
      tipo: '',
      volumetoTal: '',
      initDate: moment().subtract(7, 'days').format(),
      endDate: moment().format()
    },
    editItem: {
      data: moment().format(),
      tipo: '',
      individuos: [],
      volumeTotal: '0',
    }
  }
};

export const toraReducer = (state = initialState, action) => {
  switch (action.type) {
    case toraActionTypes.GET_TORA_CARD_REQUEST:
      return {
        ...state
      }
    case toraActionTypes.GET_TORA_CARD_FAILURE:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          tora: initialState.listDocuments.tora
        }
      };
    case toraActionTypes.GET_TORA_CARD_SUCCESS:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          // tora: action.list
          tora: _.uniqBy([...state.listDocuments.tora].concat(...action.list), 'numeroFicha')
        }
      };
    case toraActionTypes.UPDATE_TORA_CARD_REQUEST:
    case toraActionTypes.CREATE_TORA_CARD_REQUEST:
    case toraActionTypes.LIST_TORA_CARD_REQUEST:
      return {
        ...state,
        tora: {
          ...state.tora,
          loading: true
        }
      };
    case toraActionTypes.CREATE_TORA_CARD_SUCCESS:
      return {
        ...state,
        tora: {
          ...state.tora,
          editItem: initialState.tora.editItem,
          loading: false
        }
      };
    case toraActionTypes.UPDATE_TORA_CARD_SUCCESS:
    case toraActionTypes.LIST_TORA_CARD_SUCCESS:
      return {
        ...state,
        tora: {
          ...state.tora,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.tora.editItem,
          loading: false
        }
      };
    case toraActionTypes.UPDATE_TORA_CARD_FAILURE:
    case toraActionTypes.CREATE_TORA_CARD_FAILURE:
    case toraActionTypes.LIST_TORA_CARD_FAILURE:
      return {
        ...state,
        tora: {
          ...state.tora,
          loading: false
        }
      };

    case toraActionTypes.SET_TORA_FILTER:
      return {
        ...state,
        tora: {
          ...state.tora,
          filters: { ...state.tora.filters, [action.target]: action.value }
        }
      };

    case toraActionTypes.SET_TORA_FILTER_DATE_INIT:
      return {
        ...state,
        tora: {
          ...state.tora,
          filters: {
            ...state.tora.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case toraActionTypes.SET_TORA_FILTER_DATE_END:
      return {
        ...state,
        tora: {
          ...state.tora,
          filters: {
            ...state.tora.filters,
            endDate: moment(action.date).format()
          }
        }
      };

    case toraActionTypes.EDIT_TORA_CARD:
      return {
        ...state,
        tora: {
          ...state.tora,
          editItem: action.item
        }
      };
    case toraActionTypes.UPDATE_TORA_CARD_CANCELED:
      return {
        ...state,
        tora: {
          ...state.tora,
          editItem: initialState.tora.editItem,
          loading: false
        }
      };
    case toraActionTypes.HANDLE_TORA_FIELDS:
      return {
        ...state,
        tora: {
          ...state.tora,
          editItem: {
            ...state.tora.editItem,
            [action.target]: action.value
          }
        }
      };
    case toraActionTypes.EXPORT_TORA_REQUEST:
      return {
        ...state,
        tora: {
          ...state.tora,
          loading: true
        }
      };
    case toraActionTypes.EXPORT_TORA_SUCCESS:
      return {
        ...state,
        tora: {
          ...state.tora,
          loading: false
        }
      };
    case toraActionTypes.EXPORT_TORA_FAILURE:
      return {
        ...state,
        tora: {
          ...state.tora,
          loading: false
        }
      };
    // tora Saida

    case toraActionTypes.UPDATE_TORA_SAIDA_CARD_REQUEST:
    case toraActionTypes.CREATE_TORA_SAIDA_CARD_REQUEST:
    case toraActionTypes.LIST_TORA_SAIDA_CARD_REQUEST:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          loading: true
        }
      };
    case toraActionTypes.CREATE_TORA_SAIDA_CARD_SUCCESS:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          editItem: initialState.toraSaida.editItem,
          loading: false
        }
      };
    case toraActionTypes.UPDATE_TORA_SAIDA_CARD_SUCCESS:
    case toraActionTypes.LIST_TORA_SAIDA_CARD_SUCCESS:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.toraSaida.editItem,
          loading: false
        }
      };
    case toraActionTypes.UPDATE_TORA_SAIDA_CARD_FAILURE:
    case toraActionTypes.CREATE_TORA_SAIDA_CARD_FAILURE:
    case toraActionTypes.LIST_TORA_SAIDA_CARD_FAILURE:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          loading: false
        }
      };

    case toraActionTypes.SET_TORA_SAIDA_FILTER:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          filters: { ...state.toraSaida.filters, [action.target]: action.value }
        }
      };

    case toraActionTypes.SET_TORA_SAIDA_FILTER_DATE_INIT:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          filters: {
            ...state.toraSaida.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case toraActionTypes.SET_TORA_SAIDA_FILTER_DATE_END:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          filters: {
            ...state.toraSaida.filters,
            endDate: moment(action.date).format()
          }
        }
      };

    case toraActionTypes.EDIT_TORA_SAIDA_CARD:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          editItem: action.item
        }
      };
    case toraActionTypes.UPDATE_TORA_SAIDA_CARD_CANCELED:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          editItem: initialState.tora.editItem,
          loading: false
        }
      };
    case toraActionTypes.HANDLE_TORA_SAIDA_FIELDS:
      return {
        ...state,
        toraSaida: {
          ...state.toraSaida,
          editItem: {
            ...state.toraSaida.editItem,
            [action.target]: action.value
          }
        }
      };

    default:
      return state;
  }
};