import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const documentsHandler = { 
	list, 
	paginate, 
	create,
	update,
	upload,
	deleteItem
};

function list() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("documents/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginate(params, page) {
	const $nomeCientifico = params && params.nomeCientifico ? `&nomeCientifico=${params.nomeCientifico}` : ""
	const $nomePopular = params && params.nomePopular ? `&nomePopular=${params.nomePopular}` : ""
	const $classe = params && params.classe ? `&classe=${params.classe}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($nomeCientifico)
	.concat($nomePopular)
	.concat($classe)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("documents/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}


function create(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("documents/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function update(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("documents/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function deleteItem(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "DELETE",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("documents/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function upload(file, fileName, id) {
	const formdata = new FormData()
	formdata.append("documentFile", file, fileName);
	const { Authorization } = defaultHeaders
	const requestOptions = { 
		method: "POST",
		body: formdata,
		headers: {
			Authorization,
		},
	};
	return fetch(prefixDefaultApi.concat(`documents/upload/?id=${id}`), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}