import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import moment from 'moment';
import fileDownload from 'js-file-download';
import { defaultHeaders } from "../helpers/headerHelper";

export const faunaHandler = { 
	listFauna, 
	listTelemetria, 
	getByCode,
	exportFauna, 
	create, 
	update, 
	upload, 
	deleteCard,
	deleteItem, 
	listById,
	createTelemetria,
	updateTelemetria,
	deleteImage,
	getMarcacao
};

function listFauna(faunaParams, page, projectId) {
	const $cardType = faunaParams && faunaParams.cardType ? `&cardType=${faunaParams.cardType}` : ""
	const $classType = faunaParams && faunaParams.classType ? `&classType=${faunaParams.classType}` : ""
	const $initDate = faunaParams && faunaParams.initDate ? `&initDate=${faunaParams.initDate}` : ""
	const $endDate = faunaParams && faunaParams.endDate ? `&endDate=${faunaParams.endDate}` : ""
	const $numeroFicha = faunaParams && faunaParams.numeroFicha && faunaParams.numeroFicha.length > 0 ? `&numeroFicha=${faunaParams.numeroFicha}` : ""
	const $numeroAnimal = faunaParams && faunaParams.numeroAnimal && faunaParams.numeroAnimal.length > 0 ? `&numeroAnimal=${faunaParams.numeroAnimal}` : ""
	const $nomeCientifico = faunaParams && faunaParams.nomeCientifico && faunaParams.nomeCientifico.length > 0 ? `&nomeCientifico=${faunaParams.nomeCientifico}` : ""
	const $orderBy = faunaParams && faunaParams.orderBy && faunaParams.orderBy.length > 0 ? `&orderBy=${faunaParams.orderBy}` : ''
    
	const queryParams = "?page=".concat(page)
	.concat(`&projectId=${projectId}`)
	.concat($cardType)
	.concat($orderBy)
	.concat($classType)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($numeroAnimal)
	.concat($nomeCientifico)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("getfauna/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function listTelemetria(faunaParams, page, projectId) {
	const $nomeCientifico = faunaParams && faunaParams.nomeCientifico ? `&nomeCientifico=${faunaParams.nomeCientifico}` : ""
	const $codigo = faunaParams && faunaParams.codigo && faunaParams.codigo.length > 0 ? `&codigo=${faunaParams.codigo}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat(`&projectId=${projectId}`)
	.concat($codigo)
	.concat($nomeCientifico)


	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("fauna/telemetria/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function getByCode(code, projectId) {
	const queryParams = "?projectId=".concat(projectId).concat(`&codigo=${code}`)
	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat(`/fauna/telemetria/${code}`).concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function listById(faunaParams, page, projectId) {
	
	const $numeroFicha = faunaParams && faunaParams.numeroFicha && faunaParams.numeroFicha.length > 0 ? `&numeroFicha=${faunaParams.numeroFicha}` : ""
	
    
	const queryParams = "?page=".concat(page)
	.concat(`&projectId=${projectId}`)
	.concat($numeroFicha)
	

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("fauna/get/").concat($numeroFicha).concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function exportFauna(faunaParams, projectId) {
	const $cardType = faunaParams && faunaParams.cardType ? `&cardType=${faunaParams.cardType}` : ""
	const $classType = faunaParams && faunaParams.classType ? `&classType=${faunaParams.classType}` : ""
	const $initDate = faunaParams && faunaParams.initDate ? `&initDate=${faunaParams.initDate}` : ""
	const $endDate = faunaParams && faunaParams.endDate ? `&endDate=${faunaParams.endDate}` : ""
	const $numeroFicha = faunaParams && faunaParams.numeroFicha && faunaParams.numeroFicha.length > 0 ? `&numeroFicha=${faunaParams.numeroFicha}` : ""
	const $numeroAnimal = faunaParams && faunaParams.numeroAnimal && faunaParams.numeroAnimal.length > 0 ? `&numeroAnimal=${faunaParams.numeroAnimal}` : ""
	const $nomeCientifico = faunaParams && faunaParams.nomeCientifico && faunaParams.nomeCientifico.length > 0 ? `&nomeCientifico=${faunaParams.nomeCientifico}` : ""
    
	const queryParams = "?"
	.concat(`&projectId=${projectId}`)
	.concat($cardType)
	.concat($classType)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($numeroAnimal)
	.concat($nomeCientifico)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("exportfauna/").concat(queryParams), requestOptions)
	.then( res => {
		apiHelpers.handleStatus(res)
		if(res?.status === 400){
			return res
		}else{
			return res.blob()
		}
	})
	.then( blob => {
		if(blob?.status === 400){
			return "erro"
		}else{
			fileDownload(blob, `${moment().format('YYYY-MM-DD-hh-mm-ss')}_export_fauna.xlsx`);
		}
	})
}

function create(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		headers: defaultHeaders,
		body: JSON.stringify(params),
	
	};
	return fetch(prefixDefaultApi.concat("faunacard/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function update(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		headers: defaultHeaders,
		body: JSON.stringify(params),
	};
	return fetch(prefixDefaultApi.concat("faunacard/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function deleteCard(params) {
	const queryParams = "?numeroFicha=".concat(params)

	const requestOptions = { method: "DELETE", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("fauna/removeficha").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function upload(file, fileName, id) {
	const formdata = new FormData()
	formdata.append("documentFile", file, fileName);
	const { Authorization } = defaultHeaders;
	const requestOptions = { 
		method: "POST",
		body: formdata,
		headers: {
			Authorization,
		},
	};
	return fetch(prefixDefaultApi.concat(`fauna/upload/?id=${id}`), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}
function deleteItem(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "DELETE",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("fauna/documents/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createTelemetria(params, projectId) {

	const queryParams = "?projectId=".concat(projectId)

	const requestOptions = { 
		method: "POST",
		headers: defaultHeaders,
		body: JSON.stringify(params),
	};
	return fetch(prefixDefaultApi.concat("fauna/telemetria").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateTelemetria(params) {

	const requestOptions = { 
		method: "PUT",
		headers: defaultHeaders,
		body: JSON.stringify(params),
	};
	return fetch(prefixDefaultApi.concat("fauna/telemetria"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function deleteImage(imageUrl) {
	const requestOptions = { 
		method: "DELETE",
		headers: defaultHeaders,
		body: JSON.stringify({ imageUrl }),
	};
	return fetch(prefixDefaultApi.concat("fauna/image/delete"), requestOptions)
  	.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function getMarcacao() {
	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("fauna/marcacao"), requestOptions)
  	.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}