import { projectsHandler } from '../handlers/projectsHandler';
import { actionTypes } from '../types/actionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const projectsActions = {
    getProjects,
    paginateProjects,
    createProject,
    updateProject
  };
  
function getProjects(userId) {
    return dispatch => {
        dispatch(request());
        projectsHandler.getProjects(userId)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: actionTypes.GET_PROJECTS_REQUEST } }
    function success(projects) { return { type: actionTypes.GET_PROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: actionTypes.GET_PROJECTS_FAILURE, error } }
}

function paginateProjects(params, page) {
    return dispatch => {
        dispatch(request());
        projectsHandler.paginateProjects(params, page)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.LIST_PROJECTS_REQUEST } }
    function success(listProjects) { return { type: actionTypes.LIST_PROJECTS_SUCCESS, listProjects } }
    function failure(error) { return { type: actionTypes.LIST_PROJECTS_FAILURE, error } }
}

function createProject(params, page, callback) {
    return dispatch => {
        dispatch(request());
        projectsHandler.createProject(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Projeto criado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.CREATE_PROJECTS_REQUEST } }
    function success(listProjects) { return { type: actionTypes.CREATE_PROJECTS_SUCCESS, listProjects } }
    function failure(error) { return { type: actionTypes.CREATE_PROJECTS_FAILURE, error } }
}

function updateProject(params, page, callback) {
    return dispatch => {
        dispatch(request());
        projectsHandler.updateProject(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Projeto atualizado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.UPDATE_PROJECTS_REQUEST } }
    function success(listProjects) { return { type: actionTypes.UPDATE_PROJECTS_SUCCESS, listProjects } }
    function failure(error) { return { type: actionTypes.UPDATE_PROJECTS_FAILURE, error } }
}