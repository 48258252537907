import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, FormControl, InputLabel, Select, MenuItem, } from "@material-ui/core"

import FilterDate from './filterDate'
import { viveirosHelpers } from '../../../helpers/flora/viveiros/helpers';

class filter extends Component {

  render() {
    const { dispatch, viveirosReducer, context, projectsReducer } = this.props
    const { filters, options, documents } = viveirosReducer

    return (
      <>
        <div className="filter">
          <Typography variant="h5" component="h5" className="filterTitle">
            Pesquisar
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <>
                {context === 'viveirosResgate' &&
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="numeroLote"
                      label="Número do Lote"
                      className="inputDefault"
                      value={filters.numeroLote}
                      onChange={(event) => dispatch(viveirosHelpers.handleChangeFilter(event, "numeroLote"))}
                    />
                  </Grid>
                }
                {context === 'viveirosProducao' &&
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="numeroFicha"
                      label="Número do Lote"
                      className="inputDefault"
                      value={filters.numeroFicha}
                      onChange={(event) => dispatch(viveirosHelpers.handleChangeFilter(event, "numeroFicha"))}
                    />
                  </Grid>
                }
                <Grid item xs={12} md={4}>
                  <TextField
                    id="nomeCientifico"
                    label="Nome Científico"
                    className="inputDefault"
                    value={filters.nomeCientifico}
                    onChange={(event) => dispatch(viveirosHelpers.handleChangeFilter(event, "nomeCientifico"))}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="nomePopular"
                    label="Nome Popular"
                    className="inputDefault"
                    value={filters.nomePopular}
                    onChange={(event) => dispatch(viveirosHelpers.handleChangeFilter(event, "nomePopular"))}
                  />
                </Grid>
                <FilterDate filterProps={filters} dispatch={dispatch} context={context} />
              </>
              <Divider className="dividerFull" />
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => this.props.handleModal(context, true)}
                >
                  {context === 'viveirosResgate' ? 'NOVO LOTE' : 'NOVA FICHA'}
                </Button>
              </Grid>
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => dispatch(viveirosHelpers.exportData(context, filters))}
                >
                  EXPORTAR
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={
                    () => {
                      if (context === 'viveirosResgate') {
                        dispatch(viveirosHelpers.paginateResgate(filters, 1, projectsReducer.selectedProject))
                      } else {
                        dispatch(viveirosHelpers.paginateProducao(filters, 1, projectsReducer.selectedProject))
                      }
                    }
                  }
                >
                  PESQUISAR
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        <div id="qntCounter">
          <p>
            {`Quantidade de exemplares no viveiro: `}
            <span>{documents.qntCounter ? documents.qntCounter : '0' }</span>
          </p>
        </div>
      </>
    )
  }
}

const mapStateToProps = store => ({
  viveirosReducer: store.viveirosReducer,
  paramsReducer: store.paramsReducer,
  projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(filter);