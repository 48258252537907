import { especiesHandler } from '../handlers/especiesHandler';
import { especiesActionTypes } from '../types/especiesActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const especiesActions = {
    list,
    paginate,
    create,
    update,
    getOne,
  };
function getOne(codigo){
    return async dispatch => {
        dispatch({ type: especiesActionTypes.UPDATE_ESPECIES_REQUEST })
        const response = await especiesHandler.getOne(codigo)
        try { 
            dispatch({ type: especiesActionTypes.UPDATE_ESPECIES_SUCCESS, response })
        } catch(error) {
            apiHelpers.handleError(
                dispatch,
                error,
                { type: especiesActionTypes.GET_ESPECIES_FAILURE, error },
                true
            );
            dispatch(alertActions.error(error.errorMsg));
        }
    }
}  
function list(name, qnt) {
    return dispatch => {
        dispatch(request());
        especiesHandler.list(name, qnt)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: especiesActionTypes.GET_ESPECIES_REQUEST } }
    function success(places) { return { type: especiesActionTypes.GET_ESPECIES_SUCCESS, places } }
    function failure(error) { return { type: especiesActionTypes.GET_ESPECIES_FAILURE, error } }
}

function paginate(params, page) {
    return dispatch => {
        dispatch(request());
        especiesHandler.paginate(params, page)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: especiesActionTypes.LIST_ESPECIES_REQUEST } }
    function success(list) { return { type: especiesActionTypes.LIST_ESPECIES_SUCCESS, list } }
    function failure(error) { return { type: especiesActionTypes.LIST_ESPECIES_FAILURE, error } }
}

function create(params, page, callback) {
    return dispatch => {
        dispatch(request());
        especiesHandler.create(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Projeto criado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: especiesActionTypes.CREATE_ESPECIES_REQUEST } }
    function success(list) { return { type: especiesActionTypes.CREATE_ESPECIES_SUCCESS, list } }
    function failure(error) { return { type: especiesActionTypes.CREATE_ESPECIES_FAILURE, error } }
}

function update(params, page, callback) {
    return dispatch => {
        dispatch(request());
        especiesHandler.update(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Projeto atualizado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: especiesActionTypes.UPDATE_ESPECIES_REQUEST } }
    function success(list) { return { type: especiesActionTypes.UPDATE_ESPECIES_SUCCESS, list } }
    function failure(error) { return { type: especiesActionTypes.UPDATE_ESPECIES_FAILURE, error } }
}