
import { faunaActions } from "../../actions/faunaActions"
import { faunaActionTypes } from "../../types/faunaActionTypes"
import { history } from '../history';
import moment from 'moment'

export const faunaHelpers = {
  // paginate,
  listFauna,
  listTelemetria,
  getTelemetria,
  getById,
  update,
  create,
  faunaDetails,
  handleInput,
  handleEspecies,
  handleRadios,
  handleDenticao,
  handleDate,
  handlePlace,
  handleCoordinates,
  exportData,
  handleCardNumber,
  plotOnMap,
  drawPolygonOnMap,
  addRecapture,
  addNewPontoMonitoramento,
  handlePontosObjs,
  saveMonitoramento,
  editMonitoramento,
  deleteMonitoramento,
  resetMonitoramento,
  clearTelemetriaData,
  updateTelemetria,
  createTelemetria,
  telemetriaDetails,
  handleMarcacao,
  handleTrap,
  clearFaunaCard,
  setFaunaFilter,
  deleteCard,
  trimFields,
  HandleInputNumber
}

function exportData(parameters, projectId) {
  return dispatch => {
    dispatch(faunaActions.exportFauna(parameters, projectId))

  }
}

function listFauna(parameters, page, projectId) {
  return dispatch => {
    dispatch(faunaActions.listFauna(parameters, page, projectId))
  }
}

function listTelemetria(parameters, page, projectId) {
  return dispatch => {
    dispatch(faunaActions.listTelemetria(parameters, page, projectId))
  }
}

function getTelemetria(parameters, page, projectId) {
  return dispatch => {
    dispatch(faunaActions.getTelemetria(parameters, page, projectId))
  }
}

function getById(parameters, page, projectId) {
  return dispatch => {
    dispatch(faunaActions.listById(parameters, page, projectId))
  }
}

function update(parameters, page) {
  return dispatch => {
    dispatch(faunaActions.update(parameters, page, backtofauna()))
  }
}

function deleteCard(parameters) {
  return dispatch => {
    dispatch(faunaActions.deleteCard(parameters, backtofauna()))
  }
}

function create(parameters, page) {
  return dispatch => {
    dispatch(faunaActions.create(parameters, page))
  }
}

function backtofauna() { return history.push('/fauna/ficha') }

function setFaunaCard(card, prefix) { return { type: faunaActionTypes[`SET_${prefix}_CARD`], card } }

function faunaDetails(ficha, prefix = "FAUNA") {
  const context = prefix === "FAUNA" ? 'ficha' : prefix
  return dispatch => {
    dispatch(setFaunaCard(ficha, prefix))
    return history.push(`${context.toLowerCase()}/detalhes/${prefix === "FAUNA" ? ficha?.numeroFicha ? ficha.numeroFicha : '' : ficha?.codigo ? ficha.codigo : ''}`)
  }
}
async function handleMarcacao(myValue, target, dispatch) {
  if(myValue.target.value === 'Sem marcação'){
    const objetoAnimal = await faunaActions.getMarcacao(dispatch)
    handleInput(objetoAnimal.numeroAnimal, 'numeroAnimal', dispatch)
  }
  handleInput(myValue, target, dispatch)
}

function handleInput(myValue, target, dispatch, context = "INPUT", type = 'string') {
  let validValue = myValue === undefined ? "" : myValue
  let value = validValue?.target?.value ?? validValue
  let valueType = type === 'string' ? value : value > 0 ? Number(value) : null

  return dispatch(saveStore(valueType, target, faunaActionTypes[`HANDLE_${context}_FIELDS`]));
}

function handleCardNumber(myValue, projects, selectedCard, classTypes, dispatch, cardType, selectedClass, projectId) {
  let animalClassId = selectedClass ? selectedClass : selectedCard.classe
  let onlyNumber = myValue.replace(/[^\d]+/g, '')
  let cardNumber = ("000000" + onlyNumber).slice(onlyNumber.length)
  let classeAnimal = ''
  let selectedProject = !!projectId ? projectId : selectedCard.projectId
  let currentProject = projects.listProjects.filter(project => project.projectId === selectedProject)

  if(selectedCard.ordem === 'Chiroptera') {
    classeAnimal = 'Mor'
  }
  else if(selectedCard.ordem === 'Rodentia' || selectedCard.ordem === 'Rodontia' ) {
    classeAnimal = 'Roe'
  }
  else if(selectedCard.ordem === 'Squamata' || selectedCard.ordem === 'Squamata - Anfisbênias' || selectedCard.ordem === 'Squamata - Serpentes') {
    classeAnimal = 'CS'
  }
  else if(selectedCard.ordem === 'Squamata - Lagartos' || selectedCard.ordem === 'Crocodylia') {
    classeAnimal = 'JL'
  }
  else if(selectedCard.ordem === 'Testudines') {
    classeAnimal = 'Que'
  }
  else{
    classeAnimal = animalClassId && animalClassId !== "" && animalClassId !== "--" ? animalClassId.substr(0, 3).normalize('NFD').replace(/[\u0300-\u036f]/g, "") : ''
  }
  let tipoFichaStore = selectedCard.cardType && selectedCard.cardType !== '' ? selectedCard.cardType === 'Captura' ? 'C' : selectedCard.cardType === 'Recebimento' ? 'R' : selectedCard.cardType === 'Ocorrência' ? 'O' : selectedCard.cardType === 'Recaptura' ? 'C' : '' : ''
  let tipoFichaParams = cardType && cardType !== '' ? cardType === 'Captura' ? 'C' : cardType === 'Recebimento' ? 'R' : cardType === 'Ocorrência' ? 'O' : cardType === 'Recaptura' ? 'C' : '' : ''
  let nick = currentProject && currentProject.length > 0 ? currentProject[0].projectNick : ''
  let value = `${nick}${classeAnimal}${cardType ? tipoFichaParams : tipoFichaStore}${cardNumber}`

  return dispatch(saveStore(value, 'numeroFicha', faunaActionTypes.HANDLE_INPUT_FIELDS));
}

function handleEspecies(myValue, target, listEspecies, selectedCard, dispatch, context = "INPUT") {
  let value = context === 'TELEMETRIA' ? myValue : myValue.target.value
  let myEspecie = listEspecies.filter(especie => especie.nomeCientifico === value)
  let myCard = selectedCard

  if (myEspecie.length > 0) {
    myCard.nomeCientifico = myEspecie[0].nomeCientifico
    myCard.classe = myEspecie[0].classe
    myCard.ordem = myEspecie[0].ordem
    myCard.familia = myEspecie[0].familia
    myCard.subFamilia = myEspecie[0].subFamilia
    myCard.genero = myEspecie[0].genero
    myCard.especie = myEspecie[0].especie
    myCard.subEspecie = myEspecie[0].subEspecie
    myCard.nomePopular = myEspecie[0].nomePopular
    myCard.especieId = myEspecie[0]._id

    return dispatch(saveStore(myCard, undefined, context === 'INPUT' ? faunaActionTypes.HANDLE_ESPECIES : faunaActionTypes.HANDLE_TELEMETRIA_ESPECIES));
  } else {
    return dispatch(saveStore(myValue.target.value, target, faunaActionTypes[`HANDLE_${context}_FIELDS`]));
  }

}

function handleRadios(myValue, target, dispatch) {

  return dispatch(saveStore(!myValue, target, faunaActionTypes.HANDLE_INPUT_FIELDS));
}

function handleDenticao(myValue, target, denticaoObj, dispatch) {

  let myDenticaoObj = denticaoObj ? denticaoObj : {}

  myDenticaoObj[target] = myValue.target.value

  return dispatch(saveStore(myDenticaoObj, 'denticao', faunaActionTypes.HANDLE_INPUT_FIELDS));

}

function handleDate(myValue, target, formatedDate, dispatch) {
  let value = target === 'date' || target === 'endDate' || target === 'endDateAlert' || target === 'dataCaptura' || target === 'dataRecebimento' || target === 'dataObs' || target === 'dataSoltura' || target === 'dataManipulacao' || target === 'obitoData' ? myValue : myValue.target.value

  let myDate = formatedDate ? formatedDate : {}

  myDate[target] = value

  dispatch(saveStore(myDate, 'formatedDate', faunaActionTypes.HANDLE_INPUT_FIELDS));
  dispatch(saveStore(value, target, faunaActionTypes.HANDLE_INPUT_FIELDS));
}

function saveStore(value, target, context) { return { type: context, value, target } }

function handleTrap(myValue, trapList, selectedCard, dispatch, objectName) {
  const myTrap = trapList.find(trap => trap.trapId === myValue.target.value)
  let myCard = selectedCard

  const activePosition = myTrap?.position.find(item => item.status === true)

  myCard.armadilha = myTrap?.trapId ?? myValue.target.value
  myCard[objectName] = { coordX: activePosition?.coordX ?? '', coordY: activePosition?.coordY ?? '', coordProj: activePosition?.projecao ?? '' }
  myCard.localCaptura = ''
  myCard.epe = activePosition?.EPE ?? ''

  return dispatch(saveStore(myCard, null, faunaActionTypes.HANDLE_INPUT_ALLFIELDS));
}

function handlePlace(myValue, target, places, selectedCard, dispatch, objectName) {
  let myPlace = places.find(place => place._id === myValue.target.value)
  let myCard = selectedCard

  myCard[target] = myPlace?._id ?? ''
  myCard.ambiente = myPlace?.ambiente ?? ''
  myCard[objectName] = { coordX: myPlace?.places[0]?.coodX ?? '', coordY: myPlace?.places[0]?.coodY ?? '', coordProj: myPlace ? 'UTM 23' : '' }

  if(selectedCard.armadilha !== 'Busca ativa') myCard.armadilha = ''
  if (target !== 'localSoltura') myCard.epe = myPlace?.places[0]?.EPE ?? ''
  if (target === 'localSoltura') myCard.epeSoltura = myPlace?.places[0]?.EPE ?? ''

  return dispatch(saveStore(myCard, undefined, faunaActionTypes.HANDLE_INPUT_FIELDS));
}

function handleCoordinates(myValue, target, objectName, spotObject, dispatch) {
  let myPlace = myValue.target.value
  let myCoordinate = spotObject

  myCoordinate[target] = myPlace

  return dispatch(saveStore(myCoordinate, target, faunaActionTypes.HANDLE_INPUT_FIELDS));
}

function plotOnMap(listObjects, callback) {
  const coordArray = []
  listObjects?.map(item => {
    if (item.pontoCaptura && (item.cardType === 'Captura' || item.cardType === 'Recaptura') && item?.pontoCaptura?.coordX !== '' && item?.pontoCaptura?.coordY !== '') {
      coordArray.push({ coordX: item.pontoCaptura.coordX, coordY: item.pontoCaptura.coordY, coordProj: item.pontoCaptura.coordProj, numeroFicha: item.numeroFicha, spotType: item.cardType })
    }
    if (item.pontoSoltura && (item.cardType === 'Captura' || item.cardType === 'Recaptura') && item?.pontoSoltura?.coordX !== '' && item?.pontoSoltura?.coordY !== '') {
      coordArray.push({ coordX: item.pontoSoltura.coordX, coordY: item.pontoSoltura.coordY, coordProj: item.pontoSoltura.coordProj, numeroFicha: item.numeroFicha, spotType: item.cardType })
    }
    if (item.pontoObs && item.cardType === 'Ocorrência' && item?.pontoObs?.coordX !== '' && item?.pontoObs?.coordY !== '') {
      coordArray.push({ coordX: item.pontoObs.coordX, coordY: item.pontoObs.coordY, coordProj: item.pontoObs.coordProj, numeroFicha: item.numeroFicha, spotType: item.cardType })
    }
  })
  if(coordArray.length === 0) return
  callback('faunaSpot', coordArray);
}

function drawPolygonOnMap(geoPoints, callback){
  return callback('mapPolygon', geoPoints);
}

function addRecapture(mainCardData) {
  const ficha = {
    cardType: 'Recaptura',
    numeroCaptura: mainCardData.numeroFicha,
    numeroAnimal: mainCardData.numeroAnimal,
    projectId: mainCardData.projectId,
    marcacao: mainCardData.marcacao,
    classe: mainCardData.classe,
    ordem: mainCardData.ordem,
    familia: mainCardData.familia,
    subFamilia: mainCardData.subFamilia,
    genero: mainCardData.genero,
    especie: mainCardData.especie,
    subEspecie: mainCardData.subEspecie,
    nomeCientifico: mainCardData.nomeCientifico,
    nomePopular: mainCardData.nomePopular,
    radiotransmissor: mainCardData.radiotransmissor,
    frequencia: mainCardData.frequencia,
    corAnilha: mainCardData.corAnilha
  }

  return async dispatch => {
    await dispatch(clearFaunaCard(ficha))
    await dispatch(setRecapturaCard(ficha))
    return history.push('/fauna/ficha/detalhes')
  }
}

function clearFaunaCard(card) { return { type: faunaActionTypes.CLEAR_CARD, card } }

function setRecapturaCard(card) { return { type: faunaActionTypes.SET_RECAPTURA_CARD, card } }

function addNewPontoMonitoramento(module, dispatch) {
  let newObj = module;
  let emptyModule = {
    local: '',
    dataRegistro: moment().format(),
    coordX: '',
    coordenadaY: '',
    EPE: '',
    azimut: '',
    projecao: '',
    sinal: '',
    intensidade: '',
    contato: '',
    observacoes: ''
  }

  newObj.push(emptyModule)

  return dispatch(setNewPontoMonitoramento(newObj));
}

function setNewPontoMonitoramento(card) {
  return { type: faunaActionTypes.SET_PONTO_MONITORAMENTO, card };
}

function setFaunaFilter(value, target) {return { type: faunaActionTypes.SET_FAUNA_FILTER, value, target }}

function handlePontosObjs(module, index, target, myValue, objDataName, dispatch, callback, type = 'string') {


	let newObj = module[objDataName];

  newObj[index][target] = type === 'string' ? myValue ?? '' : Number(myValue) ?? 0

	dispatch(saveStore(newObj, objDataName, faunaActionTypes.HANDLE_PONTOS_FIELDS));

	if (callback) callback(dispatch)
}

function saveMonitoramento(monitoramento){
  return {type: faunaActionTypes.SAVE_MONITORAMENTO, monitoramento}
}

function resetMonitoramento(){
  return {type: faunaActionTypes.RESET_MONITORAMENTO}
}

function clearTelemetriaData(){
  return {type: faunaActionTypes.RESET_TELEMETRIA_CARD}
}

function editMonitoramento(monitoramento, callback){
  if(callback) callback()
  return {type: faunaActionTypes.EDIT_MONITORAMENTO, monitoramento}
}

function deleteMonitoramento(itemId, monitoramentos, callback){
  const monitoramentosObject = monitoramentos.filter(i => i.id !== itemId)
  if(callback) callback()
  return {type: faunaActionTypes.DELETE_MONITORAMENTO, monitoramentosObject}
}

function updateTelemetria(dataObject, callback, dispatch) { 
  return dispatch(faunaActions.updateTelemetria(dataObject, callback))
}

function createTelemetria(dataObject, callback, projectId, dispatch){ 
  return dispatch(faunaActions.createTelemetria(dataObject, projectId, callback))
}

function telemetriaDetails() {
  return history.push('/fauna/telemetria/detalhes')
}

function trimFields(inputedData, dispatch) {
  if(inputedData){
    const fields = [
      'compFocinhoAnus',
      'compCauda',
      'compTotal',
      'peso',
      'compMembroPost',
      'alturaCernelha',
      'alturaOrelha',
      'compFocinhoCranio',
      'antebraco',
      'envergadura',
      'compPe',
      'compCloacaCauda',
      'compCranio',
      'compFocinhoCloaca',
      'compFocinhoCauda',
      'compCasco',
      'largCasco',
      'compplastrao',
      'largplastrao',
      'compDedoPost',
      'compBico',
      'larguraBico',
      'alturaBico',
      'compTarso',
      'compAsa'
    ]
  
    fields.map(field => {
      let myValue = inputedData[field]
      if(myValue){
        let replaced = myValue.replace(/[^\d.-]/g, '')
        handleInput(replaced, field, dispatch)
      }
    })
  }
  return
}

function HandleInputNumber(event, context, dispatch) {
  let myValue = event?.target?.value
  let replaced = myValue.replace(/[^\d.-]/g, '')
  handleInput(replaced, context, dispatch)
}