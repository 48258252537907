import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";
import moment from "moment";
import fileDownload from "js-file-download";

export const lenhaHandler = { 
	listLenha, 
	paginateLenha, 
	createLenha,
	updateLenha,
	deleteLenha,
	listLenhaSaida, 
	paginateLenhaSaida, 
	createLenhaSaida,
	updateLenhaSaida,
	deleteLenhaSaida,
	calculateLenhaVolume,
	exportLenha
};

function listLenha() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/lenha/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateLenha(params, page, projectId) {

	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $numeroFicha = params?.numeroFicha ? `&numeroFicha=${params.numeroFicha}` : ""
	const $areaDemarcada = params?.areaDemarcada ? `&areaDemarcada=${params.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)


	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/lenha/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createLenha(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $numeroFicha = filters?.numeroFicha ? `&numeroFicha=${filters.numeroFicha}` : ""
	const $areaDemarcada = filters?.areaDemarcada ? `&areaDemarcada=${filters.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/lenha").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateLenha(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $numeroFicha = filters?.numeroFicha ? `&numeroFicha=${filters.numeroFicha}` : ""
	const $areaDemarcada = filters?.areaDemarcada ? `&areaDemarcada=${filters.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/lenha").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function deleteLenha(params, page, projectId, deletedCard) {

	const $deletedCard = `&deletedCard=${deletedCard}`

	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $numeroFicha = params?.numeroFicha ? `&numeroFicha=${params.numeroFicha}` : ""
	const $areaDemarcada = params?.areaDemarcada ? `&areaDemarcada=${params.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($deletedCard)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)


	const requestOptions = { method: "DELETE", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/lenha").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function exportLenha(params, projectId) {
	
	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $numeroFicha = params?.numeroFicha ? `&numeroFicha=${params.numeroFicha}` : ""
	const $areaDemarcada = params?.areaDemarcada ? `&areaDemarcada=${params.areaDemarcada}` : ""
    
	const queryParams = "?project=".concat(projectId)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/lenha/export").concat(queryParams), requestOptions)
	.then( res => {
		apiHelpers.handleStatus(res)
		if(res?.status === 400){
			return res
		}else{
			return res.blob()
		}
	})
	.then( blob => {
		if(blob?.status === 400){
			return "erro"
		}else{
			fileDownload(blob, `${moment().format('YYYY-MM-DD-hh-mm-ss')}_export_lenha.xlsx`);
		}
	})
}

function listLenhaSaida() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/lenha/saida/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateLenhaSaida(page, projectId) {


	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($project)


	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/lenha/saida/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createLenhaSaida(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $tipo = filters?.tipo ? `&tipo=${filters.tipo}` : ""
	const $volumeTotal = filters?.volumeTotal ? `&volumeTotal=${filters.volumeTotal}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($tipo)
	.concat($volumeTotal)
	.concat($project)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/lenha/saida").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateLenhaSaida(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $tipo = filters?.tipo ? `&tipo=${filters.tipo}` : ""
	const $volumeTotal = filters?.volumeTotal ? `&volumeTotal=${filters.volumeTotal}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($tipo)
	.concat($volumeTotal)
	.concat($project)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/lenha/saida").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function deleteLenhaSaida(page, projectId, deletedCard) {

	const $deletedCard = `&deletedCard=${deletedCard}`
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($deletedCard)
	.concat($project)


	const requestOptions = { method: "DELETE", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/lenha/saida").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function calculateLenhaVolume(projectId) {
	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/lenha/volume").concat(`?project=${projectId}`), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}
