import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";
import qs from 'qs'

export const instListaHandler = { 
	list, 
	paginate, 
	create,
	update
};

function list() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("instlista/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginate(params, page) {
	const $name = params && params.name ? `&name=${params.name}` : ""
	const $nickname = params && params.nickname ? `&nickname=${params.nickname}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($name)
	.concat($nickname)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("instlista/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}


function create(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("instlista/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function update(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("instlista/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}