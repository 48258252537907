import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableContainer, Table, TableBody, Button, Divider } from '@material-ui/core'
import { Pagination, Alert } from '@material-ui/lab';
import Header from '../../../Components/headerAlter'
import Filter from '../../../Components/flora/areaPlantio/filter'
import CustomModal from '../../../Components/customModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { history } from '../../../helpers/history'
import { helpers } from '../../../helpers/helpers'
import { areaPlantioHelpers } from '../../../helpers/flora/areaPlantio/helpers'
import { placesHelpers } from '../../../helpers/places/placesHelpers';

class AreaPlantio extends Component {

  constructor(props){
    super(props);
    this.state = {
        modal: {showModal: false, modalType: undefined}
    }
  }

  componentDidMount(){
    const { dispatch, areaPlantioReducer, projectsReducer } = this.props
    dispatch(helpers.saveLocation(history.location.pathname));
    dispatch(areaPlantioHelpers.paginateAreaPlantio(areaPlantioReducer.areaPlantio.filters, areaPlantioReducer.areaPlantio.documents.page, projectsReducer.selectedProject))
    dispatch(placesHelpers.listPlaces(projectsReducer.selectedProject, '2'));
  }

  handleModal = (modalType, clear) => {
    const { modal } = this.state
    const { dispatch } = this.props
    this.setState({modal:{ showModal: !modal.showModal, modalType: modalType}})
    if(clear){
        dispatch(areaPlantioHelpers.editCard('areaPlantio', false))
    }
  }

  render(){
    const { areaPlantioReducer, dispatch, alert, pageContext, pageTitle, projectsReducer } = this.props
    const { modal } = this.state
    const { areaPlantio, editItem } = areaPlantioReducer
    const { documents, filters } = areaPlantio
    const { page, pagesTotal } = documents
    return (
      <div className='mainContainer'>
        <Header title={pageTitle}/>
        <div className='basicContainer'>
          {alert && alert.type === 'alert-danger' && alert.message &&
                  <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
          }
          {alert && alert.type === 'alert-success' && alert.message &&
                  <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
          }
          <Filter context={'areaPlantio'} handleModal={this.handleModal}/>
          {documents && documents.list && documents.list.length > 0 &&
            <Grid container>
              <TableContainer className="customTable paramsTable">
                <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <TableHead className="tableHead">
                    <Grid component={'tr'} container className="parametersTable">
                      <Grid component={'th'} item xs={12} md={2}>Código</Grid>
                      <Grid component={'th'} item xs={12} md={2}>Tipo</Grid>
                      <Grid component={'th'} item xs={12} md={2}>Status</Grid>
                      <Grid component={'th'} item xs={12} md={4}>Total indivíduos</Grid>
                      <Grid component={'th'} item xs={12} md={1}>{'Área (m²)'}</Grid>
                      <Grid component={'th'} item xs={12} md={1}></Grid>
                    </Grid>
                  </TableHead>
                  <TableBody>
                    {documents.list.map((item, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let individuos = ''

                      let muda = 0
                      let arvore = 0
                      let arvoreta = 0
                      let epifita = 0
                      let arvoreText = ''
                      let mudaText = ''
                      let arvoretaText = ''
                      let epifitaText = ''

                      
                      item.individuos.map((individuo) => {
                        if(individuo.tipo === 'Epífita') epifita += parseInt(individuo.numeroDeIndividuos) || 0
                        if(individuo.tipo === 'Arbóreo - Muda') muda += parseInt(individuo.numeroDeIndividuos) || 0
                        if(individuo.tipo === 'Arbóreo - Árvore') arvore += parseInt(individuo.numeroDeIndividuos) || 0
                        if(individuo.tipo === 'Arbóreo - Arvoreta') arvoreta += parseInt(individuo.numeroDeIndividuos) || 0
                        arvoreText = `${arvore > 0 ? `${arvore} - árvores` : ''}`
                        mudaText = `${muda === 0 ? '' : arvore === 0 ? `${muda} - mudas` : `, ${muda} - mudas`}`
                        arvoretaText = `${arvoreta === 0 ? '' : (arvore === 0 && muda === 0) ? `${arvoreta} - arvoretas` : `, ${arvoreta} - arvoretas`}`
                        epifitaText = `${epifita === 0 ? '' : (arvore === 0 && muda === 0 && arvoreta === 0) ? `${epifita} - epífitas` : `, ${epifita} - epífitas`}`
                        
                        individuos = `${arvoreText}${mudaText}${arvoretaText}${epifitaText}`
                      })

                      return (
                        <Grid component={'tr'} container className="parametersTable" key={index} id={labelId}>
                          <Grid component={'td'} item xs={12} md={2}>{item?.codigoDaArea}</Grid>
                          <Grid component={'td'} item xs={12} md={2}>{item?.tipo}</Grid>
                          <Grid component={'td'} item xs={12} md={2}>{item?.status}</Grid>
                          <Grid component={'td'} item xs={12} md={4}>{individuos}</Grid>
                          <Grid component={'td'} item xs={12} md={1}>{item?.area?.replace(/[^\d.-]/g, '')}</Grid>
                          <Grid component={'td'} item xs={12} md={1}>
                            <Button
                              variant="contained"
                              className="btn-secondary btn-innerTable"
                              onClick={() => dispatch(areaPlantioHelpers.editCard('areaPlantioEdicao', item, areaPlantioHelpers.areaPlantio))}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider className="dividerFull"/>
              <Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, filters, page, 'areaPlantio', projectsReducer.selectedProject))} />
            </Grid>
          }
          {documents && documents.list && documents.list.length === 0 &&
            <Alert className="alert alert-warning" severity="warning">Não foram encontrados resultados com os filtros aplicados.</Alert>
          }
        </div>
        {modal.showModal &&
          <CustomModal modalFn={this.handleModal} modalType={modal.modalType}/>
        }
      </div>
    )
  }
}

const mapStateToProps = store => ({
  areaPlantioReducer: store.areaPlantioReducer,
  projectsReducer: store.projectsReducer,
  paramsReducer: store.paramsReducer,
  auth: store.loginReducer.auth,
  alert: store.alertReducer
});

export default connect(mapStateToProps)(AreaPlantio);