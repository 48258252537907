import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const areaMonitoramentoHandler = { 
	listAreaMonitoramento, 
	paginateAreaMonitoramento, 
	createAreaMonitoramento,
	updateAreaMonitoramento,
	deleteAreaMonitoramento
};

function listAreaMonitoramento() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/areaMonitoramento/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateAreaMonitoramento(params, page, projectId) {

	const $codigoDaArea = params?.codigoDaArea ? `&codigoDaArea=${params.codigoDaArea}` : ""
	const $tipo = params?.tipo ? `&tipo=${params.tipo}` : ""
	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params?.endDate ? `&endDate=${params.endDate}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($codigoDaArea)
	.concat($tipo)
	.concat($initDate)
	.concat($endDate)
	.concat($project)


	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/areaMonitoramento/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createAreaMonitoramento(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $numeroFicha = filters?.numeroFicha ? `&numeroFicha=${filters.numeroFicha}` : ""
	const $areaDemarcada = filters?.areaDemarcada ? `&areaDemarcada=${filters.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/areaMonitoramento").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateAreaMonitoramento(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $numeroFicha = filters?.numeroFicha ? `&numeroFicha=${filters.numeroFicha}` : ""
	const $areaDemarcada = filters?.areaDemarcada ? `&areaDemarcada=${filters.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/areaMonitoramento").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function deleteAreaMonitoramento(params, page, projectId, deletedCard) {

	const $deletedCard = `&deletedCard=${deletedCard}`

	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $numeroFicha = params?.numeroFicha ? `&numeroFicha=${params.numeroFicha}` : ""
	const $areaDemarcada = params?.areaDemarcada ? `&areaDemarcada=${params.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($deletedCard)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)


	const requestOptions = { method: "DELETE", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/areaMonitoramento").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}
