import { raizesActions } from "../../../actions/raizesActions"
import { raizesActionTypes } from "../../../types/raizesActionTypes"
import { history } from '../../history';

function listRaizes(){
	return dispatch => {
		dispatch(raizesActions.listRaizes())
	}
}

function paginateRaizes(parameters, page, projectId) {
	return dispatch => {
		dispatch(raizesActions.paginateRaizes(parameters, page, projectId))
	}
}

function handleChangeFilter(event, target, context) {
	const value = event.target.value
	const prefix = context === 'raizes' ? 'RAIZES' : context === 'raizesSaida' ? 'RAIZES_SAIDA' : ''

	return dispatch => {
		dispatch(setFilter(value, target, prefix))
	}
}

function setFilter(value, target, prefix) { return { type: raizesActionTypes[`SET_${prefix}_FILTER`], value, target } }

function handleDateChangeInit(date, context) {
	const prefix = context === 'raizes' ? 'RAIZES' : context === 'raizesSaida' ? 'RAIZES_SAIDA' : ''

	return dispatch => {
		dispatch(handleDateInit(date, prefix))
	}
};


function handleDateChangeEnd(date, context) {
	const prefix = context === 'raizes' ? 'RAIZES' : context === 'raizesSaida' ? 'RAIZES_SAIDA' : ''

	return dispatch => {
		dispatch(handleDateEnd(date, prefix))
	}
};

function handleDateInit(date, prefix) { return { type: raizesActionTypes[`SET_${prefix}_FILTER_DATE_INIT`], date } }

function handleDateEnd(date, prefix) { return { type: raizesActionTypes[`SET_${prefix}_FILTER_DATE_END`], date } }



function editCard(context, item, callback) {
	if (item) {
		if (callback) { callback(context) }
		return { type: raizesActionTypes.EDIT_RAIZES_CARD, item, context }
	} else {
		return { type: raizesActionTypes.UPDATE_RAIZES_CARD_CANCELED }
	}
}

async function handleInput(myValue, target, context, dispatch, callback, isNumber) {
	let value = target === 'dataMedicao' || isNumber ? myValue : myValue.target.value

	await dispatch(saveStore(value, target, context));

	if (callback) callback(dispatch)
}

function HandleInputNumber(event, target, context, dispatch, callback) {
	let myValue = event?.target?.value
	let replaced = myValue.replace(/[^\d.-]/g, '')
	handleInput(replaced, target, context, dispatch, callback, true)
}

function saveStore(value, target, context) { return { type: raizesActionTypes.HANDLE_RAIZES_FIELDS, context, value, target } }

function calculateDiametroMedio(diametro1, diametro2, diametro3, comprimento, context, dispatch) {
	const valores = []
	if (diametro1 && (diametro1 !== '' && diametro1 !== '--' && Number(diametro1) !== 0)) valores.push(Number(diametro1))
	if (diametro2 && (diametro2 !== '' && diametro2 !== '--' && Number(diametro2) !== 0)) valores.push(Number(diametro2))
	if (diametro3 && (diametro3 !== '' && diametro3 !== '--' && Number(diametro3) !== 0)) valores.push(Number(diametro3))
	
	var media = 0
	
	valores.map(valor => media += valor)
	
	media /= valores.length
	if ((comprimento && Number(comprimento) > 0)) {
		calculateVolume(comprimento, media, context, dispatch)
	}
	
	dispatch(saveStore(media, 'diametroMedio', context));
}

function calculateVolume(comprimento, diametroMedio, context, dispatch) {
	let altura = parseFloat(comprimento)
	let raio = parseFloat(diametroMedio / 2)
	let volume = Math.PI * raio * raio * altura
	
	dispatch(saveStore(volume, 'volume', context));
}

function createRaizes(parameters, page, callback, projectId, params) {
	
	const data = {
		areaDemarcada: parameters.areaDemarcada,
		dataMedicao: parameters.dataMedicao,
		volume: parameters.volume,
		project: projectId
	}
	
	return dispatch => {
		dispatch(raizesActions.createRaizes(data, page, callback, projectId, params))
	}
}

function updateRaizes(parameters, page, callback, projectId, params) {
	
	const data = {
		_id: parameters._id,
		areaDemarcada: parameters.areaDemarcada,
		volumeTotal: parameters.volumeTotal,
		project: projectId ?? parameters.project,
		individuos: parameters.individuos
	}
	
	return dispatch => {
		dispatch(raizesActions.updateRaizes(data, page, callback, projectId, params))
	}
}

function deleteRaizes(cardId, individuos, dispatch) {
	const individuosList = individuos.filter(item => item._id !== cardId)
	updateVolumeTotal(individuosList, dispatch)
	
	return dispatch(saveIndividuos(individuosList))
}

function saveIndividuos(value) { return { type: raizesActionTypes.UPDATE_RAIZES_INDIVIDUOS, value } }

function updateVolumeTotal(individuos, dispatch) {
	let volumeTotal = 0
	individuos.map(item => volumeTotal += Number(item.volume))
	dispatch(saveVolumeStore(volumeTotal, 'volumeTotal', 'raizes'))
}

function saveVolumeStore(value, target, context) { return { type: raizesActionTypes.HANDLE_RAIZES_TOTAL_VOLUME, context, value, target } }

// Raizes Saida

function raizes() {
	return dispatch => {
		return history.push(`/flora/raizes`)
	}
}

function raizesSaidas() {
	return dispatch => {
		return history.push(`raizes/saidas`)
	}
}

function paginateRaizesSaida(page, projectId) {
	return dispatch => {
		dispatch(raizesActions.paginateRaizesSaida(page, projectId))
	}
}

async function handleSaidaInput(myValue, target, context, dispatch, callback, isNumber) {
	let value = target === 'data' || isNumber ? myValue : myValue.target.value

	await dispatch(saveSaidaStore(value, target, context));

	if (callback) callback(dispatch)
}

function HandleSaidaInputNumber(event, target, context, dispatch, callback) {
	let myValue = event?.target?.value
	let replaced = myValue.replace(/[^\d.-]/g, '')
	handleSaidaInput(replaced, target, context, dispatch, callback, true)
}

function saveSaidaStore(value, target, context) { return { type: raizesActionTypes.HANDLE_RAIZES_SAIDA_FIELDS, context, value, target } }

function editSaidaCard(context, item, callback) {
	if (item) {
		if (callback) { callback(context) }
		return { type: raizesActionTypes.EDIT_RAIZES_SAIDA_CARD, item, context }
	} else {
		return { type: raizesActionTypes.UPDATE_RAIZES_SAIDA_CARD_CANCELED }
	}
}

function createRaizesSaida(parameters, page, callback, projectId, params) {
	
	const data = {
		data: parameters.data,
		destino: parameters.destino,
		licenca: parameters.licenca,
		volume: parameters.volume,
		project: projectId
	}
	
	return dispatch => {
		dispatch(raizesActions.createRaizesSaida(data, page, callback, projectId, params))
	}
}

function updateRaizesSaida(parameters, page, callback, projectId, params) {
	
	const data = {
		_id: parameters._id,
		data: parameters.data,
		destino: parameters.destino,
		licenca: parameters.licenca,
		volume: parameters.volume,
		project: projectId ?? parameters.project,
	}
	
	return dispatch => {
		dispatch(raizesActions.updateRaizesSaida(data, page, callback, projectId, params))
	}
}

function deleteRaizesSaida(parameters, page, projectId, cardId, closeModal, dispatch) {
	dispatch(raizesActions.deleteRaizesSaida(parameters, page, projectId, cardId, closeModal))
}

function deleteRaiz(parameters, page, projectId, cardId, closeModal, dispatch) {
	dispatch(raizesActions.deleteRaizes(parameters, page, projectId, cardId, closeModal))
}

export const raizesHelpers = {
	handleChangeFilter,
	handleDateChangeInit,
	handleDateChangeEnd,
	editCard,
	handleInput,
	HandleInputNumber,
	calculateDiametroMedio,
	calculateVolume,
	listRaizes,
	paginateRaizes,
	createRaizes,
	updateRaizes,
	deleteRaizes,
	raizesSaidas,
	raizes,
	paginateRaizesSaida,
	handleSaidaInput,
	HandleSaidaInputNumber,
	createRaizesSaida,
	updateRaizesSaida,
	editSaidaCard,
	deleteRaizesSaida,
	deleteRaiz
}