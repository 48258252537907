import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, InputAdornment, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"
import { raizesHelpers } from '../../../helpers/flora/raizes/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

function RaizesSaidaForm(props) {

    const [formSubmited, setFormSubmited] = useState(false)

    const { dispatch, raizesReducer, projectsReducer, closeModal } = props
    const { raizesSaida } = raizesReducer
    const { editItem, filters, documents } = raizesSaida

    const formValidator = () => {
        const { raizesReducer } = props
        const { raizesSaida } = raizesReducer
        const { editItem } = raizesSaida
        const { data, volume } = editItem

        if (data !== null && volume !== '') {
            return true
        } else {
            return false
        }
    }

    const submitForm = () => {
        const { raizesReducer, dispatch, closeModal, projectsReducer } = props
        const { raizesSaida } = raizesReducer
        const { editItem, filters } = raizesSaida
        setFormSubmited(true)

        if (formValidator() === false) return

        if (editItem._id && editItem._id !== '') {
            dispatch(raizesHelpers.updateRaizesSaida(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        } else {
            dispatch(raizesHelpers.createRaizesSaida(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        }
    }

    return (
        <>
            <Typography variant="h5" component="h5" className="filterTitle">
                {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
            </Typography>
            <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} md={4}>
                            <KeyboardDatePicker
                                className="inputDefault dateInput"
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Data"
                                value={editItem.data}
                                onChange={(event) => raizesHelpers.handleSaidaInput(event, 'data', 'raizesSaida', dispatch)}
                                KeyboardButtonProps={{
                                    'aria-label': 'Alterar data',
                                }}
                                invalidDateMessage={'Data inválida'}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="destino"
                            label="Destino"
                            className="inputDefault"
                            value={editItem.destino}
                            autoComplete='off'
                            onChange={(event) => raizesHelpers.handleSaidaInput(event, 'destino', 'raizesSaida', dispatch)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="licenca"
                            label="Licença"
                            className="inputDefault"
                            value={editItem.licenca}
                            autoComplete='off'
                            onChange={(event) => raizesHelpers.handleSaidaInput(event, 'licenca', 'raizesSaida', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            id="volume"
                            label="Quantidade"
                            className="inputDefault"
                            value={editItem.volume}
                            autoComplete='off'
                            onChange={(event) => raizesHelpers.HandleSaidaInputNumber(event, 'volume', 'raizesSaida', dispatch)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                            }}
                            error={formSubmited && editItem.volume === ''}
                            helperText={formSubmited && editItem.volume === '' ? 'Campo obrigatório' : ''}
                        />
                    </Grid>

                    <Divider className="dividerFull" />
                    <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                        <Box padding={10}>
                            <Button variant="contained"
                                className="btn-primary"
                                component="label"
                                startIcon={<SaveIcon />}
                                onClick={() => submitForm()}> Salvar Ficha </Button>
                        </Box>
                    </Box>
                    {editItem._id && editItem._id !== '' &&
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained"
                                    className="btn-secondary"
                                    component="label"
                                    startIcon={<DeleteForeverIcon />}
                                    onClick={(event) => raizesHelpers.deleteRaizesSaida(filters, documents.page, projectsReducer.selectedProject, editItem._id, closeModal, dispatch)}> Excluir </Button>
                            </Box>
                        </Box>
                    }
                    <Divider className="dividerFull" />
                </Grid>
            </form>
        </>
    )
}

const mapStateToProps = store => ({
    raizesReducer: store.raizesReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(RaizesSaidaForm);