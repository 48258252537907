import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers'; 
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import moment from 'moment';

const AccordionRecebimento = ({ selectedCard, dispatch, params, documents, loggedUser, validateForm}) => {
    const environmentFilter = params && params.environmentType && params.environmentType.filter(environment => environment._id === selectedCard.ambiente)
    const validEnvironment = environmentFilter && environmentFilter.length <= 0 
    const documentFilter = documents && documents.filter(document => document._id === selectedCard.documento)
    const validDocument = documentFilter && documentFilter.length <= 0 
    const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

    return (
        <Grid container spacing={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                        className="inputDefault dateInput"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data do recebimento"
                        value={selectedCard.formatedDate && selectedCard.formatedDate.dataRecebimento ? selectedCard.formatedDate.dataRecebimento : null}
                        onChange={(event) => faunaHelpers.handleDate(event, 'dataRecebimento', selectedCard.formatedDate, dispatch)}
                        KeyboardButtonProps={{
                            'aria-label': 'Alterar data',
                        }}
                        invalidDateMessage={'Data inválida'}
                        disabled={!writeModuleVerify}
                        error={validateForm && !selectedCard.formatedDate.dataRecebimento}
                        helperText={validateForm && !selectedCard.formatedDate.dataRecebimento ? 'Campo obrigatório' : ''}
                        required
                    />
                </Grid>
            </MuiPickersUtilsProvider>
            
            <Grid item xs={12} md={4}>
                <TextField id="localRecebimento" 
                    disabled={!writeModuleVerify} 
                    label="Local do Recebimento" 
                    className="inputDefault" 
                    value={selectedCard.localRecebimento} 
                    onChange={(event) => faunaHelpers.handleInput(event, 'localRecebimento', dispatch)}
                    error={validateForm && (selectedCard.localRecebimento === '' || selectedCard.localRecebimento === '--')}
                    helperText={validateForm && (selectedCard.localRecebimento === '' || selectedCard.localRecebimento === '--') ? 'Campo obrigatório' : ''}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Ambiente</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.ambiente}
                        onChange={(event) => faunaHelpers.handleInput(event, 'ambiente', dispatch)}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem value="--">--</MenuItem>
                    {validEnvironment && selectedCard.ambiente !== '--' && selectedCard.ambiente !== '' &&
                        <MenuItem value={selectedCard.ambiente}>{selectedCard.ambiente}</MenuItem>
                    }
                    {params && params.environmentType.map((environment, index) => {
                        return <MenuItem key={index} value={environment._id}>{environment.environmentType}</MenuItem>
                    })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField id="manipulador" disabled={!writeModuleVerify} label="Manipulador" className="inputDefault" value={selectedCard.manipulador} onChange={(event) => faunaHelpers.handleInput(event, 'manipulador', dispatch)}/>
            </Grid>
            
            <Grid item xs={12} md={4}>
                <TextField id="orgaoResponsavel" disabled={!writeModuleVerify} label="Orgão responsável" className="inputDefault" value={selectedCard.orgaoResponsavel} onChange={(event) => faunaHelpers.handleInput(event, 'orgaoResponsavel', dispatch)}/>
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField id="licenca" disabled={!writeModuleVerify} label="Licença" className="inputDefault" value={selectedCard.licenca} onChange={(event) => faunaHelpers.handleInput(event, 'licenca', dispatch)}/>
            </Grid>

            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Documento</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.documento}
                        onChange={(event) => faunaHelpers.handleInput(event, 'documento', dispatch)}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem value="--">--</MenuItem>
                    {validDocument && selectedCard.documento !== '--' && selectedCard.documento !== '' &&
                        <MenuItem value={selectedCard.documento}>{selectedCard.documento}</MenuItem>
                    }
                    {documents && documents.map((document, index) => {
                        return <MenuItem key={index} value={document._id}>{document.gaiaCode}</MenuItem>
                    })}
                    </Select>
                </FormControl>
            </Grid>
        
            <Grid item xs={12} md={4}>
                <TextField id="amostraNumero" disabled={!writeModuleVerify} label="amostraNumero" className="inputDefault" value={selectedCard.amostraNumero} onChange={(event) => faunaHelpers.handleInput(event, 'amostraNumero', dispatch)}/>
            </Grid>

            <Grid item xs={12} md={12}>
                <TextField id="obsAdicionais" disabled={!writeModuleVerify} label="Observações adicionais" className="inputDefault" multiline rows={1} rowsMax={8} value={selectedCard.obsAdicionais} onChange={(event) => faunaHelpers.handleInput(event, 'obsAdicionais', dispatch)}/>
            </Grid>
        </Grid>
    )
}

export default AccordionRecebimento