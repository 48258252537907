export const documentsActionTypes = {
    GET_DOCUMENTS_REQUEST: "GET_DOCUMENTS_REQUEST",
    GET_DOCUMENTS_SUCCESS: "GET_DOCUMENTS_SUCCESS",
    GET_DOCUMENTS_FAILURE: "GET_DOCUMENTS_FAILURE",

    CREATE_DOCUMENTS_REQUEST: "CREATE_DOCUMENTS_REQUEST",
    CREATE_DOCUMENTS_SUCCESS: "CREATE_DOCUMENTS_SUCCESS",
    CREATE_DOCUMENTS_FAILURE: "CREATE_DOCUMENTS_FAILURE",

    UPLOAD_DOCUMENTS_REQUEST: "UPLOAD_DOCUMENTS_REQUEST",
    UPLOAD_DOCUMENTS_SUCCESS: "UPLOAD_DOCUMENTS_SUCCESS",
    UPLOAD_DOCUMENTS_FAILURE: "UPLOAD_DOCUMENTS_FAILURE",

    UPDATE_DOCUMENTS_REQUEST: "UPDATE_DOCUMENTS_REQUEST",
    UPDATE_DOCUMENTS_SUCCESS: "UPDATE_DOCUMENTS_SUCCESS",
    UPDATE_DOCUMENTS_FAILURE: "UPDATE_DOCUMENTS_FAILURE",
    UPDATE_DOCUMENTS_CANCELED: "UPDATE_DOCUMENTS_CANCELED",

    DELETE_DOCUMENTS_REQUEST: "DELETE_DOCUMENTS_REQUEST",
    DELETE_DOCUMENTS_SUCCESS: "DELETE_DOCUMENTS_SUCCESS",
    DELETE_DOCUMENTS_FAILURE: "DELETE_DOCUMENTS_FAILURE",
    DELETE_DOCUMENTS_CANCELED: "DELETE_DOCUMENTS_CANCELED",

    LIST_DOCUMENTS_REQUEST: "LIST_DOCUMENTS_REQUEST",
    LIST_DOCUMENTS_SUCCESS: "LIST_DOCUMENTS_SUCCESS",
    LIST_DOCUMENTS_FAILURE: "LIST_DOCUMENTS_FAILURE",

    HANDLE_INPUT_DOCUMENTS: "HANDLE_INPUT_DOCUMENTS",
    HANDLE_RADIOS_DOCUMENTS: "HANDLE_RADIOS_DOCUMENTS",
    HANDLE_DOCUMENTS: "HANDLE_DOCUMENTS",
    
    SET_DOCUMENTS_FILTER: "SET_DOCUMENTS_FILTER",
    EDIT_DOCUMENTS: "EDIT_DOCUMENTS",
    DELETE_DOCUMENTS: "DELETE_DOCUMENTS"
};