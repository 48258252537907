import { actionTypes } from "../types/actionTypes";

const initialState = {
  listUsers: {
    users: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0
  },
  filters: {
    nome: '',
    email: '',
  },
  editUser: {
    email: '',
    nome: '',
    status: true,
    user: '',
    module: [
      {description: 'fauna', read: false, write: false},
      {description: 'flora', read: false, write: false},
      {description: 'user', read: false, write: false},
      {description: 'admin', read: false, write: false},
      {description: 'params', read: false, write: false},
      {description: 'trap', read: false, write: false},
      {description: 'places', read: false, write: false},
      {description: 'especies', read: false, write: false},
      {description: 'especies_risk', read: false, write: false},
      {description: 'documents', read: false, write: false},
      {description: 'projects', read: false, write: false}
    ]
  }
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_REQUEST:
    case actionTypes.UPDATE_USER_REQUEST:
    case actionTypes.PAGINATE_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_USER_SUCCESS:
    case actionTypes.UPDATE_USER_SUCCESS:
    case actionTypes.PAGINATE_USERS_SUCCESS:
      return {
        ...state,
        listUsers: {users: action.listUsers.docs, total: action.listUsers.total, limit: action.listUsers.limit, page: Number(action.listUsers.page), pagesTotal: action.listUsers.pages},
        editUser: initialState.editUser,
        loading: false
      };
    case actionTypes.UPDATE_USER_CANCELED:
      return {
        ...state,
        editUser: initialState.editUser,
        loading: false
      };
    case actionTypes.CREATE_USER_FAILURE:
    case actionTypes.UPDATE_USER_FAILURE:
    case actionTypes.PAGINATE_USERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case actionTypes.SET_USERS_FILTER:
      return { 
        ...state, 
        filters: {
          ...state.filters, 
          [action.target]: action.value} 
        };
    case actionTypes.EDIT_USER:
      return { ...state, editUser: action.user };

    case actionTypes.HANDLE_INPUT_USERS:
      return {
        ...state,
        editUser: {...state.editUser, [action.target]: action.value}
      };
    case actionTypes.HANDLE_MODULE_USERS:
      return {
        ...state,
        editUser: {...state.editUser, module: action.value}
      };
    case actionTypes.LIST_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LIST_USERS_SUCCESS:
      return {
        ...state,
        listUsers: {...state.listUsers, users: action.listUsers},
        loading: false
      };
    case actionTypes.LIST_USERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};