import { palmitoHandler } from '../handlers/palmitoHandler';
import { palmitoActionTypes } from '../types/palmitoActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const palmitoActions = {
    listMatriz,
    paginateMatriz,
    createMatriz,
    updateMatriz,
    listTalhao,
    paginateTalhao,
    createTalhao,
    updateTalhao,
    listSaida,
    paginateSaida,
    createSaida,
    updateSaida,
  };

function listMatriz() {
    return dispatch => {
        dispatch(request());
        palmitoHandler.listMatriz()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: palmitoActionTypes.GET_PALMITO_MATRIZ_REQUEST } }
    function success(places) { return { type: palmitoActionTypes.GET_PALMITO_MATRIZ_SUCCESS, places } }
    function failure(error) { return { type: palmitoActionTypes.GET_PALMITO_MATRIZ_FAILURE, error } }
}

function paginateMatriz(params, page, project) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.paginateMatriz(params, page, project)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.LIST_PALMITO_MATRIZ_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.LIST_PALMITO_MATRIZ_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.LIST_PALMITO_MATRIZ_FAILURE, error } }
}

function createMatriz(params, page, callback, project) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.createMatriz(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateMatriz(false, page, project))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.CREATE_PALMITO_MATRIZ_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.CREATE_PALMITO_MATRIZ_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.CREATE_PALMITO_MATRIZ_FAILURE, error } }
}

function updateMatriz(params, page, callback) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.updateMatriz(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.UPDATE_PALMITO_MATRIZ_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.UPDATE_PALMITO_MATRIZ_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.UPDATE_PALMITO_MATRIZ_FAILURE, error } }
}

function listTalhao() {
    return dispatch => {
        dispatch(request());
        palmitoHandler.listTalhao()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: palmitoActionTypes.GET_PALMITO_TALHAO_REQUEST } }
    function success(places) { return { type: palmitoActionTypes.GET_PALMITO_TALHAO_SUCCESS, places } }
    function failure(error) { return { type: palmitoActionTypes.GET_PALMITO_TALHAO_FAILURE, error } }
}

function paginateTalhao(params, page, project) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.paginateTalhao(params, page, project)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.LIST_PALMITO_TALHAO_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.LIST_PALMITO_TALHAO_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.LIST_PALMITO_TALHAO_FAILURE, error } }
}

function createTalhao(params, page, callback, project) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.createTalhao(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateTalhao(false, page, project))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.CREATE_PALMITO_TALHAO_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.CREATE_PALMITO_TALHAO_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.CREATE_PALMITO_TALHAO_FAILURE, error } }
}

function updateTalhao(params, page, callback) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.updateTalhao(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.UPDATE_PALMITO_TALHAO_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.UPDATE_PALMITO_TALHAO_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.UPDATE_PALMITO_TALHAO_FAILURE, error } }
}

function listSaida() {
    return dispatch => {
        dispatch(request());
        palmitoHandler.listSaida()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: palmitoActionTypes.GET_PALMITO_SAIDA_REQUEST } }
    function success(places) { return { type: palmitoActionTypes.GET_PALMITO_SAIDA_SUCCESS, places } }
    function failure(error) { return { type: palmitoActionTypes.GET_PALMITO_SAIDA_FAILURE, error } }
}

function paginateSaida(params, page, project) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.paginateSaida(params, page, project)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.LIST_PALMITO_SAIDA_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.LIST_PALMITO_SAIDA_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.LIST_PALMITO_SAIDA_FAILURE, error } }
}

function createSaida(params, page, callback, project) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.createSaida(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateSaida(false, page, project))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.CREATE_PALMITO_SAIDA_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.CREATE_PALMITO_SAIDA_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.CREATE_PALMITO_SAIDA_FAILURE, error } }
}

function updateSaida(params, page, callback) {
    return dispatch => {
        dispatch(request());
        palmitoHandler.updateSaida(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: palmitoActionTypes.UPDATE_PALMITO_SAIDA_REQUEST } }
    function success(list) { return { type: palmitoActionTypes.UPDATE_PALMITO_SAIDA_SUCCESS, list } }
    function failure(error) { return { type: palmitoActionTypes.UPDATE_PALMITO_SAIDA_FAILURE, error } }
}