import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Divider, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Typography } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { faunaHelpers } from '../../../helpers/fauna/faunaHelpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import Header from '../../../Components/headerAlter';
import { especiesHelpers } from '../../../helpers/especies/especiesHelpers';
import CustomModal from '../../../Components/customModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { history } from '../../../helpers/history';
import { withRouter } from 'react-router-dom';

class TelemetriaDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formSubmited: false,
      modal: false
    }
  }
  componentDidMount() {
    const { dispatch } = this.props;
    const idFicha = this.props.match.params.idFicha
    // if(!!idFicha) 
    dispatch(faunaHelpers.getTelemetria(idFicha))
    // dispatch(especiesHelpers.list())
  }

  formValidator = () => {
    const { faunaReducer } = this.props
    const { selectedTelemetriaCard } = faunaReducer
    const { codigo, classe, ordem, familia, genero, especie, nomeCientifico, radiotransmissor, frequencia } = selectedTelemetriaCard

    if (codigo && classe !== '' && ordem !== '' && familia !== '' && genero !== '' && especie !== '' && nomeCientifico !== '' && radiotransmissor !== '' && frequencia !== '') {
      return true
    } else {
      return false
    }
  }

  submitForm = async () => {
    const { dispatch, faunaReducer, projectsReducer } = this.props
    const { selectedTelemetriaCard } = faunaReducer
    this.setState({ formSubmited: true })

    if (this.formValidator() === false) return

    if (selectedTelemetriaCard._id && selectedTelemetriaCard._id !== '') {
      await faunaHelpers.updateTelemetria(selectedTelemetriaCard, this.clearTelemetriaData(), dispatch)
    } else {
      await faunaHelpers.createTelemetria(selectedTelemetriaCard, this.clearTelemetriaData(), projectsReducer.selectedProject, dispatch)
    }
  }

  clearTelemetriaData = () => {
    const { dispatch } = this.props
    setTimeout(() => {
      dispatch(faunaHelpers.clearTelemetriaData())
      history.push("/fauna/telemetria")
    }, 1000)
    
  }

  handleModal = (modalType, content) => {
    const { modal } = this.state
    this.setState({ modal: { showModal: !modal.showModal, modalType: modalType, content: content } })
  }
  
  handleListEspecies =  async() => {
    await this.props.dispatch(especiesHelpers.list())
  }
  handleInputWithUpdate = (value, target) => {
    
    const { dispatch, especiesReducer, faunaReducer } = this.props
    const { listEspecies } = especiesReducer
    const { selectedTelemetriaCard } = faunaReducer

    if (!value) return

    faunaHelpers.handleEspecies(value?.nomeCientifico, target, listEspecies, selectedTelemetriaCard, dispatch, 'TELEMETRIA')
  }

  render() {
    const { dispatch, faunaReducer, especiesReducer } = this.props
    const { selectedTelemetriaCard, selectOptions } = faunaReducer
    const { listEspecies } = especiesReducer
    const { formSubmited, modal } = this.state

    return (
      <div className='mainContainer'>
        <Header title='Monitoramento de Fauna' />
        <div className='basicContainer'>
          <div className='basicInformation box-content-primary'>
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="codigo"
                    label="Código do Animal"
                    InputLabelProps={{ shrink: true }}
                    className="inputDefault"
                    inputProps={{ readOnly: selectedTelemetriaCard?._id ? true : false }}
                    value={selectedTelemetriaCard.codigo}
                    autoComplete='off'
                    onChange={(event) => faunaHelpers.handleInput(event, 'codigo', dispatch, 'TELEMETRIA', 'number')}
                    error={formSubmited && !selectedTelemetriaCard.codigo}
                    helperText={formSubmited && !selectedTelemetriaCard.codigo ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="classe"
                    label="Classe"
                    disabled={true}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{ readOnly: true }}
                    className="inputDefault"
                    value={selectedTelemetriaCard.classe}
                    error={formSubmited && selectedTelemetriaCard.classe === ''}
                    helperText={formSubmited && selectedTelemetriaCard.classe === '' ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="ordem"
                    label="Ordem"
                    disabled={true}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{ readOnly: true }}
                    className="inputDefault"
                    value={selectedTelemetriaCard.ordem}
                    error={formSubmited && selectedTelemetriaCard.ordem === ''}
                    helperText={formSubmited && selectedTelemetriaCard.ordem === '' ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="familia"
                    label="Família"
                    disabled={true}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{ readOnly: true }}
                    className="inputDefault"
                    value={selectedTelemetriaCard.familia}
                    error={formSubmited && selectedTelemetriaCard.familia === ''}
                    helperText={formSubmited && selectedTelemetriaCard.familia === '' ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="subFamilia"
                    label="Subfamília"
                    disabled={true}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{ readOnly: true }}
                    className="inputDefault"
                    value={selectedTelemetriaCard.subFamilia}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="genero"
                    label="Gênero"
                    disabled={true}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{ readOnly: true }}
                    className="inputDefault"
                    value={selectedTelemetriaCard.genero}
                    error={formSubmited && selectedTelemetriaCard.genero === ''}
                    helperText={formSubmited && selectedTelemetriaCard.genero === '' ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="especie"
                    label="Espécie"
                    disabled={true}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{ readOnly: true }}
                    className="inputDefault"
                    value={selectedTelemetriaCard.especie}
                    error={formSubmited && selectedTelemetriaCard.especie === ''}
                    helperText={formSubmited && selectedTelemetriaCard.especie === '' ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="subEspecie"
                    label="Subespécie"
                    disabled={true}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{ readOnly: true }}
                    className="inputDefault"
                    value={selectedTelemetriaCard.subEspecie}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={listEspecies}
                    defaultValue={{ nomeCientifico: selectedTelemetriaCard.nomeCientifico }}
                    getOptionLabel={(option) => option.nomeCientifico}
                    id="select-on-focus"
                    selectOnFocus
                    onChange={(event, value) => this.handleInputWithUpdate(value, 'nomeCientifico')}
                    onOpen={this.handleListEspecies}
                    loading='true'
                    loadingText="Carregando..."
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nome científico"
                        variant="standard"
                        error={formSubmited && selectedTelemetriaCard.nomeCientifico === ''}
                        helperText={formSubmited && selectedTelemetriaCard.nomeCientifico === '' ? 'Campo obrigatório' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="nomePopular"
                    label="Nome popular"
                    disabled={true}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{ readOnly: true }}
                    className="inputDefault"
                    value={selectedTelemetriaCard.nomePopular}
                    // error={formSubmited && selectedTelemetriaCard.nomePopular === ''}
                    // helperText={formSubmited && selectedTelemetriaCard.nomePopular === '' ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="radiotransmissor"
                    label="Radiotransmissor"
                    className="inputDefault"
                    value={selectedTelemetriaCard.radiotransmissor}
                    autoComplete='off'
                    onChange={(event) => faunaHelpers.handleInput(event, 'radiotransmissor', dispatch, 'TELEMETRIA')}
                    error={formSubmited && selectedTelemetriaCard.radiotransmissor === ''}
                    helperText={formSubmited && selectedTelemetriaCard.radiotransmissor === '' ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="frequencia"
                    label="Frequência"
                    className="inputDefault"
                    value={selectedTelemetriaCard.frequencia}
                    autoComplete='off'
                    onChange={(event) => faunaHelpers.handleInput(event, 'frequencia', dispatch, 'TELEMETRIA')}
                    error={formSubmited && selectedTelemetriaCard.frequencia === ''}
                    helperText={formSubmited && selectedTelemetriaCard.frequencia === '' ? 'Campo obrigatório' : ''}
                  />
                </Grid>
                {selectedTelemetriaCard?.monitoramento.length > 0 &&
                  <>
                    <Divider className="dividerFull" />
                    <Grid item xs={12} md={12} >
                      <Typography variant="h6" component="h6" className="filterTitle">Monitoramentos</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} >
                    <Button variant="contained" className="btn-secondary" download onClick={() => faunaHelpers.drawPolygonOnMap(selectedTelemetriaCard.monitoramento, this.handleModal)}>
                        MAPA
                    </Button>
                    </Grid>
                    <Divider className="dividerFull" />
                    <TableContainer className="customTable">
                      <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                        <TableHead className="tableHead">
                          <TableRow>
                            <TableCell key='dataInit' align='right' padding='normal'>Data Início</TableCell>
                            <TableCell key='TimeInit' align='right' padding='normal'>Hora Início</TableCell>
                            <TableCell key='TimeEnd' align='right' padding='normal'>Hora Fim</TableCell>
                            {/* <TableCell key='nomeCientifico' align='right' padding='normal'>Nome Científico</TableCell> */}
                            <TableCell padding="none" />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedTelemetriaCard?.monitoramento.map((row, index) => {
                            const isItemSelected = false;
                            // const isItemSelected = isSelected(row.name);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow>
                                <TableCell component="th" id={row?.id} scope="row"><b>{moment(row.dataInit).format('DD/MM/YYYY') ?? ''}</b></TableCell>
                                <TableCell align="right">{moment(row.dataInit).format('HH:mm') ?? ''}</TableCell>
                                <TableCell align="right">{moment(row.dataEnd).format('HH:mm') ?? ''}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    className="btn-secondary btn-innerTable btn-small"
                                    onClick={() => dispatch(faunaHelpers.editMonitoramento(row, this.handleModal('radiotalemetria_monitoramento')))}
                                  >
                                    Editar
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                }
                <Divider className="dividerFull" />
                <Grid item xs={12} md={3}>
                  <Button variant="contained"
                    className="btn-secondary"
                    component="label"
                    // startIcon={<SaveIcon />}
                    onClick={() => this.handleModal('radiotalemetria_monitoramento')}> Novo monitoramento
                  </Button>
                </Grid>
                <Divider className="dividerFull" />
                <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                  <Box padding={10}>
                    <Button variant="contained"
                      className="btn-primary"
                      component="label"
                      startIcon={<SaveIcon />}
                      onClick={() => this.submitForm()}> Salvar Ficha </Button>
                  </Box>
                </Box>
                <Divider className="dividerFull" />
              </Grid>
            </form>
          </div>
        </div>
        {modal.showModal &&
          <CustomModal modalFn={this.handleModal} content={modal.content} modalType={modal.modalType} />
        }
      </div>
    )
  }
}

const mapStateToProps = store => ({
  faunaReducer: store.faunaReducer,
  especiesReducer: store.especiesReducer,
  paramsReducer: store.paramsReducer,
  projectsReducer: store.projectsReducer,
});

export default withRouter(connect(mapStateToProps)(TelemetriaDetails));