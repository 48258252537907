import { areaPlantioHandler } from '../handlers/areaPlantioHandler';
import { areaPlantioActionTypes } from '../types/areaPlantioActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const areaPlantioActions = {
    listAreaPlantio,
    paginateAreaPlantio,
    createAreaPlantio,
    updateAreaPlantio,
    deleteAreaPlantio

};

function listAreaPlantio() {
    return dispatch => {
        dispatch(request());
        areaPlantioHandler.listAreaPlantio()
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: areaPlantioActionTypes.GET_AREA_PLANTIO_CARD_REQUEST } }
    function success(list) { return { type: areaPlantioActionTypes.GET_AREA_PLANTIO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaPlantioActionTypes.GET_AREA_PLANTIO_CARD_FAILURE, error } }
}

function paginateAreaPlantio(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        areaPlantioHandler.paginateAreaPlantio(params, page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_REQUEST } }
    function success(list) { return { type: areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_FAILURE, error } }
}

function createAreaPlantio(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        areaPlantioHandler.createAreaPlantio(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: areaPlantioActionTypes.CREATE_AREA_PLANTIO_CARD_REQUEST } }
    function success(list) { return { type: areaPlantioActionTypes.CREATE_AREA_PLANTIO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaPlantioActionTypes.CREATE_AREA_PLANTIO_CARD_FAILURE, error } }
}

function updateAreaPlantio(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        areaPlantioHandler.updateAreaPlantio(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: areaPlantioActionTypes.UPDATE_AREA_PLANTIO_CARD_REQUEST } }
    function success(list) { return { type: areaPlantioActionTypes.UPDATE_AREA_PLANTIO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaPlantioActionTypes.UPDATE_AREA_PLANTIO_CARD_FAILURE, error } }
}

function deleteAreaPlantio(params, page, projectId, cardId, callback) {
    return dispatch => {
        dispatch(request());
        areaPlantioHandler.deleteAreaPlantio(params, page, projectId, cardId)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_REQUEST } }
    function success(list) { return { type: areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_FAILURE, error } }
}
