import { auditActions } from "../../actions/auditActions"
import { auditActionTypes } from "../../types/auditActionTypes"
import { history } from '../history';
import utmToLatLng from "../coordConverter"
import { computeArea } from "spherical-geometry-js"
import { v4 as uuidv4 } from 'uuid';


function listAudit() {
	return dispatch => {
		dispatch(auditActions.listAudit())
	}
}

function paginateAudit(parameters, page, projectId) {
	return dispatch => {
		dispatch(auditActions.paginateAudit(parameters, page, projectId))
	}
}

function handleChangeFilter(event, target, context) {
	const value = event.target.value
	const prefix = context === 'audit' ? 'AUDIT' : context === 'auditSaida' ? 'AUDIT_SAIDA' : ''

	return dispatch => {
		dispatch(setFilter(value, target, prefix))
	}
}

function setFilter(value, target, prefix) { return { type: auditActionTypes[`SET_${prefix}_FILTER`], value, target } }

function handleDateChangeInit(date, context) {
	return dispatch => {
		dispatch(handleDateInit(date))
	}
};


function handleDateChangeEnd(date, context) {

	return dispatch => {
		dispatch(handleDateEnd(date))
	}
};

function handleDateInit(date) { return { type: auditActionTypes[`SET_AUDIT_FILTER_DATE_INIT`], date } }

function handleDateEnd(date) { return { type: auditActionTypes[`SET_AUDIT_FILTER_DATE_END`], date } }



function editCard(context, item, callback, dispatch) {
	if (callback) { callback() }
	if (item) {
		return { type: auditActionTypes.EDIT_AUDIT_CARD, item, context }
	} else {
		return { type: auditActionTypes.EDIT_AUDIT_CARD_CANCELED }
	}
}


function clearNewItem() { return { type: auditActionTypes.CLEAR_NEW_ITEM_FIELDS } }

export const auditHelpers = {
	handleChangeFilter,
	handleDateChangeInit,
	handleDateChangeEnd,
	editCard,
	listAudit,
	paginateAudit,
	clearNewItem,
}