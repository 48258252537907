import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, InputLabel, Select, FormControl, MenuItem } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'



class Filter extends Component {

    render(){
        const { dispatch, parameters, projects, pageContext } = this.props

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        {parameters.fields._id ? 'Editar' : 'Adicionar Novo' }
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        {pageContext === 'ringColors' && 
                            <Grid item xs={12} md={8}>
                                <TextField id="color" label="Cor da anilha" value={parameters.fields.color} className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "color", "parameters"))} />
                            </Grid>
                        }
                        {(pageContext === 'trapType' || pageContext === 'environmentType') && 
                            <Grid item xs={12} md={8}>
                                <TextField id="type" label="Tipo" value={pageContext === 'trapType' ? parameters.fields.trapType : parameters.fields.environmentType} className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, pageContext === 'trapType' ? "trapType" : "environmentType", "parameters"))} />
                            </Grid>
                        }
                        {pageContext === 'documentsType' && 
                            <>
                                <Grid item xs={12} md={4}>
                                    <TextField id="module" label="Módulo" value={parameters.fields.modulo} className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "modulo", "parameters"))} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField id="short" label="Sigla" value={parameters.fields.short} className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "short", "parameters"))} />
                                </Grid>
                            </>
                        }
                        {(pageContext === 'caracFlora' || pageContext === 'documentsType') && 
                            <Grid item xs={12} md={pageContext === 'documentsType' ? 4 : 8}>
                                <TextField id="description" label="Descrição" value={parameters.fields.description} className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "description", "parameters"))} />
                            </Grid>
                        }
                        {pageContext === 'orgEmissor' && 
                            <Grid item xs={12} md={8}>
                                <TextField id="name" label="Nome" value={parameters.fields.name} className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "name", "parameters"))} />
                            </Grid>
                        }
                        {pageContext === 'faunaClass' && 
                            <Grid item xs={12} md={8}>
                                <TextField id="classe" label="Classe" value={parameters.fields.classe} className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "classe", "parameters"))} />
                            </Grid>
                        }

                        <Grid item xs={12} md={pageContext === 'documentsType' ? 2 : 4}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={parameters.fields.status}
                                    onChange={(event) => dispatch(helpers.handleChangeFilter(event, "status", "parameters"))}
                                >
                                {parameters.selectOptions.status.map(type => {
                                    return <MenuItem value={type.value}>{type.label}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(helpers.includeParams(parameters.fields, parameters.page, projects.selectedProject, pageContext ))}>
                                SALVAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    parameters: store.paramsReducer,
    projects: store.projectsReducer
});

export default connect(mapStateToProps)(Filter);