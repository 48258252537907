import { actionTypes } from "../types/actionTypes";
import moment from 'moment'


const initialState = {
  selectedProject: '1',
  listProjects: [],
  loading: false,
  projects: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0
  },
  filters: {
    projectName: '',
    projectNick: '',
  },
  options:{
    projecao: ['UTM - 20 S', 'UTM - 21 S', 'UTM - 22 S', 'UTM - 23 S', 'UTM - 24 S', 'UTM - 20 N', 'UTM - 21 N', 'UTM - 22 N']
  },
  editProject: {
    projectId: '', 
    projectName: '',
    projectNick: '',
    description: '',
    admin: '',
    status: true,
    endDate: moment().format(),
    endDateAlert: moment().subtract(1, 'months').format(),
    modules: [
      {description: 'fauna', status: true},
      {description: 'flora', status: true},
      {description: 'meio-fisico', status: true},
    ],
    geoData: {
      projecao: '', 
      coodMinX: '', 
      coodMaxX: '', 
      coodMinY: '',
      coodMaxY: '',
    },
    users: []
  }
};

export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROJECTS_REQUEST:
      return { ...state, listProjects: state.listProjects, loading: true };
    case actionTypes.GET_PROJECTS_SUCCESS:
      return { ...state, listProjects: action.projects, loading: false };
    case actionTypes.GET_PROJECTS_FAILURE:
      return { ...state, listProjects: initialState.listProjects, loading: false };
    
    case actionTypes.SET_PROJECT:
      return { ...state, selectedProject: action.value };

    case actionTypes.CREATE_PROJECTS_REQUEST:
    case actionTypes.UPDATE_PROJECTS_REQUEST:
    case actionTypes.LIST_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_PROJECTS_SUCCESS:
    case actionTypes.UPDATE_PROJECTS_SUCCESS:
    case actionTypes.LIST_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: {list: action.listProjects.docs, total: action.listProjects.total, limit: action.listProjects.limit, page: Number(action.listProjects.page), pagesTotal: action.listProjects.pages},
        editProject: initialState.editProject,
        loading: false
      };
    case actionTypes.UPDATE_PROJECTS_CANCELED:
      return {
        ...state,
        editProject: initialState.editProject,
        loading: false
      };
    case actionTypes.CREATE_PROJECTS_FAILURE:
    case actionTypes.UPDATE_PROJECTS_FAILURE:
    case actionTypes.LIST_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case actionTypes.EDIT_PROJECT:
      return { ...state, editProject: action.project };
    case actionTypes.HANDLE_INPUT_PROJECT:
      return {
        ...state,
        editProject: {...state.editProject, [action.target]: action.value}
      };
    case actionTypes.HANDLE_MODULES_PROJECT:
      return {
        ...state,
        editProject: {...state.editProject, modules: action.value}
      };
    case actionTypes.HANDLE_GEODATA_PROJECT:
      return {
        ...state,
        editProject: {...state.editProject, geoData: {...state.editProject.geoData, [action.target]: action.value}}
      };
    case actionTypes.SET_PROJECTS_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
    default:
      return state;
  }
};