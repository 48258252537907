import { areaPlantioActions } from "../../../actions/areaPlantioActions"
import { areaPlantioActionTypes } from "../../../types/areaPlantioActionTypes"
import { history } from '../../history';
import utmToLatLng from "../../coordConverter"
import { computeArea } from "spherical-geometry-js"

function listAreaPlantio() {
	return dispatch => {
		dispatch(areaPlantioActions.listAreaPlantio())
	}
}

function paginateAreaPlantio(parameters, page, projectId) {
	return dispatch => {
		dispatch(areaPlantioActions.paginateAreaPlantio(parameters, page, projectId))
	}
}

function handleChangeFilter(event, target, context) {
	const value = event.target.value
	const prefix = context === 'areaPlantio' ? 'AREA_PLANTIO' : context === 'areaPlantioSaida' ? 'AREA_PLANTIO_SAIDA' : ''

	return dispatch => {
		dispatch(setFilter(value, target, prefix))
	}
}

function setFilter(value, target, prefix) { return { type: areaPlantioActionTypes[`SET_${prefix}_FILTER`], value, target } }

function handleDateChangeInit(date, context) {
	const prefix = context === 'areaPlantio' ? 'AREA_PLANTIO' : context === 'areaPlantioSaida' ? 'AREA_PLANTIO_SAIDA' : ''

	return dispatch => {
		dispatch(handleDateInit(date, prefix))
	}
};


function handleDateChangeEnd(date, context) {
	const prefix = context === 'areaPlantio' ? 'AREA_PLANTIO' : context === 'areaPlantioSaida' ? 'AREA_PLANTIO_SAIDA' : ''

	return dispatch => {
		dispatch(handleDateEnd(date, prefix))
	}
};

function handleDateInit(date, prefix) { return { type: areaPlantioActionTypes[`SET_${prefix}_FILTER_DATE_INIT`], date } }

function handleDateEnd(date, prefix) { return { type: areaPlantioActionTypes[`SET_${prefix}_FILTER_DATE_END`], date } }



function editCard(context, item, callback, dispatch) {
	if (callback) { callback() }
	if (item) {
		return { type: areaPlantioActionTypes.EDIT_AREA_PLANTIO_CARD, item, context }
	} else {
		return { type: areaPlantioActionTypes.UPDATE_AREA_PLANTIO_CARD_CANCELED }
	}
}

function areaPlantio() {

	return history.push(`area_plantio/detalhes`)
}

async function handleInput(myValue, target, context, dispatch, callback, isNumber) {
	let value = target === 'dataMedicao' || isNumber ? myValue : myValue.target.value

	await dispatch(saveStore(value, target, context));

	if (callback) callback(dispatch)
}

function HandleInputNumber(event, target, context, dispatch, callback) {
	let myValue = event?.target?.value
	let replaced = myValue.replace(/[^\d.-]/g, '')
	handleInput(replaced, target, context, dispatch, callback, true)
}

function saveStore(value, target, context) { return { type: areaPlantioActionTypes.HANDLE_AREA_PLANTIO_FIELDS, context, value, target } }

function calculateDiametroMedio(diametro1, diametro2, diametro3, comprimento, context, dispatch) {
	const valores = []
	if (diametro1 && (diametro1 !== '' && diametro1 !== '--' && Number(diametro1) !== 0)) valores.push(Number(diametro1))
	if (diametro2 && (diametro2 !== '' && diametro2 !== '--' && Number(diametro2) !== 0)) valores.push(Number(diametro2))
	if (diametro3 && (diametro3 !== '' && diametro3 !== '--' && Number(diametro3) !== 0)) valores.push(Number(diametro3))

	var media = 0

	valores.map(valor => media += valor)

	media /= valores.length
	if ((comprimento && Number(comprimento) > 0)) {
		calculateVolume(comprimento, media, context, dispatch)
	}

	dispatch(saveStore(media, 'diametroMedio', context));
}

function calculateVolume(comprimento, diametroMedio, context, dispatch) {
	let altura = parseFloat(comprimento)
	let raio = parseFloat(diametroMedio / 2)
	let volume = Math.PI * raio * raio * altura

	dispatch(saveStore(volume, 'volume', context));
}

function createAreaPlantio(parameters, page, callback, projectId, params) {

	const data = {
		codigoDaArea: parameters.codigoDaArea,
		tipo: parameters.tipo,
		status: parameters.status,
		area: parameters.area,
		coord: parameters.coord,
		project: projectId
	}

	return dispatch => {
		dispatch(areaPlantioActions.createAreaPlantio(data, page, callback, projectId, params))
	}
}

function updateAreaPlantio(parameters, page, callback, projectId, params) {

	const data = {
		_id: parameters._id,
		codigoDaArea: parameters.codigoDaArea,
		tipo: parameters.tipo,
		status: parameters.status,
		area: parameters.area,
		coord: parameters.coord,
		project: projectId ?? parameters.project,
		individuos: parameters.individuos
	}

	return dispatch => {
		dispatch(areaPlantioActions.updateAreaPlantio(data, page, callback, projectId, params))
	}
}

function deleteAreaPlantio(cardId, individuos, dispatch) {
	const individuosList = individuos.filter(item => item._id !== cardId)
	updateVolumeTotal(individuosList, dispatch)

	return dispatch(saveIndividuos(individuosList))
}

function saveIndividuos(value) { return { type: areaPlantioActionTypes.UPDATE_AREA_PLANTIO_INDIVIDUOS, value } }

function updateVolumeTotal(individuos, dispatch) {
	let volumeTotal = 0
	individuos.map(item => volumeTotal += Number(item.volume))
	dispatch(saveVolumeStore(volumeTotal, 'volumeTotal', 'areaPlantio'))
}

function saveVolumeStore(value, target, context) { return { type: areaPlantioActionTypes.HANDLE_AREA_PLANTIO_TOTAL_VOLUME, context, value, target } }

function handlePlaces(module, index, target, myValue, context, callback, dispatch) {

	let replaced = myValue.replace(/[^\d.-]/g, '')

	let newObj = module;

	newObj[index][target] = replaced

	if (callback) callback(newObj, context, dispatch)

	return dispatch(saveStore(newObj, 'coord', context));
}

function addNewPlace(module, target, context, dispatch) {
	let newObj = module;
	const emptyModule = { coordenadaX: "", coordenadaY: "", EPE: "" }

	newObj.push(emptyModule)

	return dispatch(saveStore(newObj, target, context));
}

const getArea = async (coordObj, context, dispatch) => {

	let isValidCoordObj = await validateCoordObj(coordObj)

	if (!isValidCoordObj) return

	let convertedArray = await convertCoords(coordObj)

	let area = await coordsArray(convertedArray)

	return dispatch(saveStore(Number(area.toFixed()), 'area', context));
}

const convertCoords = async (coordObj) => {
	if (!coordObj) return
	let convertedArray = []

	coordObj.map(utmCoord => {
		let parsedCoord = {}
		let convertedCoord = utmToLatLng(false, utmCoord.coordenadaX, utmCoord.coordenadaY, utmCoord.EPE)
		parsedCoord.lat = convertedCoord.latitude
		parsedCoord.lng = convertedCoord.longitude
		convertedArray.push(parsedCoord)
	})
	return convertedArray
}

const coordsArray = async (convertedObj) => {
	if (!convertedObj) return

	return computeArea(convertedObj)
}

const validateCoordObj = async (coordObj) => {
	if (!coordObj || coordObj.length < 3) return false
	const validData = coordObj.filter(coord => (coord.coordenadaX === '' || coord.coordenadaY === ''))
	if (validData.length === 0) {
		return true
	} else {
		return false
	}
}

function isValidAreaPlantio(editedCard) {
	console.log('aqui')
	if (editedCard._id === '') {
		return history.push(`/flora/area_plantio`)
	}
}

async function handleNewItemInput(myValue, target, context, dispatch, callback, isNumber) {
	let value = target === 'dataMedicao' || isNumber ? myValue : myValue.target.value

	await dispatch(saveNewItemStore(value, target, context));

	if (callback) callback(dispatch)
}

function HandleNewItemInputNumber(event, target, context, dispatch, callback) {
	let myValue = event?.target?.value
	let replaced = myValue.replace(/[^\d.-]/g, '')
	handleNewItemInput(replaced, target, context, dispatch, callback, true)
}

function saveNewItemStore(value, target, context) { return { type: areaPlantioActionTypes.HANDLE_NEW_ITEM_FIELDS, context, value, target } }

function AddNewItem(context, item, dispatch) {

	let newObj = context.individuos;
	if(item._id && item._id !== '') {
		newObj.map((individuo, index) => {
			if(individuo._id === item._id) {
				newObj[index] = item
			}
		})
	}else{
		newObj.push(item)
	}

	dispatch(clearNewItem())

	return dispatch(saveStore(newObj, 'individuos', context));
}

function clearNewItem() { return { type: areaPlantioActionTypes.CLEAR_NEW_ITEM_FIELDS } }

function editItem(value) { return { type: areaPlantioActionTypes.EDIT_ITEM, value } }

function deletePlantio(parameters, page, projectId, cardId, dispatch) {
	dispatch(areaPlantioActions.deleteAreaPlantio(parameters, page, projectId, cardId, null))
	areaPlantioRaiz()
}

function areaPlantioRaiz() {
		return history.push(`/flora/area_plantio`)
}


export const areaPlantioHelpers = {
	handleChangeFilter,
	handleDateChangeInit,
	handleDateChangeEnd,
	editCard,
	handleInput,
	HandleInputNumber,
	calculateDiametroMedio,
	calculateVolume,
	listAreaPlantio,
	paginateAreaPlantio,
	createAreaPlantio,
	updateAreaPlantio,
	deleteAreaPlantio,
	handlePlaces,
	addNewPlace,
	getArea,
	isValidAreaPlantio,
	handleNewItemInput,
	HandleNewItemInputNumber,
	AddNewItem,
	clearNewItem,
	editItem,
	areaPlantio,
	deletePlantio
}