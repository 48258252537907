import { placesHandler } from '../handlers/placesHandler';
import { actionTypes } from '../types/actionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const placesActions = {
  getPlaces,
  paginatePlaces,
  createPlaces,
  updatePlaces
};

function getPlaces(selectedProject, type) {
  return dispatch => {
    dispatch(request());
    placesHandler.getPlaces(selectedProject, type)
      .then(response => {
        dispatch(success(response));
      },
        error => {
          apiHelpers.handleError(
            dispatch,
            error,
            failure(error),
            true
          );
          dispatch(alertActions.error(error.errorMsg));
        }
      ).catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
      });
  };
  function request() { return { type: actionTypes.GET_PLACES_REQUEST } }
  function success(places) { return { type: actionTypes.GET_PLACES_SUCCESS, places } }
  function failure(error) { return { type: actionTypes.GET_PLACES_FAILURE, error } }
}

function paginatePlaces(params, page) {
  return dispatch => {
    dispatch(request());
    placesHandler.paginatePlaces(params, page)
      .then(response => {
        dispatch(success(response));
      },
        error => {
          apiHelpers.handleError(
            dispatch,
            error,
            failure(error),
            true
          );
          dispatch(alertActions.error(error.errorMsg));
        }
      ).catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.errorMsg));
      });
  };
  function request() { return { type: actionTypes.LIST_PLACES_REQUEST } }
  function success(listPlaces) { return { type: actionTypes.LIST_PLACES_SUCCESS, listPlaces } }
  function failure(error) { return { type: actionTypes.LIST_PLACES_FAILURE, error } }
}

function createPlaces(params, page, callback) {
  return dispatch => {
    dispatch(request());
    placesHandler.createPlaces(params, page)
      .then(response => {
        dispatch(success(response));
        dispatch(alertActions.success('Projeto criado com sucesso'))
        if (callback) { callback('project', true) }
      },
        error => {
          apiHelpers.handleError(
            dispatch,
            error,
            failure(error),
            true
          );
          dispatch(alertActions.error(error.errorMsg));
        }
      ).catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.errorMsg));
      });
  };
  function request() { return { type: actionTypes.CREATE_PLACES_REQUEST } }
  function success(listPlaces) { return { type: actionTypes.CREATE_PLACES_SUCCESS, listPlaces } }
  function failure(error) { return { type: actionTypes.CREATE_PLACES_FAILURE, error } }
}

function updatePlaces(params, page, callback) {
  return dispatch => {
    dispatch(request());
    placesHandler.updatePlaces(params, page)
      .then(response => {
        dispatch(success(response));
        dispatch(alertActions.success('Projeto atualizado com sucesso'))
        if (callback) { callback('project', true) }
      },
        error => {
          apiHelpers.handleError(
            dispatch,
            error,
            failure(error),
            true
          );
          dispatch(alertActions.error(error.errorMsg));
        }
      ).catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.errorMsg));
      });
  };
  function request() { return { type: actionTypes.UPDATE_PLACES_REQUEST } }
  function success(listPlaces) { return { type: actionTypes.UPDATE_PLACES_SUCCESS, listPlaces } }
  function failure(error) { return { type: actionTypes.UPDATE_PLACES_FAILURE, error } }
}