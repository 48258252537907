import { alertActions } from '../actions/alertActions'
import { helpers } from './helpers';

export const apiHelpers = {
    handleStatus,
    handleResponse,
    handleEmptyResponse,
    handleError
}

async function handleStatus(response) {
	if (response.ok) {
		return Promise.resolve(response);
	}
	if (response.status === 401 || response.status === 403) {
		const data = await response.json();
		if(data.requireLogin === true) {
			helpers.logout();
		}
		return Promise.reject(data);
	}
	return Promise.reject(await response.json());
}

function handleResponse(response) {
	return response.json();
}

function handleEmptyResponse() {
	return true;
}

async function handleError(dispatch, error, successCallback, errorCallback, alert) {
	dispatch(alertActions.error(error.errorMsg));
}