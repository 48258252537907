import { raizesHandler } from '../handlers/raizesHandler';
import { raizesActionTypes } from '../types/raizesActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const raizesActions = {
    listRaizes,
    paginateRaizes,
    createRaizes,
    updateRaizes,
    listRaizesSaida,
    paginateRaizesSaida,
    createRaizesSaida,
    updateRaizesSaida,
    deleteRaizesSaida,
    deleteRaizes,
};

function listRaizes() {
    return dispatch => {
        dispatch(request());
        raizesHandler.listRaizes()
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: raizesActionTypes.GET_RAIZES_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.GET_RAIZES_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.GET_RAIZES_CARD_FAILURE, error } }
}

function paginateRaizes(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        raizesHandler.paginateRaizes(params, page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: raizesActionTypes.LIST_RAIZES_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.LIST_RAIZES_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.LIST_RAIZES_CARD_FAILURE, error } }
}

function createRaizes(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        raizesHandler.createRaizes(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateRaizes(filters, page, projectId))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: raizesActionTypes.CREATE_RAIZES_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.CREATE_RAIZES_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.CREATE_RAIZES_CARD_FAILURE, error } }
}

function updateRaizes(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        raizesHandler.updateRaizes(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: raizesActionTypes.UPDATE_RAIZES_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.UPDATE_RAIZES_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.UPDATE_RAIZES_CARD_FAILURE, error } }
}

function listRaizesSaida() {
    return dispatch => {
        dispatch(request());
        raizesHandler.listRaizesSaida()
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: raizesActionTypes.GET_RAIZES_SAIDA_CARD_REQUEST } }
    function success(places) { return { type: raizesActionTypes.GET_RAIZES_SAIDA_CARD_SUCCESS, places } }
    function failure(error) { return { type: raizesActionTypes.GET_RAIZES_SAIDA_CARD_FAILURE, error } }
}

function deleteRaizes(params, page, projectId, cardId, callback) {
    return dispatch => {
        dispatch(request());
        raizesHandler.deleteRaizes(params, page, projectId, cardId)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: raizesActionTypes.LIST_RAIZES_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.LIST_RAIZES_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.LIST_RAIZES_CARD_FAILURE, error } }
}

function paginateRaizesSaida(page, projectId) {
    return dispatch => {
        dispatch(request());
        raizesHandler.paginateRaizesSaida(page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: raizesActionTypes.LIST_RAIZES_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.LIST_RAIZES_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.LIST_RAIZES_SAIDA_CARD_FAILURE, error } }
}

function createRaizesSaida(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        raizesHandler.createRaizesSaida(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateRaizesSaida(filters, page, projectId))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: raizesActionTypes.CREATE_RAIZES_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.CREATE_RAIZES_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.CREATE_RAIZES_SAIDA_CARD_FAILURE, error } }
}

function updateRaizesSaida(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        raizesHandler.updateRaizesSaida(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: raizesActionTypes.UPDATE_RAIZES_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.UPDATE_RAIZES_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.UPDATE_RAIZES_SAIDA_CARD_FAILURE, error } }
}

function deleteRaizesSaida(params, page, projectId, cardId, callback) {
    return dispatch => {
        dispatch(request());
        raizesHandler.deleteRaizesSaida(params, page, projectId, cardId)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: raizesActionTypes.LIST_RAIZES_SAIDA_CARD_REQUEST } }
    function success(list) { return { type: raizesActionTypes.LIST_RAIZES_SAIDA_CARD_SUCCESS, list } }
    function failure(error) { return { type: raizesActionTypes.LIST_RAIZES_SAIDA_CARD_FAILURE, error } }
}
