import React from 'react';
import { Box, Button, ButtonGrou, Typography, ButtonGroup, Divider } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import { uploadHelper } from '../../helpers/uploadHelper';
import { faunaActions } from '../../actions/faunaActions';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { deleteItem } from '../../helpers/item';

export default function accordionDocumentos(props) {
  initializeFileTypeIcons();
  const { dispatch, editItem, selectedCard, loggedUser } = props;
  const { documents } = selectedCard;
  const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

  return (
    <div style={{ width: '100%' }}>
      {!!selectedCard.numeroFicha &&
        <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">

          <Box p={1}>
            <Button variant="contained" disabled={!writeModuleVerify} className="btn-secondary" component="label" startIcon={<PublishIcon />} > Adicionar Arquivo <input type="file" name="documentFile" hidden onChange={(event) => uploadHelper(event, selectedCard._id, dispatch, faunaActions)} /></Button>
          </Box>


        </Box>
      }

      {documents.length > 0 &&
        <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
          <Box p={2} flexGrow={1}>
            <TableContainer component={Paper} >
              <Table size="small" aria-label="a dense table" p={2} m={2}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Arquivo</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCard.documents.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        <Icon {...getFileTypeIconProps({ extension: row.originalname.match(/\.[0-9a-z]+$/i)[0], size: 16 })} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography>  {row.originalname}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                          <Button
                            className="btn-innerTable btn-orange-secondary"
                            startIcon={<DeleteForeverIcon />}

                            onClick={() => dispatch(deleteItem({ fileId: row, cardId: selectedCard._id }, faunaActions))}
                            disabled={!writeModuleVerify}
                          > Excluir
                          </Button>

                          <Button
                            className="btn-innerTable btn-orange-secondary"
                            startIcon={<OpenInNewIcon />}
                            onClick={() => window.open(row.location)}
                            disabled={!writeModuleVerify}
                          >
                            Abrir
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      }


    </div>
  );
}
