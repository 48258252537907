import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableRow, TableCell, Checkbox, TableContainer, Table, TableBody, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import Header from '../../../Components/headerAlter';
import Filter from '../../../Components/fauna/radio/filters';
import CustomModal from '../../../Components/customModal';


import { history } from '../../../helpers/history';
import { helpers } from '../../../helpers/helpers';
import { faunaHelpers } from '../../../helpers/fauna/faunaHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'


class Radiotelemetria extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: { showModal: false, modalType: undefined, content: undefined }
        };
    }

    componentDidMount() {
        const { dispatch, faunaReducer, projects } = this.props
        dispatch(helpers.saveLocation(history.location.pathname));
        dispatch(faunaHelpers.listTelemetria(faunaReducer.telemetriaFilters, faunaReducer.page, projects.selectedProject));
    }

    handleModal = (modalType, content) => {
        const { modal } = this.state
        this.setState({ modal: { showModal: !modal.showModal, modalType: modalType, content: content } })
    }

    render() {

        const { dispatch, faunaReducer, projects, alert } = this.props
        const { listTelemetria, page, pagesTotal } = faunaReducer
        const { selectedProject } = projects
        const { modal } = this.state

        const hasCardList = listTelemetria && listTelemetria.length > 0
        const hasCardType = hasCardList && listTelemetria[0].cardType
        const pageContext = "TELEMETRIA"


        return (
            <div className='mainContainer'>
                <Header title='Monitoramento de Fauna' />
                <div className='basicContainer'>
                    {alert && alert.type && alert.message &&
                        <>
                            {alert.type === 'alert-danger' &&
                                <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
                            }
                            {alert.type === 'alert-success' &&
                                <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
                            }
                        </>
                    }
                    <Filter />
                    {hasCardList &&
                        <>
                            {/* <Grid container spacing={3} className="mapBox">
                                <Grid item xs={12} md={9}></Grid>
                                <Grid item xs={12} md={3}>
                                    <Button variant="contained" className="btn-secondary" download onClick={() => faunaHelpers.plotOnMap(listFauna, this.handleModal)}>
                                        MAPA
                                    </Button>
                                </Grid>
                            </Grid> */}
                            <Grid container>
                                <TableContainer className="customTable">
                                    <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                                        <TableHead className="tableHead">
                                            <TableRow>
                                                <TableCell key='codigo' align='right' padding='normal'>Código</TableCell>
                                                <TableCell key='nomeCientifico' align='right' padding='normal'>Nome Científico</TableCell>
                                                <TableCell key='nomePopular' align='right' padding='normal'>Nome Popular</TableCell>
                                                <TableCell key='classe' align='right' padding='normal'>Classe</TableCell>
                                                <TableCell key='radiotransmissor' align='right' padding='normal'>Radiotransmissor</TableCell>
                                                <TableCell key='frequencia' align='right' padding='normal'>Frequência</TableCell>
                                                <TableCell padding="none" />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listTelemetria.map((row, index) => {
                                                const isItemSelected = false;
                                                // const isItemSelected = isSelected(row.name);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow>
                                                        <TableCell component="th" id={labelId} scope="row"><b>{row.codigo}</b></TableCell>
                                                        <TableCell align="right">{row.nomeCientifico ?? ''}</TableCell>
                                                        <TableCell align="right">{row.nomePopular ?? ''}</TableCell>
                                                        <TableCell align="right">{row.classe ?? ''}</TableCell>
                                                        <TableCell align="right">{row.radiotransmissor ?? ''}</TableCell>
                                                        <TableCell align="right">{row.frequencia ?? ''}</TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                className="btn-secondary btn-innerTable btn-small"
                                                                onClick={() => dispatch(faunaHelpers.faunaDetails(row, pageContext))}
                                                            >
                                                                Editar
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, faunaReducer.radioFilters, page, 'telemetria', selectedProject))} />
                            </Grid>
                        </>
                    }
                </div>
                {modal.showModal &&
                    <CustomModal modalFn={this.handleModal} content={modal.content} modalType={modal.modalType} />
                }
            </div>
        )
    }
}

const mapStateToProps = store => ({
    auth: store.loginReducer.auth,
    alert: store.alertReducer,
    faunaReducer: store.faunaReducer,
    projects: store.projectsReducer
});

export default connect(mapStateToProps)(Radiotelemetria);