import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider, InputAdornment } from "@material-ui/core"
import { palmitoHelpers } from '../../../../helpers/flora/palmito/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers'; 
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';

class PalmitoMatrizForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    formValidator = () => {
        const { palmitoReducer } = this.props
        const { editItem } = palmitoReducer
        const { matriz } = editItem
        const { numeroMatriz, dataCadastro, coordX, coordY, EPE, obito } = matriz

        if(numeroMatriz !== '' && dataCadastro !== null && coordX !== '' && coordY !== '' && EPE !== '' &&  obito !== null){
            return true
        }else{
            return false
        }
    }

    submitForm = () => {
        const { palmitoReducer, dispatch, closeModal, projectsReducer } = this.props
        const { editItem } = palmitoReducer
        const { matriz } = editItem
        this.setState({formSubmited: true})
        
        if(this.formValidator() === false) return

        if(matriz._id && matriz._id !== '') {
            dispatch(palmitoHelpers.updateMatriz(matriz, 1, closeModal))
        }else{
            dispatch(palmitoHelpers.createMatriz(matriz, 1, closeModal, projectsReducer.selectedProject))
        }
    }

    render(){
        const { dispatch, palmitoReducer } = this.props
        const { editItem } = palmitoReducer
        const { matriz } = editItem
        const { formSubmited } = this.state

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {matriz._id && matriz._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="numeroMatriz" 
                                label="Número da Matriz" 
                                className="inputDefault" 
                                disabled={matriz && matriz._id} 
                                value={matriz.numeroMatriz} 
                                autoComplete='off' 
                                onChange={(event) => palmitoHelpers.handleInput(event, 'numeroMatriz', 'matriz', dispatch)}
                                error={formSubmited && matriz.numeroMatriz === ''}
                                helperText={formSubmited && matriz.numeroMatriz === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de cadastro"
                                    value={matriz.dataCadastro}
                                    onChange={(event) => palmitoHelpers.handleInput(event, 'dataCadastro', 'matriz', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="coordX" 
                                label="Coordenada X" 
                                className="inputDefault" 
                                value={matriz.coordX} 
                                autoComplete='off' 
                                onChange={(event) => palmitoHelpers.handleInput(event, 'coordX', 'matriz', dispatch)}
                                error={formSubmited && matriz.coordX === ''}
                                helperText={formSubmited && matriz.coordX === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="coordY" 
                                label="Coordenada Y" 
                                className="inputDefault" 
                                value={matriz.coordY} 
                                autoComplete='off' 
                                onChange={(event) => palmitoHelpers.handleInput(event, 'coordY', 'matriz', dispatch)}
                                error={formSubmited && matriz.coordY === ''}
                                helperText={formSubmited && matriz.coordY === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="projecao" 
                                label="Projeção" 
                                className="inputDefault" 
                                value={matriz.projecao} 
                                autoComplete='off' 
                                onChange={(event) => palmitoHelpers.handleInput(event, 'projecao', 'matriz', dispatch)}
                                error={formSubmited && matriz.projecao === ''}
                                helperText={formSubmited && matriz.projecao === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="EPE" 
                                label="EPE" 
                                className="inputDefault" 
                                value={matriz.EPE} 
                                autoComplete='off' 
                                onChange={(event) => palmitoHelpers.handleInput(event, 'EPE', 'matriz', dispatch)}
                                error={formSubmited && matriz.EPE === ''}
                                helperText={formSubmited && matriz.EPE === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Óbito</InputLabel>
                                <Select labelId="obito-select-label" id="obito-select" value={matriz.obito} autoComplete='off' onChange={(event) => palmitoHelpers.handleInput(event, 'obito', 'matriz', dispatch)}>
                                    <MenuItem key='sim' value={true}>{'Sim'}</MenuItem>
                                    <MenuItem key='nao' value={false}>{'Não'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {matriz.obito &&
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data do óbito"
                                    value={matriz.dataObito}
                                    onChange={(event) => palmitoHelpers.handleInput(event, 'dataObito', 'matriz', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        }
                        <Grid item xs={12} md={12} >
                            <Typography variant="h6" component="h6" className="filterTitle">Coletas</Typography>
                        </Grid>
                        <Divider className="dividerFull"/>
                        {matriz.coleta && matriz.coleta.map((item, index) => {
                            return(
                                <>
                                    <Grid container spacing={3} className="box-content-primary center blocked-itens" md={11}>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Quantidade de cachos" 
                                                className="inputDefault" 
                                                value={item.qntCachos} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(matriz.coleta, index, 'qntCachos', event.target.value, 'coleta', 'matriz', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Kg. Coletado" 
                                                className="inputDefault" 
                                                value={item.kgColetado} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(matriz.coleta, index, 'kgColetado', event.target.value, 'coleta', 'matriz', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid item xs={12} md={4}>
                                                <KeyboardDatePicker
                                                    className="inputDefault dateInput"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Data de coleta"
                                                    value={item.dataColeta}
                                                    onChange={(event) => palmitoHelpers.handleObjs(matriz.coleta, index, 'dataColeta', event, 'coleta', 'matriz', dispatch)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'Alterar data',
                                                    }}
                                                    invalidDateMessage={'Data inválida'}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                        <Divider className="dividerFull"/>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Sementes coletadas" 
                                                className="inputDefault" 
                                                value={item.sementesColetadas} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(matriz.coleta, index, 'sementesColetadas', event.target.value, 'coleta', 'matriz', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Quantidade plantada" 
                                                className="inputDefault" 
                                                value={item.qntPlantadas} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(matriz.coleta, index, 'qntPlantadas', event.target.value, 'coleta', 'matriz', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Qnt Boiou" 
                                                className="inputDefault" 
                                                value={item.qntBoiou} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(matriz.coleta, index, 'qntBoiou', event.target.value, 'coleta', 'matriz', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Divider className="dividerFull"/>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Qnt Germinada" 
                                                className="inputDefault"
                                                value={item.qntGerminada} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(matriz.coleta, index, 'qntGerminada', event.target.value, 'coleta', 'matriz', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Qnt Descartada" 
                                                className="inputDefault" 
                                                value={item.qntDescartada} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(matriz.coleta, index, 'qntDescartada', event.target.value, 'coleta', 'matriz', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Taxa de germinação" 
                                                className="inputDefault" 
                                                disabled={true} 
                                                value={item.taxaGerminacao} 
                                                autoComplete='off' 
                                                InputProps={
                                                    {
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        readOnly: true
                                                    }
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-secondary" onClick={() => palmitoHelpers.addNewObjs(matriz.coleta, 'coleta', 'matriz', dispatch)}>
                                Adicionar...
                            </Button>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained" 
                                    className="btn-secondary" 
                                    component="label" 
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        <Divider className="dividerFull"/>
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    palmitoReducer: store.palmitoReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(PalmitoMatrizForm);