import { placesActions } from "../../actions/placesActions"
import { actionTypes } from "../../types/actionTypes"

export const placesHelpers = {
  listPlaces,
  paginatePlaces,
  editPlace,
  handlePlaceRadios,
  handlePlaces,
  addNewPlace,
  updatePlaces,
  createPlaces
}

function listPlaces(selectedProject, type = "1") {
  return dispatch => {
    dispatch(placesActions.getPlaces(selectedProject, type))
  }
}

function paginatePlaces(parameters, page) {
  return dispatch => {
    dispatch(placesActions.paginatePlaces(parameters, page))
  }
}

function editPlace(places, callback) {
  if (places) {
    if (callback) { callback('places') }
    return { type: actionTypes.EDIT_PLACE, places }
  } else {
    return { type: actionTypes.UPDATE_PLACES_CANCELED }
  }
}

function handlePlaceRadios(module, index, target, myValue, dispatch) {

  let newObj = module;

  newObj[index].status = !module[index][myValue]

  return dispatch(savePlaceRadios(newObj, actionTypes.HANDLE_RADIOS_PLACES, target));
}

function savePlaceRadios(value, context, target) { return { type: context, value, target } }

function handlePlaces(module, index, target, myValue, dispatch) {

  let newObj = module;

  newObj[index][target] = myValue

  return dispatch(savePlaces(newObj, actionTypes.HANDLE_PLACES, 'places'));
}

function addNewPlace(module, dispatch) {
  let newObj = module;
  const emptyModule = { coodX: "", coodY: "", EPE: "" }

  newObj.push(emptyModule)

  return dispatch(savePlaces(newObj, actionTypes.HANDLE_PLACES, 'places'));
}

function savePlaces(value, context, target) { return { type: context, value, target } }

function updatePlaces(parameters, page, callback) {
  return dispatch => {
    dispatch(placesActions.updatePlaces(parameters, page, callback))
  }
}

function createPlaces(parameters, page, callback) {
  return dispatch => {
    dispatch(placesActions.createPlaces(parameters, page, callback))
  }
}