import React from 'react';
import { Button, Divider, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, Typography } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers'; 
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import moment from 'moment';

const AccordionSoltura = ({ selectedCard, modalFn, dispatch, places, loggedUser }) => {
    const placeFilter = places && places.filter(place => place._id === selectedCard.localSoltura)
    const validPlace = placeFilter && placeFilter.length <= 0
    const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

    return (
        <Grid container spacing={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                        className="inputDefault dateInput"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data de soltura"
                        value={selectedCard.formatedDate && selectedCard.formatedDate.dataSoltura ? selectedCard.formatedDate.dataSoltura : null}
                        onChange={(event) => faunaHelpers.handleDate(event, 'dataSoltura', selectedCard.formatedDate, dispatch)}
                        KeyboardButtonProps={{
                            'aria-label': 'Alterar data',
                        }}
                        invalidDateMessage={'Data inválida'}
                        disabled={!writeModuleVerify}
                    />
                </Grid>
            </MuiPickersUtilsProvider>

            <Divider className="dividerFull"/>

            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Local da Soltura</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.localSoltura}
                        onChange={(event) => faunaHelpers.handlePlace(event, 'localSoltura', places, selectedCard, dispatch, 'pontoSoltura')}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem value="">--</MenuItem>
                    {validPlace && selectedCard.localSoltura !== '--' && selectedCard.localSoltura !== '' &&
                        <MenuItem value={selectedCard.localSoltura}>{selectedCard.localSoltura}</MenuItem>
                    }
                    {places && places.map((place, index) => {
                        return <MenuItem key={index} value={place._id}>{place.name}</MenuItem>
                    })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField id="pontoSolturaCoordX" label="Coordenada X" className="inputDefault" disabled={!writeModuleVerify || selectedCard.localSoltura} value={selectedCard?.pontoSoltura?.coordX ?? "--"} onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordX', 'pontoSoltura', selectedCard.pontoSoltura, dispatch)}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField id="pontoSolturaCoordY" label="Coordenada Y" className="inputDefault" disabled={!writeModuleVerify || selectedCard.localSoltura} value={selectedCard?.pontoSoltura?.coordY ?? "--"} onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordY', 'pontoSoltura', selectedCard.pontoSoltura, dispatch)}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField id="pontoSolturaCoordProj" label="Projeção" className="inputDefault" disabled={!writeModuleVerify || selectedCard.localSoltura} value={selectedCard?.pontoSoltura?.coordProj ?? "--"} onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordProj', 'pontoSoltura', selectedCard.pontoSoltura, dispatch)}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField id="epeSoltura" label="EPE" className="inputDefault" disabled={!writeModuleVerify || selectedCard.localSoltura} value={selectedCard?.epeSoltura ?? "--"} onChange={(event) => faunaHelpers.handleInput(event, 'epeSoltura', dispatch)}/>
            </Grid>

            <Divider className="dividerFull"/>
 
            <Grid item xs={12} md={4}>
                <TextField id="origemSoltura" disabled={!writeModuleVerify} label="Origem (para animais de soltura)" className="inputDefault" value={selectedCard.origemSoltura} onChange={(event) => faunaHelpers.handleInput(event, 'origemSoltura', dispatch)}/>
            </Grid>
            
            <Grid item xs={12} md={4}>
                <TextField id="capturadoPor" disabled={!writeModuleVerify} label="Capturado por" className="inputDefault" value={selectedCard.capturadoPor} onChange={(event) => faunaHelpers.handleInput(event, 'capturadoPor', dispatch)}/>
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField id="manipuladoPor" disabled={!writeModuleVerify} label="Manipulado por" className="inputDefault" value={selectedCard.manipuladoPor} onChange={(event) => faunaHelpers.handleInput(event, 'manipuladoPor', dispatch)}/>
            </Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                        className="inputDefault dateInput"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data de manipulação"
                        value={selectedCard.formatedDate && selectedCard.formatedDate.dataManipulacao ? selectedCard.formatedDate.dataManipulacao : null}
                        onChange={(event) => faunaHelpers.handleDate(event, 'dataManipulacao', selectedCard.formatedDate, dispatch)}
                        KeyboardButtonProps={{
                            'aria-label': 'Alterar data',
                        }}
                        invalidDateMessage={'Data inválida'}
                        disabled={!writeModuleVerify}
                    />
                </Grid>
            </MuiPickersUtilsProvider>

            <Grid item xs={12} md={12}>
                <TextField id="obsAdicionaisSoltura" disabled={!writeModuleVerify} label="Observações Adicionais" className="inputDefault" multiline rows={1} rowsMax={8}  value={selectedCard.obsAdicionaisSoltura} onChange={(event) => faunaHelpers.handleInput(event, 'obsAdicionaisSoltura', dispatch)}/>
            </Grid>

            <Divider className="dividerFull"/>
            <Grid item xs={12} md={4}>
                <Checkbox
                    className="faunaCheckBox"
                    checked={selectedCard.obito && selectedCard.obito === "Sim" ? true : selectedCard.obito === "Não" ? false : selectedCard.obito}
                    onChange={() => faunaHelpers.handleRadios(selectedCard.obito && selectedCard.obito === "Sim" ? true : selectedCard.obito === "Não" ? false : selectedCard.obito, 'obito', dispatch)}
                    disabled={!writeModuleVerify}
                />
                <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                    Houve óbito?
                </Typography>
            </Grid>

            {selectedCard.obito && selectedCard.obito !== "Não" && 
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={4}>
                        <KeyboardDatePicker
                            className="inputDefault dateInput"
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Data do óbito"
                            value={selectedCard.formatedDate && selectedCard.formatedDate.obitoData ? selectedCard.formatedDate.obitoData : null}
                            onChange={(event) => faunaHelpers.handleDate(event, 'obitoData', selectedCard.formatedDate, dispatch)}
                            KeyboardButtonProps={{
                                'aria-label': 'Alterar data',
                            }}
                            invalidDateMessage={'Data inválida'}
                            disabled={!writeModuleVerify}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            }

            {selectedCard.obito && selectedCard.obito !== "Não" && 
                <Grid item xs={12} md={12}>
                    <TextField id="obitoObs" disabled={!writeModuleVerify} label="Observações do óbito" className="inputDefault" multiline rows={1} rowsMax={8}  value={selectedCard.obitoObs} onChange={(event) => faunaHelpers.handleInput(event, 'obitoObs', dispatch)}/>
                </Grid>
            }

            <Divider className="dividerFull"/>
            {selectedCard.pontoSoltura && selectedCard.pontoSoltura.coordX && selectedCard.pontoSoltura.coordY && selectedCard.pontoSoltura.coordProj && selectedCard.epeSoltura &&
                <Grid item xs={12} md={4}>
                    <Button variant="contained" className="btn-primary" onClick={() =>  modalFn('Captura', selectedCard.pontoSoltura)}>Visualizar no mapa</Button>
                </Grid>
            }
        </Grid>
    )
}

export default AccordionSoltura