import { lenhaActions } from "../../../actions/lenhaActions"
import { lenhaActionTypes } from "../../../types/lenhaActionTypes"
import { history } from '../../history';

function listLenha() {
	return dispatch => {
		dispatch(lenhaActions.listLenha())
	}
}

function paginateLenha(parameters, page, projectId) {
	return dispatch => {
		dispatch(lenhaActions.paginateLenha(parameters, page, projectId))
	}
}

function handleChangeFilter(event, target, context) {
	const value = event.target.value
	const prefix = context === 'lenha' ? 'LENHA' : context === 'lenhaSaida' ? 'LENHA_SAIDA' : ''

	return dispatch => {
		dispatch(setFilter(value, target, prefix))
	}
}

function setFilter(value, target, prefix) { return { type: lenhaActionTypes[`SET_${prefix}_FILTER`], value, target } }

function handleDateChangeInit(date, context) {
	const prefix = context === 'lenha' ? 'LENHA' : context === 'lenhaSaida' ? 'LENHA_SAIDA' : ''

	return dispatch => {
		dispatch(handleDateInit(date, prefix))
	}
};


function handleDateChangeEnd(date, context) {
	const prefix = context === 'lenha' ? 'LENHA' : context === 'lenhaSaida' ? 'LENHA_SAIDA' : ''

	return dispatch => {
		dispatch(handleDateEnd(date, prefix))
	}
};

function handleDateInit(date, prefix) { return { type: lenhaActionTypes[`SET_${prefix}_FILTER_DATE_INIT`], date } }

function handleDateEnd(date, prefix) { return { type: lenhaActionTypes[`SET_${prefix}_FILTER_DATE_END`], date } }

function editCard(context, item, callback) {
	if (item) {
		if (callback) { callback(context) }
		return { type: lenhaActionTypes.EDIT_LENHA_CARD, item, context }
	} else {
		return { type: lenhaActionTypes.UPDATE_LENHA_CARD_CANCELED }
	}
}

async function handleInput(myValue, target, context, dispatch, callback, isNumber) {
	let value = target === 'dataMedicao' || isNumber ? myValue : myValue.target.value

	if(target === 'numeroFicha') {
		value = ("00000000" + value).slice(value.length)
	}

	await dispatch(saveStore(value, target, context));

	if (callback) callback(dispatch)
}

function HandleInputNumber(event, target, context, dispatch, callback) {
	let myValue = event?.target?.value
	let replaced = myValue.replace(/[^\d.-]/g, '')
	handleInput(replaced, target, context, dispatch, callback, true)
}

function saveStore(value, target, context) { return { type: lenhaActionTypes.HANDLE_LENHA_FIELDS, context, value, target } }

function calculateAlturaMedia(altura1, altura2, altura3, altura4, altura5, altura6, comprimento, largura, context, dispatch) {
	const valores = []
	if (altura1 && (altura1 !== '' && altura1 !== '--' && Number(altura1) !== 0)) valores.push(Number(altura1))
	if (altura2 && (altura2 !== '' && altura2 !== '--' && Number(altura2) !== 0)) valores.push(Number(altura2))
	if (altura3 && (altura3 !== '' && altura3 !== '--' && Number(altura3) !== 0)) valores.push(Number(altura3))
	if (altura4 && (altura4 !== '' && altura4 !== '--' && Number(altura4) !== 0)) valores.push(Number(altura4))
	if (altura5 && (altura5 !== '' && altura5 !== '--' && Number(altura5) !== 0)) valores.push(Number(altura5))
	if (altura6 && (altura6 !== '' && altura6 !== '--' && Number(altura6) !== 0)) valores.push(Number(altura6))

	var media = 0

	valores.map(valor => media += valor)

	media /= valores.length
	if ((comprimento && Number(comprimento) > 0) && (largura && Number(largura) > 0)) {
		calculateVolume(comprimento, media, largura, context, dispatch)
	}

	dispatch(saveStore(media, 'alturaMedia', context));
}

function calculateVolume(comprimento, alturaMedia, largura, context, dispatch) {
	let volume = parseFloat(comprimento) * parseFloat(alturaMedia) * parseFloat(largura)

	calculateVolumeEstero(volume, context, dispatch)

	dispatch(saveStore(volume, 'volume', context));
}

function calculateVolumeEstero(volume, context, dispatch) {
	let volumeEstero = parseFloat(volume) * 0.7

	dispatch(saveStore(volumeEstero, 'volumeEsterio', context));
}

function createLenha(parameters, page, callback, projectId, params) {

	const data = {
		areaDemarcada: parameters.areaDemarcada,
		comprimento: parameters.comprimento,
		dataMedicao: parameters.dataMedicao,
		altura1: parameters.altura1,
		altura2: parameters.altura2,
		altura3: parameters.altura3,
		altura4: parameters.altura4,
		altura5: parameters.altura5,
		altura6: parameters.altura6,
		alturaMedia: parameters.alturaMedia,
		largura: parameters.largura,
		volume: parameters.volume,
		volumeEsterio: parameters.volumeEsterio,
		project: projectId
	}

	return dispatch => {
		dispatch(lenhaActions.createLenha(data, page, callback, projectId, params))
	}
}

function updateLenha(parameters, page, callback, projectId, params) {

	const data = {
		_id: parameters._id,
		numeroFicha: parameters.numeroFicha,
		areaDemarcada: parameters.areaDemarcada,
		comprimento: parameters.comprimento,
		dataMedicao: parameters.dataMedicao,
		altura1: parameters.altura1,
		altura2: parameters.altura2,
		altura3: parameters.altura3,
		altura4: parameters.altura4,
		altura5: parameters.altura5,
		altura6: parameters.altura6,
		alturaMedia: parameters.alturaMedia,
		largura: parameters.largura,
		volume: parameters.volume,
		volumeEsterio: parameters.volumeEsterio,
		project: projectId ?? parameters.project
	}

	return dispatch => {
		dispatch(lenhaActions.updateLenha(data, page, callback, projectId, params))
	}
}

function deleteLenha(parameters, page, projectId, cardId, closeModal, dispatch) {
	dispatch(lenhaActions.deleteLenha(parameters, page, projectId, cardId, closeModal))
}

function exportData(parameters, projectId) {
	return dispatch => {
		dispatch(lenhaActions.exportLenha(parameters, projectId))

	}
}

// Lenha Saida

function lenha() {
	return dispatch => {
		return history.push(`/flora/lenha`)
	}
}

function lenhaSaidas() {
	return dispatch => {
		return history.push(`lenha/saidas`)
	}
}

function paginateLenhaSaida(page, projectId) {
	return dispatch => {
		dispatch(lenhaActions.paginateLenhaSaida(page, projectId))
	}
}

async function handleSaidaInput(myValue, target, context, dispatch, callback, isNumber) {
	let value = target === 'data' || isNumber ? myValue : myValue.target.value

	await dispatch(saveSaidaStore(value, target, context));

	if (callback) callback(dispatch)
}

function HandleSaidaInputNumber(event, target, context, dispatch, callback) {
	let myValue = event?.target?.value
	let replaced = myValue.replace(/[^\d.-]/g, '')
	handleSaidaInput(replaced, target, context, dispatch, callback, true)
}

function saveSaidaStore(value, target, context) { return { type: lenhaActionTypes.HANDLE_LENHA_SAIDA_FIELDS, context, value, target } }

function calculateLenhaVolume(project) {
	return dispatch => {
		dispatch(lenhaActions.calculateLenhaVolume(project))
	}
}

function editSaidaCard(context, item, callback) {
	if (item) {
		if (callback) { callback(context) }
		return { type: lenhaActionTypes.EDIT_LENHA_SAIDA_CARD, item, context }
	} else {
		return { type: lenhaActionTypes.UPDATE_LENHA_SAIDA_CARD_CANCELED }
	}
}

function createLenhaSaida(parameters, page, callback, projectId, params) {

	const data = {
		data: parameters.data,
		tipo: parameters.tipo,
		destino: parameters.destino,
		licenca: parameters.licenca,
		volume: parameters.volume,
		project: projectId
	}

	return dispatch => {
		dispatch(lenhaActions.createLenhaSaida(data, page, callback, projectId, params))
	}
}

function updateLenhaSaida(parameters, page, callback, projectId, params) {

	const data = {
		_id: parameters._id,
		data: parameters.data,
		tipo: parameters.tipo,
		destino: parameters.destino,
		licenca: parameters.licenca,
		volume: parameters.volume,
		project: projectId ?? parameters.project
	}

	return dispatch => {
		dispatch(lenhaActions.updateLenhaSaida(data, page, callback, projectId, params))
	}
}

function deleteLenhaSaida(page, projectId, cardId, closeModal, dispatch) {
	dispatch(lenhaActions.deleteLenhaSaida(page, projectId, cardId, closeModal))
}

export const lenhaHelpers = {
	handleChangeFilter,
	handleDateChangeInit,
	handleDateChangeEnd,
	editCard,
	handleInput,
	HandleInputNumber,
	calculateAlturaMedia,
	calculateVolume,
	listLenha,
	paginateLenha,
	createLenha,
	updateLenha,
	deleteLenha,
	lenhaSaidas,
	lenha,
	paginateLenhaSaida,
	handleSaidaInput,
	HandleSaidaInputNumber,
	saveSaidaStore,
	calculateLenhaVolume,
	editSaidaCard,
	createLenhaSaida,
	updateLenhaSaida,
	deleteLenhaSaida,
	exportData
}