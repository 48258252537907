import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, InputAdornment, Select, MenuItem, FormControl, InputLabel, Checkbox } from "@material-ui/core"
import { toraHelpers } from '../../../helpers/flora/tora/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class ToraForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formSubmited: false,
            InsertManualPlace: false
        }
    }

    setManualPlace = (item) => {
        this.setState({ InsertManualPlace: item })
    }

    formValidator = () => {
        const { toraReducer } = this.props
        const { tora } = toraReducer
        const { editItem } = tora
        const { numeroFicha, areaDemarcada, dataMedicao, comprimento, diametro1, diametroMedio, volume } = editItem

        if (numeroFicha !== '' && dataMedicao !== null && areaDemarcada !== '' && comprimento !== '' && diametro1 !== '' && diametroMedio !== '' && volume !== '') {
            return true
        } else {
            return false
        }
    }

    submitForm = () => {
        const { toraReducer, dispatch, closeModal, projectsReducer } = this.props
        const { tora } = toraReducer
        const { editItem, filters } = tora
        this.setState({ formSubmited: true })

        if (this.formValidator() === false) return

        if (editItem._id && editItem._id !== '') {
            dispatch(toraHelpers.updateTora(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        } else {
            dispatch(toraHelpers.createTora(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        }
    }

    calculateValues = () => {
        const { toraReducer, dispatch, context } = this.props
        const { tora } = toraReducer
        const { editItem } = tora
        if ((editItem.diametro1 && Number(editItem.diametro1) > 0)) {
            toraHelpers.calculateDiametroMedio(editItem.diametro1, editItem.diametro2, editItem.diametro3, editItem.comprimento, context, dispatch)
        }
    }

    render() {
        const { dispatch, toraReducer, projectsReducer, closeModal, placesReducer } = this.props
        const { tora } = toraReducer
        const { editItem, filters, documents } = tora
        const { formSubmited, InsertManualPlace } = this.state
        const { listPlaces } = placesReducer
        const validPlace = listPlaces?.some(item => item.name === editItem.areaDemarcada)


        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="numeroFicha"
                                label="Número da ficha"
                                className="inputDefault"
                                disabled={editItem && editItem._id}
                                value={editItem.numeroFicha}
                                autoComplete='off'
                                onChange={(event) => toraHelpers.handleInput(event, 'numeroFicha', 'tora', dispatch)}
                                error={formSubmited && editItem.numeroFicha === ''}
                                helperText={formSubmited && editItem.numeroFicha === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de cadastro"
                                    value={editItem.dataMedicao}
                                    onChange={(event) => toraHelpers.handleInput(event, 'dataMedicao', 'tora', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Divider className="dividerFull" />
                        <Grid item xs={12} md={4}>
                            <Checkbox
                                className="faunaCheckBox"
                                checked={InsertManualPlace}
                                onChange={() => this.setManualPlace(!InsertManualPlace)}
                            />
                            <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                                Deseja inserir a area manualmente?
                            </Typography>
                        </Grid>
                        {InsertManualPlace && 
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="areaDemarcada"
                                    label="Área demarcada"
                                    className="inputDefault"
                                    value={editItem.areaDemarcada}
                                    autoComplete='off'
                                    onChange={(event) => toraHelpers.handleInput(event, 'areaDemarcada', 'tora', dispatch)}
                                    error={formSubmited && editItem.areaDemarcada === ''}
                                    helperText={formSubmited && editItem.areaDemarcada === '' ? 'Campo obrigatório' : ''}
                                />
                            </Grid>
                        }
                        {!InsertManualPlace && 
                            <Grid item xs={12} md={4}>
                                <FormControl className="inputDefault">
                                    <InputLabel id="demo-simple-select-label">Área Demarcada</InputLabel>
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                        value={editItem.areaDemarcada}
                                        onChange={(event) => toraHelpers.handleInput(event, 'areaDemarcada', 'tora', dispatch)}
                                    >
                                        {!validPlace && editItem.areaDemarcada !== "--" &&
                                            <MenuItem value={editItem.areaDemarcada}>{editItem.areaDemarcada}</MenuItem>
                                        }
                                        {listPlaces.map((item, index) => {
                                            return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="comprimento"
                                label="Comprimento"
                                className="inputDefault"
                                value={editItem.comprimento}
                                autoComplete='off'
                                onChange={(event) => toraHelpers.HandleInputNumber(event, 'comprimento', 'tora', dispatch, this.calculateValues)}
                                error={formSubmited && editItem.comprimento === ''}
                                helperText={formSubmited && editItem.comprimento === '' ? 'Campo obrigatório' : ''}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="diametro1"
                                label="Diâmetro 1"
                                className="inputDefault"
                                value={editItem.diametro1}
                                autoComplete='off'
                                onChange={(event) => toraHelpers.HandleInputNumber(event, 'diametro1', 'tora', dispatch, this.calculateValues)}
                                error={formSubmited && editItem.diametro1 === ''}
                                helperText={formSubmited && editItem.diametro1 === '' ? 'Campo obrigatório' : ''}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="diametro2"
                                label="Diâmetro 2"
                                className="inputDefault"
                                value={editItem.diametro2}
                                autoComplete='off'
                                onChange={(event) => toraHelpers.HandleInputNumber(event, 'diametro2', 'tora', dispatch, this.calculateValues)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="diametro3"
                                label="Diâmetro 3"
                                className="inputDefault"
                                value={editItem.diametro3}
                                autoComplete='off'
                                onChange={(event) => toraHelpers.HandleInputNumber(event, 'diametro3', 'tora', dispatch, this.calculateValues)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="diametroMedio"
                                label="Diâmetro Médio"
                                className="inputDefault"
                                value={editItem.diametroMedio}
                                autoComplete='off'
                                // onChange={(event) => toraHelpers.handleInput(event, 'diametroMedio', 'tora', dispatch)}
                                disabled={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="volume"
                                label="Volume"
                                className="inputDefault"
                                value={editItem.volume}
                                autoComplete='off'
                                // onChange={(event) => toraHelpers.handleInput(event, 'volume', 'tora', dispatch)}
                                disabled={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Divider className="dividerFull" />
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained"
                                    className="btn-primary"
                                    component="label"
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        {editItem._id && editItem._id !== '' &&
                            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                                <Box padding={10}>
                                    <Button variant="contained"
                                        className="btn-secondary"
                                        component="label"
                                        startIcon={<DeleteForeverIcon />}
                                        onClick={(event) => toraHelpers.deleteTora(filters, documents.page, projectsReducer.selectedProject, editItem._id, closeModal, dispatch)}> Excluir </Button>
                                </Box>
                            </Box>
                        }
                        <Divider className="dividerFull" />
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    toraReducer: store.toraReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
    placesReducer: store.placesReducer,
});

export default connect(mapStateToProps)(ToraForm);