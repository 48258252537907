import { trapsHandler } from '../handlers/trapsHandler';
import { trapsActionTypes } from '../types/trapsActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const trapsActions = {
    list,
    paginate,
    create,
    update
  };
  
function list(projectId) {
    return dispatch => {
        dispatch(request());
        trapsHandler.list(projectId)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: trapsActionTypes.GET_TRAPS_REQUEST } }
    function success(places) { return { type: trapsActionTypes.GET_TRAPS_SUCCESS, places } }
    function failure(error) { return { type: trapsActionTypes.GET_TRAPS_FAILURE, error } }
}

function paginate(params, projectId, page) {
    return dispatch => {
        dispatch(request());
        trapsHandler.paginate(params, projectId, page)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: trapsActionTypes.LIST_TRAPS_REQUEST } }
    function success(list) { return { type: trapsActionTypes.LIST_TRAPS_SUCCESS, list } }
    function failure(error) { return { type: trapsActionTypes.LIST_TRAPS_FAILURE, error } }
}

function create(params, page, callback) {
    return dispatch => {
        dispatch(request());
        trapsHandler.create(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Projeto criado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: trapsActionTypes.CREATE_TRAPS_REQUEST } }
    function success(list) { return { type: trapsActionTypes.CREATE_TRAPS_SUCCESS, list } }
    function failure(error) { return { type: trapsActionTypes.CREATE_TRAPS_FAILURE, error } }
}

function update(params, page, callback) {
    return dispatch => {
        dispatch(request());
        trapsHandler.update(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Projeto atualizado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: trapsActionTypes.UPDATE_TRAPS_REQUEST } }
    function success(list) { return { type: trapsActionTypes.UPDATE_TRAPS_SUCCESS, list } }
    function failure(error) { return { type: trapsActionTypes.UPDATE_TRAPS_FAILURE, error } }
}