import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, FormControl, MenuItem, Select, InputLabel } from "@material-ui/core"

import FilterDate from './filterDate'
import { toraHelpers } from '../../../helpers/flora/tora/helpers';

class filter extends Component {

  render() {
    const { dispatch, toraReducer, context, projectsReducer } = this.props
    const { toraSaida } = toraReducer
    const { filters } = toraSaida

    return (
      <>
        <div className="filter">
          <Typography variant="h5" component="h5" className="filterTitle">
            Pesquisar
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <>
                {context === 'toraSaida' &&
                  <Grid item xs={12} md={4}>
                    <FormControl className="inputDefault">
                      <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                      <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={filters.tipo}
                        onChange={(event) => dispatch(toraHelpers.handleChangeFilter(event, "tipo", context))}
                      >
                        <MenuItem key='0' value={''}>{'--'}</MenuItem>
                        <MenuItem key='1' value={'Uso interno'}>{'Uso interno'}</MenuItem>
                        <MenuItem key='2' value={'Desmembramento'}>{'Desmembramento'}</MenuItem>
                        <MenuItem key='3' value={'Deterioração'}>{'Deterioração'}</MenuItem>
                        <MenuItem key='4' value={'Saída'}>{'Saída'}</MenuItem>
                        {/* {fauna.selectOptions.classType.map((type, index) => {
                          if (type === "Todos") {
                            return <MenuItem key='0' value={false}>{type}</MenuItem>
                          } else {
                            return <MenuItem key={index} value={type}>{type}</MenuItem>
                          }
                        })} */}
                      </Select>
                    </FormControl>
                  </Grid>
                }
                <Grid item xs={12} md={4}>
                  <TextField
                    id="volumeTotal"
                    label="Volume Total"
                    className="inputDefault"
                    value={filters.volumeTotal}
                    onChange={(event) => dispatch(toraHelpers.handleChangeFilter(event, "volumeTotal", context))}
                  />
                </Grid>
                <Divider className="dividerFull" />
                <FilterDate filterProps={filters} dispatch={dispatch} context={context} />
              </>
              <Divider className="dividerFull" />
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => this.props.handleModal(context, true)}
                >
                  ADICIONAR SAÍDA
                </Button>
              </Grid>
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => dispatch(toraHelpers.tora())}
                >
                  TORAS
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={
                    () => {
                      dispatch(toraHelpers.paginateToraSaida(filters, 1, projectsReducer.selectedProject))
                    }
                  }
                >
                  PESQUISAR
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        {/* <div id="qntCounter">
          <p>
            {`Quantidade de exemplares no viveiro: `}
            <span>{documents.qntCounter ? documents.qntCounter : '0' }</span>
          </p>
        </div> */}
      </>
    )
  }
}

const mapStateToProps = store => ({
  toraReducer: store.toraReducer,
  paramsReducer: store.paramsReducer,
  projectsReducer: store.projectsReducer
});

export default connect(mapStateToProps)(filter);