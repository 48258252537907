export const trapsActionTypes = {
    GET_TRAPS_REQUEST: "GET_TRAPS_REQUEST",
    GET_TRAPS_SUCCESS: "GET_TRAPS_SUCCESS",
    GET_TRAPS_FAILURE: "GET_TRAPS_FAILURE",

    CREATE_TRAPS_REQUEST: "CREATE_TRAPS_REQUEST",
    CREATE_TRAPS_SUCCESS: "CREATE_TRAPS_SUCCESS",
    CREATE_TRAPS_FAILURE: "CREATE_TRAPS_FAILURE",

    UPDATE_TRAPS_REQUEST: "UPDATE_TRAPS_REQUEST",
    UPDATE_TRAPS_SUCCESS: "UPDATE_TRAPS_SUCCESS",
    UPDATE_TRAPS_FAILURE: "UPDATE_TRAPS_FAILURE",
    UPDATE_TRAPS_CANCELED: "UPDATE_TRAPS_CANCELED",

    LIST_TRAPS_REQUEST: "LIST_TRAPS_REQUEST",
    LIST_TRAPS_SUCCESS: "LIST_TRAPS_SUCCESS",
    LIST_TRAPS_FAILURE: "LIST_TRAPS_FAILURE",

    HANDLE_INPUT_TRAPS: "HANDLE_INPUT_TRAPS",
    HANDLE_RADIOS_TRAPS: "HANDLE_RADIOS_TRAPS",
    HANDLE_TRAPS: "HANDLE_TRAPS",
    
    SET_TRAPS_FILTER: "SET_TRAPS_FILTER",
    EDIT_TRAPS: "EDIT_TRAPS",
};