import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const placesHandler = {
  getPlaces,
  paginatePlaces,
  createPlaces,
  updatePlaces
};

function getPlaces(selectedProject, type) {

  const $type = type ? `&placeType=${type}` : "1"

  const requestOptions = { method: "GET", headers: defaultHeaders };
  return fetch(prefixDefaultApi.concat(`places/list?projectId=${selectedProject ? selectedProject : ''}`).concat($type) , requestOptions)
    .then(apiHelpers.handleStatus)
    .then(apiHelpers.handleResponse);
}

function paginatePlaces(params, page) {
  const $name = params && params.name ? `&name=${params.name}` : ""

  const queryParams = "?page=".concat(page)
    .concat($name)

  const requestOptions = { method: "GET", headers: defaultHeaders };
  return fetch(prefixDefaultApi.concat("places/paginate").concat(queryParams), requestOptions)
    .then(apiHelpers.handleStatus)
    .then(apiHelpers.handleResponse);
}


function createPlaces(params, page) {

  const queryParams = "?page=".concat(page)

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(params),
    headers: defaultHeaders
  };
  return fetch(prefixDefaultApi.concat("places/").concat(queryParams), requestOptions)
    .then(apiHelpers.handleStatus)
    .then(apiHelpers.handleResponse);
}

function updatePlaces(params, page) {
  const queryParams = "?page=".concat(page)

  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(params),
    headers: defaultHeaders
  };
  return fetch(prefixDefaultApi.concat("places/").concat(queryParams), requestOptions)
    .then(apiHelpers.handleStatus)
    .then(apiHelpers.handleResponse);
}