import React from 'react';
import { Grid } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';  
import { viveirosHelpers } from '../../../helpers/flora/viveiros/helpers'

const FilterDate = ({ filterProps , dispatch}) => {

    const handleDateChangeInit = (date) => {
        dispatch(viveirosHelpers.handleDateChangeInit(new Date(date)));
    };
    
    const handleDateChangeEnd = (date) => {
        dispatch(viveirosHelpers.handleDateChangeEnd(new Date(date)));
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} md={4}>
                <KeyboardDatePicker
                    className="inputDefault"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="De"
                    value={filterProps.initDate}
                    onChange={handleDateChangeInit}
                    KeyboardButtonProps={{
                        'aria-label': 'Alterar data',
                    }}
                    invalidDateMessage={'Data inválida'}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <KeyboardDatePicker
                    className="inputDefault"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="à"
                    value={filterProps.endDate}
                    onChange={handleDateChangeEnd}
                    KeyboardButtonProps={{
                        'aria-label': 'Alterar data',
                    }}
                    invalidDateMessage={'Data inválida'}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default FilterDate