import { alertActionTypes } from '../types/alertActionTypes';

export const alertReducer = (state = {}, action) => {
	switch (action.type) {
		case alertActionTypes.SUCCESS:
			return {
				type: 'alert-success',
				message: action.message,
				fade: false
			};
		case alertActionTypes.ERROR:
			return {
				type: 'alert-danger',
				message: action.error,
				fade: false
			};
		case alertActionTypes.FADE:
			return {
				fade: true
			};
		case alertActionTypes.CLEAR:
			return {
				type: '',
				message: '',
				fade: false
			};
		case alertActionTypes.POP_UP:
			return {
				type: 'popUp',
				message: action.message,
				fade: false
			};
		default:
			return state
	}
}