import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, InputLabel, Select, FormControl, MenuItem } from "@material-ui/core"
import { helpers } from '../../../helpers/helpers'
import { faunaHelpers } from '../../../helpers/fauna/faunaHelpers'

class Filter extends Component {

    render(){
        const { dispatch, faunaReducer, projects } = this.props

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextField id="codigo" label="Código" className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "codigo", "telemetria"))} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField id="nomeCientifico" label="Nome Científico" className="inputDefault" onChange={(event) => dispatch(helpers.handleChangeFilter(event, "nomeCientifico", "telemetria"))} />
                        </Grid>
                        <Grid item xs={12} md={4}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => faunaHelpers.telemetriaDetails()}>
                                NOVA FICHA
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        {/* <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" download onClick={() => dispatch(faunaHelpers.exportData(fauna.telemetriaFilters, projects.selectedProject))}>
                                EXPORTAR
                            </Button>
                        </Grid> */}
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(faunaHelpers.listTelemetria(faunaReducer.telemetriaFilters, 1, projects.selectedProject ))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    faunaReducer: store.faunaReducer,
    projects: store.projectsReducer
});

export default connect(mapStateToProps)(Filter);