import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";
import fileDownload from 'js-file-download';
import moment from 'moment';

export const toraHandler = { 
	listTora, 
	paginateTora, 
	createTora,
	updateTora,
	deleteTora,
	listToraSaida, 
	paginateToraSaida, 
	createToraSaida,
	updateToraSaida,
	deleteToraSaida,
	exportTora
};

function listTora(findParams) {
	var url = new URL(prefixDefaultApi.concat("flora/tora/list"))
	url.search = new URLSearchParams(findParams).toString();

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(url, requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateTora(params, page, projectId) {

	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $numeroFicha = params?.numeroFicha ? `&numeroFicha=${params.numeroFicha}` : ""
	const $areaDemarcada = params?.areaDemarcada ? `&areaDemarcada=${params.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)


	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/tora/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createTora(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $numeroFicha = filters?.numeroFicha ? `&numeroFicha=${filters.numeroFicha}` : ""
	const $areaDemarcada = filters?.areaDemarcada ? `&areaDemarcada=${filters.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/tora").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateTora(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $numeroFicha = filters?.numeroFicha ? `&numeroFicha=${filters.numeroFicha}` : ""
	const $areaDemarcada = filters?.areaDemarcada ? `&areaDemarcada=${filters.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/tora").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function deleteTora(params, page, projectId, deletedCard) {

	const $deletedCard = `&deletedCard=${deletedCard}`

	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $numeroFicha = params?.numeroFicha ? `&numeroFicha=${params.numeroFicha}` : ""
	const $areaDemarcada = params?.areaDemarcada ? `&areaDemarcada=${params.areaDemarcada}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($deletedCard)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)
	.concat($project)


	const requestOptions = { method: "DELETE", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/tora").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function exportTora(params, projectId) {
	
	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $numeroFicha = params?.numeroFicha ? `&numeroFicha=${params.numeroFicha}` : ""
	const $areaDemarcada = params?.areaDemarcada ? `&areaDemarcada=${params.areaDemarcada}` : ""
    
	const queryParams = "?project=".concat(projectId)
	.concat($initDate)
	.concat($endDate)
	.concat($numeroFicha)
	.concat($areaDemarcada)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/tora/export").concat(queryParams), requestOptions)
	.then( res => {
		apiHelpers.handleStatus(res)
		if(res?.status === 400){
			return res
		}else{
			return res.blob()
		}
	})
	.then( blob => {
		if(blob?.status === 400){
			return "erro"
		}else{
			fileDownload(blob, `${moment().format('YYYY-MM-DD-hh-mm-ss')}_export_tora.xlsx`);
		}
	})
}

function listToraSaida() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/tora/saida/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateToraSaida(params, page, projectId) {

	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $tipo = params?.tipo ? `&tipo=${params.tipo}` : ""
	const $volumeTotal = params?.volumeTotal ? `&volumeTotal=${params.volumeTotal}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($tipo)
	.concat($volumeTotal)
	.concat($project)


	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/tora/saida/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createToraSaida(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $tipo = filters?.tipo ? `&tipo=${filters.tipo}` : ""
	const $volumeTotal = filters?.volumeTotal ? `&volumeTotal=${filters.volumeTotal}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($tipo)
	.concat($volumeTotal)
	.concat($project)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/tora/saida").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateToraSaida(params, page, projectId, filters) {
    
	const $initDate = filters && filters.initDate ? `&initDate=${filters.initDate}` : ""
	const $endDate = filters && filters.endDate ? `&endDate=${filters.endDate}` : ""
	const $tipo = filters?.tipo ? `&tipo=${filters.tipo}` : ""
	const $volumeTotal = filters?.volumeTotal ? `&volumeTotal=${filters.volumeTotal}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($initDate)
	.concat($endDate)
	.concat($tipo)
	.concat($volumeTotal)
	.concat($project)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/tora/saida").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function deleteToraSaida(params, page, projectId, deletedCard) {

	const $deletedCard = `&deletedCard=${deletedCard}`

	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	const $tipo = params?.tipo ? `&tipo=${params.tipo}` : ""
	const $volumeTotal = params?.volu$volumeTotal ? `&volu$volumeTotal=${params.volu$volumeTotal}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($deletedCard)
	.concat($initDate)
	.concat($endDate)
	.concat($tipo)
	.concat($volumeTotal)
	.concat($project)


	const requestOptions = { method: "DELETE", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/tora/saida").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}
