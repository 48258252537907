import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';  
import { helpers } from '../../helpers/helpers'
import { instListaHelpers } from '../../helpers/instListaEspecies/instListaHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

class InstListaForm extends Component {

    constructor(props){
        super(props)
        this.state = {
        }
    }

    render(){
        const { dispatch, instListaEspeciesReducer, closeModal } = this.props
        const { editItem } = instListaEspeciesReducer

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="name" label="Instituição" className="inputDefault" value={editItem.name} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'name', 'editInstLista', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="nickname" label="Sigla" className="inputDefault" value={editItem.nickname} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'nickname', 'editInstLista', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.status} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'status', 'editInstLista', dispatch)}>
                                    <MenuItem key='ativa' value={true}>{'Ativa'}</MenuItem>
                                    <MenuItem key='inativa' value={false}>{'Inativa'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de Publicação"
                                    value={editItem.date}
                                    onChange={(event) => helpers.handleInput(new Date(event), 'date', 'editInstLista', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>

                        <Grid item xs={12} md={12} >
                            <Typography variant="h6" component="h6" className="filterTitle">Classes</Typography>
                        </Grid>
                        <Divider className="dividerFull"/>
                        {editItem.classe.map((item, index) => {
                            return(
                                <>
                                    <Grid key={`${index}-description`} item xs={12} md={3}>
                                        <TextField label="Nome" className="inputDefault" value={item.description} autoComplete='off' onChange={(event) => instListaHelpers.handleClass(editItem.classe, index, 'description', event.target.value, dispatch)}/>
                                    </Grid>
                                    <Grid key={`${index}-status`} item xs={12} md={3}>
                                        <FormControl className="inputDefault">
                                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                            <Select labelId="itemstatus-select-label" id="status-select" value={item.status} autoComplete='off' onChange={(event) => instListaHelpers.handleClass(editItem.classe, index, 'status', event.target.value, dispatch)}>
                                                <MenuItem key='ativa' value={true}>{'Ativa'}</MenuItem>
                                                <MenuItem key='inativa' value={false}>{'Inativa'}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid key={`${index}-button`} item xs={12} md={1}>
                                        <Button
                                        variant="contained"
                                        className="btn-secondary btn-innerTable"
                                        onClick={() => dispatch(instListaHelpers.removeClass(editItem.classe, index, dispatch))}
                                        >
                                        <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </Grid>
                                    <Divider className="dividerFull"/>
                                </>
                            )
                        })}
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-secondary" onClick={() => instListaHelpers.addNewClass(editItem.classe, dispatch)}>
                                Nova Classe
                            </Button>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-primary" onClick={editItem._id && editItem._id !== '' ? () => dispatch(instListaHelpers.update(editItem, 1, closeModal)) : () => dispatch(instListaHelpers.create(editItem, 1, closeModal))}>
                                SALVAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    instListaEspeciesReducer: store.instListaEspeciesReducer,
});

export default connect(mapStateToProps)(InstListaForm);