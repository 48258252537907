const getUserToken = () => {
    try {
        const token = localStorage.getItem('gaia_token');
        if (token) {
            return token;
        }
    } catch (error) {
        return null;
    }

}


export const defaultHeaders = {
    'Content-Type': 'application/json',
    ...getUserToken() ? {'Authorization': `Bearer ${getUserToken()}`} : {}
};
