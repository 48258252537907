export const actionTypes = {

    AUTH: 'AUTH',
    GET_USER: 'GET_USER',
    
    HANDLE_INPUT: "HANDLE_INPUT",
    
    LOCATION: "LOCATION",
    
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",

    LOGGED_USER_REQUEST: 'LOGGED_USER_REQUEST',
    LOGGED_USER_SUCCESS: 'LOGGED_USER_SUCCESS',
    LOGGED_USER_FAILURE: 'LOGGED_USER_FAILURE',
    
    USER_VERIFY_REQUEST: "USER_VERIFY_REQUEST",
    USER_VERIFY_SUCCESS: "USER_VERIFY_SUCCESS",
    USER_VERIFY_FAILURE: "USER_VERIFY_FAILURE",
    
    SET_USERS_FILTER: "SET_USERS_FILTER",
    EDIT_USER: "EDIT_USER",
    HANDLE_INPUT_USERS: "HANDLE_INPUT_USERS",
    HANDLE_MODULE_USERS: "HANDLE_MODULE_USERS",
    
    LIST_USERS_REQUEST: "LIST_USERS_REQUEST",
    LIST_USERS_SUCCESS: "LIST_USERS_SUCCESS",
    LIST_USERS_FAILURE: "LIST_USERS_FAILURE",

    PAGINATE_USERS_REQUEST: "PAGINATE_USERS_REQUEST",
    PAGINATE_USERS_SUCCESS: "PAGINATE_USERS_SUCCESS",
    PAGINATE_USERS_FAILURE: "PAGINATE_USERS_FAILURE",
    
    CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
    
    UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_CANCELED: "UPDATE_USER_CANCELED",
    UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
    
    LIST_PARAMS_REQUEST: "LIST_PARAMS_REQUEST",
    LIST_PARAMS_SUCCESS: "LIST_PARAMS_SUCCESS",
    LIST_PARAMS_FAILURE: "LIST_PARAMS_FAILURE",
    LIST_OPTIONS_PARAMS_SUCCESS: "LIST_OPTIONS_PARAMS_SUCCESS",
    
    CREATE_PARAMS_REQUEST: "CREATE_PARAMS_REQUEST",
    CREATE_PARAMS_SUCCESS: "CREATE_PARAMS_SUCCESS",
    CREATE_PARAMS_FAILURE: "CREATE_PARAMS_FAILURE",
    
    EDIT_PARAMS: "EDIT_PARAMS",
    
    UPDATE_PARAMS_REQUEST: "UPDATE_PARAMS_REQUEST",
    UPDATE_PARAMS_SUCCESS: "UPDATE_PARAMS_SUCCESS",
    UPDATE_PARAMS_FAILURE: "UPDATE_PARAMS_FAILURE",
    
    SET_PARAMS_FIELD: "SET_PARAMS_FIELD",
    
    GET_PROJECTS_REQUEST: "GET_PROJECTS_REQUEST",
    GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
    GET_PROJECTS_FAILURE: "GET_PROJECTS_FAILURE",

    LIST_PROJECTS_REQUEST: "LIST_PROJECTS_REQUEST",
    LIST_PROJECTS_SUCCESS: "LIST_PROJECTS_SUCCESS",
    LIST_PROJECTS_FAILURE: "LIST_PROJECTS_FAILURE",

    CREATE_PROJECTS_REQUEST: "CREATE_PROJECTS_REQUEST",
    CREATE_PROJECTS_SUCCESS: "CREATE_PROJECTS_SUCCESS",
    CREATE_PROJECTS_FAILURE: "CREATE_PROJECTS_FAILURE",
    
    UPDATE_PROJECTS_REQUEST: "UPDATE_PROJECTS_REQUEST",
    UPDATE_PROJECTS_SUCCESS: "UPDATE_PROJECTS_SUCCESS",
    UPDATE_PROJECTS_FAILURE: "UPDATE_PROJECTS_FAILURE",
    UPDATE_PROJECTS_CANCELED: "UPDATE_PROJECTS_CANCELED",

    EDIT_PROJECT: "EDIT_PROJECT",
    HANDLE_INPUT_PROJECT: "HANDLE_INPUT_PROJECT",
    HANDLE_MODULES_PROJECT: "HANDLE_MODULES_PROJECT",
    HANDLE_GEODATA_PROJECT: "HANDLE_GEODATA_PROJECT",

    SET_PROJECTS_FILTER: "SET_PROJECTS_FILTER",
    SET_PROJECT: "SET_PROJECT",

    GET_PLACES_REQUEST: "GET_PLACES_REQUEST",
    GET_PLACES_SUCCESS: "GET_PLACES_SUCCESS",
    GET_PLACES_FAILURE: "GET_PLACES_FAILURE",

    CREATE_PLACES_REQUEST: "CREATE_PLACES_REQUEST",
    CREATE_PLACES_SUCCESS: "CREATE_PLACES_SUCCESS",
    CREATE_PLACES_FAILURE: "CREATE_PLACES_FAILURE",

    UPDATE_PLACES_REQUEST: "UPDATE_PLACES_REQUEST",
    UPDATE_PLACES_SUCCESS: "UPDATE_PLACES_SUCCESS",
    UPDATE_PLACES_FAILURE: "UPDATE_PLACES_FAILURE",
    UPDATE_PLACES_CANCELED: "UPDATE_PLACES_CANCELED",

    LIST_PLACES_REQUEST: "LIST_PLACES_REQUEST",
    LIST_PLACES_SUCCESS: "LIST_PLACES_SUCCESS",
    LIST_PLACES_FAILURE: "LIST_PLACES_FAILURE",

    HANDLE_INPUT_PLACE: "HANDLE_INPUT_PLACE",
    HANDLE_RADIOS_PLACES: "HANDLE_RADIOS_PLACES",
    HANDLE_PLACES: "HANDLE_PLACES",
    
    SET_PLACES_FILTER: "SET_PLACES_FILTER",
    EDIT_PLACE: "EDIT_PLACE",
};

