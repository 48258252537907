import { alertActionTypes } from '../types/alertActionTypes';

export const alertActions = {
	success,
	error,
	fade,
	clear,
	popUp
};

function success(message) {
	return { type: alertActionTypes.SUCCESS, message };
}

function error(error) {
	return { type: alertActionTypes.ERROR, error };
}

function fade() {
	return { type: alertActionTypes.FADE };
}

function clear() {
	return { type: alertActionTypes.CLEAR };
}

function popUp(message) {
	return {type: alertActionTypes.POP_UP, message}
}