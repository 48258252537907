import { faunaHandler } from '../handlers/faunaHandler';
import { faunaActionTypes } from '../types/faunaActionTypes';
import { apiHelpers } from '../helpers/apiHelpers';
import { alertActions } from './alertActions';
import { especiesActions } from './especiesActions';
import { especiesHandler } from '../handlers/especiesHandler';

export const faunaActions = {
    listFauna,
    listTelemetria,
    getTelemetria,
    listById,
    exportFauna,
    create,
    update,
    upload,
    deleteCard,
    deleteItem,
    createTelemetria,
    updateTelemetria,
    deleteImage,
    getMarcacao,
  };

function listFauna(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        faunaHandler.listFauna(params, page, projectId)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.LIST_FAUNA_REQUEST } }
    function success(list) { return { type: faunaActionTypes.LIST_FAUNA_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.LIST_FAUNA_FAILURE, error } }
}

function listTelemetria(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        faunaHandler.listTelemetria(params, page, projectId)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.LIST_TELEMETRIA_REQUEST } }
    function success(list) { return { type: faunaActionTypes.LIST_TELEMETRIA_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.LIST_TELEMETRIA_FAILURE, error } }
}
function getTelemetria(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        faunaHandler.getByCode(params, page, projectId)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.LIST_TELEMETRIA_REQUEST } }
    function success(card) { return { type: faunaActionTypes.SET_TELEMETRIA_CARD, card } }
    function failure(error) { return { type: faunaActionTypes.LIST_TELEMETRIA_FAILURE, error } }
}


function listById(params, page, projectId) {
    return dispatch => {
        dispatch(request());
    faunaHandler.listById(params, page, projectId)
        .then(response => {
            
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.LIST_FAUNA_REQUEST } }
    function success(card) { return { type: faunaActionTypes.SET_FAUNA_CARD, card } }
    function failure(error) { return { type: faunaActionTypes.LIST_FAUNA_FAILURE, error } }
}


function exportFauna(params, projectId) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.clear())
        faunaHandler.exportFauna(params, projectId)
        .then(response => {
                if(response === "erro"){
                    dispatch(failure());
                    dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
                }else{
                    dispatch(success());
                    dispatch(alertActions.success('Extração realizada com sucesso'))
                }
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(failure(error));
                dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error('A Extração ultrapassa o limite da aplicação de 15.000, por favor refine sua buas e tente novamente'));
        });
    };
    function request() { return { type: faunaActionTypes.EXPORT_FAUNA_REQUEST } }
    function success() { return { type: faunaActionTypes.EXPORT_FAUNA_SUCCESS } }
    function failure() { return { type: faunaActionTypes.EXPORT_FAUNA_FAILURE } }
}


function create(params, page, callback) {
    return dispatch => {
        dispatch(request());
        faunaHandler.create(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Ficha criada com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.CREATE_FAUNA_CARD_REQUEST } }
    function success(list) { return { type: faunaActionTypes.CREATE_FAUNA_CARD_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.CREATE_FAUNA_CARD_FAILURE, error } }
}

function update(params, page, callback) {
    return dispatch => {
        dispatch(request());
        faunaHandler.update(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Ficha atualizada com sucesso'))
                if(callback){callback()}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.UPDATE_FAUNA_CARD_REQUEST } }
    function success(list) { return { type: faunaActionTypes.UPDATE_FAUNA_CARD_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.UPDATE_FAUNA_CARD_FAILURE, error } }
}

function deleteCard(params, callback) {
    return dispatch => {
        dispatch(request());
        faunaHandler.deleteCard(params)
        .then(response => {
                dispatch(success());
                dispatch(alertActions.success('Ficha excluida com sucesso'))
                if(callback){callback()}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.DELETE_FAUNA_CARD_REQUEST } }
    function success() { return { type: faunaActionTypes.DELETE_FAUNA_CARD_SUCCESS } }
    function failure(error) { return { type: faunaActionTypes.DELETE_FAUNA_CARD_FAILURE, error } }
}

function upload(file, fileName, id) {
    return dispatch => {
        dispatch(request());
        faunaHandler.upload(file, fileName, id)
        .then(response => {
                dispatch(success(response));
                // if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.FAUNA_UPLOAD_DOCUMENTS_REQUEST } }
    function success(list) { return { type: faunaActionTypes.FAUNA_UPLOAD_DOCUMENTS_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.FAUNA_UPLOAD_DOCUMENTS_FAILURE, error } }
}

function deleteItem(params, page, callback) {
    return dispatch => {
        dispatch(request());
        faunaHandler.deleteItem(params, page)
        .then(response => {
            dispatch(success(response));
            dispatch(alertActions.success('Documento excluido com sucesso'))
            if(callback){ callback('project', true)}
        },
        error => {
            apiHelpers.handleError(
                dispatch,
                error,
                failure(error),
                true
            );
            dispatch(alertActions.error(error.errorMsg));
        }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    }
    function request() { return { type: faunaActionTypes.FAUNA_DELETE_DOCUMENTS_REQUEST } }
    function success(list) { return { type: faunaActionTypes.FAUNA_DELETE_DOCUMENTS_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.FAUNA_DELETE_DOCUMENTS_FAILURE, error } }
}


function createTelemetria(params, projectId, callback) {
    return dispatch => {
        dispatch(request());
        faunaHandler.createTelemetria(params, projectId)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Ficha criada com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.CREATE_TELEMETRIA_REQUEST } }
    function success(list) { return { type: faunaActionTypes.CREATE_TELEMETRIA_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.CREATE_TELEMETRIA_FAILURE, error } }
}

function updateTelemetria(params, callback) {
    return dispatch => {
        dispatch(request());
        faunaHandler.updateTelemetria(params)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Ficha atualizada com sucesso'))
                if(callback){callback()}
            },
            error => { 
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.UPDATE_TELEMETRIA_REQUEST } }
    function success(list) { return { type: faunaActionTypes.UPDATE_TELEMETRIA_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.UPDATE_TELEMETRIA_FAILURE, error } }
}

function deleteImage(imageUrl, callback) {
    return dispatch => {
        dispatch(request());
        faunaHandler.deleteImage(imageUrl)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Imagem excluida com sucesso'))
                if(callback){callback()}
            },
            error => { 
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                  );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: faunaActionTypes.FAUNA_DELETE_IMAGE_REQUEST } }
    function success(list) { return { type: faunaActionTypes.FAUNA_DELETE_IMAGE_SUCCESS, list } }
    function failure(error) { return { type: faunaActionTypes.FAUNA_DELETE_IMAGE_FAILURE, error } }
}

function getMarcacao(dispatch) {
    return faunaHandler.getMarcacao()
        .then(response => {
                return response
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(alertActions.error(error.errorMsg));
        });
}