export function deleteItem(parameters, actionFn) {
	return dispatch => {
		dispatch(actionFn.deleteItem(parameters))
	}
}

export function update(parameters, page, callback, actionFn){
	return dispatch => {
		dispatch(actionFn.update(parameters, page, callback))
	}
}

export function create(parameters, page, callback, actionFn){
	return dispatch => {
		dispatch(actionFn.create(parameters, page, callback))
	}
}