import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core"
import { palmitoHelpers } from '../../../../helpers/flora/palmito/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers'; 
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';

class PalmitoSaidaForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    formValidator = () => {
        const { palmitoReducer } = this.props
        const { editItem } = palmitoReducer
        const { saida } = editItem
        const { numeroSaida, dataSaida, tipo, quantidade } = saida

        if(numeroSaida !== '' && dataSaida !== null && tipo !== '' && quantidade !== ''){
            return true
        }else{
            return false
        }
    }

    submitForm = () => {
        const { palmitoReducer, dispatch, closeModal, projectsReducer } = this.props
        const { editItem } = palmitoReducer
        const { saida } = editItem
        this.setState({formSubmited: true})
        
        if(this.formValidator() === false) return

        if(saida._id && saida._id !== '') {
            dispatch(palmitoHelpers.updateSaida(saida, 1, closeModal))
        }else{
            dispatch(palmitoHelpers.createSaida(saida, 1, closeModal, projectsReducer.selectedProject))
        }
    }

    render(){
        const { dispatch, palmitoReducer, placesReducer } = this.props
        const { editItem, options, listDocuments } = palmitoReducer
        const { saida } = editItem
        const { formSubmited } = this.state

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {saida._id && saida._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="numeroSaida" 
                                label="Número da Saída" 
                                className="inputDefault" 
                                disabled={saida && saida._id} 
                                value={saida.numeroSaida} 
                                autoComplete='off' 
                                onChange={(event) => palmitoHelpers.handleInput(event, 'numeroSaida', 'saida', dispatch)}
                                error={formSubmited && saida.numeroSaida === ''}
                                helperText={formSubmited && saida.numeroSaida === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de saída"
                                    value={saida.dataSaida}
                                    onChange={(event) => palmitoHelpers.handleInput(event, 'dataSaida', 'talhao', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="quantidade" 
                                label="Quantidade" 
                                className="inputDefault" 
                                value={saida.quantidade} 
                                autoComplete='off' 
                                onChange={(event) => palmitoHelpers.handleInput(event, 'quantidade', 'saida', dispatch)}
                                error={formSubmited && saida.quantidade === ''}
                                helperText={formSubmited && saida.quantidade === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                <Select 
                                    labelId="tipo-select-label" 
                                    id="tipo-select" 
                                    value={saida.tipo} 
                                    autoComplete='off' 
                                    onChange={(event) => palmitoHelpers.handleInput(event, 'tipo', 'saida', dispatch)}
                                    error={formSubmited && saida.tipo === ''}
                                    helperText={formSubmited && saida.tipo === '' ? 'Campo obrigatório' : ''}
                                >
                                    <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                    {options.saida.tipo.map((type, index) => {
                                        return <MenuItem key={index} value={type}>{type}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Matriz</InputLabel>
                                <Select 
                                    labelId="matriz-select-label" 
                                    id="matriz-select" 
                                    value={saida.matriz} 
                                    autoComplete='off' 
                                    onChange={(event) => palmitoHelpers.handleInput(event, 'matriz', 'saida', dispatch)}
                                >
                                    <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                    {listDocuments.matriz.map((type, index) => {
                                        return <MenuItem key={index} value={type._id}>{type.numeroMatriz}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Tipo de Saída</InputLabel>
                                <Select 
                                    labelId="tipoSaida-select-label" 
                                    id="tipoSaida-select" 
                                    value={saida.tipoSaida} 
                                    autoComplete='off' 
                                    onChange={(event) => palmitoHelpers.handleInput(event, 'tipoSaida', 'saida', dispatch)}
                                >
                                    <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                    {options.saida.tipoSaida.map((type, index) => {
                                        return <MenuItem key={index} value={type}>{type}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        {saida && saida.tipoSaida && saida.tipoSaida === 'doação' &&
                            <>
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        id="localDestino" 
                                        label="Local de destino" 
                                        className="inputDefault" 
                                        value={saida.localDestino} 
                                        autoComplete='off' 
                                        onChange={(event) => palmitoHelpers.handleInput(event, 'localDestino', 'saida', dispatch)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        id="licenca" 
                                        label="Licença" 
                                        className="inputDefault" 
                                        value={saida.licenca} 
                                        autoComplete='off' 
                                        onChange={(event) => palmitoHelpers.handleInput(event, 'licenca', 'saida', dispatch)}
                                    />
                                </Grid>
                            </>
                        }
                        {saida && saida.tipoSaida && saida.tipoSaida === 'plantio' &&
                            <>
                                <Grid item xs={12} md={6}>
                                    <FormControl className="inputDefault">
                                        <InputLabel id="demo-simple-select-label">Local do plantio</InputLabel>
                                        <Select 
                                            labelId="localPlantio-select-label" 
                                            id="localPlantio-select" 
                                            value={saida.localPlantio} 
                                            autoComplete='off' 
                                            onChange={(event) => palmitoHelpers.handleInput(event, 'localPlantio', 'saida', dispatch)}
                                        >
                                            <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                            {options.saida.localPlantio.map((type, index) => {
                                                return <MenuItem key={index} value={type}>{type}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {saida && saida.localPlantio && saida.localPlantio === 'talhão' &&
                                    <Grid item xs={12} md={6}>
                                        <FormControl className="inputDefault">
                                            <InputLabel id="demo-simple-select-label">Talhão</InputLabel>
                                            <Select 
                                                labelId="talhao-select-label" 
                                                id="talhao-select" 
                                                value={saida.talhao} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleInput(event, 'talhao', 'saida', dispatch)}
                                            >
                                                <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                                {listDocuments.talhao && listDocuments.talhao.map((type, index) => {
                                                    return <MenuItem key={index} value={type._id}>{type.numeroTalhao}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {saida && saida.localPlantio && saida.localPlantio === 'área demarcada' &&
                                    <Grid item xs={12} md={6}>
                                        <FormControl className="inputDefault">
                                            <InputLabel id="demo-simple-select-label">Área demarcada</InputLabel>
                                            <Select 
                                                labelId="areaDemarcada-select-label" 
                                                id="areaDemarcada-select" 
                                                value={saida.areaDemarcada} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleInput(event, 'areaDemarcada', 'saida', dispatch)}
                                            >
                                                <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                                {placesReducer.listPlaces && placesReducer.listPlaces.map((type, index) => {
                                                    return <MenuItem key={index} value={type.name}>{type.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                            </>
                        }
                        <Divider className="dividerFull"/>
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained" 
                                    className="btn-primary" 
                                    component="label" 
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        <Divider className="dividerFull"/>
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    palmitoReducer: store.palmitoReducer,
    placesReducer: store.placesReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(PalmitoSaidaForm);