import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, FormControl, MenuItem, InputLabel, Select } from "@material-ui/core"

import { areaMonitoramentoHelpers } from '../../../helpers/flora/areaMonitoramento/helpers';
import FilterDate from './filterDate';

class filter extends Component {

  render() {
    const { dispatch, areaMonitoramentoReducer, context, projectsReducer } = this.props
    const { areaMonitoramento } = areaMonitoramentoReducer
    const { filters } = areaMonitoramento

    return (
      <>
        <div className="filter">
          <Typography variant="h5" component="h5" className="filterTitle">
            Pesquisar
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="codigoDaArea"
                    label="Código da área"
                    className="inputDefault"
                    value={filters.codigoDaArea}
                    onChange={(event) => dispatch(areaMonitoramentoHelpers.handleChangeFilter(event, "codigoDaArea", context))}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                      value={filters.tipo}
                      onChange={(event) => dispatch(areaMonitoramentoHelpers.handleChangeFilter(event, "tipo", context))}
                    >
                      <MenuItem value={''}>Selecione...</MenuItem>
                      <MenuItem value={'Arbóreas'}>Arbóreas</MenuItem>
                      <MenuItem value={'Epífitas'}>Epífitas</MenuItem>
                      <MenuItem value={'Piezômetro'}>Piezômetro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Divider className="dividerFull" />
                <FilterDate filterProps={filters} dispatch={dispatch} context={context} />
              </>
              <Divider className="dividerFull" />
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  // onClick={() => this.props.handleModal(context, true)}
                  onClick={() => dispatch(areaMonitoramentoHelpers.editCard('areaMonitoramentoEdicao', null, areaMonitoramentoHelpers.areaMonitoramento))}
                >
                  ADICIONAR ÁREA DE MONITORAMENTO
                </Button>
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                {/* <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => dispatch(areaMonitoramentoHelpers.areaMonitoramentoSaidas())}
                >
                  SAÍDAS
                </Button> */}
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={
                    () => {
                      if (context === 'areaMonitoramento') {
                        dispatch(areaMonitoramentoHelpers.paginateAreaMonitoramento(filters, 1, projectsReducer.selectedProject))
                      } else {
                        // dispatch(areaMonitoramentoHelpers.paginateProducao(filters, 1))
                      }
                    }
                  }
                >
                  PESQUISAR
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        {/* <div id="qntCounter">
          <p>
            {`Quantidade de exemplares no viveiro: `}
            <span>{documents.qntCounter ? documents.qntCounter : '0' }</span>
          </p>
        </div> */}
      </>
    )
  }
}

const mapStateToProps = store => ({
  areaMonitoramentoReducer: store.areaMonitoramentoReducer,
  paramsReducer: store.paramsReducer,
  projectsReducer: store.projectsReducer
});

export default connect(mapStateToProps)(filter);