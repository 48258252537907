import { viveirosActionTypes } from "../types/viveirosActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: {
    resgate: {},
    producao: {}
  },
  loading: false,
  documents: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0,
    qntCounter: 0
  },
  filters: {
    numeroLote: '',
    numeroFicha: '',
    nomeCientifico: '',
    nomePopular: '',
    initDate: moment().subtract(7, 'days').format(),
    endDate: moment().format(),
  },
  options:{
    localOrigemTipo: [{type: "areaDemarcada", label: "Área Demarcada"}, {type: "serrapilheira", label: "Serrapilheira"}],
    areaPlantio: [{type: "areaDemarcada", label: "Área Demarcada"}, {type: "localConhecido", label: "Local Conhecido"}, {type: "coordenadas", label: "Coordenadas"}],
    producaoType: [{type: "obito", label: "Óbito"}, {type: "doacao", label: "Doação"}, {type: "plantio", label: "Plantio"}],
  },
  editItem: {
    viveirosResgate:{
      numeroLote: '', 
      nomeCientifico: '', 
      nomePopular: '', 
      quantidadeResgatada:  0,
      quantidadeViveiro:  0,
      addDate: moment().format(),
      localOrigemTipo: '', 
      localOrigemId: '', 
      status: true,
      saidas: []
    },
    viveirosProducao:{
      numeroFicha: '', 
      nomeCientifico: '', 
      nomePopular: '',
      addDate: moment().format(),
      project: '',
      quantidadePlantio: 0,
      quantidadeViveiro: 0,
      matriz: '',
      taxaGerminacao: 0,
      status: true,
      monitoramentos: [],
      saidas: []
    }
  }
};

export const viveirosReducer = (state = initialState, action) => {
  switch (action.type) {
    case viveirosActionTypes.GET_VIVEIROS_RESGATE_REQUEST:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments,
          resgate: initialState.listDocuments.resgate, 
          loading: true
        }
      };
    case viveirosActionTypes.GET_VIVEIROS_RESGATE_SUCCESS:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments, 
          resgate: action.places, 
          loading: false 
        }
      };
    case viveirosActionTypes.GET_VIVEIROS_RESGATE_FAILURE:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments, 
          resgate: initialState.listDocuments.resgate, 
          loading: false 
        }
      };
    case viveirosActionTypes.GET_VIVEIROS_PRODUCAO_REQUEST:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments,
          producao: initialState.listDocuments.producao, 
          loading: true
        }
      };
    case viveirosActionTypes.GET_VIVEIROS_PRODUCAO_SUCCESS:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments, 
          producao: action.places, 
          loading: false 
        }
      };
    case viveirosActionTypes.GET_VIVEIROS_PRODUCAO_FAILURE:
      return { 
        ...state, 
        listDocuments: {
          ...state.listDocuments, 
          producao: initialState.listDocuments.producao, 
          loading: false 
        }
      };
    case viveirosActionTypes.CREATE_VIVEIROS_RESGATE_REQUEST:
    case viveirosActionTypes.UPDATE_VIVEIROS_RESGATE_REQUEST:
    case viveirosActionTypes.LIST_VIVEIROS_RESGATE_REQUEST:
    case viveirosActionTypes.CREATE_VIVEIROS_PRODUCAO_REQUEST:
    case viveirosActionTypes.UPDATE_VIVEIROS_PRODUCAO_REQUEST:
    case viveirosActionTypes.LIST_VIVEIROS_PRODUCAO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case viveirosActionTypes.CREATE_VIVEIROS_RESGATE_SUCCESS:
    case viveirosActionTypes.CREATE_VIVEIROS_PRODUCAO_SUCCESS:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case viveirosActionTypes.UPDATE_VIVEIROS_RESGATE_SUCCESS:
    case viveirosActionTypes.LIST_VIVEIROS_RESGATE_SUCCESS:
    case viveirosActionTypes.UPDATE_VIVEIROS_PRODUCAO_SUCCESS:
    case viveirosActionTypes.LIST_VIVEIROS_PRODUCAO_SUCCESS:
      return {
        ...state,
        documents: {
          list: action.list.docs, 
          total: action.list.total, 
          limit: action.list.limit, 
          page: Number(action.list.page), 
          pagesTotal: action.list.pages,
          qntCounter: Number(action.list.qntCounter)
        },
        editItem: initialState.editItem,
        loading: false
      };
    case viveirosActionTypes.UPDATE_VIVEIROS_CANCELED:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case viveirosActionTypes.CREATE_VIVEIROS_RESGATE_FAILURE:
    case viveirosActionTypes.UPDATE_VIVEIROS_RESGATE_FAILURE:
    case viveirosActionTypes.LIST_VIVEIROS_RESGATE_FAILURE:
    case viveirosActionTypes.CREATE_VIVEIROS_PRODUCAO_FAILURE:
    case viveirosActionTypes.UPDATE_VIVEIROS_PRODUCAO_FAILURE:
    case viveirosActionTypes.LIST_VIVEIROS_PRODUCAO_FAILURE:
      return {
        ...state,
        loading: false
      };
    case viveirosActionTypes.EDIT_VIVEIROS:
      return { 
        ...state, 
        editItem: { ...state.editItem, [action.context]: action.item}
      };
    case viveirosActionTypes.HANDLE_VIVEIROS:
    case viveirosActionTypes.HANDLE_RADIOS_VIVEIROS:
    case viveirosActionTypes.HANDLE_INPUT_VIVEIROS:
      return {
        ...state,
        editItem: {
          ...state.editItem, 
          [action.context]: { 
            ...state.editItem[action.context],
            [action.target]: action.value
          } 
        }
      };
    case viveirosActionTypes.SET_VIVEIROS_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
    case viveirosActionTypes.SET_FILTER_DATE_INIT:
      return { 
        ...state, 
        filters: {
          ...state.filters, 
          initDate: moment(action.date).format()
        } 
      };
    case viveirosActionTypes.SET_FILTER_DATE_END:
      return { 
        ...state, 
        filters: {
          ...state.filters, 
          endDate: moment(action.date).format()
        }
      };
    case viveirosActionTypes.EXPORT_VIVEIROS_RESGATE_REQUEST:
    case viveirosActionTypes.EXPORT_VIVEIROS_PRODUCAO_REQUEST:
      return { ...state, loading: true };
    case viveirosActionTypes.EXPORT_VIVEIROS_RESGATE_SUCCESS:
    case viveirosActionTypes.EXPORT_VIVEIROS_RESGATE_FAILURE:
    case viveirosActionTypes.EXPORT_VIVEIROS_PRODUCAO_SUCCESS:
    case viveirosActionTypes.EXPORT_VIVEIROS_PRODUCAO_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};