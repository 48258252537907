import { instListaActions } from "../../actions/instListaActions"
import { instListaActionTypes } from "../../types/instListaActionTypes"

export const instListaHelpers = {
    paginate,
	edit,
	handleClass,
	addNewClass,
	update,
	create,
	removeClass,
	handleChangeFilter
}

function paginate(parameters, page){
	return dispatch => {
		dispatch(instListaActions.paginate(parameters, page))
	}
}

function edit(places, callback) {
	if(places){
		if(callback){ callback('instLista') }
		return { type: instListaActionTypes.EDIT_INSTLISTA, places }
	}else{
		return { type: instListaActionTypes.UPDATE_INSTLISTA_CANCELED }
	}
}

function handleClass(module, index, target, myValue, dispatch) {

	let newObj = module;

	newObj[index][target] = myValue

	return dispatch(saveClass(newObj, instListaActionTypes.HANDLE_INSTLISTA, 'classe'));
}

function addNewClass(module, dispatch) {
	let newObj = module;
	const emptyModule = { description: "", status: true}

	newObj.push(emptyModule)

	return dispatch(saveClass(newObj, instListaActionTypes.HANDLE_INSTLISTA, 'classe'));
}

function removeClass(module, index ,dispatch) {
	let newObj = module;

	newObj.splice(index, 1)

	return dispatch(saveClass(newObj, instListaActionTypes.HANDLE_INSTLISTA, 'classe'));
}

function saveClass(value, context, target){return { type: context, value, target }}

function update(parameters, page, callback){
	return dispatch => {
		dispatch(instListaActions.update(parameters, page, callback))
	}
}

function create(parameters, page, callback){
	return dispatch => {
		dispatch(instListaActions.create(parameters, page, callback))
	}
}

function handleChangeFilter(event, target) {
	const value = event.target.value

	return dispatch => {
		dispatch(setFilter(value, target))
	}
}

function setFilter(value, target) {return { type: instListaActionTypes.SET_INSTLISTA_FILTER, value, target }}