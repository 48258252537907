import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, Divider, FormControlLabel } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'
import { placesHelpers } from '../../helpers/places/placesHelpers';

class PlaceForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showPassword: false
    }
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state
    this.setState({ showPassword: !showPassword })
  };

  render() {
    const { dispatch, placesReducer, closeModal, projectsReducer, paramsReducer } = this.props
    const { editPlace, options } = placesReducer
    const { listProjects } = projectsReducer

    return (
      <>
        <Typography variant="h5" component="h5" className="filterTitle">
          {editPlace._id && editPlace._id !== '' ? 'Editar' : 'Novo usuário'}
        </Typography>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField id="name" label="Código" className="inputDefault" value={editPlace.name} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'name', 'editPlace', dispatch)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className="inputDefault">
                <InputLabel id="demo-simple-select-label">Projeto</InputLabel>
                <Select labelId="project-select-label" id="project-select" value={editPlace.projectId} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'projectId', 'editPlace', dispatch)}>
                  {listProjects && listProjects.map((project, index) => <MenuItem key={project.projectId} value={project.projectId}>{project.projectName}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className="inputDefault">
                <InputLabel id="demo-simple-select-label">Tipo de local</InputLabel>
                <Select labelId="placeType-select-label" id="placeType-select" value={editPlace.placeType} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'placeType', 'editPlace', dispatch)}>
                  <MenuItem key={1} value={1}>{'Ponto demarcado'}</MenuItem>
                  <MenuItem key={2} value={2}>{'Área demarcada'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className="inputDefault">
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select labelId="placeType-select-label" id="placeType-select" value={editPlace.status} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'status', 'editPlace', dispatch)}>
                  <MenuItem key={1} value={true}>{'Ativo'}</MenuItem>
                  <MenuItem key={2} value={false}>{'Inativo'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className="inputDefault">
                <InputLabel id="demo-simple-select-label">Ambiente</InputLabel>
                <Select labelId="admin-label" id="admin-select" value={editPlace.ambiente} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'ambiente', 'editPlace', dispatch)}>
                  {paramsReducer && paramsReducer.listOptions.environmenttype && paramsReducer.listOptions.environmenttype.map((params, index) => (
                    <MenuItem key={index} value={params._id}>{params.environmentType}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {editPlace.placeType === 2 &&
              <>
                <Grid item xs={12} md={6}>
                  <TextField id="area" label="Área" className="inputDefault" value={editPlace.area} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'area', 'editPlace', dispatch)} />
                </Grid>
                {options && options.status &&
                  <Grid item xs={12} md={6}>
                    <FormControl className="inputDefault">
                      <InputLabel id="demo-simple-select-label">Status do local</InputLabel>
                      <Select labelId="placeStatus-select-label" id="placeStatus-select" value={editPlace.placeStatus} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'placeStatus', 'editPlace', dispatch)}>
                        {options.status.map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                }
                <Divider className="dividerFull" />
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" component="span" className="moduleTitle">{'Umidade do Ambiente'}</Typography>
                  </Grid>
                  {editPlace.umidadeDoAmbiente && editPlace.umidadeDoAmbiente.map((item, index) => {
                    return (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel key={index} control={<Checkbox checked={item.status} name={item.description} className='inputCheckbox' onChange={() => placesHelpers.handlePlaceRadios(editPlace.umidadeDoAmbiente, index, 'umidadeDoAmbiente', item.description, dispatch)} />} label={item.description} />
                      </Grid>
                    )
                  }
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" component="span" className="moduleTitle">{'Umidade do Ambiente'}</Typography>
                  </Grid>
                  {editPlace.sombreamentoDoAmbiente && editPlace.sombreamentoDoAmbiente.map((item, index) => {
                    return (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel key={index} control={<Checkbox checked={item.status} name={item.description} className='inputCheckbox' onChange={() => placesHelpers.handlePlaceRadios(editPlace.sombreamentoDoAmbiente, index, 'sombreamentoDoAmbiente', item.description, dispatch)} />} label={item.description} />
                      </Grid>
                    )
                  }
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" component="span" className="moduleTitle">{'Umidade do Ambiente'}</Typography>
                  </Grid>
                  {editPlace.tratamentoPreliminar && editPlace.tratamentoPreliminar.map((item, index) => {
                    return (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel key={index} control={<Checkbox checked={item.status} name={item.description} className='inputCheckbox' onChange={() => placesHelpers.handlePlaceRadios(editPlace.tratamentoPreliminar, index, 'tratamentoPreliminar', item.description, dispatch)} />} label={item.description} />
                      </Grid>
                    )
                  }
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" component="span" className="moduleTitle">{'Umidade do Ambiente'}</Typography>
                  </Grid>
                  {editPlace.tipologiaVegetal && editPlace.tipologiaVegetal.map((item, index) => {
                    return (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel key={index} control={<Checkbox checked={item.status} name={item.description} className='inputCheckbox' onChange={() => placesHelpers.handlePlaceRadios(editPlace.tipologiaVegetal, index, 'tipologiaVegetal', item.description, dispatch)} />} label={item.description} />
                      </Grid>
                    )
                  }
                  )}
                </Grid>
                <Divider className="dividerFull" />
              </>
            }
            <Grid item xs={12} md={12} >
              <Typography variant="h6" component="h6" className="filterTitle">Coordenadas</Typography>
            </Grid>
            <Divider className="dividerFull" />
            {editPlace.places.map((place, index) => {
              return (
                <>
                  <Grid item xs={12} md={3}>
                    <TextField label="Coordenada X" className="inputDefault" value={place.coodX} autoComplete='off' onChange={(event) => placesHelpers.handlePlaces(editPlace.places, index, 'coodX', event.target.value, dispatch)} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField label="Coordenada Y" className="inputDefault" value={place.coodY} autoComplete='off' onChange={(event) => placesHelpers.handlePlaces(editPlace.places, index, 'coodY', event.target.value, dispatch)} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField label="EPE" className="inputDefault" value={place.EPE} autoComplete='off' onChange={(event) => placesHelpers.handlePlaces(editPlace.places, index, 'EPE', event.target.value, dispatch)} />
                  </Grid>
                  <Divider className="dividerFull" />
                </>
              )
            })}
            <Divider className="dividerFull" />
            {editPlace.placeType === 2 &&
              <Grid item xs={12} md={2}>
                <Button variant="contained" className="btn-secondary" onClick={() => placesHelpers.addNewPlace(editPlace.places, dispatch)}>
                  Adicionar...
                </Button>
              </Grid>
            }
            <Divider className="dividerFull" />
            <Grid item xs={12} md={2}>
              <Button variant="contained" className="btn-primary" onClick={editPlace._id && editPlace._id !== '' ? () => dispatch(placesHelpers.updatePlaces(editPlace, 1, closeModal)) : () => dispatch(placesHelpers.createPlaces(editPlace, 1, closeModal))}>
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    )
  }
}

const mapStateToProps = store => ({
  placesReducer: store.placesReducer,
  projectsReducer: store.projectsReducer,
  paramsReducer: store.paramsReducer,
  usersReducer: store.usersReducer,
});

export default connect(mapStateToProps)(PlaceForm);