import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../Components/headerAlter'

import { history } from '../helpers/history'
import { helpers } from '../helpers/helpers'


class Forbidden extends Component {

	componentDidMount(){
		const { dispatch } = this.props
		dispatch(helpers.saveLocation(history.location.pathname));
	}

	render(){
		
		return (
			<div className='mainContainer'>
				{!this.props.loginReducer.loading &&
					<Header title='Usuário sem permissão de acesso a esta página'/>
				}
			</div>
		)
	}
}

const mapStateToProps = store => ({
	loginReducer: store.loginReducer
});
  
export default connect(mapStateToProps)(Forbidden);