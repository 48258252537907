import { actionTypes } from "../types/actionTypes";


const initialState = {
  auth: {
    status: false,
    name: '',
    email: '',
    pass: '',
    user: '',
    userType: '',
  },
  loggedUser: {
    status: false,
    name: '',
    email: '',
    user: '',
    userType: '',
  },
  loading: false
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_VERIFY_SUCCESS:
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loggedUser: action.auth,
        loading: false
      }
    case actionTypes.AUTH:
      return {
        ...state,
        auth: action.auth
      };
    case actionTypes.HANDLE_INPUT:
      return {
        ...state,
        auth: {...state.auth, [action.target]: action.value}
      };
    case actionTypes.USER_VERIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.USER_VERIFY_FAILURE:
      return {
        ...state,
        loggedUser: initialState.loggedUser,
        loading: false
      };
    default:
      return state;
  }
};