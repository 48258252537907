import { usersHandler } from '../handlers/usersHandler';
import { actionTypes } from '../types/actionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const usersActions = {
    paginateUsers,
    updateUser,
    createUser,
    listUsers
  };

function listUsers() {
    return dispatch => {
        dispatch(request());
        usersHandler.listUsers()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.LIST_USERS_REQUEST } }
    function success(listUsers) { return { type: actionTypes.LIST_USERS_SUCCESS, listUsers } }
    function failure(error) { return { type: actionTypes.LIST_USERS_FAILURE, error } }
}

function paginateUsers(params, page) {
    return dispatch => {
        dispatch(request());
        usersHandler.paginateUsers(params, page)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.PAGINATE_USERS_REQUEST } }
    function success(listUsers) { return { type: actionTypes.PAGINATE_USERS_SUCCESS, listUsers } }
    function failure(error) { return { type: actionTypes.PAGINATE_USERS_FAILURE, error } }
}

function updateUser(params, page, callback) {
    return dispatch => {
        dispatch(request());
        usersHandler.updateUser(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Usuário atualizado com sucesso'))
                if(callback){ callback('user', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.UPDATE_USER_REQUEST } }
    function success(listUsers) { return { type: actionTypes.UPDATE_USER_SUCCESS, listUsers } }
    function failure(error) { return { type: actionTypes.UPDATE_USER_FAILURE, error } }
}

function createUser(params, page, callback) {
    return dispatch => {
        dispatch(request());
        usersHandler.createUser(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Usuário criado com sucesso'))
                if(callback){ callback('user', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.CREATE_USER_REQUEST } }
    function success(listUsers) { return { type: actionTypes.CREATE_USER_SUCCESS, listUsers } }
    function failure(error) { return { type: actionTypes.CREATE_USER_FAILURE, error } }
}