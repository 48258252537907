import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableRow, TableCell, TableContainer, Table, TableBody, Button } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import Header from '../Components/headerAlter'
import Filter from '../Components/parameters/filters'
import { Alert } from '@material-ui/lab'

import { history } from '../helpers/history'
import { helpers } from '../helpers/helpers'

class Parameters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oldRoute: ""
        };
    }

    componentDidMount(){
        const { dispatch, projects, pageContext, parameters } = this.props
        dispatch(helpers.saveLocation(history.location.pathname));
        
        dispatch(helpers.listParams(parameters.filters, parameters.page, projects.selectedProject, pageContext, "paginate" ));
        this.setState({oldRoute: pageContext})
    }
	componentDidUpdate(){
        const { oldRoute } = this.state
		const { dispatch, projects, pageContext, parameters } = this.props

		dispatch(helpers.saveLocation(history.location.pathname))
        
        if(oldRoute !== pageContext){
            dispatch(helpers.listParams(parameters.filters, parameters.page, projects.selectedProject, pageContext, "paginate" ));
            this.setState({oldRoute: pageContext})
        }
	}

    render(){

        const { dispatch, parameters, projects, pageContext, pageTitle, alert } = this.props
        const { listParams, page, pagesTotal, fields } = parameters
        const { selectedProject } = projects

        const hasCardList = listParams && listParams.length > 0
        const isItemSelected = false;

        return (
            <div className='mainContainer'>
                <Header title={pageTitle ? pageTitle : "Parâmetros"}/>
                <div className='basicContainer'>
                    {alert && alert.type && alert.message && 
                        <>
                            {alert.type === 'alert-danger' && 
                                <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
                            }
                            {alert.type === 'alert-success' && 
                                <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
                            }
                        </>
                    }
                    <Filter pageContext={pageContext}/>
                    {hasCardList &&
                    <Grid container>
                        <TableContainer className="customTable paramsTable">
                            <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                                <TableHead className="tableHead">
                                    <TableRow>
                                        <Grid container className="parametersTable">
                                            {pageContext === 'ringColors' && 
                                                <Grid item xs={12} md={9}>
                                                    <TableCell key='color' align='right' padding='none'>Cor</TableCell>
                                                </Grid>
                                            }
                                            {(pageContext === 'trapType' || pageContext === 'environmentType') && 
                                                <Grid item xs={12} md={9}>
                                                    <TableCell key='trapType' align='right' padding='none'>Tipo</TableCell>
                                                </Grid>
                                            }
                                            {pageContext === 'documentsType' && 
                                                <>
                                                    <Grid item xs={12} md={4}>
                                                        <TableCell key='module' align='right' padding='none'>Módulo</TableCell>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <TableCell key='prefix' align='right' padding='none'>Sigla</TableCell>
                                                    </Grid>
                                                </>
                                            }
                                            {(pageContext === 'caracFlora' || pageContext === 'documentsType') && 
                                                <Grid item xs={12} md={pageContext === 'documentsType' ? 4 : 9}>
                                                    <TableCell key='description' align='right' padding='none'>Descrição</TableCell>
                                                </Grid>
                                            }
                                            {pageContext === 'orgEmissor' && 
                                                <Grid item xs={12} md={9}>
                                                    <TableCell key='name' align='right' padding='none'>Nome</TableCell>
                                                </Grid>
                                            }
                                            {pageContext === 'faunaClass' && 
                                                <Grid item xs={12} md={9}>
                                                    <TableCell key='class' align='right' padding='none'>Classe</TableCell>
                                                </Grid>
                                            }
                                            <Grid item xs={12} md={2}>
                                                <TableCell key='status' align='right' size='small' padding='none'>Status</TableCell>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <TableCell padding="none"></TableCell>
                                            </Grid>
                                        </Grid>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {listParams.map((row, index) => {
                                    // const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            >
                                            <Grid container className="parametersTable">
                                                {pageContext === 'ringColors' && 
                                                    <Grid item xs={12} md={9}>
                                                        <TableCell key='color' id={labelId} align='right' padding='none'>{row.color}</TableCell>
                                                    </Grid>
                                                }
                                                {(pageContext === 'trapType' || pageContext === 'environmentType') && 
                                                    <Grid item xs={12} md={9}>
                                                        <TableCell key='trapType' id={labelId} align='right' padding='none'>{pageContext === 'trapType' ? row.trapType : row.environmentType}</TableCell>
                                                    </Grid>
                                                }
                                                {pageContext === 'documentsType' && 
                                                    <>
                                                        <Grid item xs={12} md={4}>
                                                            <TableCell key='module' align='right' padding='none'>{row.modulo}</TableCell>
                                                        </Grid>
                                                        <Grid item xs={12} md={1}>
                                                            <TableCell key='prefix' align='right' padding='none'>{row.short}</TableCell>
                                                        </Grid>
                                                    </>
                                                }
                                                {(pageContext === 'caracFlora' || pageContext === 'documentsType') && 
                                                    <Grid item xs={12} md={pageContext === 'documentsType' ? 4 : 9}>
                                                        <TableCell key='description' id={labelId} align='right' padding='none'>{row.description}</TableCell>
                                                    </Grid>
                                                }
                                                {pageContext === 'orgEmissor' && 
                                                    <Grid item xs={12} md={9}>
                                                        <TableCell key='name' id={labelId} align='right' padding='none'>{row.name}</TableCell>
                                                    </Grid>
                                                }
                                                {pageContext === 'faunaClass' && 
                                                    <Grid item xs={12} md={9}>
                                                        <TableCell key='class' id={labelId} align='right' padding='none'>{row.classe}</TableCell>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} md={2}>
                                                    <TableCell key='status' align='right' size='small' padding='none'>{row.status ? 'Ativo' : 'Inativo' }</TableCell>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <TableCell padding="none">
                                                        <Button 
                                                            variant="contained" 
                                                            className="btn-secondary btn-innerTable" 
                                                            onClick={() => dispatch(helpers.editParams(row))}
                                                            disabled={(fields._id) ? true : false}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                    </TableCell>
                                                </Grid>
                                            </Grid>
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, parameters.filters, page, 'parameters', selectedProject, pageContext))} />
                    </Grid>
					}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    auth: store.loginReducer.auth,
    alert: store.alertReducer,
    projects: store.projectsReducer,
    parameters: store.paramsReducer
  });

export default connect(mapStateToProps)(Parameters);