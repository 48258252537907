import { floraEspeciesActions } from "../../../actions/floraEspeciesActions"
import { floraEspeciesActionTypes } from "../../../types/floraEspeciesActionTypes"

function editCard(context, item, callback) {
	if(item){
		if(callback){ callback(context) }
		return { type: floraEspeciesActionTypes.EDIT_FLORA_ESPECIES, item, context}
	} else {
		return { type: floraEspeciesActionTypes.UPDATE_FLORA_ESPECIES_CANCELED }
	}
}

function handleChangeFilter(event, target) {
	const value = event.target.value

	return dispatch => {
		dispatch(setFilter(value, target))
	}
}

function setFilter(value, target) {return { type: floraEspeciesActionTypes.SET_FLORA_ESPECIES_FILTER, value, target }}

function saveStore(value, target, context) { return { type: floraEspeciesActionTypes.HANDLE_INPUT_FLORA_ESPECIES, context, value, target } }

function handleInput(myValue, target, context, dispatch, callback) {
	let value = target === 'addDate' || target === 'endDate' || target === 'initDate' ? myValue : myValue.target.value
	
	dispatch(saveStore(value, target, context));

	if (callback) callback(dispatch)
}

function paginate(parameters, page){
	return dispatch => {
		dispatch(floraEspeciesActions.paginate(parameters, page))
	}
}

function list(){
	return dispatch => {
		dispatch(floraEspeciesActions.list())
	}
}

function update(parameters, page, callback, callbackParams){

	const data = {
		_id: parameters._id,
		nomeCientifico: parameters.nomeCientifico ? parameters.nomeCientifico : '',
		nomePopular: parameters.nomePopular ? parameters.nomePopular : '',
		ordem: parameters.ordem ? parameters.ordem : '',
		familia: parameters.familia ? parameters.familia : '', 
		subFamilia: parameters.subFamilia ? parameters.subFamilia : '', 
		genero: parameters.genero ? parameters.genero : '', 
		especie: parameters.especie ? parameters.especie : '', 
		subEspecie: parameters.subEspecie ? parameters.subEspecie : '',
	}

	return dispatch => {
		dispatch(floraEspeciesActions.update(data, page, callback, callbackParams))
	}
}

function create(parameters, page, callback, callbackParams){

	const data = {
		nomeCientifico: parameters.nomeCientifico ? parameters.nomeCientifico : '',
		nomePopular: parameters.nomePopular ? parameters.nomePopular : '',
		ordem: parameters.ordem ? parameters.ordem : '',
		familia: parameters.familia ? parameters.familia : '', 
		subFamilia: parameters.subFamilia ? parameters.subFamilia : '', 
		genero: parameters.genero ? parameters.genero : '', 
		especie: parameters.especie ? parameters.especie : '', 
		subEspecie: parameters.subEspecie ? parameters.subEspecie : '',
	}

	return dispatch => {
		dispatch(floraEspeciesActions.create(data, page, callback, callbackParams))
	}
}


export const floraEspeciesHelpers = {
	editCard,
	handleChangeFilter,
	handleInput,
	paginate,
	list,
	update,
	create,
}