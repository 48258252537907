export const areaMonitoramentoActionTypes = {

    // AREA MONITORAMENTO

    GET_AREA_MONITORAMENTO_CARD_REQUEST: "GET_AREA_MONITORAMENTO_CARD_REQUEST",
    GET_AREA_MONITORAMENTO_CARD_SUCCESS: "GET_AREA_MONITORAMENTO_CARD_SUCCESS",
    GET_AREA_MONITORAMENTO_CARD_FAILURE: "GET_AREA_MONITORAMENTO_CARD_FAILURE",

    LIST_AREA_MONITORAMENTO_CARD_REQUEST: "LIST_AREA_MONITORAMENTO_CARD_REQUEST",
    LIST_AREA_MONITORAMENTO_CARD_SUCCESS: "LIST_AREA_MONITORAMENTO_CARD_SUCCESS",
    LIST_AREA_MONITORAMENTO_CARD_FAILURE: "LIST_AREA_MONITORAMENTO_CARD_FAILURE",

    SET_AREA_MONITORAMENTO_FILTER: "SET_AREA_MONITORAMENTO_FILTER",

    SET_AREA_MONITORAMENTO_FILTER_DATE_INIT: "SET_AREA_MONITORAMENTO_FILTER_DATE_INIT",
    SET_AREA_MONITORAMENTO_FILTER_DATE_END: "SET_AREA_MONITORAMENTO_FILTER_DATE_END",

    EDIT_AREA_MONITORAMENTO_CARD: "EDIT_AREA_MONITORAMENTO_CARD",
    UPDATE_AREA_MONITORAMENTO_CARD_CANCELED: "UPDATE_AREA_MONITORAMENTO_CARD_CANCELED",
    HANDLE_AREA_MONITORAMENTO_FIELDS: "HANDLE_AREA_MONITORAMENTO_FIELDS",
    HANDLE_NEW_ITEM_FIELDS: "HANDLE_NEW_ITEM_FIELDS",
    HANDLE_AREA_MONITORAMENTO_TOTAL_VOLUME: "HANDLE_AREA_MONITORAMENTO_TOTAL_VOLUME",

    CREATE_AREA_MONITORAMENTO_CARD_REQUEST: "CREATE_AREA_MONITORAMENTO_CARD_REQUEST",
    CREATE_AREA_MONITORAMENTO_CARD_SUCCESS: "CREATE_AREA_MONITORAMENTO_CARD_SUCCESS",
    CREATE_AREA_MONITORAMENTO_CARD_FAILURE: "CREATE_AREA_MONITORAMENTO_CARD_FAILURE",

    UPDATE_AREA_MONITORAMENTO_CARD_REQUEST: "UPDATE_AREA_MONITORAMENTO_CARD_REQUEST",
    UPDATE_AREA_MONITORAMENTO_CARD_SUCCESS: "UPDATE_AREA_MONITORAMENTO_CARD_SUCCESS",
    UPDATE_AREA_MONITORAMENTO_CARD_FAILURE: "UPDATE_AREA_MONITORAMENTO_CARD_FAILURE",

    UPDATE_AREA_MONITORAMENTO_INDIVIDUOS: "UPDATE_AREA_MONITORAMENTO_INDIVIDUOS",
    CLEAR_NEW_ITEM_FIELDS: "CLEAR_NEW_ITEM_FIELDS",
    EDIT_ITEM: "EDIT_ITEM",
    ADD_MONITORAMENTO: "ADD_MONITORAMENTO",
    CLEAR_MONITORAMENTO: "CLEAR_MONITORAMENTO",
    HANDLE_NEW_MONITORAMENTO_FIELDS: "HANDLE_NEW_MONITORAMENTO_FIELDS",
    HANDLE_NEW_MONITORAMENTO: "HANDLE_NEW_MONITORAMENTO",
    EDIT_MONITORAMENTO: "EDIT_MONITORAMENTO",
};