import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  Grid,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core'
import { Pagination, Alert } from '@material-ui/lab';
import Header from '../../../Components/headerAlter'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';

import { history } from '../../../helpers/history'
import { helpers } from '../../../helpers/helpers'
import { areaPlantioHelpers } from '../../../helpers/flora/areaPlantio/helpers'
import { placesHelpers } from '../../../helpers/places/placesHelpers';
import { floraEspeciesHelpers } from '../../../helpers/flora/especies/helpers';

function AreaPlantioDetalhes(props) {

  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(true);
  const [addItem, setAddItem] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);
  const { areaPlantioReducer, dispatch, alert, pageContext, pageTitle, projectsReducer, placesReducer, floraEspeciesReducer } = props
  const { listPlaces } = placesReducer
  const { listDocuments } = floraEspeciesReducer
  const { areaPlantio } = areaPlantioReducer
  const { newItem, editItem, filters, documents } = areaPlantio



  useEffect(() => {
    const { dispatch, areaPlantioReducer, projectsReducer } = props
    dispatch(helpers.saveLocation(history.location.pathname));
    // dispatch(areaPlantioHelpers.paginateAreaPlantio(areaPlantioReducer.areaPlantio.filters, areaPlantioReducer.areaPlantio.documents.page, projectsReducer.selectedProject))
    dispatch(placesHelpers.listPlaces(projectsReducer.selectedProject, '2'));
    dispatch(floraEspeciesHelpers.list());
    if (areaPlantioReducer.areaPlantio.editItem._id && areaPlantioReducer.areaPlantio.editItem._id !== '') {
      setPanel1(false)
    }
  }, [])

  const handleAccordion = (accordion) => {
    if (accordion === 'panel1') setPanel1(!panel1)
    else if (accordion === 'panel2') setPanel2(!panel2)
    // else if (accordion === 'panel3') setPanel3(!panel3)
    // else if (accordion === 'panel4') setPanel4(!panel4)
    // else if (accordion === 'panel5') setPanel5(!panel5)
  }

  const formValidator = () => {
    const { areaPlantioReducer } = props
    const { areaPlantio } = areaPlantioReducer
    const { editItem } = areaPlantio
    const { codigoDaArea, coord } = editItem

    if (codigoDaArea !== '' && coord !== [] && coord.length >= 3 &&
      coord[0].coordenadaX !== '' && coord[0].coordenadaY !== '' && coord[0].EPE !== ''
      && coord[1].coordenadaX !== '' && coord[1].coordenadaY !== '' && coord[1].EPE !== ''
      && coord[2].coordenadaX !== '' && coord[2].coordenadaY !== '' && coord[2].EPE !== '') {
      console.log('passouu')
      return true
    } else {
      console.log('não passouu')
      return false
    }
  }

  const submitForm = () => {
    const { areaPlantioReducer, dispatch, closeModal, projectsReducer } = props
    const { areaPlantio } = areaPlantioReducer
    const { editItem, filters } = areaPlantio
    setFormSubmited(true)

    if (formValidator() === false) return
    if (editItem._id && editItem._id !== '') {
      dispatch(areaPlantioHelpers.updateAreaPlantio(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
    }
    else {
      dispatch(areaPlantioHelpers.createAreaPlantio(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
    }
  }

  const cancelAddItem = () => {
    const { dispatch } = props
    if (addItem) dispatch(areaPlantioHelpers.clearNewItem())
    setAddItem(!addItem)
  }

  const editedItem = (item) => {
    const { dispatch } = props
    dispatch(areaPlantioHelpers.editItem(item))
    setAddItem(true)
  }

  const addNewItem = () => {
    const { dispatch, areaMonitoramentoReducer } = props
    const { areaMonitoramento } = areaMonitoramentoReducer
    const { editItem, newItem } = areaMonitoramento
    dispatch(areaPlantioHelpers.addNewItem(editItem, newItem, dispatch))
    setAddItem(false)
  }

  const validAreaDemarcada = listPlaces?.some(item => item.name === newItem.areaDemarcada)

  return (
    <div className='mainContainer'>
      <Header title={pageTitle} />
      <div className='basicContainer'>
        {alert && alert.type === 'alert-danger' && alert.message &&
          <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
        }
        {alert && alert.type === 'alert-success' && alert.message &&
          <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
        }
        <div className='basicInformation box-content-primary'>
          <Grid container spacing={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="codigoDaArea"
                  label="Código da área"
                  className="inputDefault"
                  value={editItem.codigoDaArea}
                  autoComplete='off'
                  onChange={(event) => areaPlantioHelpers.handleInput(event, 'codigoDaArea', 'areaPlantio', dispatch)}
                  error={formSubmited && editItem.codigoDaArea === ''}
                  helperText={formSubmited && editItem.codigoDaArea === '' ? 'Campo obrigatório' : ''}
                  disabled={(editItem._id && editItem._id !== '') ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl className="inputDefault" required error={formSubmited && editItem.tipo === ''}>
                  <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select"
                    value={editItem.tipo}
                    onChange={(event) => areaPlantioHelpers.handleInput(event, 'tipo', 'areaPlantio', dispatch)}
                  >
                    <MenuItem key={'Arbóreas'} selected value={'Arbóreas'}>{'Arbóreas'}</MenuItem>
                    <MenuItem key={'Epífitas'} value={'Epífitas'}>{'Epífitas'}</MenuItem>
                    <MenuItem key={'Arbóreas ou Epífitas'} value={'Arbóreas ou Epífitas'}>{'Arbóreas ou Epífitas'}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl className="inputDefault" required error={formSubmited && editItem.status === ''}>
                  <InputLabel id="demo-simple-select-label">Área Demarcada</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select"
                    value={editItem.status}
                    onChange={(event) => areaPlantioHelpers.handleInput(event, 'status', 'areaPlantio', dispatch)}
                  >
                    <MenuItem key={'Recomposição'} selected value={'Recomposição'}>{'Recomposição'}</MenuItem>
                    <MenuItem key={'Adensamento'} value={'Adensamento'}>{'Adensamento'}</MenuItem>
                  </Select>
                  {formSubmited && editItem.status === '' && <FormHelperText>Campo obrigatório</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Accordion className='basicAccordion' expanded={panel1} onChange={() => handleAccordion('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
            <Typography>Coordenadas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {editItem.coord.map((position, index) => {
                return (
                  <>
                    <Grid item xs={12} md={4} key={`${index}-coordx`}>
                      <TextField
                        label="Coordenada X"
                        className="inputDefault"
                        value={position.coordenadaX}
                        autoComplete='off'
                        onChange={(event) => areaPlantioHelpers.handlePlaces(editItem.coord, index, 'coordenadaX', event.target.value, 'areaPlantio', areaPlantioHelpers.getArea, dispatch)}
                        error={formSubmited && index < 3 && position.coordenadaX === ''}
                        helperText={formSubmited && index < 3 && position.coordenadaX === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} key={`${index}-coordy`}>
                      <TextField
                        label="Coordenada Y"
                        className="inputDefault"
                        value={position.coordenadaY}
                        autoComplete='off'
                        onChange={(event) => areaPlantioHelpers.handlePlaces(editItem.coord, index, 'coordenadaY', event.target.value, 'areaPlantio', areaPlantioHelpers.getArea, dispatch)}
                        error={formSubmited && index < 3 && position.coordenadaY === ''}
                        helperText={formSubmited && index < 3 && position.coordenadaY === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} key={`${index}-epe`}>
                      <TextField
                        label="EPE"
                        className="inputDefault"
                        value={position.EPE}
                        autoComplete='off'
                        onChange={(event) => areaPlantioHelpers.handlePlaces(editItem.coord, index, 'EPE', event.target.value, 'areaPlantio', areaPlantioHelpers.getArea, dispatch)}
                        error={formSubmited && index < 3 && position.EPE === ''}
                        helperText={formSubmited && index < 3 && position.EPE === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                  </>
                )
              })}
              <Divider className="dividerFull" />
              <Grid item xs={12} md={2}>
                <Button variant="contained" className="btn-secondary" onClick={() => areaPlantioHelpers.addNewPlace(editItem.coord, 'coord', 'areaPlantio', dispatch)}>
                  Adicionar...
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {
          editItem._id && editItem._id !== '' &&

          <Accordion className='basicAccordion' expanded={panel2} onChange={() => handleAccordion('panel2')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordionHeader">
              <Typography>Indivíduos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                {addItem &&
                  <Grid item xs={12} md={12}>
                    <div className='basicInformation box-content-primary'>
                      <Grid container spacing={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault" required error={formSubmited && newItem.tipo === ''}>
                              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                              <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                value={newItem.tipo}
                                onChange={(event) => areaPlantioHelpers.handleNewItemInput(event, 'tipo', 'areaPlantio', dispatch)}
                              >
                                {(editItem.tipo === 'Arbóreas ou Epífitas' || editItem.tipo === 'Arbóreas') &&
                                  <MenuItem key={'Arbóreo - Muda'} value={'Arbóreo - Muda'}>{'Arbóreo - Muda'}</MenuItem>
                                }
                                {(editItem.tipo === 'Arbóreas ou Epífitas' || editItem.tipo === 'Arbóreas') &&
                                  <MenuItem key={'Arbóreo - Árvore'} value={'Arbóreo - Árvore'}>{'Arbóreo - Árvore'}</MenuItem>
                                }
                                {(editItem.tipo === 'Arbóreas ou Epífitas' || editItem.tipo === 'Arbóreas') &&
                                  <MenuItem key={'Arbóreo - Arvoreta'} value={'Arbóreo - Arvoreta'}>{'Arbóreo - Arvoreta'}</MenuItem>
                                }
                                {(editItem.tipo === 'Epífitas' || editItem.tipo === 'Arbóreas ou Epífitas') &&
                                  <MenuItem key={'Epífita'} value={'Epífita'}>{'Epífita'}</MenuItem>
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={4}>
                              <KeyboardDatePicker
                                className="inputDefault dateInput"
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Data plantio"
                                value={newItem.dataDePlantio}
                                onChange={(event) => areaPlantioHelpers.handleNewItemInput(event, 'dataDePlantio', 'areaPlantio', dispatch)}
                                KeyboardButtonProps={{
                                  'aria-label': 'Alterar data',
                                }}
                                invalidDateMessage={'Data inválida'}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="numeroFicha"
                              label="Número da ficha"
                              className="inputDefault"
                              value={newItem.numeroFicha}
                              autoComplete='off'
                              onChange={(event) => areaPlantioHelpers.handleNewItemInput(event, 'numeroFicha', 'areaPlantio', dispatch)}
                            // error={formSubmited && newItem.numeroFicha === ''}
                            // helperText={formSubmited && newItem.numeroFicha === '' ? 'Campo obrigatório' : ''}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault" required error={formSubmited && newItem.areaDemarcada === ''}>
                              <InputLabel id="demo-simple-select-label">Área demarcada</InputLabel>
                              <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                value={newItem.areaDemarcada}
                                onChange={(event) => areaPlantioHelpers.handleNewItemInput(event, 'areaDemarcada', 'areaPlantio', dispatch)}
                              >
                                <MenuItem selected value={''}>{'--'}</MenuItem>
                                {!validAreaDemarcada && newItem.areaDemarcada !== "--" &&
                                  <MenuItem value={newItem.areaDemarcada}>{newItem.areaDemarcada}</MenuItem>
                                }
                                {listPlaces.map((item, index) => {
                                  return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="numeroDeIndividuos"
                              label="Num. de indivíduos"
                              className="inputDefault"
                              value={newItem.numeroDeIndividuos}
                              autoComplete='off'
                              onChange={(event) => areaPlantioHelpers.HandleNewItemInputNumber(event, 'numeroDeIndividuos', 'areaPlantio', dispatch)}
                            // error={formSubmited && newItem.numeroDeIndividuos === ''}
                            // helperText={formSubmited && newItem.numeroDeIndividuos === '' ? 'Campo obrigatório' : ''}
                            />
                          </Grid>
                          <Divider className="dividerFull" />
                          <Grid item xs={12} md={8}>
                            <FormControl className="inputDefault" required error={formSubmited && newItem.nomecientifico === ''}>
                              <InputLabel id="demo-simple-select-label">Espécie</InputLabel>
                              <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                value={newItem.nomecientifico}
                                onChange={(event) => areaPlantioHelpers.handleNewItemInput(event, 'nomecientifico', 'areaPlantio', dispatch)}
                              >
                                <MenuItem selected value={''}>{'--'}</MenuItem>
                                {listDocuments?.especies.map((item, index) => {
                                  return <MenuItem key={item._id} value={item.nomeCientifico}>{item.nomeCientifico}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="nomePopular"
                              label="Nome popular"
                              className="inputDefault"
                              value={newItem.nomePopular}
                              autoComplete='off'
                              onChange={(event) => areaPlantioHelpers.handleNewItemInput(event, 'nomePopular', 'areaPlantio', dispatch)}
                            // error={formSubmited && newItem.nomePopular === ''}
                            // helperText={formSubmited && newItem.nomePopular === '' ? 'Campo obrigatório' : ''}
                            />
                          </Grid>
                          <Divider className="dividerFull" />
                          <Grid item xs={12} md={3}>
                            <Button
                              variant="contained"
                              className="btn-secondary"
                              onClick={() => addNewItem()}
                            >
                              {newItem._id && newItem._id !== '' ? 'Salvar' : 'Adicionar'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                }
                <Divider className="dividerFull" />
                <Grid item xs={12} md={4}>
                  <Button variant="contained" className="btn-secondary" onClick={() => cancelAddItem()}>
                    {!addItem ? 'Adicionar Indivíduo' : 'Cancelar'}
                  </Button>
                </Grid>
                <Divider className="dividerFull" />
                {editItem.individuos.length > 0 &&
                  <Grid item xs={12} md={12}>
                    <TableContainer className="customTable paramsTable">
                      <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                        <TableHead className="tableHead">
                          <Grid component={'tr'} container className="parametersTable">
                            <Grid component={'th'} item xs={12} md={1}>Data plantio</Grid>
                            <Grid component={'th'} item xs={12} md={1}>Ficha</Grid>
                            <Grid component={'th'} item xs={12} md={2}>Tipo</Grid>
                            <Grid component={'th'} item xs={12} md={2}>Área demarcada</Grid>
                            <Grid component={'th'} item xs={12} md={2}>Nome científico</Grid>
                            <Grid component={'th'} item xs={12} md={2}>Nome popular</Grid>
                            <Grid component={'th'} item xs={12} md={1}>N. ind</Grid>
                            <Grid component={'th'} item xs={12} md={1}></Grid>
                          </Grid>
                        </TableHead>
                        <TableBody>
                          {editItem?.individuos.map((item, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <Grid component={'tr'} container className="parametersTable multiLine" key={index} id={labelId}>
                                <Grid component={'td'} item xs={12} md={1}>{moment(item?.dataDePlantio).format('DD/MM/YY')}</Grid>
                                <Grid component={'td'} item xs={12} md={1}>{item?.numeroFicha}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>{item?.tipo}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>{item?.areaDemarcada}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>{item?.nomecientifico}</Grid>
                                <Grid component={'td'} item xs={12} md={2}>{item?.nomePopular}</Grid>
                                <Grid component={'td'} item xs={12} md={1}>{item?.numeroDeIndividuos}</Grid>
                                <Grid component={'td'} item xs={12} md={1}>
                                  <Button
                                    variant="contained"
                                    className="btn-secondary btn-innerTable"
                                    onClick={() => dispatch(() => editedItem(item))}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                }
                {editItem.individuos.length === 0 &&
                  <Grid item xs={12} md={12}>
                    <Alert className="alert alert-warning" severity="warning">Ficha sem indivíduos cadastrados</Alert>
                  </Grid>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        }
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Divider className="dividerFull" />
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            <Box padding={10}>
              <Button variant="contained"
                className="btn-primary"
                component="label"
                startIcon={<SaveIcon />}
                onClick={() => submitForm()}> Salvar Ficha </Button>
            </Box>
          </Box>
          {editItem._id && editItem._id !== '' &&
            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
              <Box padding={10}>
                <Button variant="contained"
                  className="btn-secondary"
                  component="label"
                  startIcon={<DeleteForeverIcon />}
                  onClick={(event) => areaPlantioHelpers.deletePlantio(filters, documents.page, projectsReducer.selectedProject, editItem._id, dispatch)}
                >
                  Excluir
                </Button>
              </Box>
            </Box>
          }
        </Grid>
        <Divider className="dividerFull" />
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  areaPlantioReducer: store.areaPlantioReducer,
  projectsReducer: store.projectsReducer,
  paramsReducer: store.paramsReducer,
  placesReducer: store.placesReducer,
  floraEspeciesReducer: store.floraEspeciesReducer,
  auth: store.loginReducer.auth,
  alert: store.alertReducer
});

export default connect(mapStateToProps)(AreaPlantioDetalhes);