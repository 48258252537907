import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, InputAdornment, InputLabel, FormControl, Select, MenuItem, FormHelperText } from "@material-ui/core"
import { lenhaHelpers } from '../../../helpers/flora/lenha/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class LenhaForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    formValidator = () => {
        const { lenhaReducer } = this.props
        const { lenha } = lenhaReducer
        const { editItem } = lenha
        const { areaDemarcada, dataMedicao, comprimento, altura1, largura } = editItem

        if (dataMedicao !== null && areaDemarcada !== '' && comprimento !== '' && altura1 !== '' && largura !== '') {
            return true
        } else {
            return false
        }
    }

    submitForm = () => {
        const { lenhaReducer, dispatch, closeModal, projectsReducer } = this.props
        const { lenha } = lenhaReducer
        const { editItem, filters } = lenha
        this.setState({ formSubmited: true })

        if (this.formValidator() === false) return

        if (editItem._id && editItem._id !== '') {
            dispatch(lenhaHelpers.updateLenha(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        } else {
            dispatch(lenhaHelpers.createLenha(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        }
    }

    calculateValues = () => {
        const { lenhaReducer, dispatch, context } = this.props
        const { lenha } = lenhaReducer
        const { editItem } = lenha
        if ((editItem.altura1 && Number(editItem.altura1) > 0)) {
            lenhaHelpers.calculateAlturaMedia(editItem.altura1, editItem.altura2, editItem.altura3, editItem.altura4, editItem.altura5, editItem.altura6, editItem.comprimento, editItem.largura, context, dispatch)
        }
    }

    render() {
        const { dispatch, lenhaReducer, projectsReducer, closeModal, placesReducer } = this.props
        const { listPlaces } = placesReducer
        const { lenha } = lenhaReducer
        const { editItem, filters, documents } = lenha
        const { formSubmited } = this.state

        const validAreaDemarcada = listPlaces?.some(item => item.name === editItem.areaDemarcada)

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        {editItem._id && editItem._id !== '' &&
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="numeroFicha"
                                    label="Número da ficha"
                                    className="inputDefault"
                                    disabled={editItem && editItem._id}
                                    value={editItem.numeroFicha}
                                    autoComplete='off'
                                    onChange={(event) => lenhaHelpers.handleInput(event, 'numeroFicha', 'lenha', dispatch)}
                                    error={formSubmited && editItem.numeroFicha === ''}
                                    helperText={formSubmited && editItem.numeroFicha === '' ? 'Campo obrigatório' : ''}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault" required error={formSubmited && editItem.areaDemarcada === ''}>
                                <InputLabel id="demo-simple-select-label">Área Demarcada</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={editItem.areaDemarcada}
                                    onChange={(event) => lenhaHelpers.handleInput(event, 'areaDemarcada', 'lenha', dispatch)}
                                >
                                    <MenuItem selected value={''}>{'--'}</MenuItem>
                                    {!validAreaDemarcada && editItem.areaDemarcada !== "--" &&
                                        <MenuItem value={editItem.areaDemarcada}>{editItem.areaDemarcada}</MenuItem>
                                    }
                                    {listPlaces.map((item, index) => {
                                        return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                {formSubmited && editItem.areaDemarcada === '' && <FormHelperText>Campo obrigatório</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="comprimento"
                                label="Comprimento"
                                className="inputDefault"
                                value={editItem.comprimento}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleInputNumber(event, 'comprimento', 'lenha', dispatch, this.calculateValues)}
                                error={formSubmited && editItem.comprimento === ''}
                                helperText={formSubmited && editItem.comprimento === '' ? 'Campo obrigatório' : ''}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="altura1"
                                label="Altura 1"
                                className="inputDefault"
                                value={editItem.altura1}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleInputNumber(event, 'altura1', 'lenha', dispatch, this.calculateValues)}
                                error={formSubmited && editItem.altura1 === ''}
                                helperText={formSubmited && editItem.altura1 === '' ? 'Campo obrigatório' : ''}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="altura2"
                                label="Altura 2"
                                className="inputDefault"
                                value={editItem.altura2}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleInputNumber(event, 'altura2', 'lenha', dispatch, this.calculateValues)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="altura3"
                                label="Altura 3"
                                className="inputDefault"
                                value={editItem.altura3}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleInputNumber(event, 'altura3', 'lenha', dispatch, this.calculateValues)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="altura4"
                                label="Altura 4"
                                className="inputDefault"
                                value={editItem.altura4}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleInputNumber(event, 'altura4', 'lenha', dispatch, this.calculateValues)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="altura5"
                                label="Altura 5"
                                className="inputDefault"
                                value={editItem.altura5}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleInputNumber(event, 'altura5', 'lenha', dispatch, this.calculateValues)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="altura6"
                                label="Altura 6"
                                className="inputDefault"
                                value={editItem.altura6}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleInputNumber(event, 'altura6', 'lenha', dispatch, this.calculateValues)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="alturaMedia"
                                label="Altura média"
                                className="inputDefault"
                                value={editItem.alturaMedia}
                                autoComplete='off'
                                disabled={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="largura"
                                label="Largura"
                                className="inputDefault"
                                value={editItem.largura}
                                autoComplete='off'
                                onChange={(event) => lenhaHelpers.HandleInputNumber(event, 'largura', 'lenha', dispatch, this.calculateValues)}
                                error={formSubmited && editItem.altura1 === ''}
                                helperText={formSubmited && editItem.altura1 === '' ? 'Campo obrigatório' : ''}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="volume"
                                label="Volume"
                                className="inputDefault"
                                value={editItem.volume}
                                autoComplete='off'
                                disabled={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="volumeEsterio"
                                label="Volume do metro estério"
                                className="inputDefault"
                                value={editItem.volumeEsterio}
                                autoComplete='off'
                                disabled={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de medição"
                                    value={editItem.dataMedicao}
                                    onChange={(event) => lenhaHelpers.handleInput(event, 'dataMedicao', 'lenha', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>


                        <Divider className="dividerFull" />
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained"
                                    className="btn-primary"
                                    component="label"
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        {editItem._id && editItem._id !== '' &&
                            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                                <Box padding={10}>
                                    <Button variant="contained"
                                        className="btn-secondary"
                                        component="label"
                                        startIcon={<DeleteForeverIcon />}
                                        onClick={(event) => lenhaHelpers.deleteLenha(filters, documents.page, projectsReducer.selectedProject, editItem._id, closeModal, dispatch)}> Excluir </Button>
                                </Box>
                            </Box>
                        }
                        <Divider className="dividerFull" />
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    lenhaReducer: store.lenhaReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
    placesReducer: store.placesReducer,
});

export default connect(mapStateToProps)(LenhaForm);