export const lenhaActionTypes = {

    // LENHA

    GET_LENHA_CARD_REQUEST: "GET_LENHA_CARD_REQUEST",
    GET_LENHA_CARD_SUCCESS: "GET_LENHA_CARD_SUCCESS",
    GET_LENHA_CARD_FAILURE: "GET_LENHA_CARD_FAILURE",

    LIST_LENHA_CARD_REQUEST: "LIST_LENHA_CARD_REQUEST",
    LIST_LENHA_CARD_SUCCESS: "LIST_LENHA_CARD_SUCCESS",
    LIST_LENHA_CARD_FAILURE: "LIST_LENHA_CARD_FAILURE",

    SET_LENHA_FILTER: "SET_LENHA_FILTER",

    SET_LENHA_FILTER_DATE_INIT: "SET_LENHA_FILTER_DATE_INIT",
    SET_LENHA_FILTER_DATE_END: "SET_LENHA_FILTER_DATE_END",

    EDIT_LENHA_CARD: "EDIT_LENHA_CARD",
    UPDATE_LENHA_CARD_CANCELED: "UPDATE_LENHA_CARD_CANCELED",
    HANDLE_LENHA_FIELDS: "HANDLE_LENHA_FIELDS",

    CREATE_LENHA_CARD_REQUEST: "CREATE_LENHA_CARD_REQUEST",
    CREATE_LENHA_CARD_SUCCESS: "CREATE_LENHA_CARD_SUCCESS",
    CREATE_LENHA_CARD_FAILURE: "CREATE_LENHA_CARD_FAILURE",

    UPDATE_LENHA_CARD_REQUEST: "UPDATE_LENHA_CARD_REQUEST",
    UPDATE_LENHA_CARD_SUCCESS: "UPDATE_LENHA_CARD_SUCCESS",
    UPDATE_LENHA_CARD_FAILURE: "UPDATE_LENHA_CARD_FAILURE",
   
    CALCULATE_LENHA_VOLUME_REQUEST: "CALCULATE_LENHA_VOLUME_REQUEST",
    CALCULATE_LENHA_VOLUME_SUCCESS: "CALCULATE_LENHA_VOLUME_SUCCESS",
    CALCULATE_LENHA_VOLUME_FAILURE: "CALCULATE_LENHA_VOLUME_FAILURE",

    EXPORT_LENHA_REQUEST: "EXPORT_LENHA_REQUEST",
    EXPORT_LENHA_SUCCESS: "EXPORT_LENHA_SUCCESS",
    EXPORT_LENHA_FAILURE: "EXPORT_LENHA_FAILURE",

    // LENHA SAIDA

    GET_LENHA_SAIDA_CARD_REQUEST: "GET_LENHA_SAIDA_CARD_REQUEST",
    GET_LENHA_SAIDA_CARD_SUCCESS: "GET_LENHA_SAIDA_CARD_SUCCESS",
    GET_LENHA_SAIDA_CARD_FAILURE: "GET_LENHA_SAIDA_CARD_FAILURE",

    LIST_LENHA_SAIDA_CARD_REQUEST: "LIST_LENHA_SAIDA_CARD_REQUEST",
    LIST_LENHA_SAIDA_CARD_SUCCESS: "LIST_LENHA_SAIDA_CARD_SUCCESS",
    LIST_LENHA_SAIDA_CARD_FAILURE: "LIST_LENHA_SAIDA_CARD_FAILURE",

    CREATE_LENHA_SAIDA_CARD_REQUEST: "CREATE_LENHA_SAIDA_CARD_REQUEST",
    CREATE_LENHA_SAIDA_CARD_SUCCESS: "CREATE_LENHA_SAIDA_CARD_SUCCESS",
    CREATE_LENHA_SAIDA_CARD_FAILURE: "CREATE_LENHA_SAIDA_CARD_FAILURE",

    UPDATE_LENHA_SAIDA_CARD_REQUEST: "UPDATE_LENHA_SAIDA_CARD_REQUEST",
    UPDATE_LENHA_SAIDA_CARD_SUCCESS: "UPDATE_LENHA_SAIDA_CARD_SUCCESS",
    UPDATE_LENHA_SAIDA_CARD_FAILURE: "UPDATE_LENHA_SAIDA_CARD_FAILURE",

    SET_LENHA_SAIDA_FILTER: "SET_LENHA_SAIDA_FILTER",

    SET_LENHA_SAIDA_FILTER_DATE_INIT: "SET_LENHA_SAIDA_FILTER_DATE_INIT",
    SET_LENHA_SAIDA_FILTER_DATE_END: "SET_LENHA_SAIDA_FILTER_DATE_END",

    EDIT_LENHA_SAIDA_CARD: "EDIT_LENHA_SAIDA_CARD",
    UPDATE_LENHA_SAIDA_CARD_CANCELED: "UPDATE_LENHA_SAIDA_CARD_CANCELED",
    HANDLE_LENHA_SAIDA_FIELDS: "HANDLE_LENHA_SAIDA_FIELDS",

};