import { prefixAuthApi, prefixDefaultApi } from "../helpers/prefixApi";
import { defaultHeaders } from "../helpers/headerHelper";
import { apiHelpers } from "../helpers/apiHelpers";

export const loginHandler = { login, userVerify };

function login(loginParams) {
    
	const requestOptions = { 
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		method: "POST",
		body: JSON.stringify(loginParams)
	};
	return fetch(prefixAuthApi, requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function userVerify(user, email) {
    let queryParams = `${user ? "?user=".concat(user) : "?email=".concat(email)}`
	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat('users/list').concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}