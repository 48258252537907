import { raizesActionTypes } from "../types/raizesActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: {
    raizes: {},
    raizesSaida: {}
  },
  raizes: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {
      areaDemarcada: '',
    },
    editItem: {
      newItem: {
        areaDemarcada: '',
        dataMedicao: moment().format(),
        volume: ''
      },
      editedCard: {
        areaDemarcada: '',
        project: '',
        volumetoTal: '',
        individuos: [],
      }
    }
  },
  raizesSaida: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {},
    editItem: {
      data: moment().format(),
      destino: '',
      licenca: '',
      volume: ''
    }
  }
};

export const raizesReducer = (state = initialState, action) => {
  switch (action.type) {
    case raizesActionTypes.GET_RAIZES_CARD_REQUEST:
    case raizesActionTypes.GET_RAIZES_CARD_FAILURE:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          raizes: {}
        }
      };
    case raizesActionTypes.GET_RAIZES_CARD_SUCCESS:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          raizes: action.list
        }
      };
    case raizesActionTypes.UPDATE_RAIZES_CARD_REQUEST:
    case raizesActionTypes.CREATE_RAIZES_CARD_REQUEST:
    case raizesActionTypes.LIST_RAIZES_CARD_REQUEST:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          loading: true
        }
      };
    case raizesActionTypes.CREATE_RAIZES_CARD_SUCCESS:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          editItem: initialState.raizes.editItem,
          loading: false
        }
      };
    case raizesActionTypes.UPDATE_RAIZES_CARD_SUCCESS:
    case raizesActionTypes.LIST_RAIZES_CARD_SUCCESS:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.raizes.editItem,
          loading: false
        }
      };
    case raizesActionTypes.UPDATE_RAIZES_CARD_FAILURE:
    case raizesActionTypes.CREATE_RAIZES_CARD_FAILURE:
    case raizesActionTypes.LIST_RAIZES_CARD_FAILURE:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          loading: false
        }
      };

    case raizesActionTypes.SET_RAIZES_FILTER:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          filters: { ...state.raizes.filters, [action.target]: action.value }
        }
      };

    case raizesActionTypes.SET_RAIZES_FILTER_DATE_INIT:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          filters: {
            ...state.raizes.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case raizesActionTypes.SET_RAIZES_FILTER_DATE_END:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          filters: {
            ...state.raizes.filters,
            endDate: moment(action.date).format()
          }
        }
      };

    case raizesActionTypes.EDIT_RAIZES_CARD:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          editItem: {
            ...state.raizes.editItem,
            newItem: initialState.raizes.editItem.newItem,
            editedCard: action.item
          } 
        }
      };
    case raizesActionTypes.UPDATE_RAIZES_INDIVIDUOS:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          editItem: {
            ...state.raizes.editItem,
            newItem: initialState.raizes.editItem.newItem,
            editedCard: {
              ...state.raizes.editItem.editedCard,
              individuos: action.value
            } 
          } 
        }
      };

    case raizesActionTypes.UPDATE_RAIZES_CARD_CANCELED:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          editItem: initialState.raizes.editItem,
          loading: false
        }
      };
    case raizesActionTypes.HANDLE_RAIZES_FIELDS:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          editItem: {
            ...state.raizes.editItem,
            newItem: {
              ...state.raizes.editItem.newItem,
              [action.target]: action.value
            }
          }
        }
      };
    case raizesActionTypes.HANDLE_RAIZES_TOTAL_VOLUME:
      return {
        ...state,
        raizes: {
          ...state.raizes,
          editItem: {
            ...state.raizes.editItem,
            editedCard: {
              ...state.raizes.editItem.editedCard,
              [action.target]: action.value
            }
          }
        }
      };
    // raizes Saida

    case raizesActionTypes.UPDATE_RAIZES_SAIDA_CARD_REQUEST:
    case raizesActionTypes.CREATE_RAIZES_SAIDA_CARD_REQUEST:
    case raizesActionTypes.LIST_RAIZES_SAIDA_CARD_REQUEST:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          loading: true
        }
      };
    case raizesActionTypes.CREATE_RAIZES_SAIDA_CARD_SUCCESS:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          editItem: initialState.raizesSaida.editItem,
          loading: false
        }
      };
    case raizesActionTypes.UPDATE_RAIZES_SAIDA_CARD_SUCCESS:
    case raizesActionTypes.LIST_RAIZES_SAIDA_CARD_SUCCESS:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.raizesSaida.editItem,
          loading: false
        }
      };
    case raizesActionTypes.UPDATE_RAIZES_SAIDA_CARD_FAILURE:
    case raizesActionTypes.CREATE_RAIZES_SAIDA_CARD_FAILURE:
    case raizesActionTypes.LIST_RAIZES_SAIDA_CARD_FAILURE:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          loading: false
        }
      };

    case raizesActionTypes.SET_RAIZES_SAIDA_FILTER:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          filters: { ...state.raizesSaida.filters, [action.target]: action.value }
        }
      };

    case raizesActionTypes.SET_RAIZES_SAIDA_FILTER_DATE_INIT:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          filters: {
            ...state.raizesSaida.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case raizesActionTypes.SET_RAIZES_SAIDA_FILTER_DATE_END:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          filters: {
            ...state.raizesSaida.filters,
            endDate: moment(action.date).format()
          }
        }
      };

    case raizesActionTypes.EDIT_RAIZES_SAIDA_CARD:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          editItem: action.item
        }
      };
    case raizesActionTypes.UPDATE_RAIZES_SAIDA_CARD_CANCELED:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          editItem: initialState.raizes.editItem,
          loading: false
        }
      };
    case raizesActionTypes.HANDLE_RAIZES_SAIDA_FIELDS:
      return {
        ...state,
        raizesSaida: {
          ...state.raizesSaida,
          editItem: {
            ...state.raizesSaida.editItem,
            [action.target]: action.value
          }
        }
      };

    default:
      return state;
  }
};