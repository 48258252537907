import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core"
import { faunaHelpers } from '../../../helpers/fauna/faunaHelpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';


class TelemetriaMonitoramentoForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formSubmited: false
    }
  }

  pointsValidator = (pontos) => {
    if(pontos.length < 1) return false
    pontos.map(ponto => {
      if (ponto.local !== '' || !!ponto.dataRegistro || ponto.coordX !== '' || ponto.coordY !== '' || ponto.EPE !== '' || ponto.azimut !== '' || ponto.projecao !== '' || ponto.coordProj !== '') {
        return false
      }
    })
    return true
  }

  formValidator = () => {
    const { faunaReducer } = this.props
    const { telemetriaMonitoramentoCard } = faunaReducer
    const { dataInit, dataEnd, coordX, coordY, EPE, coordProj, pontos } = telemetriaMonitoramentoCard

    if (dataInit !== '' && (coordX || coordX === '') && (coordY || coordY === '') && (EPE || EPE === '' ) && (coordProj || coordProj === '' ) && dataEnd !== '' && this.pointsValidator(pontos)) {
      return true
    }
    return false
  }

  submitForm = () => {
    const {dispatch, faunaReducer } = this.props
    const { telemetriaMonitoramentoCard, selectedTelemetriaCard } = faunaReducer

    const monitoramentos = selectedTelemetriaCard.monitoramento

    monitoramentos.map((monitoramento, index) => {
      if(monitoramento._id === telemetriaMonitoramentoCard._id){
        monitoramentos.splice(index, 1)
      }
    })

    monitoramentos.push(telemetriaMonitoramentoCard)

    this.setState({ formSubmited: true })

    if (this.formValidator() === false) return
    dispatch(faunaHelpers.saveMonitoramento(monitoramentos))
    this.overrideCloseModal()
  }

  overrideCloseModal = () => {
    const { dispatch, closeModal } = this.props
    dispatch(faunaHelpers.resetMonitoramento())
    closeModal()
  }

  render() {
    const { dispatch, faunaReducer } = this.props
    const { telemetriaMonitoramentoCard, selectedTelemetriaCard } = faunaReducer
    const { formSubmited } = this.state

    const editedItem = selectedTelemetriaCard.monitoramento.filter(j => j.id === telemetriaMonitoramentoCard.id).length > 0

    return (
      <>
        <Grid item xs={12} md={12} >
          <Typography variant="h6" component="h6" className="filterTitle">Data</Typography>
        </Grid>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} md={3}>
                <KeyboardDatePicker
                  className="inputDefault dateInput"
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy HH:mm:ss"
                  margin="normal"
                  label="Data Inicial"
                  value={telemetriaMonitoramentoCard.dataInit}
                  onChange={(event) => faunaHelpers.handleInput(event, 'dataInit', dispatch, 'TELEMETRIA_MONITORAMENTO')}
                  KeyboardButtonProps={{
                    'aria-label': 'Alterar data',
                  }}
                  invalidDateMessage={'Data inválida'}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} md={3}>
                <KeyboardDatePicker
                  className="inputDefault dateInput"
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy HH:mm:ss"
                  margin="normal"
                  label="Data final"
                  value={telemetriaMonitoramentoCard.dataEnd}
                  onChange={(event) => faunaHelpers.handleInput(event, 'dataEnd', dispatch, 'TELEMETRIA_MONITORAMENTO')}
                  KeyboardButtonProps={{
                    'aria-label': 'Alterar data',
                  }}
                  invalidDateMessage={'Data inválida'}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Divider className="dividerFull" />
            <Grid item xs={12} md={12} >
              <Typography variant="h6" component="h6" className="filterTitle">Pontos</Typography>
            </Grid>
            <Divider className="dividerFull" />
            {telemetriaMonitoramentoCard.pontos.map((item, index) => {
              return (
                <>
                  <Grid container spacing={3} className="box-content-primary center blocked-itens" md={11}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Local"
                        className="inputDefault"
                        value={item.local}
                        autoComplete='off'
                        onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'local', event.target.value, 'pontos', dispatch)}
                        error={formSubmited && item.local === ''}
                        helperText={formSubmited && item.local === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={12} md={6}>
                        <KeyboardDatePicker
                          className="inputDefault dateInput"
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy HH:mm:ss"
                          margin="normal"
                          label="Data"
                          value={item.dataRegistro}
                          onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'dataRegistro', event, 'pontos', dispatch)}
                          KeyboardButtonProps={{
                            'aria-label': 'Alterar data',
                          }}
                          invalidDateMessage={'Data inválida'}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Coordenada X"
                        className="inputDefault"
                        value={item.coordX}
                        autoComplete='off'
                        onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'coordX', event.target.value, 'pontos', dispatch, null, 'number')}
                        error={formSubmited && item.coordX === ''}
                        helperText={formSubmited && item.coordX === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Coordenada Y"
                        className="inputDefault"
                        value={item.coordY}
                        autoComplete='off'
                        onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'coordY', event.target.value, 'pontos', dispatch, null, 'number')}
                        error={formSubmited && item.coordY === ''}
                        helperText={formSubmited && item.coordY === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="EPE"
                        className="inputDefault"
                        value={item.EPE}
                        autoComplete='off'
                        onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'EPE', event.target.value, 'pontos', dispatch)}
                        error={formSubmited && item.EPE === ''}
                        helperText={formSubmited && item.EPE === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Azimut"
                        className="inputDefault"
                        value={item.azimut}
                        autoComplete='off'
                        onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'azimut', event.target.value, 'pontos', dispatch)}
                        error={formSubmited && item.azimut === ''}
                        helperText={formSubmited && item.azimut === '' ? 'Campo obrigatório' : ''}
                      />
                    </Grid>
                    <Divider className="dividerFull" />
                    <Grid item xs={12} md={4}>
                      <FormControl className="inputDefault">
                        <InputLabel id="demo-simple-select-label">Sinal</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                          value={item.sinal}
                          onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'sinal', event.target.value, 'pontos', dispatch)}
                        >
                          <MenuItem value={'sonoro'}>{'Sonoro'}</MenuItem>
                          <MenuItem value={'visual'}>{'Visual'}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Intensidade do sinal"
                        className="inputDefault"
                        value={item.intensidade}
                        autoComplete='off'
                        onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'intensidade', event.target.value, 'pontos', dispatch)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl className="inputDefault">
                        <InputLabel id="demo-simple-select-label">Contato</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                          value={item.contato}
                          onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'contato', event.target.value, 'pontos', dispatch)}
                        >
                          <MenuItem value={'auditivo'}>{'Auditivo'}</MenuItem>
                          <MenuItem value={'visual'}>{'Visual'}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Observações"
                        className="inputDefault"
                        value={item.observacoes}
                        autoComplete='off'
                        onChange={(event) => faunaHelpers.handlePontosObjs(telemetriaMonitoramentoCard, index, 'observacoes', event.target.value, 'pontos', dispatch)}
                      />
                    </Grid>
                  </Grid>
                </>
              )
            })}
            <Divider className="dividerFull" />
            <Grid item xs={12} md={3}>
              <Button 
                variant="contained" 
                disabled={faunaReducer?.telemetriaMonitoramentoCard?.pontos.length >= 5} 
                className="btn-secondary" 
                onClick={() => faunaHelpers.addNewPontoMonitoramento(faunaReducer.telemetriaMonitoramentoCard.pontos, dispatch)}
              >
                Adicionar Ponto
              </Button>
            </Grid>
            <Grid item xs={12} md={12} >
              <Typography variant="h6" component="h6" className="filterTitle">Triângulação</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Coordenada X"
                className="inputDefault"
                value={telemetriaMonitoramentoCard.coordX ?? ''}
                autoComplete='off'
                onChange={(event) => faunaHelpers.handleInput(event, 'coordX', dispatch, 'TELEMETRIA_MONITORAMENTO', 'number')}
                error={formSubmited && (telemetriaMonitoramentoCard.coordX === null || telemetriaMonitoramentoCard.coordX === '')}
                helperText={formSubmited && telemetriaMonitoramentoCard.coordX === null ? 'Campo obrigatório' : ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Coordenada Y"
                className="inputDefault"
                value={telemetriaMonitoramentoCard.coordY ?? ''}
                autoComplete='off'
                onChange={(event) => faunaHelpers.handleInput(event, 'coordY', dispatch, 'TELEMETRIA_MONITORAMENTO', 'number')}
                error={formSubmited && (telemetriaMonitoramentoCard.coordY === null || telemetriaMonitoramentoCard.coordY === '')}
                helperText={formSubmited && telemetriaMonitoramentoCard.coordY === null ? 'Campo obrigatório' : ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="EPE"
                className="inputDefault"
                value={telemetriaMonitoramentoCard.EPE ?? ''}
                autoComplete='off'
                onChange={(event) => faunaHelpers.handleInput(event, 'EPE', dispatch, 'TELEMETRIA_MONITORAMENTO')}
                error={formSubmited && (telemetriaMonitoramentoCard.EPE === null || telemetriaMonitoramentoCard.EPE === '')}
                helperText={formSubmited && telemetriaMonitoramentoCard.EPE === null ? 'Campo obrigatório' : ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Projeção"
                className="inputDefault"
                value={telemetriaMonitoramentoCard.coordProj ?? ''}
                autoComplete='off'
                onChange={(event) => faunaHelpers.handleInput(event, 'coordProj', dispatch, 'TELEMETRIA_MONITORAMENTO')}
                error={formSubmited && (telemetriaMonitoramentoCard.coordProj === null || telemetriaMonitoramentoCard.coordProj === '')}
                helperText={formSubmited && telemetriaMonitoramentoCard.coordProj === null ? 'Campo obrigatório' : ''}
              />
            </Grid>
            <Divider className="dividerFull" />
            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
              <Box padding={10}>
                <Button variant="contained"
                  className="btn-primary"
                  component="label"
                  startIcon={<SaveIcon />}
                  onClick={() => this.submitForm()}> Salvar Monitoramento
                </Button>
              </Box>
            </Box>
            {editedItem && 
              <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                <Box padding={10}>
                  <Button variant="contained"
                    className="btn-secondary"
                    component="label"
                    startIcon={<SaveIcon />}
                    onClick={() => dispatch(faunaHelpers.deleteMonitoramento(telemetriaMonitoramentoCard.id, selectedTelemetriaCard.monitoramento, this.overrideCloseModal))}> Excluir Monitoramento
                  </Button>
                </Box>
              </Box>
            }
            <Divider className="dividerFull" />
          </Grid>
        </form>
      </>
    )
  }
}

const mapStateToProps = store => ({
  viveirosReducer: store.viveirosReducer,
  placesReducer: store.placesReducer,
  faunaReducer: store.faunaReducer,
  projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(TelemetriaMonitoramentoForm);