import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core"
import { palmitoHelpers } from '../../../../helpers/flora/palmito/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers'; 
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';

class PalmitoTalhaoForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    formValidator = () => {
        const { palmitoReducer } = this.props
        const { editItem } = palmitoReducer
        const { talhao } = editItem
        const { numeroTalhao, dataCadastro, tipo, tipoAmbiente, iluminacao, area } = talhao

        if(numeroTalhao !== '' && dataCadastro !== null && tipo !== '' && tipoAmbiente !== '' && iluminacao !== '' && area.length >= 4){
            return true
        }else{
            return false
        }
    }

    submitForm = () => {
        const { palmitoReducer, dispatch, closeModal, projectsReducer } = this.props
        const { editItem } = palmitoReducer
        const { talhao } = editItem
        this.setState({formSubmited: true})
        
        if(this.formValidator() === false) return

        if(talhao._id && talhao._id !== '') {
            dispatch(palmitoHelpers.updateTalhao(talhao, 1, closeModal))
        }else{
            dispatch(palmitoHelpers.createTalhao(talhao, 1, closeModal, projectsReducer.selectedProject))
        }
    }

    render(){
        const { dispatch, palmitoReducer } = this.props
        const { editItem, options } = palmitoReducer
        const { talhao } = editItem
        const { formSubmited } = this.state

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {talhao._id && talhao._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="numeroTalhao" 
                                label="Número da Talhão" 
                                className="inputDefault" 
                                disabled={talhao && talhao._id} 
                                value={talhao.numeroTalhao} 
                                autoComplete='off' 
                                onChange={(event) => palmitoHelpers.handleInput(event, 'numeroTalhao', 'talhao', dispatch)}
                                error={formSubmited && talhao.numeroTalhao === ''}
                                helperText={formSubmited && talhao.numeroTalhao === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de cadastro"
                                    value={talhao.dataCadastro}
                                    onChange={(event) => palmitoHelpers.handleInput(event, 'dataCadastro', 'talhao', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                <Select 
                                    labelId="tipo-select-label" 
                                    id="tipo-select" 
                                    value={talhao.tipo} 
                                    autoComplete='off' 
                                    onChange={(event) => palmitoHelpers.handleInput(event, 'tipo', 'talhao', dispatch)}
                                    error={formSubmited && talhao.tipo === ''}
                                    helperText={formSubmited && talhao.tipo === '' ? 'Campo obrigatório' : ''}
                                >
                                    <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                    {options.tipo.map((type, index) => {
                                        return <MenuItem key={index} value={type}>{type}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Tipo de ambiente</InputLabel>
                                <Select labelId="tipoAmbiente-select-label" id="tipoAmbiente-select" value={talhao.tipoAmbiente} autoComplete='off' onChange={(event) => palmitoHelpers.handleInput(event, 'tipoAmbiente', 'talhao', dispatch)}>
                                    <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                    {options.tipoAmbiente.map((type, index) => {
                                        return <MenuItem key={index} value={type}>{type}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Iluminação</InputLabel>
                                <Select labelId="iluminacao-select-label" id="iluminacao-select" value={talhao.iluminacao} autoComplete='off' onChange={(event) => palmitoHelpers.handleInput(event, 'iluminacao', 'talhao', dispatch)}>
                                    <MenuItem key='default' value={''}>Selecione...</MenuItem>
                                    {options.iluminacao.map((type, index) => {
                                        return <MenuItem key={index} value={type}>{type}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Typography variant="h6" component="h6" className="filterTitle">Área</Typography>
                        </Grid>
                        <Divider className="dividerFull"/>
                        {talhao.area && talhao.area.map((item, index) => {
                            return(
                                <>
                                    <Grid container spacing={3} className="box-content-primary center blocked-itens" md={11}>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Coordenada X" 
                                                className="inputDefault" 
                                                value={item.coordX} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.area, index, 'coordX', event.target.value, 'area', 'talhao', dispatch, palmitoHelpers.getArea, 'string')}
                                                type="text"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Coordenada Y" 
                                                className="inputDefault" 
                                                value={item.coordY} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.area, index, 'coordY', event.target.value, 'area', 'talhao', dispatch, palmitoHelpers.getArea, 'string')}
                                                type="text"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Projeção" 
                                                className="inputDefault" 
                                                value={item.projecao} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.area, index, 'projecao', event.target.value, 'area', 'talhao', dispatch, palmitoHelpers.getArea, 'string')}
                                                type="text"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="EPE" 
                                                className="inputDefault" 
                                                value={item.EPE} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.area, index, 'EPE', event.target.value, 'area', 'talhao', dispatch, false, 'string')}
                                                type="text"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="areaTotal"
                                label="Área Total"
                                className="inputDefault"
                                disabled={talhao && talhao._id}
                                value={talhao.areaTotal}
                                autoComplete='off'
                                onChange={(event) => palmitoHelpers.handleInput(event, 'areaTotal', 'talhao', dispatch)}
                                error={formSubmited && talhao.areaTotal === ''}
                                helperText={formSubmited && talhao.areaTotal === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => palmitoHelpers.addNewObjs(talhao.area, 'area', 'talhao', dispatch)}>
                                Nova Área
                            </Button>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={12} >
                            <Typography variant="h6" component="h6" className="filterTitle">Monitoramento</Typography>
                        </Grid>
                        <Divider className="dividerFull"/>
                        {talhao.monitoramento && talhao.monitoramento.map((item, index) => {
                            return(
                                <>
                                    <Grid container spacing={3} className="box-content-primary center blocked-itens" md={11}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid item xs={12} md={4}>
                                                <KeyboardDatePicker
                                                    className="inputDefault dateInput"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Data"
                                                    value={item.dataMonitoramento}
                                                    onChange={(event) => palmitoHelpers.handleObjs(talhao.monitoramento, index, 'dataMonitoramento', event, 'monitoramento', 'talhao', dispatch)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'Alterar data',
                                                    }}
                                                    invalidDateMessage={'Data inválida'}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Qnt de mudas até 0,5 m" 
                                                className="inputDefault" 
                                                value={item.qntMudasAte50} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.monitoramento, index, 'qntMudasAte50', event.target.value, 'monitoramento', 'talhao', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Qnt de mudas 0,5 a 1 m" 
                                                className="inputDefault" 
                                                value={item.qntMudasAte100} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.monitoramento, index, 'qntMudasAte100', event.target.value, 'monitoramento', 'talhao', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Qnt de mudas 1 a 1,5 m" 
                                                className="inputDefault" 
                                                value={item.qntMudasAte150} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.monitoramento, index, 'qntMudasAte150', event.target.value, 'monitoramento', 'talhao', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Qnt de mudas acima de 1,5 m" 
                                                className="inputDefault" 
                                                value={item.qntMudasAcima150} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.monitoramento, index, 'qntMudasAcima150', event.target.value, 'monitoramento', 'talhao', dispatch)}
                                                type="number"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField 
                                                label="Observações" 
                                                className="inputDefault" 
                                                value={item.observacoes} 
                                                autoComplete='off' 
                                                onChange={(event) => palmitoHelpers.handleObjs(talhao.monitoramento, index, 'observacoes', event.target.value, 'monitoramento', 'talhao', dispatch, false, 'string')}
                                                type="text"
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => palmitoHelpers.addNewObjs(talhao.monitoramento, 'monitoramento', 'talhao', dispatch)}>
                                Novo Monitoramento
                            </Button>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained" 
                                    className="btn-primary" 
                                    component="label" 
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        <Divider className="dividerFull"/>
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    palmitoReducer: store.palmitoReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(PalmitoTalhaoForm);