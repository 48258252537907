import React from 'react';
import { Grid } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';  
import { palmitoHelpers } from '../../../helpers/flora/palmito/helpers'

const FilterDate = ({ filterProps , dispatch, context}) => {

    const handleFilterDateChange = (date) => {
        const target =  context !== 'saida' ? "dataCadastro" : "dataSaida"

        dispatch(palmitoHelpers.handleFilterDateChange(new Date(date), target));
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} md={6}>
                <KeyboardDatePicker
                    className="inputDefault"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label={context !== 'saida' ? "Data de cadastro" : "Data de saída"}
                    value={context !== 'saida' ? filterProps.dataCadastro : filterProps.dataSaida}
                    onChange={handleFilterDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'Alterar data',
                    }}
                    invalidDateMessage={'Data inválida'}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default FilterDate