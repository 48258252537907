import React from 'react';
import { Divider, Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers'; 
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import moment from 'moment';

const AccordionDescricaoOcorrencia = ({ selectedCard, dispatch, selectOptions, loggedUser, validateForm}) => {
    const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

    return (
        <Grid container spacing={3}>
            
            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault" required error={validateForm && (selectedCard.ocorrenciaType === '' || selectedCard.ocorrenciaType === '--')}>
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.tipo}
                        onChange={(event) => faunaHelpers.handleInput(event, 'tipo', dispatch)}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem key={"00001"} value={""}>--</MenuItem>
                    {selectOptions && selectOptions.ocorrenciaType.map((item, index) => {
                        return <MenuItem key={index} value={item}>{item}</MenuItem>
                    })}
                    </Select>
                    {validateForm && (selectedCard.ocorrenciaType === '' || selectedCard.ocorrenciaType === '--') && <FormHelperText>Campo obrigatório</FormHelperText>}
                </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Vestígio</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.vestigio}
                        onChange={(event) => faunaHelpers.handleInput(event, 'vestigio', dispatch)}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem key={"00001"} value={""}>--</MenuItem>
                    {selectOptions && selectOptions.vestigioType.map((item, index) => {
                        return <MenuItem key={index} value={item}>{item}</MenuItem>
                    })}
                    </Select>
                </FormControl>
            </Grid>
            
            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Comportamento</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.comportamento}
                        onChange={(event) => faunaHelpers.handleInput(event, 'comportamento', dispatch)}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem key={"00001"} value={""}>--</MenuItem>
                    {selectOptions && selectOptions.comportamentoType.map((item, index) => {
                        return <MenuItem key={index} value={item}>{item}</MenuItem>
                    })}
                    </Select>
                </FormControl>
            </Grid>

            <Divider className="dividerFull"/>

            <Grid item xs={12} md={12}>
                <TextField id="obsAdicionaisObs" disabled={!writeModuleVerify} label="Observações da Ocorrência" className="inputDefault" value={selectedCard.obsOcorrencia} multiline rows={1} rowsMax={8} onChange={(event) => faunaHelpers.handleInput(event, 'obsOcorrencia', dispatch)} />
            </Grid>
        </Grid>
    )
}

export default AccordionDescricaoOcorrencia