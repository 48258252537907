import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableContainer, Table, TableBody, Button, Divider } from '@material-ui/core'
import { Pagination, Alert } from '@material-ui/lab';
import Header from '../../../Components/headerAlter'
import Filter from '../../../Components/flora/raizes/filter'
import CustomModal from '../../../Components/customModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { history } from '../../../helpers/history'
import { helpers } from '../../../helpers/helpers'
import { raizesHelpers } from '../../../helpers/flora/raizes/helpers'
import { placesHelpers } from '../../../helpers/places/placesHelpers';

class Raizes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: { showModal: false, modalType: undefined }
    }
  }

  componentDidMount() {
    const { dispatch, raizesReducer, projectsReducer } = this.props
    dispatch(helpers.saveLocation(history.location.pathname));
    dispatch(raizesHelpers.paginateRaizesSaida(raizesReducer.raizes.documents.page, projectsReducer.selectedProject))
    // dispatch(placesHelpers.listPlaces(projectsReducer.selectedProject, '2'));
  }

  handleModal = (modalType, clear) => {
    const { modal } = this.state
    const { dispatch } = this.props
    this.setState({ modal: { showModal: !modal.showModal, modalType: modalType } })
    if (clear) {
      dispatch(raizesHelpers.editCard('raizes', false))
    }
  }

  render() {
    const { raizesReducer, dispatch, alert, pageContext, pageTitle, projectsReducer } = this.props
    const { modal } = this.state
    const { raizesSaida, editItem } = raizesReducer
    const { documents, filters } = raizesSaida
    const { page, pagesTotal } = documents
    return (
      <div className='mainContainer'>
        <Header title={pageTitle} />
        <div className='basicContainer'>
          {alert && alert.type === 'alert-danger' && alert.message &&
            <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
          }
          {alert && alert.type === 'alert-success' && alert.message &&
            <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
          }
          {documents && documents.list && documents.list.length > 0 &&
            <>
              <Grid container spacing={3} className="mapBox">
                <Grid item xs={12} md={3}>
                  <Button variant="contained" className="btn-secondary" onClick={() => dispatch(raizesHelpers.raizes())}>
                    RAÍZES
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={3}>
                  <Button variant="contained" className="btn-primary" onClick={() => this.handleModal('raizesSaida', true)}>
                    ADICIONAR SAÍDA
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <TableContainer className="customTable paramsTable">
                  <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                    <TableHead className="tableHead">
                      <Grid component={'tr'} container className="parametersTable">
                        <Grid component={'th'} item xs={12} md={2}>Data</Grid>
                        <Grid component={'th'} item xs={12} md={3}>Destino</Grid>
                        <Grid component={'th'} item xs={12} md={4}>Licença</Grid>
                        <Grid component={'th'} item xs={12} md={2}>{'Quantidade (m³)'}</Grid>
                        <Grid component={'th'} item xs={12} md={1}></Grid>
                      </Grid>
                    </TableHead>
                    <TableBody>
                      {documents.list.map((item, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <Grid component={'tr'} container className="parametersTable" key={index} id={labelId}>
                            <Grid component={'td'} item xs={12} md={2}>{item.data ? moment(item.data).format('DD/MM/YYYY') : ""}</Grid>
                            <Grid component={'td'} item xs={12} md={3}>{item?.destino}</Grid>
                            <Grid component={'td'} item xs={12} md={4}>{item?.licenca}</Grid>
                            <Grid component={'td'} item xs={12} md={2}>{item?.volume?.replace(/[^\d.-]/g, '')}</Grid>
                            <Grid component={'td'} item xs={12} md={1}>
                              <Button
                                variant="contained"
                                className="btn-secondary btn-innerTable"
                                onClick={() => dispatch(raizesHelpers.editSaidaCard('raizesSaida', item, this.handleModal))}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider className="dividerFull" />
                <Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, filters, page, 'raizesSaida', projectsReducer.selectedProject))} />
              </Grid>
            </>
          }
          {documents && documents.list && documents.list.length === 0 &&
            <Alert className="alert alert-warning" severity="warning">Não foram encontrados items.</Alert>
          }
        </div>
        {modal.showModal &&
          <CustomModal modalFn={this.handleModal} modalType={modal.modalType} />
        }
      </div>
    )
  }
}

const mapStateToProps = store => ({
  raizesReducer: store.raizesReducer,
  projectsReducer: store.projectsReducer,
  paramsReducer: store.paramsReducer,
  auth: store.loginReducer.auth,
  alert: store.alertReducer
});

export default connect(mapStateToProps)(Raizes);