import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'
import { trapsHelpers } from '../../helpers/traps/trapsHelpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';  

class TrapsForm extends Component {

    constructor(props){
        super(props)
        this.state = {
        }
    }

    render(){
        const { dispatch, trapsReducer, closeModal, paramsReducer, projectsReducer } = this.props
        const { editItem, options } = trapsReducer

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Tipo de armadilha</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.classe} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'trapType', 'editTraps', dispatch)}>
                                    {paramsReducer && paramsReducer.listOptions.trapType && paramsReducer.listOptions.trapType.map((item, index) => <MenuItem key={index} value={item._id}>{item.trapType}</MenuItem> )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="trapId" label="Identificador" className="inputDefault" value={editItem.trapId} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'trapId', 'editTraps', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="description" label="Descrição" className="inputDefault" value={editItem.description} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'description', 'editTraps', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.status} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'status', 'editTraps', dispatch)}>
                                    <MenuItem key='ativa' value={true}>{'Ativa'}</MenuItem>
                                    <MenuItem key='inativa' value={false}>{'Inativa'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={12} >
                            <Typography variant="h6" component="h6" className="filterTitle">Dimensões</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField id="largura" label="Largura (cm)" className="inputDefault" value={editItem.trapSize.largura} autoComplete='off' onChange={(event) => trapsHelpers.handleTrapSize(event, 'largura', editItem.trapSize, dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField id="altura" label="Altura (cm)" className="inputDefault" value={editItem.trapSize.altura} autoComplete='off' onChange={(event) => trapsHelpers.handleTrapSize(event, 'altura', editItem.trapSize, dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField id="comprimento" label="Comprimento (cm)" className="inputDefault" value={editItem.trapSize.comprimento} autoComplete='off' onChange={(event) => trapsHelpers.handleTrapSize(event, 'comprimento', editItem.trapSize, dispatch)}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={12} >
                            <Typography variant="h6" component="h6" className="filterTitle">Histórico de posicionamento</Typography>
                        </Grid>
                        <Divider className="dividerFull"/>
                            <Grid item xs={12} md={4}>
                                <Button variant="contained" className="btn-secondary" disabled={!!(editItem.position.filter(position => position.status === true).length)} onClick={(event) => trapsHelpers.addNewPosition(editItem.position, dispatch)}>
                                    Nova Posição
                                </Button>
                            </Grid>
                        <Divider className="dividerFull"/>
                        {editItem.position.map((place, index) => {
                            return(
                                <Grid key={index} item xs={12} md={12}>
                                    <div className='box-content-primary traps'>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3}>
                                                <TextField label="Coordenada X" className="inputDefault" value={place.coordX} autoComplete='off' disabled={!place.status} onChange={(event) => trapsHelpers.handlePosition(editItem.position, index, 'coordX', event.target.value , dispatch)}/>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField label="Coordenada Y" className="inputDefault" value={place.coordY} autoComplete='off' disabled={!place.status} onChange={(event) => trapsHelpers.handlePosition(editItem.position, index, 'coordY', event.target.value , dispatch)}/>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField label="EPE" className="inputDefault" value={place.EPE} autoComplete='off' disabled={!place.status} onChange={(event) => trapsHelpers.handlePosition(editItem.position, index, 'EPE', event.target.value , dispatch)}/>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl className="inputDefault">
                                                    <InputLabel id="demo-simple-select-label">Projeção</InputLabel>
                                                    <Select labelId="status-select-label" id="status-select" value={place.projecao} autoComplete='off' disabled={!place.status} onChange={(event) => trapsHelpers.handlePosition(editItem.position, index, 'projecao', event.target.value, dispatch)}>
                                                        <MenuItem key='deafult' value={'--'}>{'--'}</MenuItem>
                                                        {options.projecao.map((item, index) => {
                                                            return (
                                                                <MenuItem key={index} value={item}>{item}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid item xs={12} md={4}>
                                                    <KeyboardDatePicker
                                                        className="inputDefault dateInput"
                                                        disableToolbar
                                                        variant="inline"
                                                        format="dd/MM/yyyy"
                                                        margin="normal"
                                                        label="Data de Inclusão"
                                                        value={place.addDate}
                                                        disabled={!place.status}
                                                        onChange={(event) => trapsHelpers.handlePosition(editItem.position, index, 'addDate', new Date(event), dispatch)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'Alterar data',
                                                        }}
                                                        invalidDateMessage={'Data inválida'}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                            {!place.status &&
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid item xs={12} md={4}>
                                                        <KeyboardDatePicker
                                                            className="inputDefault dateInput"
                                                            disableToolbar
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            margin="normal"
                                                            label="Data de Remoção"
                                                            value={place.removeDate}
                                                            // disabled={!place.status}
                                                            required={!place.status} 
                                                            error={!!((place.status === false && place.removeDate === "" ) || (place.status === true && !!place.removeDate))}
                                                            helperText={!!((place.status === false && place.removeDate === "" ) || (place.status === true && !!place.removeDate)) ? "Informe a data de remoção" : ""}
                                                            onChange={(event) => trapsHelpers.handlePosition(editItem.position, index, 'removeDate', new Date(event), dispatch)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'Alterar data',
                                                            }}
                                                            invalidDateMessage={'Data inválida'}
                                                        />
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            }
                                            <Grid item xs={12} md={4}>
                                                <FormControl className="inputDefault">
                                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                    <Select labelId="status-select-label" id="status-select" value={place.status} autoComplete='off' onChange={(event) => trapsHelpers.handlePosition(editItem.position, index, 'status', event.target.value, dispatch)}>
                                                        <MenuItem key='ativa' value={true}>{'Ativo'}</MenuItem>
                                                        <MenuItem key='inativa' value={false}>{'Inativo'}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            )
                        })}
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-primary" onClick={editItem._id && editItem._id !== '' ? () => dispatch(trapsHelpers.update(editItem, 1, closeModal)) : () => dispatch(trapsHelpers.create(editItem, 1, projectsReducer.selectedProject, closeModal))}>
                                SALVAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    trapsReducer: store.trapsReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(TrapsForm);