import { loginHandler } from '../handlers/loginHandler';
import { actionTypes } from '../types/actionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'
import { helpers } from '../helpers/helpers'

export const loginActions = {
    login,
  };
  
function login(params) {
    return dispatch => {
        dispatch(request());
        loginHandler.login(params)
        .then(auth => {
            if(auth.error !== true){
                helpers.setToken(auth.token);
                helpers.setCookie(auth, dispatch);
                dispatch(success(auth));
                return window.location.reload();
            }else{
                failure(auth)
                dispatch(alertActions.error(auth.errorMsg));
            }
            
        },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );

                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.LOGIN_REQUEST } }
    function success(auth) { return { type: actionTypes.LOGIN_SUCCESS, auth } }
    function failure(error) { return { type: actionTypes.LOGIN_FAILURE, error } }
}