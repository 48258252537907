import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputAdornment, InputLabel, Input, IconButton, Select, MenuItem, FormControlLabel, Checkbox, Divider } from "@material-ui/core"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { helpers } from '../../helpers/helpers'

class UserForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            showPassword: false
        }
    }

    handleClickShowPassword = () => {
        const { showPassword } = this.state
        this.setState({ showPassword: !showPassword})
    };

    render(){
        const { dispatch, usersReducer, closeModal } = this.props
        const { editUser } = usersReducer
        const { showPassword } = this.state

        const fieldsValidator = !(editUser.nome && editUser.email && (editUser.status !== undefined && editUser.status !== null) && editUser.user)

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editUser._id && editUser._id !== '' ? 'Editar' : 'Novo usuário'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="name" 
                                label="Nome" 
                                className="inputDefault" 
                                required 
                                value={editUser.nome} 
                                autoComplete='off' 
                                onChange={(event) => helpers.handleInput(event, 'nome', 'editCard', dispatch)}
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                      autocomplete: 'off',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="email" 
                                label="E-mail" 
                                className="inputDefault" 
                                required 
                                value={editUser.email} 
                                autoComplete='off' 
                                onChange={(event) => helpers.handleInput(event, 'email', 'editCard', dispatch)}
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                      autocomplete: 'off',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" required value={editUser.status} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'status', 'editCard', dispatch)}>
                                    <MenuItem key='status-false' value={false}>Desabilitado</MenuItem>
                                    <MenuItem key='status-true' value={true}>Habilitado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="tel" label="Telefone" className="inputDefault" value={editUser.tel} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'tel', 'editCard', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="user" 
                                label="Usuário" 
                                className="inputDefault" 
                                value={editUser.user} 
                                required
                                onChange={(event) => helpers.handleInput(event, 'user', 'editCard', dispatch)}
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                      autocomplete: 'off',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault" required={true} autoComplete='off'>
                                <InputLabel  required={false} htmlFor="standard-adornment-password" autocomplete="342erwfds">Nova Senha</InputLabel>
                                <Input
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={editUser?.pass}
                                onChange={(event) => helpers.handleInput(event, 'pass', 'editCard', dispatch)}
                                autoComplete='off'
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                      autocomplete: 'off',
                                    },
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                />
                            </FormControl>
                        </Grid>
                        <Divider className="dividerFull"/>
                        {editUser.module && editUser.module.map((item, index) => {
                            return (
                                <Grid item xs={12} md={6} key={`module-${index}`}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" component="span" className="moduleTitle">
                                            {
                                            item.description === 'fauna' ? 'Fauna' : 
                                            item.description === 'flora' ?'Flora' : 
                                            item.description === 'user' ? 'Usuários' : 
                                            item.description === 'admin' ? 'Administrativo' : 
                                            item.description === 'params' ? 'Parametros' : 
                                            item.description === 'trap' ? 'Armadilhas' : 
                                            item.description === 'places' ? 'Locais Conhecidos' : 
                                            item.description === 'especies' ? 'Espécies' : 
                                            item.description === 'especies_risk' ? 'Espécies Ameaçadas' : 
                                            item.description === 'documents' ? 'Documentos' : 
                                            item.description === 'projects' ? 'Projetos' : ""
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={ <Checkbox checked={item.read} onChange={() => helpers.handleUserModule(editUser.module, index, 'read', dispatch)} name="read" className='inputCheckbox'/> } label="Consulta"/>
                                        <FormControlLabel control={ <Checkbox checked={item.write} onChange={() => helpers.handleUserModule(editUser.module, index, 'write', dispatch)} name="write" className='inputCheckbox'/> } label="Edição"/>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={2}>
                            <Button 
                                variant="contained" 
                                className={`btn-primary ${fieldsValidator ? 'btn-disabled' : ''}`} 
                                onClick={editUser._id && editUser._id !== '' ? () => dispatch(helpers.updateUser(editUser, 1, closeModal)) : () => dispatch(helpers.createUser(editUser, 1, closeModal))}
                                disabled={fieldsValidator}
                            >
                                SALVAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    usersReducer: store.usersReducer,
});

export default connect(mapStateToProps)(UserForm);