export const viveirosActionTypes = {

    // Viveiros geral

    HANDLE_INPUT_VIVEIROS: "HANDLE_INPUT_VIVEIROS",
    HANDLE_RADIOS_VIVEIROS: "HANDLE_RADIOS_VIVEIROS",
    HANDLE_VIVEIROS: "HANDLE_VIVEIROS",
    SET_FILTER_DATE_INIT: "SET_FILTER_DATE_INIT",
    SET_FILTER_DATE_END: "SET_FILTER_DATE_END",
    
    SET_VIVEIROS_FILTER: "SET_VIVEIROS_FILTER",
    EDIT_VIVEIROS: "EDIT_VIVEIROS",
    DELETE_VIVEIROS: "DELETE_VIVEIROS",
    UPDATE_VIVEIROS_CANCELED: "UPDATE_VIVEIROS_CANCELED",
    DELETE_VIVEIROS_CANCELED: "DELETE_VIVEIROS_CANCELED",

    // Viveiros RESGATE

    GET_VIVEIROS_RESGATE_REQUEST: "GET_VIVEIROS_RESGATE_REQUEST",
    GET_VIVEIROS_RESGATE_SUCCESS: "GET_VIVEIROS_RESGATE_SUCCESS",
    GET_VIVEIROS_RESGATE_FAILURE: "GET_VIVEIROS_RESGATE_FAILURE",

    CREATE_VIVEIROS_RESGATE_REQUEST: "CREATE_VIVEIROS_RESGATE_REQUEST",
    CREATE_VIVEIROS_RESGATE_SUCCESS: "CREATE_VIVEIROS_RESGATE_SUCCESS",
    CREATE_VIVEIROS_RESGATE_FAILURE: "CREATE_VIVEIROS_RESGATE_FAILURE",

    UPDATE_VIVEIROS_RESGATE_REQUEST: "UPDATE_VIVEIROS_RESGATE_REQUEST",
    UPDATE_VIVEIROS_RESGATE_SUCCESS: "UPDATE_VIVEIROS_RESGATE_SUCCESS",
    UPDATE_VIVEIROS_RESGATE_FAILURE: "UPDATE_VIVEIROS_RESGATE_FAILURE",

    LIST_VIVEIROS_RESGATE_REQUEST: "LIST_VIVEIROS_RESGATE_REQUEST",
    LIST_VIVEIROS_RESGATE_SUCCESS: "LIST_VIVEIROS_RESGATE_SUCCESS",
    LIST_VIVEIROS_RESGATE_FAILURE: "LIST_VIVEIROS_RESGATE_FAILURE",

    EXPORT_VIVEIROS_RESGATE_REQUEST: "EXPORT_VIVEIROS_RESGATE_REQUEST",
    EXPORT_VIVEIROS_RESGATE_SUCCESS: "EXPORT_VIVEIROS_RESGATE_SUCCESS",
    EXPORT_VIVEIROS_RESGATE_FAILURE: "EXPORT_VIVEIROS_RESGATE_FAILURE",

    // Viveiros PRODUCAO

    GET_VIVEIROS_PRODUCAO_REQUEST: "GET_VIVEIROS_PRODUCAO_REQUEST",
    GET_VIVEIROS_PRODUCAO_SUCCESS: "GET_VIVEIROS_PRODUCAO_SUCCESS",
    GET_VIVEIROS_PRODUCAO_FAILURE: "GET_VIVEIROS_PRODUCAO_FAILURE",

    CREATE_VIVEIROS_PRODUCAO_REQUEST: "CREATE_VIVEIROS_PRODUCAO_REQUEST",
    CREATE_VIVEIROS_PRODUCAO_SUCCESS: "CREATE_VIVEIROS_PRODUCAO_SUCCESS",
    CREATE_VIVEIROS_PRODUCAO_FAILURE: "CREATE_VIVEIROS_PRODUCAO_FAILURE",

    UPDATE_VIVEIROS_PRODUCAO_REQUEST: "UPDATE_VIVEIROS_PRODUCAO_REQUEST",
    UPDATE_VIVEIROS_PRODUCAO_SUCCESS: "UPDATE_VIVEIROS_PRODUCAO_SUCCESS",
    UPDATE_VIVEIROS_PRODUCAO_FAILURE: "UPDATE_VIVEIROS_PRODUCAO_FAILURE",

    LIST_VIVEIROS_PRODUCAO_REQUEST: "LIST_VIVEIROS_PRODUCAO_REQUEST",
    LIST_VIVEIROS_PRODUCAO_SUCCESS: "LIST_VIVEIROS_PRODUCAO_SUCCESS",
    LIST_VIVEIROS_PRODUCAO_FAILURE: "LIST_VIVEIROS_PRODUCAO_FAILURE",

    EXPORT_VIVEIROS_PRODUCAO_REQUEST: "EXPORT_VIVEIROS_PRODUCAO_REQUEST",
    EXPORT_VIVEIROS_PRODUCAO_SUCCESS: "EXPORT_VIVEIROS_PRODUCAO_SUCCESS",
    EXPORT_VIVEIROS_PRODUCAO_FAILURE: "EXPORT_VIVEIROS_PRODUCAO_FAILURE",

    
    UPLOAD_VIVEIROS_REQUEST: "UPLOAD_VIVEIROS_REQUEST",
    UPLOAD_VIVEIROS_SUCCESS: "UPLOAD_VIVEIROS_SUCCESS",
    UPLOAD_VIVEIROS_FAILURE: "UPLOAD_VIVEIROS_FAILURE",
};