import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, FormControl, MenuItem, InputLabel, Select } from "@material-ui/core"

import { auditHelpers } from '../../helpers/audit/helpers';
import FilterDate from './filterDate';

class filter extends Component {

  render() {
    const { dispatch, auditReducer, context, projectsReducer } = this.props
    const { audit } = auditReducer
    const { filters } = audit

    return (
      <>
        <div className="filter">
          <Typography variant="h5" component="h5" className="filterTitle">
            Pesquisar
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="user"
                    label="Usuário"
                    className="inputDefault"
                    value={filters.user}
                    onChange={(event) => dispatch(auditHelpers.handleChangeFilter(event, "user", context))}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="email"
                    label="Email"
                    className="inputDefault"
                    value={filters.email}
                    onChange={(event) => dispatch(auditHelpers.handleChangeFilter(event, "email", context))}
                  />
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <TextField
                    id="module"
                    label="Módulo"
                    className="inputDefault"
                    value={filters.module}
                    onChange={(event) => dispatch(auditHelpers.handleChangeFilter(event, "module", context))}
                  />
                </Grid> */}
                <Grid item xs={12} md={4}>
                  <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                      value={filters.type}
                      onChange={(event) => dispatch(auditHelpers.handleChangeFilter(event, "type", context))}
                    >
                      <MenuItem value={'todos'}>Todos</MenuItem>
                      <MenuItem value={'login'}>Acesso</MenuItem>
                      <MenuItem value={'create'}>Criado</MenuItem>
                      <MenuItem value={'update'}>Atualizado</MenuItem>
                      <MenuItem value={'delete'}>Deletado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Divider className="dividerFull" />
                <FilterDate filterProps={filters} dispatch={dispatch} context={context} />
              </>
              <Divider className="dividerFull" />
              <Grid item xs={12} md={9}/>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={
                    () => {
                      if (context === 'audit') {
                        dispatch(auditHelpers.paginateAudit(filters, 1, projectsReducer.selectedProject))
                      } else {
                        // dispatch(auditHelpers.paginateProducao(filters, 1))
                      }
                    }
                  }
                >
                  PESQUISAR
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </>
    )
  }
}

const mapStateToProps = store => ({
  auditReducer: store.auditReducer,
  projectsReducer: store.projectsReducer
});

export default connect(mapStateToProps)(filter);