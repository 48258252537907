import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'
import { documentsHelpers } from '../../helpers/documents/documentsHelpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';




class DocumentsForm extends Component {

    constructor(props){
        super(props)
        this.state = {
        }
    }

    render(){
        initializeFileTypeIcons();
        const { dispatch, documentsReducer, closeModal, paramsReducer, projectsReducer } = this.props
        const { editItem } = documentsReducer

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Projeto</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.project} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'project', 'editDocuments', dispatch)}>
                                    {projectsReducer && projectsReducer.listProjects && projectsReducer.listProjects.map((item, index) => <MenuItem key={index} value={item._id}>{item.projectName}</MenuItem> )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Tipo de documento</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.documentType} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'documentType', 'editDocuments', dispatch)}>
                                    {paramsReducer && paramsReducer.listOptions.documentsType && paramsReducer.listOptions.documentsType.map((item, index) => <MenuItem key={index} value={item._id}>{item.modulo}</MenuItem> )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="gaiaCode" label="Código Gaia" className="inputDefault" value={editItem.gaiaCode} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'gaiaCode', 'editDocuments', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="originalCode" label="Código Original" className="inputDefault" value={editItem.originalCode} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'originalCode', 'editDocuments', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Orgão Emissor</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.orgEmissor} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'orgEmissor', 'editDocuments', dispatch)}>
                                    {paramsReducer && paramsReducer.listOptions.orgEmissor && paramsReducer.listOptions.orgEmissor.map((item, index) => <MenuItem key={index} value={item._id}>{item.name}</MenuItem> )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de Inclusão"
                                    value={editItem.addDate}
                                    onChange={(event) => helpers.handleInput(event, 'addDate', 'editDocuments', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de Validade"
                                    value={editItem.endDate}
                                    onChange={(event) => helpers.handleInput(event, 'endDate', 'editDocuments', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Alerta de término"
                                    value={editItem.endDateAlert}
                                    onChange={(event) => helpers.handleInput(event, 'endDateAlert', 'editDocuments', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={6}>
                            <TextField id="description" label="Descrição" className="inputDefault" value={editItem.description} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'description', 'editDocuments', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="filePlace" label="Local no Arquivo" className="inputDefault" value={editItem.filePlace} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'filePlace', 'editDocuments', dispatch)}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={editItem.status} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'status', 'editDocuments', dispatch)}>
                                    <MenuItem key='ativa' value={true}>{'Ativa'}</MenuItem>
                                    <MenuItem key='inativa' value={false}>{'Inativa'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {editItem.files.length > 0 && 
                            <Grid item xs={12} md={12} >
                                <Typography variant="h6" component="h6" className="filterTitle">Arquivos</Typography>
                            </Grid>
                        }
                        
                        <Box p={2} flexGrow={1}>
                            {editItem.files.length > 0 && 
                                <TableContainer component={Paper} >
                                    <Table size="small" aria-label="a dense table" p={2} m={2}>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Arquivo</TableCell>
                                            <TableCell align="right">Ações</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {editItem.files.map((row, index) => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">
                                                <Icon {...getFileTypeIconProps({extension: row.originalname.match(/\.[0-9a-z]+$/i)[0], size: 16})} />
                                                </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Typography>  {row.originalname}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                                    <Button
                                                        className="btn-innerTable btn-orange-secondary"
                                                        startIcon={<DeleteForeverIcon />}

                                                        onClick={() => dispatch(documentsHelpers.deleteItem({fileId: row, documentId: editItem._id}, this.handleModal))}
                                                    > Excluir
                                                    </Button>
                                                    
                                                    <Button
                                                        className="btn-innerTable btn-orange-secondary"
                                                        startIcon={<OpenInNewIcon />}
                                                    onClick={() => window.open(row.location)}
                                                    >
                                                        Abrir
                                                    </Button>
                                                </ButtonGroup>
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexGrow={1}
                        >
                            <Box padding={10}>
                                <Button variant="contained" 
                                    className="btn-secondary" 
                                    component="label" 
                                    startIcon={<SaveIcon />}
                                    onClick={editItem._id && editItem._id !== '' ? () => dispatch(documentsHelpers.update(editItem, 1, closeModal)) : () => dispatch(documentsHelpers.create(editItem, 1))}> Salvar Ficha </Button>
                            </Box>
                            <Box padding={10}>
                                <Button variant="contained" className="btn-secondary" component="label" startIcon={<PublishIcon />} disabled={!(editItem._id && editItem._id !== '')}> Adicionar Arquivo <input type="file" name="documentFile" hidden onChange={(event) => documentsHelpers.uploadFile(event, editItem._id, dispatch)}/></Button>
                            </Box>
                        </Box>
                       
                        
                        <Divider className="dividerFull"/>

                        
                        
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    documentsReducer: store.documentsReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(DocumentsForm);