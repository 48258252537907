import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const palmitoHandler = { 
	listMatriz, 
	paginateMatriz, 
	createMatriz,
	updateMatriz,
	listTalhao, 
	paginateTalhao, 
	createTalhao,
	updateTalhao,
	listSaida, 
	paginateSaida, 
	createSaida,
	updateSaida
};

function listMatriz() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/palmito/matriz/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateMatriz(params, page, project) {
	const $numeroMatriz = params?.numeroMatriz ? `&numeroMatriz=${params.numeroMatriz}` : ""
	const $obito = params?.obito ? `&obito=${params.obito}` : ""
	const $dataCadastro = params && params.dataCadastro !== null ? `&dataCadastro=${params.dataCadastro}` : ""
	const $project = project ? `&project=${project}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($numeroMatriz)
	.concat($obito)
	.concat($dataCadastro)
	.concat($project)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/palmito/matriz/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createMatriz(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/palmito/matriz").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateMatriz(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/palmito/matriz").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function listTalhao() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/palmito/talhao/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateTalhao(params, page, project) {
	const $numeroTalhao = params?.numeroTalhao ? `&numeroTalhao=${params.numeroTalhao}` : ""
	const $dataCadastro = params && params.dataCadastro !== null ? `&dataCadastro=${params.dataCadastro}` : ""
	const $tipo = params?.tipo  ? `&tipo=${params.tipo}` : ""
	const $tipoAmbiente = params?.tipoAmbiente ? `&tipoAmbiente=${params.tipoAmbiente}` : ""
	const $iluminacao = params?.iluminacao ? `&iluminacao=${params.iluminacao}` : ""
	const $project = project ? `&project=${project}` : ""

	const queryParams = "?page=".concat(page)
	.concat($numeroTalhao)
	.concat($dataCadastro)
	.concat($tipo)
	.concat($tipoAmbiente)
	.concat($iluminacao)
	.concat($project)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/palmito/talhao/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createTalhao(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/palmito/talhao").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateTalhao(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/palmito/talhao").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function listSaida() {
	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/palmito/saida/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateSaida(params, page, project) {
	const $numeroSaida = params?.numeroSaida ? `&numeroSaida=${params.numeroSaida}` : ""
	const $dataSaida = params && params.dataSaida !== null ? `&dataSaida=${params.dataSaida}` : ""
	const $tipo = params?.tipo ? `&tipo=${params.tipo}` : ""
	const $project = project ? `&project=${project}` : ""
	
	const queryParams = "?page=".concat(page)
	.concat($numeroSaida)
	.concat($dataSaida)
	.concat($tipo)
	.concat($project)

	const requestOptions = { method: "GET" , headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/palmito/saida/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createSaida(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/palmito/saida").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateSaida(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/palmito/saida").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}