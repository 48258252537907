import { auditHandler } from '../handlers/auditHandler';
import { auditActionTypes } from '../types/auditActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const auditActions = {
    listAudit,
    paginateAudit
};

function listAudit() {
    return dispatch => {
        dispatch(request());
        auditHandler.listAudit()
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: auditActionTypes.GET_AUDIT_CARD_REQUEST } }
    function success(list) { return { type: auditActionTypes.GET_AUDIT_CARD_SUCCESS, list } }
    function failure(error) { return { type: auditActionTypes.GET_AUDIT_CARD_FAILURE, error } }
}

function paginateAudit(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        auditHandler.paginateAudit(params, page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: auditActionTypes.LIST_AUDIT_CARD_REQUEST } }
    function success(list) { return { type: auditActionTypes.LIST_AUDIT_CARD_SUCCESS, list } }
    function failure(error) { return { type: auditActionTypes.LIST_AUDIT_CARD_FAILURE, error } }
}
