export const faunaActionTypes = {
    LIST_FAUNA_REQUEST: "LIST_FAUNA_REQUEST",
    LIST_FAUNA_SUCCESS: "LIST_FAUNA_SUCCESS",
    LIST_FAUNA_FAILURE: "LIST_FAUNA_FAILURE",
    
    LIST_TELEMETRIA_REQUEST: "LIST_TELEMETRIA_REQUEST",
    LIST_TELEMETRIA_SUCCESS: "LIST_TELEMETRIA_SUCCESS",
    LIST_TELEMETRIA_FAILURE: "LIST_TELEMETRIA_FAILURE",

    EXPORT_FAUNA_REQUEST: "EXPORT_FAUNA_REQUEST",
    EXPORT_FAUNA_SUCCESS: "EXPORT_FAUNA_SUCCESS",
    EXPORT_FAUNA_FAILURE: "EXPORT_FAUNA_FAILURE",

    SET_FAUNA_DATE_INIT: "SET_FAUNA_DATE_INIT",
    SET_FAUNA_DATE_END: "SET_FAUNA_DATE_END",
    SET_TELEMETRIA_DATE_INIT: "SET_TELEMETRIA_DATE_INIT",
    SET_TELEMETRIA_DATE_END: "SET_TELEMETRIA_DATE_END",

    SET_FAUNA_FILTER: "SET_FAUNA_FILTER",
    SET_TELEMETRIA_FILTER: "SET_TELEMETRIA_FILTER",
    SET_PONTO_MONITORAMENTO: "SET_PONTO_MONITORAMENTO",
    SET_FAUNA_CARD: "SET_FAUNA_CARD",
    SET_TELEMETRIA_CARD: "SET_TELEMETRIA_CARD",
    RESET_TELEMETRIA_CARD: "RESET_TELEMETRIA_CARD",
    SET_RECAPTURA_CARD: "SET_RECAPTURA_CARD",
    CLEAR_CARD: "CLEAR_CARD",
    HANDLE_INPUT_FIELDS: "HANDLE_INPUT_FIELDS",
    HANDLE_INPUT_ALLFIELDS: "HANDLE_INPUT_ALLFIELDS",
    HANDLE_PONTOS_FIELDS: "HANDLE_PONTOS_FIELDS",
    HANDLE_TELEMETRIA_FIELDS: "HANDLE_TELEMETRIA_FIELDS",
    HANDLE_TELEMETRIA_MONITORAMENTO_FIELDS: "HANDLE_TELEMETRIA_MONITORAMENTO_FIELDS",
    HANDLE_ESPECIES: "HANDLE_ESPECIES",
    HANDLE_TELEMETRIA_ESPECIES: "HANDLE_TELEMETRIA_ESPECIES",
    FIND_SPECIES_BY_NAME: "FIND_SPECIES_BY_NAME",
    

    CREATE_FAUNA_CARD_REQUEST: "CREATE_FAUNA_CARD_REQUEST",
    CREATE_FAUNA_CARD_SUCCESS: "CREATE_FAUNA_CARD_SUCCESS",
    CREATE_FAUNA_CARD_FAILURE: "CREATE_FAUNA_CARD_FAILURE",
    
    UPDATE_FAUNA_CARD_REQUEST: "UPDATE_FAUNA_CARD_REQUEST",
    UPDATE_FAUNA_CARD_SUCCESS: "UPDATE_FAUNA_CARD_SUCCESS",
    UPDATE_FAUNA_CARD_FAILURE: "UPDATE_FAUNA_CARD_FAILURE",
    
    DELETE_FAUNA_CARD_REQUEST: "DELETE_FAUNA_CARD_REQUEST",
    DELETE_FAUNA_CARD_SUCCESS: "DELETE_FAUNA_CARD_SUCCESS",
    DELETE_FAUNA_CARD_FAILURE: "DELETE_FAUNA_CARD_FAILURE",

    FAUNA_UPLOAD_DOCUMENTS_REQUEST: "FAUNA_UPLOAD_DOCUMENTS_REQUEST",
    FAUNA_UPLOAD_DOCUMENTS_SUCCESS: "FAUNA_UPLOAD_DOCUMENTS_SUCCESS",
    FAUNA_UPLOAD_DOCUMENTS_FAILURE: "FAUNA_UPLOAD_DOCUMENTS_FAILURE",

    FAUNA_DELETE_DOCUMENTS_REQUEST: "FAUNA_DELETE_DOCUMENTS_REQUEST",
    FAUNA_DELETE_DOCUMENTS_SUCCESS: "FAUNA_DELETE_DOCUMENTS_SUCCESS",
    FAUNA_DELETE_DOCUMENTS_FAILURE: "FAUNA_DELETE_DOCUMENTS_FAILURE",
    
    FAUNA_DELETE_IMAGE_REQUEST: "FAUNA_DELETE_IMAGE_REQUEST",
    FAUNA_DELETE_IMAGE_SUCCESS: "FAUNA_DELETE_IMAGE_SUCCESS",
    FAUNA_DELETE_IMAGE_FAILURE: "FAUNA_DELETE_IMAGE_FAILURE",

    SAVE_MONITORAMENTO: "SAVE_MONITORAMENTO",
    EDIT_MONITORAMENTO: "EDIT_MONITORAMENTO",
    DELETE_MONITORAMENTO: "DELETE_MONITORAMENTO",
    RESET_MONITORAMENTO: "RESET_MONITORAMENTO",

    CREATE_TELEMETRIA_REQUEST: "CREATE_TELEMETRIA_REQUEST",
    CREATE_TELEMETRIA_SUCCESS: "CREATE_TELEMETRIA_SUCCESS",
    CREATE_TELEMETRIA_FAILURE: "CREATE_TELEMETRIA_FAILURE",
    UPDATE_TELEMETRIA_REQUEST: "UPDATE_TELEMETRIA_REQUEST",
    UPDATE_TELEMETRIA_SUCCESS: "UPDATE_TELEMETRIA_SUCCESS",
    UPDATE_TELEMETRIA_FAILURE: "UPDATE_TELEMETRIA_FAILURE"
    
};