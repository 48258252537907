import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'
import { placesHelpers} from '../../helpers/places/placesHelpers'

class filter extends Component {

    render(){
        const { dispatch, placesReducer } = this.props
        const { filters } = placesReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="name" label="Código" className="inputDefault" value={filters.name} onChange={(event) => dispatch(helpers.handleChangeFilter(event, "name", "places"))}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('places', true)}>
                                NOVO LOCAL/ÁREA
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(placesHelpers.paginatePlaces(filters, 1))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    placesReducer: store.placesReducer,
});

export default connect(mapStateToProps)(filter);