import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Input, Select, MenuItem, Checkbox, Divider, ListItemText, FormHelperText } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import { helpers } from '../../helpers/helpers'

class Form extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showPassword: false
        }
    }

    handleClickShowPassword = () => {
        const { showPassword } = this.state
        this.setState({ showPassword: !showPassword })
    };

    render() {
        const { dispatch, projectsReducer, closeModal, usersReducer } = this.props
        const { editProject, options } = projectsReducer
        const { listUsers } = usersReducer
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250
                }
            }
        };

        const fieldsValidator = !(
            editProject.projectName &&
            editProject.projectNick &&
            editProject.admin &&
            editProject.endDate &&
            editProject.endDateAlert &&
            (editProject.users && editProject.users.length > 0) &&
            (editProject.status !== undefined && editProject.status !== null)
        )

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editProject._id && editProject._id !== '' ? 'Editar' : 'Novo usuário'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="name" label="Nome do projeto" className="inputDefault" value={editProject.projectName} required autoComplete='off' onChange={(event) => helpers.handleInput(event, 'projectName', 'editProject', dispatch)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="nick" label="Sigla" className="inputDefault" value={editProject.projectNick} required autoComplete='off' onChange={(event) => helpers.handleInput(event, 'projectNick', 'editProject', dispatch)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault" required>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={editProject.status} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'status', 'editProject', dispatch)}>
                                    <MenuItem key={`status-false`} value={false}>Inativo</MenuItem>
                                    <MenuItem key={`status-true`} value={true}>Ativo</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault" required error={editProject.admin === ''}>
                                <InputLabel id="demo-simple-select-label">Administrador</InputLabel>
                                <Select
                                    labelId="admin-label"
                                    id="admin-select"
                                    value={editProject.admin}
                                    autoComplete='off'
                                    onChange={(event) => helpers.handleInput(event, 'admin', 'editProject', dispatch)}
                                >
                                    {listUsers.users.map((user, index) => (
                                        <MenuItem key={`nome-${index}`} value={user._id}>{user.nome}</MenuItem>
                                    ))}
                                </Select>
                                {editProject.admin === '' && <FormHelperText>Campo obrigatório</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data de término"
                                    required
                                    value={editProject.endDate}
                                    onChange={(event) => helpers.handleInput(new Date(event), 'endDate', 'editProject', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    className="inputDefault dateInput"
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Alerta de término"
                                    required
                                    value={editProject.endDateAlert}
                                    onChange={(event) => helpers.handleInput(new Date(event), 'endDateAlert', 'editProject', dispatch)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar data de alerta',
                                    }}
                                    invalidDateMessage={'Data inválida'}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault" required error={editProject?.users.length === 0}>
                                <InputLabel id="mutiple-checkbox-label">Usuários habilitados</InputLabel>
                                <Select
                                    labelId="mutiple-checkbox-label"
                                    id="mutiple-checkbox"
                                    multiple
                                    value={editProject.users}
                                    onChange={(event) => helpers.handleInput(event, 'users', 'editProject', dispatch)}
                                    input={<Input />}
                                    renderValue={(selected) => {
                                        const users = selected.map(item => listUsers.users.find(user => user._id === item).nome.trim())
                                        return users.join(', ')
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {listUsers.users.map((user, index) => (
                                        <MenuItem key={`users-${index}`} value={user._id}>
                                            <Checkbox checked={editProject.users.indexOf(user._id) > -1} />
                                            <ListItemText primary={user.nome} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {editProject?.users.length === 0 && <FormHelperText>Campo obrigatório</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="description" label="Descrição" className="inputDefault" value={editProject.description} autoComplete='off' onChange={(event) => helpers.handleInput(event, 'description', 'editProject', dispatch)} />
                        </Grid>
                        <Divider className="dividerFull" />
                        {editProject.modules &&
                            <Grid item xs={12} md={12} >
                                <Typography variant="h6" component="h6" className="filterTitle">Módulos</Typography>
                            </Grid>
                        }
                        {editProject.modules && editProject.modules.map((item, index) => {
                            return (
                                <Grid item xs={12} md={4} key={`modules-${index}`}>
                                    <FormControl className="inputDefault">
                                        <InputLabel id="demo-simple-select-label">
                                            {
                                                item.description === 'fauna' ? 'Fauna' :
                                                    item.description === 'flora' ? 'Flora' :
                                                        item.description === 'meio-fisico' ? 'Meio Físico' : ''
                                            }
                                        </InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={item.status} autoComplete='off' onChange={(event) => helpers.handleProjectModule(editProject.modules, index, event.target.value, dispatch)}>
                                            <MenuItem key={`${item.description}-${index}`} value={false}>Inativo</MenuItem>
                                            <MenuItem key={`${item.description}-${index}`} value={true}>Ativo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )
                        })}
                        <Divider className="dividerFull" />
                        <Grid item xs={12} md={12} >
                            <Typography variant="h6" component="h6" className="filterTitle">Dados Geográficos</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Projeção</InputLabel>
                                <Select labelId="admin-label" id="admin-select" value={editProject.geoData.projecao} autoComplete='off' onChange={(event) => helpers.handleProjectGeoData('projecao', event.target.value, dispatch)}>
                                    {options.projecao.map((val, index) => (
                                        <MenuItem key={`projecao-${index}`} value={val}>{val}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Divider className="dividerFull" />
                        <Grid item xs={12} md={6}>
                            <TextField id="coodMinX" label="Coordenada X Min" className="inputDefault" value={editProject.geoData.coodMinX} autoComplete='off' onChange={(event) => helpers.handleProjectGeoData('coodMinX', event.target.value, dispatch)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="coodMaxX" label="Coordenada X Max" className="inputDefault" value={editProject.geoData.coodMaxX} autoComplete='off' onChange={(event) => helpers.handleProjectGeoData('coodMaxX', event.target.value, dispatch)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="coodMinY" label="Coordenada Y Min" className="inputDefault" value={editProject.geoData.coodMinY} autoComplete='off' onChange={(event) => helpers.handleProjectGeoData('coodMinY', event.target.value, dispatch)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="coodMaxY" label="Coordenada Y Max" className="inputDefault" value={editProject.geoData.coodMaxY} autoComplete='off' onChange={(event) => helpers.handleProjectGeoData('coodMaxY', event.target.value, dispatch)} />
                        </Grid>

                        <Divider className="dividerFull" />
                        <Grid item xs={12} md={2}>
                            <Button
                                variant="contained"
                                className={`btn-primary ${fieldsValidator ? 'btn-disabled' : ''}`}
                                onClick={editProject._id && editProject._id !== '' ? () => dispatch(helpers.updateProject(editProject, 1, closeModal)) : () => dispatch(helpers.createProject(editProject, 1, closeModal))}
                                disabled={fieldsValidator}
                            >
                                SALVAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    projectsReducer: store.projectsReducer,
    usersReducer: store.usersReducer,
});

export default connect(mapStateToProps)(Form);