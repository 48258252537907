import { areaMonitoramentoHandler } from '../handlers/areaMonitoramentoHandler';
import { areaMonitoramentoActionTypes } from '../types/areaMonitoramentoActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const areaMonitoramentoActions = {
    listAreaMonitoramento,
    paginateAreaMonitoramento,
    createAreaMonitoramento,
    updateAreaMonitoramento,
    deleteAreaMonitoramento
};

function listAreaMonitoramento() {
    return dispatch => {
        dispatch(request());
        areaMonitoramentoHandler.listAreaMonitoramento()
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
            });
    };
    function request() { return { type: areaMonitoramentoActionTypes.GET_AREA_MONITORAMENTO_CARD_REQUEST } }
    function success(list) { return { type: areaMonitoramentoActionTypes.GET_AREA_MONITORAMENTO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaMonitoramentoActionTypes.GET_AREA_MONITORAMENTO_CARD_FAILURE, error } }
}

function paginateAreaMonitoramento(params, page, projectId) {
    return dispatch => {
        dispatch(request());
        areaMonitoramentoHandler.paginateAreaMonitoramento(params, page, projectId)
            .then(response => {
                dispatch(success(response));
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_REQUEST } }
    function success(list) { return { type: areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_FAILURE, error } }
}

function createAreaMonitoramento(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        areaMonitoramentoHandler.createAreaMonitoramento(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: areaMonitoramentoActionTypes.CREATE_AREA_MONITORAMENTO_CARD_REQUEST } }
    function success(list) { return { type: areaMonitoramentoActionTypes.CREATE_AREA_MONITORAMENTO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaMonitoramentoActionTypes.CREATE_AREA_MONITORAMENTO_CARD_FAILURE, error } }
}

function updateAreaMonitoramento(params, page, callback, projectId, filters) {
    return dispatch => {
        dispatch(request());
        areaMonitoramentoHandler.updateAreaMonitoramento(params, page, projectId, filters)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: areaMonitoramentoActionTypes.UPDATE_AREA_MONITORAMENTO_CARD_REQUEST } }
    function success(list) { return { type: areaMonitoramentoActionTypes.UPDATE_AREA_MONITORAMENTO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaMonitoramentoActionTypes.UPDATE_AREA_MONITORAMENTO_CARD_FAILURE, error } }
}

function deleteAreaMonitoramento(params, page, projectId, cardId, callback) {
    return dispatch => {
        dispatch(request());
        areaMonitoramentoHandler.deleteAreaMonitoramento(params, page, projectId, cardId)
            .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento excluido com sucesso'))
                if (callback) { callback('project', true) }
            },
                error => {
                    apiHelpers.handleError(
                        dispatch,
                        error,
                        failure(error),
                        true
                    );
                    dispatch(alertActions.error(error.errorMsg));
                }
            ).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            });
    };
    function request() { return { type: areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_REQUEST } }
    function success(list) { return { type: areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_SUCCESS, list } }
    function failure(error) { return { type: areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_FAILURE, error } }
}
