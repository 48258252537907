import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const projectsHandler = { 
	getProjects, 
	paginateProjects, 
	createProject,
	updateProject
};

function getProjects(userId) {

	const queryParams = "?userId=".concat(userId)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("projects/list").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateProjects(params, page) {
	const $name = params && params.projectName ? `&projectName=${params.projectName}` : ""
	const $nick = params && params.projectNick ? `&projectNick=${params.projectNick}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($name)
	.concat($nick)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("projects/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}


function createProject(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("projects/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateProject(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("projects/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}