import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";

import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import { especiesHelpers } from '../../helpers/especies/especiesHelpers';
import Autocomplete from '@material-ui/lab/Autocomplete';


const AccordionIdent = ({ selectedCard, selectProject, listEspecies, classTypes, dispatch, loggedUser, validateForm, projects }) => {
  const classType = classTypes && classTypes.filter(classe => classe._id === selectedCard.classe)
  const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write


  const handleInputWithUpdate = (event, target) => {
    setValue(value);

    faunaHelpers.handleEspecies(event, target, listEspecies, selectedCard, dispatch)
    handleCardNumber(selectedCard.classe ? selectedCard.classe : false)
  }

  const handleCardNumber = (selectedClass) => faunaHelpers.handleCardNumber(selectedCard.numeroFicha, projects, selectedCard, classTypes, dispatch, false, selectedClass);
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    setValue(selectedCard.nomeCientifico)
  }, [selectedCard.nomeCientifico])

  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <TextField
          id="classe"
          label="Classe"
          disabled={true}
          className="inputDefault"
          value={classType.length > 0 ? classType[0].classe : selectedCard.classe}
          onChange={(event, newValue) => handleInputWithUpdate(event, 'classe', newValue)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField id="ordem" label="Ordem" disabled={true} className="inputDefault" value={selectedCard.ordem} onChange={(event) => faunaHelpers.handleInput(event, 'ordem', dispatch)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField id="familia" label="Família" disabled={true} className="inputDefault" value={selectedCard.familia} onChange={(event) => faunaHelpers.handleInput(event, 'familia', dispatch)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField id="subFamilia" label="Subfamília" disabled={true} className="inputDefault" value={selectedCard.subFamilia} onChange={(event) => faunaHelpers.handleInput(event, 'subFamilia', dispatch)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField id="genero" label="Gênero" disabled={true} className="inputDefault" value={selectedCard.genero} onChange={(event) => faunaHelpers.handleInput(event, 'genero', dispatch)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField id="especie" label="Espécie" disabled={true} className="inputDefault" value={selectedCard.especie} onChange={(event) => faunaHelpers.handleInput(event, 'especie', dispatch)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField id="subEspecie" label="Subespécie" disabled={true} className="inputDefault" value={selectedCard.subEspecie} onChange={(event) => faunaHelpers.handleInput(event, 'subEspecie', dispatch)} />
      </Grid>
      <Grid item xs={12} md={4}>


        <FormControl className="inputDefault" required error={validateForm && selectedCard.nomeCientifico === ''} >
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable

            options={listEspecies.map((option) => option.nomeCientifico)}
            onChange={(event, newValue) => handleInputWithUpdate({
              target: {
                value: newValue,
              }
            }, 'nomeCientifico')}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            value={value}
            inputValue={inputValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nome Científico"
                
                
                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            )}
          />
          {validateForm && selectedCard.nomeCientifico === '' && <FormHelperText>Campo obrigatório</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField id="nomePopular" label="Nome popular" disabled={true} className="inputDefault" value={selectedCard.nomePopular} onChange={(event) => faunaHelpers.handleInput(event, 'nomePopular', dispatch)} />
      </Grid>
      {selectedCard.classe && selectedCard.classe === "Peixes" &&
        <Grid item xs={12} md={4}>
          <TextField id="quantidade" label="Quantidade" disabled={!writeModuleVerify} className="inputDefault" value={selectedCard.quantidade} onChange={(event) => faunaHelpers.handleInput(event, 'quantidade', dispatch)} />
        </Grid>
      }
      {selectedCard.classe && selectedCard.classe === "Invertebrados" &&
        <Grid item xs={12} md={4}>
          <TextField id="qntCapturada" label="Quantidade capturada" disabled={!writeModuleVerify} className="inputDefault" value={selectedCard.qntCapturada} onChange={(event) => faunaHelpers.handleInput(event, 'qntCapturada', dispatch)} />
        </Grid>
      }
    </Grid>
  )
}

export default AccordionIdent