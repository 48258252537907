import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider } from "@material-ui/core"

import FilterDate from './filterDate'
import { toraHelpers } from '../../../helpers/flora/tora/helpers';

class filter extends Component {

  render() {
    const { dispatch, toraReducer, context, projectsReducer } = this.props
    const { tora } = toraReducer
    const { filters } = tora

    return (
      <>
        <div className="filter">
          <Typography variant="h5" component="h5" className="filterTitle">
            Pesquisar
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <>
                {context === 'tora' &&
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="numeroFicha"
                      label="Número"
                      className="inputDefault"
                      value={filters.numeroFicha}
                      onChange={(event) => dispatch(toraHelpers.handleChangeFilter(event, "numeroFicha", context))}
                    />
                  </Grid>
                }
                <Grid item xs={12} md={4}>
                  <TextField
                    id="areaDemarcada"
                    label="Área demarcada"
                    className="inputDefault"
                    value={filters.areaDemarcada}
                    onChange={(event) => dispatch(toraHelpers.handleChangeFilter(event, "areaDemarcada", context))}
                  />
                </Grid>
                <Divider className="dividerFull" />
                <FilterDate filterProps={filters} dispatch={dispatch} context={context} />
              </>
              <Divider className="dividerFull" />
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => this.props.handleModal(context, true)}
                >
                  {context === 'tora' ? 'ADICIONAR TORA' : 'ADICIONAR LENHA'}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => dispatch(toraHelpers.toraSaidas())}
                >
                  SAÍDAS
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  download
                  onClick={() => dispatch(toraHelpers.exportData(filters, projectsReducer.selectedProject))}>
                  EXPORTAR
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={
                    () => {
                      if (context === 'tora') {
                        dispatch(toraHelpers.paginateTora(filters, 1, projectsReducer.selectedProject))
                      } else {
                        // dispatch(toraHelpers.paginateProducao(filters, 1))
                      }
                    }
                  }
                >
                  PESQUISAR
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        {/* <div id="qntCounter">
          <p>
            {`Quantidade de exemplares no viveiro: `}
            <span>{documents.qntCounter ? documents.qntCounter : '0' }</span>
          </p>
        </div> */}
      </>
    )
  }
}

const mapStateToProps = store => ({
  toraReducer: store.toraReducer,
  paramsReducer: store.paramsReducer,
  projectsReducer: store.projectsReducer
});

export default connect(mapStateToProps)(filter);