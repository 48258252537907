import React, { useState } from 'react';
import { Divider, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, Typography  } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import moment from 'moment';

const AccordionOcorrencia = ({ selectedCard, dispatch, params, places, loggedUser, validateForm }) => {
    const environmentFilter = params && params.environmentType && params.environmentType.filter(environment => environment._id === selectedCard.ambiente);
    const validEnvironment = environmentFilter && environmentFilter.length <= 0;
    const placeFilter = places && places.filter(place => place._id === selectedCard.localObs);
    const validPlace = placeFilter && placeFilter.length <= 0;
    const writeModuleVerify = loggedUser?.module.filter(item => item.description === "fauna")[0].write

    const [localManual, setlocalManual] = useState(false);

    return (
        <Grid container spacing={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                        className="inputDefault dateInput"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data da observação"
                        value={selectedCard.formatedDate && selectedCard.formatedDate.dataObs ? selectedCard.formatedDate.dataObs : null}
                        onChange={(event) => faunaHelpers.handleDate(event, 'dataObs', selectedCard.formatedDate, dispatch)}
                        KeyboardButtonProps={{
                            'aria-label': 'Alterar data',
                        }}
                        invalidDateMessage={'Data inválida'}
                        disabled={!writeModuleVerify}
                        error={validateForm && !selectedCard.formatedDate.dataObs}
                        helperText={validateForm && !selectedCard.formatedDate.dataObs ? 'Campo obrigatório' : ''}
                        required
                    />
                </Grid>
            </MuiPickersUtilsProvider>

            <Grid item xs={12} md={4}>
                <TextField id="manipulador" disabled={!writeModuleVerify} label="Manipulador" className="inputDefault" value={selectedCard.manipulador} onChange={(event) => faunaHelpers.handleInput(event, 'manipulador', dispatch)} />
            </Grid>

            <Grid item xs={12} md={4}>
                <FormControl className="inputDefault">
                    <InputLabel id="demo-simple-select-label">Ambiente</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={selectedCard.ambiente}
                        onChange={(event) => faunaHelpers.handleInput(event, 'ambiente', dispatch)}
                        disabled={!writeModuleVerify}
                    >
                        <MenuItem value="--">--</MenuItem>
                        {validEnvironment && selectedCard.ambiente !== '--' && selectedCard.ambiente !== '' &&
                            <MenuItem value={selectedCard.ambiente}>{selectedCard.ambiente}</MenuItem>
                        }
                        {params && params.environmentType.map((environment, index) => {
                            return <MenuItem key={index} value={environment._id}>{environment.environmentType}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Divider className="dividerFull" />

            <Grid item xs={12} md={4}>
                <Checkbox
                    className="faunaCheckBox"
                    checked={localManual}
                    onChange={() => setlocalManual(!localManual)}
                />
                <Typography variant="subtitle1" component="h2" className="checkBoxLabel">
                    Deseja inserir o local manualmente?
                </Typography>
            </Grid>
            {localManual &&
                <Grid item xs={12} md={4}>
                    <TextField
                        id="localObsManual"
                        label="Local da Observação"
                        className="inputDefault" disabled={!writeModuleVerify}
                        value={selectedCard.localObs}
                        onChange={(event) => faunaHelpers.handleInput(event, 'localObs', dispatch)}
                    />
                </Grid>
            }
            {!localManual &&
                <Grid item xs={12} md={4}>
                    <FormControl className="inputDefault">
                        <InputLabel id="demo-simple-select-label">Local da Observação</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                            value={selectedCard.localObs}
                            onChange={(event) => faunaHelpers.handlePlace(event, 'localObs', places, selectedCard, dispatch, 'pontoObs')}
                            disabled={!writeModuleVerify}
                        >
                            <MenuItem value="--">--</MenuItem>
                            {validPlace && selectedCard.localObs !== '--' && selectedCard.localObs !== '' &&
                                <MenuItem value={selectedCard.localObs}>{selectedCard.localObs}</MenuItem>
                            }
                            {places && places.map((place, index) => {
                                return <MenuItem key={index} value={place._id}>{place.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            }
            <Grid item xs={12} md={4}>
                <TextField
                    id="pontoObsCoordX"
                    disabled={!writeModuleVerify || selectedCard.localObs}
                    label="Coordenada X"
                    className="inputDefault"
                    value={selectedCard.pontoObs.coordX}
                    onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordX', 'pontoObs', selectedCard.pontoObs, dispatch)}
                    error={validateForm && !selectedCard.pontoObs.coordX}
                    helperText={validateForm && !selectedCard.pontoObs.coordX ? 'Campo obrigatório' : ''}
                    required
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    id="pontoObsCoordY"
                    disabled={!writeModuleVerify || selectedCard.localObs}
                    label="Coordenada Y"
                    className="inputDefault"
                    value={selectedCard.pontoObs.coordY}
                    onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordY', 'pontoObs', selectedCard.pontoObs, dispatch)}
                    error={validateForm && !selectedCard.pontoObs.coordY}
                    helperText={validateForm && !selectedCard.pontoObs.coordY ? 'Campo obrigatório' : ''}
                    required
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField id="pontoObsCoordProj" disabled={!writeModuleVerify || selectedCard.localObs} label="Projeção" className="inputDefault" value={selectedCard.pontoObs.coordProj} onChange={(event) => faunaHelpers.handleCoordinates(event, 'coordProj', 'pontoObs', selectedCard.pontoObs, dispatch)} />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField id="epe" label="EPE" disabled={!writeModuleVerify || selectedCard.localObs} className="inputDefault" value={selectedCard.epe} onChange={(event) => faunaHelpers.handleInput(event, 'epe', dispatch)} />
            </Grid>

            <Divider className="dividerFull" />

            <Grid item xs={12} md={12}>
                <TextField id="obsAdicionaisObs" disabled={!writeModuleVerify} label="Observações adicionais" className="inputDefault" value={selectedCard.obsAdicionaisObs} multiline rows={1} rowsMax={8} onChange={(event) => faunaHelpers.handleInput(event, 'obsAdicionaisObs', dispatch)} />
            </Grid>
        </Grid>
    )
}

export default AccordionOcorrencia