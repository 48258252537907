import { areaMonitoramentoActionTypes } from "../types/areaMonitoramentoActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: {
    areaMonitoramento: {},
  },
  areaMonitoramento: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {
      areaDemarcada: '',
      tipo: '',
      initDate: moment().subtract(7, 'days').format(),
      endDate: moment().format()
    },
    editItem: {
      dataCriacao: moment().format(),
      codigoDaArea: '',
      tipo: 'Arbóreas',
      area: '',
      coord: [
        {
          coordenadaX: "",
          coordenadaY: "",
          EPE: "",
        },
        {
          coordenadaX: "",
          coordenadaY: "",
          EPE: "",
        },
        {
          coordenadaX: "",
          coordenadaY: "",
          EPE: "",
        }
      ],
      individuos: []
    },
    newItem: {
      numero: '',
      areaDemarcada: '',
      nomeCientifico: '',
      nomePopular: '',
    },
    newMonitoramento: {
      dataDeMonitoramento: moment().format(),
      DPA: '',
      altura: '',
      morte: false
    },
    monitoramentos: {
      _id: '',
      numero: '',
      areaDemarcada: '',
      nomeCientifico: '',
      nomePopular: '',
      monitoramento: []
    }
  },
};

export const areaMonitoramentoReducer = (state = initialState, action) => {
  switch (action.type) {
    case areaMonitoramentoActionTypes.GET_AREA_MONITORAMENTO_CARD_REQUEST:
    case areaMonitoramentoActionTypes.GET_AREA_MONITORAMENTO_CARD_FAILURE:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          areaMonitoramento: {}
        }
      };
    case areaMonitoramentoActionTypes.GET_AREA_MONITORAMENTO_CARD_SUCCESS:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          areaMonitoramento: action.list
        }
      };
    case areaMonitoramentoActionTypes.UPDATE_AREA_MONITORAMENTO_CARD_REQUEST:
    case areaMonitoramentoActionTypes.CREATE_AREA_MONITORAMENTO_CARD_REQUEST:
    case areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_REQUEST:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          loading: true
        }
      };
    case areaMonitoramentoActionTypes.CREATE_AREA_MONITORAMENTO_CARD_SUCCESS:
    case areaMonitoramentoActionTypes.UPDATE_AREA_MONITORAMENTO_CARD_SUCCESS:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          editItem: action.list,
          loading: false
        }
      };
    case areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_SUCCESS:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.areaMonitoramento.editItem,
          loading: false
        }
      };
    case areaMonitoramentoActionTypes.UPDATE_AREA_MONITORAMENTO_CARD_FAILURE:
    case areaMonitoramentoActionTypes.CREATE_AREA_MONITORAMENTO_CARD_FAILURE:
    case areaMonitoramentoActionTypes.LIST_AREA_MONITORAMENTO_CARD_FAILURE:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          loading: false
        }
      };

    case areaMonitoramentoActionTypes.SET_AREA_MONITORAMENTO_FILTER:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          filters: { ...state.areaMonitoramento.filters, [action.target]: action.value }
        }
      };

    case areaMonitoramentoActionTypes.SET_AREA_MONITORAMENTO_FILTER_DATE_INIT:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          filters: {
            ...state.areaMonitoramento.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case areaMonitoramentoActionTypes.SET_AREA_MONITORAMENTO_FILTER_DATE_END:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          filters: {
            ...state.areaMonitoramento.filters,
            endDate: moment(action.date).format()
          }
        }
      };

    case areaMonitoramentoActionTypes.EDIT_AREA_MONITORAMENTO_CARD:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          editItem: action.item
        }
      };
    case areaMonitoramentoActionTypes.UPDATE_AREA_MONITORAMENTO_INDIVIDUOS:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          editItem: {
            ...state.areaMonitoramento.editItem,
            individuos: action.value
          }
        }
      };

    case areaMonitoramentoActionTypes.UPDATE_AREA_MONITORAMENTO_CARD_CANCELED:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          editItem: initialState.areaMonitoramento.editItem,
          loading: false
        }
      };
    case areaMonitoramentoActionTypes.HANDLE_AREA_MONITORAMENTO_FIELDS:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          editItem: {
            ...state.areaMonitoramento.editItem,
            [action.target]: action.value
          }
        }
      };
    case areaMonitoramentoActionTypes.HANDLE_NEW_ITEM_FIELDS:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          newItem: {
            ...state.areaMonitoramento.newItem,
            [action.target]: action.value
          }
        }
      };
    case areaMonitoramentoActionTypes.HANDLE_NEW_MONITORAMENTO_FIELDS:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          newMonitoramento: {
            ...state.areaMonitoramento.newMonitoramento,
            [action.target]: action.value
          }
        }
      };
    case areaMonitoramentoActionTypes.HANDLE_NEW_MONITORAMENTO:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          monitoramentos: {
            ...state.areaMonitoramento.monitoramentos,
            monitoramento: action.value
          }
        }
      };
    case areaMonitoramentoActionTypes.EDIT_ITEM:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          newItem: action.value
        }
      };

    case areaMonitoramentoActionTypes.EDIT_MONITORAMENTO:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          newMonitoramento: action.value
        }
      };
    case areaMonitoramentoActionTypes.ADD_MONITORAMENTO:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          monitoramentos: action.value
        }
      };
    case areaMonitoramentoActionTypes.CLEAR_NEW_ITEM_FIELDS:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          newItem: initialState.areaMonitoramento.newItem
        }
      };
    case areaMonitoramentoActionTypes.CLEAR_MONITORAMENTO:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          newMonitoramento: initialState.areaMonitoramento.newMonitoramento
        }
      };
    case areaMonitoramentoActionTypes.HANDLE_AREA_MONITORAMENTO_TOTAL_VOLUME:
      return {
        ...state,
        areaMonitoramento: {
          ...state.areaMonitoramento,
          editItem: {
            ...state.areaMonitoramento.editItem,
            [action.target]: action.value
          }
        }
      };


    default:
      return state;
  }
};