import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from "../Components/header"
import { TextField, Button, Grid, Typography } from "@material-ui/core"
import { Alert } from '@material-ui/lab'
import { helpers } from "../helpers/helpers"
import { loginActions } from "../actions/loginActions"
import { history } from "../helpers/history"

class Login extends Component {

    login = (params) => {
        const { dispatch } = this.props;
        dispatch(loginActions.login(params));
    }

    componentDidMount(){
        helpers.resetPermissionCookie();
        const { dispatch } = this.props
        dispatch(helpers.saveLocation(history.location.pathname));
        
    }
    
    
    render() {
        const { dispatch, auth, alert } = this.props;
        return (
            <div className="mainContainer">
                <Header/>
                <div className="loginBox">
                    {alert && alert.type && alert.message && 
                        <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
                    }
                    <Typography variant="h4" component="h4" className="loginTitle">
                        ENTRAR
                    </Typography>
                    <form noValidate autoComplete="off">
                        <Grid item xs={12}>
                            <TextField id="user" required label="Usuário" autoComplete="username" className="inputDefault" value={auth.user} onChange={(event) => helpers.handleInput(event, 'user', 'login', dispatch)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="pass" required type="password" autoComplete="current-password" label="Senha" className="inputDefault" onChange={(event) => helpers.handleInput(event, 'pass', 'login', dispatch)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" className={`${auth && (auth.pass && auth.pass !== "") && (auth.user && auth.user !== "") ? "" : "btn-disabled"} btn-primary`} disabled={auth && (auth.pass && auth.pass !== "") && (auth.user && auth.user !== "") ? false : true} onClick={() => this.login(auth)}>
                                ACESSAR CONTA
                            </Button>
                        </Grid>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    auth: store.loginReducer.auth,
    alert: store.alertReducer
  });
  
export default connect(mapStateToProps)(Login);