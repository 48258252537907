import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const trapsHandler = { 
	list, 
	paginate, 
	create,
	update
};

function list(projectId) {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat(`traps/list?projectId=${projectId}`), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginate(params, projectId, page) {
	const $nomeCientifico = params && params.nomeCientifico ? `&nomeCientifico=${params.nomeCientifico}` : ""
	const $nomePopular = params && params.nomePopular ? `&nomePopular=${params.nomePopular}` : ""
	const $classe = params && params.classe ? `&classe=${params.classe}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat(`&projectId=${projectId}`)
	.concat($nomeCientifico)
	.concat($nomePopular)
	.concat($classe)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("traps/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}


function create(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("traps/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function update(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("traps/").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}