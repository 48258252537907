import { especiesActionTypes } from "../types/especiesActionTypes";

const initialState = {
  listEspecies: [],
  loading: false,
  especies: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0
  },
  filters: {
    name: ''
  },
  editItem: {
    classe: "", 
    ordem: "", 
    familia: "", 
    subFamilia: "", 
    genero: "", 
    especie: "", 
    subEspecie: "",
    nomeCientifico: "",
    nomePopular: "",
    status: true,
  }
};

export const especiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case especiesActionTypes.GET_ESPECIES_REQUEST:
      return { ...state, listEspecies: state.listEspecies, loading: true };
    case especiesActionTypes.GET_ESPECIES_SUCCESS:
      return { ...state, listEspecies: action.places, loading: false };
    case especiesActionTypes.GET_ESPECIES_FAILURE:
      return { ...state, listEspecies: state.listEspecies, loading: false };

    case especiesActionTypes.CREATE_ESPECIES_REQUEST:
    case especiesActionTypes.UPDATE_ESPECIES_REQUEST:
    case especiesActionTypes.LIST_ESPECIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case especiesActionTypes.CREATE_ESPECIES_SUCCESS:
    case especiesActionTypes.UPDATE_ESPECIES_SUCCESS:
    case especiesActionTypes.LIST_ESPECIES_SUCCESS:
      return {
        ...state,
        especies: {list: action.list.docs, total: action.list.total, limit: action.list.limit, page: Number(action.list.page), pagesTotal: action.list.pages},
        editItem: initialState.editItem,
        loading: false
      };
    case especiesActionTypes.UPDATE_ESPECIES_CANCELED:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case especiesActionTypes.CREATE_ESPECIES_FAILURE:
    case especiesActionTypes.UPDATE_ESPECIES_FAILURE:
    case especiesActionTypes.LIST_ESPECIES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case especiesActionTypes.EDIT_ESPECIES:
      return { ...state, editItem: action.places };
    case especiesActionTypes.HANDLE_ESPECIES:
    case especiesActionTypes.HANDLE_RADIOS_ESPECIES:
    case especiesActionTypes.HANDLE_INPUT_ESPECIES:
      return {
        ...state,
        editItem: {...state.editItem, [action.target]: action.value}
      };
    case especiesActionTypes.SET_ESPECIES_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
    default:
      return state;
  }
};