import React, { Component } from 'react';
import { Router, Switch, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { List, Drawer, ListItem, CssBaseline, FormControl, InputLabel, Select, MenuItem, ListItemText, Collapse } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faPaw, faSeedling, faUserCog, faCaretDown, faCaretUp, faTasks, faMapMarkedAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { history } from './helpers/history'
import { helpers } from './helpers/helpers'

import Login from './Containers/login'
import Users from './Containers/users'
import Projects from './Containers/projects'
import Places from './Containers/places'
import InstLista from './Containers/instListaEspecies'
import Especies from './Containers/especies'
import Traps from './Containers/traps'
import Documents from './Containers/documents'
import Fauna from './Containers/fauna'
import Radiotelemetria from './Containers/fauna/radio/radiotelemetria'
import TelemetriaDetails from './Containers/fauna/radio/detalhes'
import FaunaDetails from './Containers/faunaDetails'
import Parameters from './Containers/parameters'
import PalmitoMatriz from './Containers/flora/palmito/matriz'
import PalmitoTalhao from './Containers/flora/palmito/talhao'
import PalmitoSaida from './Containers/flora/palmito/saida'
import ViveirosResgate from './Containers/flora/viveiros/resgate'
import ViveirosProducao from './Containers/flora/viveiros/producao'
import Tora from './Containers/flora/tora/tora'
import Lenha from './Containers/flora/lenha/lenha'
import Raizes from './Containers/flora/raizes/raizes'
import FloraEspecies from './Containers/flora/especies/flora_especies'
import Forbidden from './Containers/forbidden'
import Loading from './Components/loading'


import './App.scss'
import ToraSaida from './Containers/flora/tora/toraSaida';
import RaizesSaida from './Containers/flora/raizes/raizesSaida';
import LenhaSaida from './Containers/flora/lenha/lenhaSaida';
import AreaPlantio from './Containers/flora/areaPlantio/areaPlantio';
import AreaPlantioDetalhes from './Containers/flora/areaPlantio/detalhes';
import AreaMonitoramento from './Containers/flora/areaMonitoramento/areaMonitoramento';
import AreaMonitoramentoDetalhes from './Containers/flora/areaMonitoramento/detalhes';
import Audit from './Containers/audit/audit';
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: {
        flora: false,
        adm: false
      }
    };
  }
  componentDidUpdate() {
    // const { dispatch } = this.props;
    // const pathName = history.location.pathname;
    // const token = helpers.getToken();
    helpers.checkPermissions();
    helpers.hasValidCookie()

  }
  componentDidMount() {
    const { dispatch } = this.props;
    const token = helpers.getToken();


    dispatch(helpers.setCurrentProject())
    if (token !== null) dispatch(helpers.getProjects(token))
    dispatch(helpers.userVerify(token))
  }

  handleClick = (myItem) => {
    const { open } = this.state
    this.setState({ open: { ...open, [myItem]: !open[myItem] } })
  };

  render() {
    const { location, projects, dispatch } = this.props
    const { open } = this.state
    return (
      <Router history={history}>
        <Loading />
        <CssBaseline />
        <div>
          {location && location !== "/" &&
            <Drawer
              className={`drawer sidebar`}
              variant="permanent"
              classes={{
                paper: 'drawerPaper',
              }}
              anchor="left"
            >
              <div id='logOut' onClick={() => helpers.logout()}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Sair</span>
              </div>
              <div className="logo">
                <img src="/images/logo.png" alt="logo" />
              </div>
              {projects && projects.selectedProject && projects.listProjects &&
                <List className="selectProject">
                  <ListItem>
                    <FormControl className="inputDefault">
                      <InputLabel id="demo-simple-select-label">Projeto</InputLabel>
                      <Select labelId="demo-simple-select-label" id="demo-simple-select"
                        value={projects.selectedProject ? projects.selectedProject : '1'}
                        onChange={(event) => dispatch(helpers.handleProject(event))}
                      >
                        <MenuItem key={999} value={999}>Todos</MenuItem>
                        {projects && projects.listProjects && projects.listProjects.length > 0 && projects.listProjects.map(type => {
                          return <MenuItem key={type.projectId} value={type.projectId}>{type.projectName}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </ListItem>
                </List>
              }
              <List>
                {((helpers.isSuperUser(this.props.login)) || (helpers.moduleVerify(this.props.login, 'user') && helpers.moduleVerify(this.props.login, 'user').read)) &&

                  <ListItem component={Link} to="/users" button key='users' className={`${location === "/users" ? 'activate' : ''}`}>
                    <FontAwesomeIcon icon={faUser} />
                    Usuários
                  </ListItem>

                }
                {helpers.moduleVerify(this.props.login, 'projects') && helpers.moduleVerify(this.props.login, 'projects').read &&

                  <ListItem component={Link} to="/projetos" button key='projetos' className={`${location === "/projetos" ? 'activate' : ''}`}>
                    <FontAwesomeIcon icon={faTasks} />
                    Projetos
                  </ListItem>

                }
                {((helpers.isSuperUser(this.props.login)) || (helpers.projectVerify(this.props.projects, 'fauna') && helpers.moduleVerify(this.props.login, 'fauna') && helpers.moduleVerify(this.props.login, 'fauna').read)) &&
                  <>
                    <ListItem button key='fauna' onClick={() => this.handleClick('fauna')}>
                      <FontAwesomeIcon icon={faPaw} />
                      <ListItemText>Fauna</ListItemText>
                      {open.fauna ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                    </ListItem>
                    <Collapse in={open.fauna} timeout="auto" unmountOnExit>
                      <List component="div" className="subMenu" disablePadding>
                        <ListItem component={Link} to="/fauna/ficha" button key='ficha' className={`${(location === "/fauna/ficha" || location === "/fauna/ficha/detalhes") ? 'activate' : ''}`}>
                          Ficha de Fauna
                        </ListItem>
                        <ListItem component={Link} to="/fauna/telemetria" button key='telemetria' className={`${(location === "/fauna/telemetria") ? 'activate' : ''}`}>
                          Radiotelemetria
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                }
                {((helpers.isSuperUser(this.props.login)) || (helpers.projectVerify(this.props.projects, 'flora') && helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read)) &&
                  <>
                    <ListItem button key='flora' onClick={() => this.handleClick('flora')}>
                      <FontAwesomeIcon icon={faSeedling} />
                      <ListItemText>Flora</ListItemText>
                      {open.flora ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                    </ListItem>
                    <Collapse in={open.flora} timeout="auto" unmountOnExit>
                      <List component="div" className="subMenu" disablePadding>
                        <Link to="/flora/palmito/matriz">
                          <ListItem to="/flora/palmito/matriz" button key='palmito_matriz' className={`${location === "/flora/palmito/matriz" ? 'activate' : ''}`}>
                            Matriz de Palmito
                          </ListItem>
                        </Link>
                        <Link to="/flora/palmito/saidas">
                          <ListItem to="/flora/palmito/saidas" button key='palmito_saida' className={`${location === "/flora/palmito/saidas" ? 'activate' : ''}`}>
                            Saída de Palmito
                          </ListItem>
                        </Link>
                        <Link to="/flora/palmito/talhao">
                          <ListItem to="/flora/palmito/talhao" button key='talhao' className={`${location === "/flora/palmito/talhao" ? 'activate' : ''}`}>
                            Talhão
                          </ListItem>
                        </Link>
                        <Link to="/flora/viveiros/resgate">
                          <ListItem to="/flora/viveiros/resgate" button key='viveiro_resgate' className={`${location === "/flora/viveiros/resgate" ? 'activate' : ''}`}>
                            Viveiro de Resgate
                          </ListItem>
                        </Link>
                        <Link to="/flora/viveiros/producao">
                          <ListItem to="/flora/viveiros/producao" button key='viveiro_producao' className={`${location === "/flora/viveiros/producao" ? 'activate' : ''}`}>
                            Viveiro de Produção
                          </ListItem>
                        </Link>
                        <Link to="/flora/especies" >
                          <ListItem to="/flora/especies" button key='flora_especies' className={`${location === "/flora/especies" ? 'activate' : ''}`}>
                            Espécies de Flora
                          </ListItem>
                        </Link>
                        <Link to="/flora/tora">
                          <ListItem button key='tora' className={`${(location === "/flora/tora" || location === "/flora/tora/saidas") ? 'activate' : ''}`}>
                            Tora
                          </ListItem>
                        </Link>
                        <Link to="/flora/lenha">
                          <ListItem button key='lenha' className={`${location === "/flora/lenha" ? 'activate' : ''}`}>
                            Lenha
                          </ListItem>
                        </Link>
                        <Link to="/flora/raizes">
                          <ListItem button key='raizes' className={`${location === "/flora/raizes" ? 'activate' : ''}`}>
                            Raizes
                          </ListItem>
                        </Link>
                        <Link to="/flora/area_plantio">
                          <ListItem button key='area_plantio' className={`${location === "/flora/area_plantio" ? 'activate' : ''}`}>
                            Área de Plantio
                          </ListItem>
                        </Link>
                        <Link to="/flora/area_monitoramento">
                          <ListItem button key='area_monitoramento' className={`${location === "/flora/area_monitoramento" ? 'activate' : ''}`}>
                            Área de Monitoramento
                          </ListItem>
                        </Link>
                      </List>
                    </Collapse>
                  </>
                }
                {helpers.moduleVerify(this.props.login, 'admin') && helpers.moduleVerify(this.props.login, 'admin').read &&
                  <>
                    <ListItem button key='administracao' onClick={() => this.handleClick('adm')}>
                      <FontAwesomeIcon icon={faUserCog} />
                      <ListItemText>Administração</ListItemText>
                      {open.adm ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                    </ListItem>
                    <Collapse in={open.adm} timeout="auto" unmountOnExit>
                      <List component="div" className="subMenu" disablePadding>
                        {(helpers.isSuperUser(this.props.login)) &&
                          <Link to="/auditoria">
                            <ListItem button key='auditoria' className={`${location === "/auditoria" ? 'activate' : ''}`}>
                              Auditoria
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read &&
                          <Link to="/cores_de_anilhas">
                            <ListItem button key='cores_de_anilhas' className={`${location === "/cores_de_anilhas" ? 'activate' : ''}`}>
                              Cores de Anilhas
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read &&
                          <Link to="/tiposdearmadilha">
                            <ListItem button key='tiposdearmadilha' className={`${location === "/tiposdearmadilha" ? 'activate' : ''}`}>
                              Tipos de Armadilhas
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read &&
                          <Link to="/carac_oficial_flora">
                            <ListItem button key='carac_oficial_flora' className={`${location === "/carac_oficial_flora" ? 'activate' : ''}`}>
                              Carac. Oficial Flora
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read &&
                          <Link to="/tipos_de_documentos">
                            <ListItem button key='tipos_de_documentos' className={`${location === "/tipos_de_documentos" ? 'activate' : ''}`}>
                              Tipos de Documentos
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read &&
                          <Link to="/tipos_de_ambiente">
                            <ListItem button key='tipos_de_ambiente' className={`${location === "/tipos_de_ambiente" ? 'activate' : ''}`}>
                              Tipos de Ambiente
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read &&
                          <Link to="/orgaos_emissores">
                            <ListItem button key='orgaos_emissores' className={`${location === "/orgaos_emissores" ? 'activate' : ''}`}>
                              Órgãos Emissores
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read &&
                          <Link to="/classes_de_fauna">
                            <ListItem button key='classes_de_fauna' className={`${location === "/classes_de_fauna" ? 'activate' : ''}`}>
                              Classes de Fauna
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'especies_risk') && helpers.moduleVerify(this.props.login, 'especies_risk').read &&
                          <Link to="/listas_de_especies">
                            <ListItem button key='listasespecies' className={`${location === "/listas_de_especies" ? 'activate' : ''}`}>
                              Listas de Espécies
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'especies') && helpers.moduleVerify(this.props.login, 'especies').read &&
                          <Link to="/especies">
                            <ListItem button key='especies' className={`${location === "/especies" ? 'activate' : ''}`}>
                              Espécies
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'trap') && helpers.moduleVerify(this.props.login, 'trap').read &&
                          <Link to="/armadilhas">
                            <ListItem button key='armadilhas' className={`${location === "/armadilhas" ? 'activate' : ''}`}>
                              Armadilhas
                            </ListItem>
                          </Link>
                        }
                        {helpers.moduleVerify(this.props.login, 'documents') && helpers.moduleVerify(this.props.login, 'documents').read &&
                          <Link to="/documentos">
                            <ListItem button key='documentos' className={`${location === "/documentos" ? 'activate' : ''}`}>
                              Documentos
                            </ListItem>
                          </Link>
                        }
                      </List>
                    </Collapse>
                  </>
                }
                {helpers.moduleVerify(this.props.login, 'places') && helpers.moduleVerify(this.props.login, 'places').read &&
                  <ListItem button key='locais' className={`${location === "/locais" ? 'activate' : ''}`}>
                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                    <Link to="/locais">{'Locais & Áreas'}</Link>
                  </ListItem>
                }
              </List>
            </Drawer>
          }
          <main className={`${location && location === "/" ? 'full-box' : 'content'}`}>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route exact path="/users">
                {helpers.moduleVerify(this.props.login, 'user') && helpers.moduleVerify(this.props.login, 'user').read ? <Users /> : <Forbidden />}
              </Route>
              <Route exact path="/fauna/ficha">
                {helpers.moduleVerify(this.props.login, 'fauna') && helpers.moduleVerify(this.props.login, 'fauna').read ? <Fauna /> : <Forbidden />}
              </Route>
              {/* <Route path="/flora">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <Forbidden/> : <Forbidden/>}
              </Route> */}
              <Route exact path="/flora/palmito/matriz">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <PalmitoMatriz /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/palmito/talhao">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <PalmitoTalhao /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/palmito/saidas">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <PalmitoSaida /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/viveiros/resgate">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <ViveirosResgate /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/viveiros/producao">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <ViveirosProducao /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/tora">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <Tora pageContext='tora' pageTitle="TORA" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/tora/saidas">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <ToraSaida pageContext='toraSaida' pageTitle="SAÍDA DE TORA" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/lenha">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <Lenha pageContext='lenha' pageTitle="LENHA" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/lenha/saidas">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <LenhaSaida pageContext='toraSaida' pageTitle="SAÍDA DE LENHA" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/raizes">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <Raizes pageContext='raizes' pageTitle="RAIZES" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/raizes/saidas">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <RaizesSaida pageContext='raizesSaida' pageTitle="SAÍDA DE RAÍZES" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/area_plantio">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <AreaPlantio pageContext='areaPlantio' pageTitle="ÁREA DE PLANTIO" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/area_plantio/detalhes">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <AreaPlantioDetalhes pageContext='areaPlantioDetalhes' pageTitle="ÁREA DE PLANTIO" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/area_monitoramento">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <AreaMonitoramento pageContext='areaMonitoramento' pageTitle="ÁREA DE MONITORAMENTO" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/area_monitoramento/detalhes">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <AreaMonitoramentoDetalhes pageContext='areaMonitoramentoDetalhes' pageTitle="ÁREA DE MONITORAMENTO" /> : <Forbidden />}
              </Route>
              <Route exact path="/flora/especies">
                {helpers.moduleVerify(this.props.login, 'flora') && helpers.moduleVerify(this.props.login, 'flora').read ? <FloraEspecies /> : <Forbidden />}
              </Route>
              <Route exact path="/fauna/ficha/detalhes/:idFicha" >
                {helpers.moduleVerify(this.props.login, 'fauna') && helpers.moduleVerify(this.props.login, 'fauna').read ? <FaunaDetails /> : <Forbidden />}
              </Route>
              <Route exact path="/fauna/ficha/detalhes" >
                {helpers.moduleVerify(this.props.login, 'fauna') && helpers.moduleVerify(this.props.login, 'fauna').read ? <FaunaDetails /> : <Forbidden />}
              </Route>
              <Route exact path="/fauna/telemetria">
                {helpers.moduleVerify(this.props.login, 'fauna') && helpers.moduleVerify(this.props.login, 'fauna').read ? <Radiotelemetria /> : <Forbidden />}
              </Route>
              <Route exact path="/fauna/telemetria/detalhes/:idFicha" >
                {helpers.moduleVerify(this.props.login, 'fauna') && helpers.moduleVerify(this.props.login, 'fauna').read ? <TelemetriaDetails /> : <Forbidden />}
              </Route>
              <Route exact path="/fauna/telemetria/detalhes" >
                {helpers.moduleVerify(this.props.login, 'fauna') && helpers.moduleVerify(this.props.login, 'fauna').read ? <TelemetriaDetails /> : <Forbidden />}
              </Route>
              <Route exact path="/cores_de_anilhas">
                {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read ? <Parameters pageContext="ringColors" pageTitle="Cores de Anilhas" /> : <Forbidden />}
              </Route>
              <Route exact path="/tiposdearmadilha">
                {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read ? <Parameters pageContext="trapType" pageTitle="Tipos de Armadilhas" /> : <Forbidden />}
              </Route>
              <Route exact path="/carac_oficial_flora">
                {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read ? <Parameters pageContext="caracFlora" pageTitle="Caracterizações oficiais de flora" /> : <Forbidden />}
              </Route>
              <Route exact path="/tipos_de_documentos">
                {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read ? <Parameters pageContext="documentsType" pageTitle="Tipos de Documentos" /> : <Forbidden />}
              </Route>
              <Route exact path="/tipos_de_ambiente">
                {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read ? <Parameters pageContext="environmentType" pageTitle="Tipos de Ambientes" /> : <Forbidden />}
              </Route>
              <Route exact path="/orgaos_emissores">
                {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read ? <Parameters pageContext="orgEmissor" pageTitle="Órgãos emissores" /> : <Forbidden />}
              </Route>
              <Route exact path="/classes_de_fauna">
                {helpers.moduleVerify(this.props.login, 'params') && helpers.moduleVerify(this.props.login, 'params').read ? <Parameters pageContext="faunaClass" pageTitle="Classes de Fauna" /> : <Forbidden />}
              </Route>
              <Route exact path="/projetos">
                {helpers.moduleVerify(this.props.login, 'projects') && helpers.moduleVerify(this.props.login, 'projects').read ? <Projects /> : <Forbidden />}
              </Route>
              <Route exact path="/locais">
                {helpers.moduleVerify(this.props.login, 'places') && helpers.moduleVerify(this.props.login, 'places').read ? <Places /> : <Forbidden />}
              </Route>
              <Route exact path="/listas_de_especies">
                {helpers.moduleVerify(this.props.login, 'especies_risk') && helpers.moduleVerify(this.props.login, 'especies_risk').read ? <InstLista /> : <Forbidden />}
              </Route>
              <Route exact path="/especies">
                {helpers.moduleVerify(this.props.login, 'especies') && helpers.moduleVerify(this.props.login, 'especies').read ? <Especies /> : <Forbidden />}
              </Route>
              <Route exact path="/armadilhas">
                {helpers.moduleVerify(this.props.login, 'trap') && helpers.moduleVerify(this.props.login, 'trap').read ? <Traps /> : <Forbidden />}
              </Route>
              <Route exact path="/documentos">
                {helpers.moduleVerify(this.props.login, 'documents') && helpers.moduleVerify(this.props.login, 'documents').read ? <Documents /> : <Forbidden />}
              </Route>
              <Route exact path="/auditoria">
                {helpers.isSuperUser(this.props.login) ? <Audit pageContext="auditoria" pageTitle="Auditoria" /> : <Forbidden />}
              </Route>
            </Switch>
          </main>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = store => ({
  users: store.usersReducer.users,
  location: store.locationReducer.location,
  projects: store.projectsReducer,
  login: store.loginReducer
});

export default connect(mapStateToProps)(App);