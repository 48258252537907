import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const auditHandler = { 
	listAudit, 
	paginateAudit
};

function listAudit() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("audit/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateAudit(params, page, projectId) {

	const $user = params?.user ? `&user=${params.user}` : ""
	const $email = params?.email ? `&email=${params.email}` : ""
	const $module = params?.module ? `&module=${params.module}` : ""
	const $type = params?.type && params?.type !== 'todos' ? `&type=${params.type}` : ""
	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params?.endDate ? `&endDate=${params.endDate}` : ""
	const $project = projectId ? `&project=${projectId}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($user)
	.concat($email)
	.concat($module)
	.concat($type)
	.concat($initDate)
	.concat($endDate)
	.concat($project)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("audit/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}
