import { auditActionTypes } from "../types/auditActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: {
    audit: {},
  },
  audit: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {
      user: '',
      email: '',
      type: 'todos',
      module: '',
      initDate: moment().subtract(7, 'days').format(),
      endDate: moment().format()
    },
    editItem: {
      createdAt: null,
      user: '',
      email: '',
      type: '',
      module: '',
      document: [],
    }
  },
};

export const auditReducer = (state = initialState, action) => {
  switch (action.type) {
    case auditActionTypes.GET_AUDIT_CARD_REQUEST:
    case auditActionTypes.GET_AUDIT_CARD_FAILURE:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          audit: {}
        }
      };
    case auditActionTypes.GET_AUDIT_CARD_SUCCESS:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          audit: action.list
        }
      };
    case auditActionTypes.LIST_AUDIT_CARD_REQUEST:
      return {
        ...state,
        audit: {
          ...state.audit,
          loading: true
        }
      };
    case auditActionTypes.LIST_AUDIT_CARD_SUCCESS:
      return {
        ...state,
        audit: {
          ...state.audit,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.audit.editItem,
          loading: false
        }
      };
    case auditActionTypes.LIST_AUDIT_CARD_FAILURE:
      return {
        ...state,
        audit: {
          ...state.audit,
          loading: false
        }
      };

    case auditActionTypes.SET_AUDIT_FILTER:
      return {
        ...state,
        audit: {
          ...state.audit,
          filters: { ...state.audit.filters, [action.target]: action.value }
        }
      };

    case auditActionTypes.SET_AUDIT_FILTER_DATE_INIT:
      return {
        ...state,
        audit: {
          ...state.audit,
          filters: {
            ...state.audit.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case auditActionTypes.SET_AUDIT_FILTER_DATE_END:
      return {
        ...state,
        audit: {
          ...state.audit,
          filters: {
            ...state.audit.filters,
            endDate: moment(action.date).format()
          }
        }
      };
    case auditActionTypes.EDIT_AUDIT_CARD:
      return {
        ...state,
        audit: {
          ...state.audit,
          editItem: action.item
        }
      };
    case auditActionTypes.EDIT_AUDIT_CARD_CANCELED:
      return {
        ...state,
        audit: {
          ...state.audit,
          editItem: initialState.audit.editItem
        }
      };
    case auditActionTypes.CLEAR_FIELDS:
      return {
        ...state,
        audit: {
          ...state.audit,
          newItem: initialState.audit.newItem
        }
      };

    default:
      return state;
  }
};