import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"
import { especiesHelpers} from '../../helpers/especies/especiesHelpers'

class filter extends Component {

    render(){
        const { dispatch, especiesReducer, paramsReducer } = this.props
        const { filters } = especiesReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="nomeCientifico" label="Nome Científico" className="inputDefault" value={filters.nomeCientifico} onChange={(event) => dispatch(especiesHelpers.handleChangeFilter(event, "nomeCientifico"))}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="nomePopular" label="Nome Popular" className="inputDefault" value={filters.nomePopular} onChange={(event) => dispatch(especiesHelpers.handleChangeFilter(event, "nomePopular"))}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="inputDefault">
                                <InputLabel id="demo-simple-select-label">Classe</InputLabel>
                                <Select labelId="status-select-label" id="status-select" value={filters.classe} autoComplete='off' onChange={(event) => dispatch(especiesHelpers.handleChangeFilter(event, "classe"))}>
                                    <MenuItem key={'default'} value={''}>{'--'}</MenuItem>
                                    {paramsReducer && paramsReducer.listOptions.faunaClass && paramsReducer.listOptions.faunaClass.map((item, index) => <MenuItem key={index} value={item.classe}>{item.classe}</MenuItem> )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('especies', true)}>
                                NOVA ESPÉCIE
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(especiesHelpers.paginate(filters, 1))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    especiesReducer: store.especiesReducer,
    paramsReducer: store.paramsReducer,
});

export default connect(mapStateToProps)(filter);