import moment from "moment"
import { palmitoActions } from "../../../actions/palmitoActions"
import { palmitoActionTypes } from "../../../types/palmitoActionTypes"
import utmToLatLng from "../../coordConverter"
import { computeArea } from "spherical-geometry-js"

function paginateMatriz(parameters, page, project){
	return dispatch => {
		dispatch(palmitoActions.paginateMatriz(parameters, page, project))
	}
}

function paginatePalmito(parameters, page, context, project){
	return dispatch => {
		if(context === "saida") dispatch(palmitoActions.paginateSaida(parameters, page, project))
		if(context === "talhao") dispatch(palmitoActions.paginateTalhao(parameters, page, project))
		if(context === "matriz") dispatch(palmitoActions.paginateMatriz(parameters, page, project))
	}
}

function listMatriz(){
	return dispatch => {
		dispatch(palmitoActions.listMatriz())
	}
}

function editCard(context, places, callback) {
	if(places){
		if(context === 'matriz'){
			if(callback){ callback('palmitoMatriz') }
			return { type: palmitoActionTypes.EDIT_PALMITO, places, context}
		}else if(context === 'talhao'){
			if(callback){ callback('palmitoTalhao') }
			return { type: palmitoActionTypes.EDIT_PALMITO, places, context}
		}else{
			if(callback){ callback('palmitoSaida') }
			return { type: palmitoActionTypes.EDIT_PALMITO, places, context}
		}
	}else{
		return { type: palmitoActionTypes.UPDATE_PALMITO_CANCELED }
	}
}

function updateMatriz(parameters, page, callback){
	return dispatch => {
		dispatch(palmitoActions.updateMatriz(parameters, page, callback))
	}
}

function createMatriz(parameters, page, callback, project){
	const card = {
		...parameters,
		project: project
	}
	return dispatch => {
		dispatch(palmitoActions.createMatriz(card, page, callback, project))
	}
}


function handleFilterDateChange(date, target){
	return dispatch => {
		dispatch(handleFilterDate(date, target))
	}
};

function handleFilterDate(date, target) {return { type: palmitoActionTypes.SET_FILTER_DATE, date, target }}

function handleChangeFilter(event, target) {
	const value = event.target.value

	return dispatch => {
		dispatch(setFilter(value, target))
	}
}

function setFilter(value, target) {return { type: palmitoActionTypes.SET_PALMITO_FILTER, value, target }}

function saveStore(value, target, context) { return { type: palmitoActionTypes.HANDLE_INPUT_PALMITO, context, value, target } }

function handleObjs(module, index, target, myValue, objDataName, context, dispatch, callback, type) {

	let newObj = module;

	if(target === 'dataColeta' || target === 'taxaGerminacao') {
		newObj[index][target] = myValue
	}else if(target === 'qntGerminada' || target === 'qntPlantadas'){
		newObj[index][target] = Number(myValue)

		const qntGerminada = newObj[index]['qntGerminada'] ? newObj[index]['qntGerminada'] : 0
		const qntPlantadas = newObj[index]['qntPlantadas'] ? newObj[index]['qntPlantadas'] : 0
		if(qntPlantadas !== 0){
			newObj[index]['taxaGerminacao'] = ((qntGerminada * 100 ) / qntPlantadas).toFixed(1)
		}else{
			newObj[index]['taxaGerminacao'] = 0
			newObj[index]['qntGerminada'] = 0
		}
	}else{
		if(type === 'string'){
			newObj[index][target] = myValue
		}else{
			newObj[index][target] = Number(myValue)
		}
	}

	if(callback) callback(newObj, context, dispatch)

	return dispatch(saveStore(newObj, objDataName, context));
}

function addNewObjs(module, objDataName, context, dispatch) {
	let newObj = module;
	let emptyModule = {}

	if(context === 'matriz'){
		emptyModule = { dataColeta: moment().format(), kgColetado: 0, qntCachos: 0, sementesColetadas: 0, qntPlantadas: 0, qntBoiou: 0, qntGerminada: 0, qntDescartada: 0, taxaGerminacao: 0 }
	}else if(context === 'talhao'){
		emptyModule = { coordX: '', coordY: '', EPE: '', projecao: '23' }
	}

	newObj.push(emptyModule)

	return dispatch(saveStore(newObj, objDataName, context));
}

function handleInput(myValue, target, context, dispatch) {

	let value = target === 'date' || target === 'endDate' || target === 'endDateAlert' || target === 'dataObito' || target === 'dataCadastro' || target === 'dataColeta' ? myValue : myValue.target.value

	if(target === 'numeroMatriz' || target === 'numeroTalhao' || target === 'numeroSaida') value = ("000000" + value).slice(value.length)
	switch(context){
		case 'matriz':
			return dispatch(saveStore(value, target, context));
		case 'talhao':
			return dispatch(saveStore(value, target, context));
		case 'saida':
			return dispatch(saveStore(value, target, context));
		default:
			return
	}
}

function paginateTalhao(parameters, page, project){
	return dispatch => {
		dispatch(palmitoActions.paginateTalhao(parameters, page, project))
	}
}

function listTalhao(){
	return dispatch => {
		dispatch(palmitoActions.listTalhao())
	}
}



const getArea = async (coordObj, context, dispatch) => {

	let isValidCoordObj = await validateCoordObj(coordObj)

	if(!isValidCoordObj) return

	let convertedArray = await convertCoords(coordObj)

	let area = await coordsArray(convertedArray)

	return dispatch(saveStore(Number(area.toFixed()), 'areaTotal', context));
}

const convertCoords = async (coordObj) => {
	if(!coordObj) return
	let convertedArray = []

	coordObj.map(utmCoord => {
		let parsedCoord = {}
		let projecao = utmCoord.projecao && utmCoord.projecao !== '' ? utmCoord.projecao : false
		let convertedCoord = utmToLatLng(projecao, utmCoord.coordX, utmCoord.coordY, false)
		parsedCoord.lat = convertedCoord.latitude
		parsedCoord.lng = convertedCoord.longitude
		convertedArray.push(parsedCoord)
	})
	return convertedArray
}

const coordsArray = async (convertedObj) => {
	if(!convertedObj) return

	return computeArea(convertedObj)
}

const validateCoordObj = async (coordObj) => {
	if(!coordObj || coordObj.length < 4) return false
	const validData = coordObj.filter( coord => (coord.coordX === '' || coord.coordY === ''))
	if(validData.length === 0){
		return true
	}else{
		return false
	}
}

function updateTalhao(parameters, page, callback){
	return dispatch => {
		dispatch(palmitoActions.updateTalhao(parameters, page, callback))
	}
}

function createTalhao(parameters, page, callback, project){
	const card = {
		...parameters,
		project: project
	}
	return dispatch => {
		dispatch(palmitoActions.createTalhao(card, page, callback, project))
	}
}

function paginateSaida(parameters, page, project){
	return dispatch => {
		dispatch(palmitoActions.paginateSaida(parameters, page, project))
	}
}

function listSaida(){
	return dispatch => {
		dispatch(palmitoActions.listSaida())
	}
}

function updateSaida(parameters, page, callback){

	const data = {
		_id: parameters._id,
		numeroSaida: parameters.numeroSaida,
		dataSaida: parameters.dataSaida,
		quantidade: Number(parameters.quantidade), 
		tipo: parameters.tipo,
		matriz: parameters.matriz,
		tipoSaida: parameters.tipoSaida,
		localDestino: parameters.tipoSaida && parameters.tipoSaida === 'doação' ? parameters.localDestino : null, 
		licenca: parameters.tipoSaida && parameters.tipoSaida === 'doação' ?  parameters.licenca : null, 
		localPlantio: parameters.tipoSaida && parameters.tipoSaida === 'plantio' ? parameters.localPlantio : null, 
		talhao: parameters.tipoSaida && parameters.tipoSaida === 'plantio' && parameters.localPlantio && parameters.localPlantio === 'talhão' ? parameters.talhao : null, 
		areaDemarcada: parameters.tipoSaida && parameters.tipoSaida === 'plantio' && parameters.localPlantio && parameters.localPlantio === 'área demarcada' ? parameters.areaDemarcada : null, 
		status: parameters.status,
		project: parameters.project
	}

	return dispatch => {
		dispatch(palmitoActions.updateSaida(data, page, callback))
	}
}

function createSaida(parameters, page, callback, project){

	const data = {
		numeroSaida: parameters.numeroSaida,
		dataSaida: parameters.dataSaida,
		quantidade: Number(parameters.quantidade), 
		tipo: parameters.tipo,
		matriz: parameters.matriz,
		tipoSaida: parameters.tipoSaida,
		localDestino: parameters.tipoSaida && parameters.tipoSaida === 'doação' ? parameters.localDestino : null, 
		licenca: parameters.tipoSaida && parameters.tipoSaida === 'doação' ?  parameters.licenca : null, 
		localPlantio: parameters.tipoSaida && parameters.tipoSaida === 'plantio' ? parameters.localPlantio : null, 
		talhao: parameters.tipoSaida && parameters.tipoSaida === 'plantio' && parameters.localPlantio && parameters.localPlantio === 'talhão' ? parameters.talhao : null, 
		areaDemarcada: parameters.tipoSaida && parameters.tipoSaida === 'plantio' && parameters.localPlantio && parameters.localPlantio === 'área demarcada' ? parameters.areaDemarcada : null, 
		status: parameters.status,
		project: project
	}

	return dispatch => {
		dispatch(palmitoActions.createSaida(data, page, callback, project))
	}
}


export const palmitoHelpers = {
    paginateMatriz,
    paginatePalmito,
	listMatriz,
	editCard,
	updateMatriz,
	createMatriz,
	handleChangeFilter,
	handleFilterDateChange,
	handleObjs,
	addNewObjs,
	paginateTalhao,
	listTalhao,
	handleInput,
	getArea,
	updateTalhao,
	createTalhao,
	paginateSaida,
	listSaida,
	updateSaida,
	createSaida
}