import moment from "moment"
import { viveirosActions } from "../../../actions/viveirosActions"
import { viveirosActionTypes } from "../../../types/viveirosActionTypes"
import { Store } from '../../../app/store';

function editCard(context, item, callback) {
	if(item){
		if(callback){ callback(context) }
		return { type: viveirosActionTypes.EDIT_VIVEIROS, item, context}
	} else {
		return { type: viveirosActionTypes.UPDATE_VIVEIROS_CANCELED }
	}
}

function handleDateChangeInit(date){
	return dispatch => {
		dispatch(handleDateInit(date))
	}
};

function handleDateChangeEnd(date){
	return dispatch => {
		dispatch(handleDateEnd(date))
	}
};

function handleDateInit(date) {return { type: viveirosActionTypes.SET_FILTER_DATE_INIT, date }}

function handleDateEnd(date) {return { type: viveirosActionTypes.SET_FILTER_DATE_END, date }}

function handleChangeFilter(event, target) {
	const value = event.target.value

	return dispatch => {
		dispatch(setFilter(value, target))
	}
}

function handleEspecie(nomeCientifico, especiesObj, context, dispatch) {
	dispatch(saveStore(nomeCientifico, 'nomeCientifico', context));
	let myEspecie = especiesObj.filter(item => item.nomeCientifico === nomeCientifico)
	myEspecie = myEspecie.length > 0 ?  myEspecie[0] : null

	if(myEspecie){
		let nomePopular = myEspecie.nomePopular? myEspecie.nomePopular : '' 
		dispatch(saveStore(nomePopular, 'nomePopular', context));
	}
}

function setFilter(value, target) {return { type: viveirosActionTypes.SET_VIVEIROS_FILTER, value, target }}

function saveStore(value, target, context) { return { type: viveirosActionTypes.HANDLE_INPUT_VIVEIROS, context, value, target } }

function handleObjs(module, index, target, myValue, objDataName, context, dispatch, callback, type) {

	let newObj = module[objDataName];

	if(type === 'string'){
		newObj[index][target] = myValue
	}else{
		newObj[index][target] = Number(myValue)
	}

	dispatch(saveStore(newObj, objDataName, context));

	if (callback) callback(dispatch)
}

function addNewObjs(module, objDataName, context, dispatch) {
	let newObj = module;
	let emptyModule = {}

	if(context === 'viveirosResgate'){
		emptyModule = {
			obito: null,
			quantidadeSaida:  0,
			dataSaida:  moment().format(),
			areaPlantio: '', 
			localConhecido: '', 
			areaDemarcada: '', 
			coordX: '', 
			coordY: '', 
			projecao: '', 
			epe: '', 
		}
	}

	newObj.push(emptyModule)

	return dispatch(saveStore(newObj, objDataName, context));
}

function handleInput(myValue, target, context, dispatch, callback) {
	let value = target === 'addDate' || target === 'endDate' || target === 'initDate' ? myValue : myValue.target.value

	if(target === 'numeroLote' || target === 'numeroFicha') value = ("000000" + value).slice(value.length)
	dispatch(saveStore(value, target, context));

	if (callback) callback(dispatch)
}

function paginateResgate(parameters, page, project){
	return dispatch => {
		dispatch(viveirosActions.paginateResgate(parameters, page, project))
	}
}

function listResgate(){
	return dispatch => {
		dispatch(viveirosActions.listResgate())
	}
}

function updateResgate(parameters, page, callback, callbackParams, project){

	const data = {
		...parameters,
		_id: parameters._id,
		nomeCientifico: parameters.nomeCientifico,
		nomePopular: parameters.nomePopular,
		quantidadeResgatada: parameters.quantidadeResgatada,
		quantidadeViveiro: parameters.quantidadeViveiro,
		numeroLote: parameters.numeroLote,
		addDate: parameters.addDate,
		localOrigemTipo: parameters.localOrigemTipo,
		localOrigemId: parameters.localOrigemTipo === 'serrapilheira' ? parameters.localOrigemId : '', 
		status: parameters.status,
		saidas: validateSaida(parameters.saidas),
	}

	return dispatch => {
		dispatch(viveirosActions.updateResgate(data, page, callback, callbackParams, project))
	}
}

function createResgate(parameters, page, callback, callbackParams, project){

	const data = {
		nomeCientifico: parameters.nomeCientifico,
		nomePopular: parameters.nomePopular,
		quantidadeResgatada: parameters.quantidadeResgatada,
		quantidadeViveiro: parameters.quantidadeViveiro,
		numeroLote: parameters.numeroLote,
		addDate: parameters.addDate,
		localOrigemTipo: parameters.localOrigemTipo,
		localOrigemId: parameters.localOrigemTipo === 'serrapilheira' ? parameters.localOrigemId : '', 
		status: parameters.status,
		saidas: validateSaida(parameters.saidas),
		project: project
	}

	return dispatch => {
		dispatch(viveirosActions.createResgate(data, page, callback, callbackParams, project))
	}
}

function updateProducao(parameters, page, callback, callbackParams, project){


	const data = {
		...parameters,
		_id: parameters._id,
		nomeCientifico: parameters.nomeCientifico,
		nomePopular: parameters.nomePopular,
		quantidadeResgatada: parameters.quantidadeResgatada,
		quantidadeViveiro: parameters.quantidadeViveiro,
		numeroLote: parameters.numeroLote,
		addDate: parameters.addDate,
		localOrigemTipo: parameters.localOrigemTipo,
		localOrigemId: parameters.localOrigemTipo === 'serrapilheira' ? parameters.localOrigemId : '', 
		status: parameters.status,
		saidas: validateSaida(parameters.saidas),
	}

	return dispatch => {
		dispatch(viveirosActions.updateProducao(parameters, page, callback, callbackParams, project))
	}
}

function createProducao(parameters, page, callback, callbackParams, project){

	const data = {
		...parameters,
		project: project
	}


	return dispatch => {
		dispatch(viveirosActions.createProducao(data, page, callback, callbackParams, project))
	}
}

function validateSaida(data){
	const saidaArr = []

	if (data.length <= 0) return

	data.map(item => {
		let mappedObj = {
			obito: item.obito,
			quantidadeSaida: item.quantidadeSaida,
			dataSaida: item.dataSaida,
			areaPlantio: !item.obito ? item.areaPlantio : '', 
			localConhecido: !item.obito && item.areaPlantio === 'localConhecido' ? item.localConhecido : '', 
			areaDemarcada: !item.obito && item.areaPlantio === 'areaDemarcada' ? item.areaDemarcada : '', 
			coordX: !item.obito && item.areaPlantio === 'coordernada' ? item.coordX : '', 
			coordY: !item.obito && item.areaPlantio === 'coordernada' ? item.coordY : '', 
			projecao:!item.obito && item.areaPlantio === 'coordernada' ? item.projecao : '', 
			epe: !item.obito && item.areaPlantio === 'coordernada' ? item.epe : '', 
		}
		saidaArr.push(mappedObj)
	})

	return saidaArr
}


function exportData(context, parameters, projectId){
	return dispatch => {
		dispatch(viveirosActions.exportResgate(parameters, projectId))
	}
}

function updateQnt(dispatch){
	
	const state = Store.getState();
	const module = state.viveirosReducer.editItem.viveirosResgate
	
	let saidasCounter = 0

	module.saidas.map((item) => {
		saidasCounter = saidasCounter + item.quantidadeSaida
	})
	let value = module.quantidadeResgatada - saidasCounter

	return dispatch(saveStore(value, 'quantidadeViveiro', 'viveirosResgate'));
}

function paginateProducao(parameters, page, project){
	return dispatch => {
		dispatch(viveirosActions.paginateProducao(parameters, page, project))
	}
}

function listProducao(){
	return dispatch => {
		dispatch(viveirosActions.listProducao())
	}
}

export const viveirosHelpers = {
	editCard,
	handleChangeFilter,
	handleDateChangeInit,
	handleDateChangeEnd,
	handleObjs,
	addNewObjs,
	handleInput,
	paginateResgate,
	listResgate,
	updateResgate,
	createResgate,
	updateProducao,
	createProducao,
	exportData,
	updateQnt,
	paginateProducao,
	listProducao,
	handleEspecie
}