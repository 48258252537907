import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core"
import { areaPlantioHelpers } from '../../../helpers/flora/areaPlantio/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class AreaPlantioForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    formValidator = () => {
        const { areaPlantioReducer } = this.props
        const { areaPlantio } = areaPlantioReducer
        const { editItem } = areaPlantio
        const { newItem } = editItem
        const { codigoDaArea, coord } = newItem

        if (codigoDaArea !== '' && coord !== [] && coord.length >= 3 &&
            coord[0].coordenadaX !== '' && coord[0].coordenadaY !== '' && coord[0].EPE !== ''
            && coord[1].coordenadaX !== '' && coord[1].coordenadaY !== '' && coord[1].EPE !== ''
            && coord[2].coordenadaX !== '' && coord[2].coordenadaY !== '' && coord[2].EPE !== '') {
            console.log('passouu')
            return true
        } else {
            console.log('não passouu')
            return false
        }
    }

    submitForm = () => {
        const { areaPlantioReducer, dispatch, closeModal, projectsReducer } = this.props
        const { areaPlantio } = areaPlantioReducer
        const { editItem, filters } = areaPlantio
        this.setState({ formSubmited: true })

        if (this.formValidator() === false) return
        dispatch(areaPlantioHelpers.createAreaPlantio(editItem.newItem, 1, closeModal, projectsReducer.selectedProject, filters))
    }

    render() {
        const { dispatch, areaPlantioReducer, projectsReducer, closeModal, placesReducer } = this.props
        const { areaPlantio } = areaPlantioReducer
        const { listPlaces } = placesReducer
        const { editItem, filters, documents } = areaPlantio
        const { formSubmited } = this.state

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="codigoDaArea"
                                label="Código da área"
                                className="inputDefault"
                                value={editItem?.newItem?.codigoDaArea}
                                autoComplete='off'
                                onChange={(event) => areaPlantioHelpers.handleInput(event, 'codigoDaArea', 'areaPlantio', dispatch)}
                                error={formSubmited && editItem.newItem.codigoDaArea === ''}
                                helperText={formSubmited && editItem.newItem.codigoDaArea === '' ? 'Campo obrigatório' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault" required error={formSubmited && editItem.newItem.tipo === ''}>
                                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={editItem.newItem.tipo}
                                    onChange={(event) => areaPlantioHelpers.handleInput(event, 'tipo', 'areaPlantio', dispatch)}
                                >
                                    <MenuItem key={'Arbóreas'} selected value={'Arbóreas'}>{'Arbóreas'}</MenuItem>
                                    <MenuItem key={'Epífitas'} value={'Epífitas'}>{'Epífitas'}</MenuItem>
                                    <MenuItem key={'Arbóreas ou Epífitas'} value={'Arbóreas ou Epífitas'}>{'Arbóreas ou Epífitas'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className="inputDefault" required error={formSubmited && editItem.newItem.status === ''}>
                                <InputLabel id="demo-simple-select-label">Área Demarcada</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={editItem.newItem.status}
                                    onChange={(event) => areaPlantioHelpers.handleInput(event, 'status', 'areaPlantio', dispatch)}
                                >
                                    <MenuItem key={'Recomposição'} selected value={'Recomposição'}>{'Recomposição'}</MenuItem>
                                    <MenuItem key={'Adensamento'} value={'Adensamento'}>{'Adensamento'}</MenuItem>
                                </Select>
                                {formSubmited && editItem.newItem.status === '' && <FormHelperText>Campo obrigatório</FormHelperText>}
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} md={12} >
                            <Typography variant="h6" component="h6" className="filterTitle">Coordenadas</Typography>
                        </Grid>
                        <Divider className="dividerFull" />
                        <Grid container spacing={3} className="box-content-primary center blocked-itens" md={11}>
                            {editItem.newItem.coord.map((position, index) => {
                                return (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Coordenada X"
                                                className="inputDefault"
                                                value={position.coordenadaX}
                                                autoComplete='off'
                                                onChange={(event) => areaPlantioHelpers.handlePlaces(editItem.newItem.coord, index, 'coordenadaX', event.target.value, 'areaPlantio', areaPlantioHelpers.getArea, dispatch)}
                                                error={formSubmited && index < 3 && position.coordenadaX === ''}
                                                helperText={formSubmited && index < 3 && position.coordenadaX === '' ? 'Campo obrigatório' : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Coordenada Y"
                                                className="inputDefault"
                                                value={position.coordenadaY}
                                                autoComplete='off'
                                                onChange={(event) => areaPlantioHelpers.handlePlaces(editItem.newItem.coord, index, 'coordenadaY', event.target.value, 'areaPlantio', areaPlantioHelpers.getArea, dispatch)}
                                                error={formSubmited && index < 3 && position.coordenadaY === ''}
                                                helperText={formSubmited && index < 3 && position.coordenadaY === '' ? 'Campo obrigatório' : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="EPE"
                                                className="inputDefault"
                                                value={position.EPE}
                                                autoComplete='off'
                                                onChange={(event) => areaPlantioHelpers.handlePlaces(editItem.newItem.coord, index, 'EPE', event.target.value, 'areaPlantio', areaPlantioHelpers.getArea, dispatch)}
                                                error={formSubmited && index < 3 && position.EPE === ''}
                                                helperText={formSubmited && index < 3 && position.EPE === '' ? 'Campo obrigatório' : ''}
                                            />
                                        </Grid>
                                        <Divider className="dividerFull" />
                                    </>
                                )
                            })}
                        </Grid>
                        <Divider className="dividerFull" />
                        <Grid item xs={12} md={2}>
                            <Button variant="contained" className="btn-secondary" onClick={() => areaPlantioHelpers.addNewPlace(editItem.newItem.coord, 'coord', 'areaPlantio', dispatch)}>
                                Adicionar...
                            </Button>
                        </Grid>
                        <Divider className="dividerFull" />
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained"
                                    className="btn-primary"
                                    component="label"
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        <Divider className="dividerFull" />
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    areaPlantioReducer: store.areaPlantioReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
    placesReducer: store.placesReducer,
});

export default connect(mapStateToProps)(AreaPlantioForm);