import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider } from "@material-ui/core"
import { trapsHelpers} from '../../helpers/traps/trapsHelpers'

class filter extends Component {

    render(){
        const { dispatch, especiesReducer } = this.props
        const { filters } = especiesReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="trapId" label="Identificador" className="inputDefault" value={filters.trapId} onChange={(event) => dispatch(trapsHelpers.handleChangeFilter(event, "trapId"))}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('traps', true)}>
                                NOVA ARMADILHA
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(trapsHelpers.paginate(filters, 1))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    especiesReducer: store.especiesReducer,
    paramsReducer: store.paramsReducer,
});

export default connect(mapStateToProps)(filter);