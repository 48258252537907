import { toraActions } from "../../../actions/toraActions"
import { toraActionTypes } from "../../../types/toraActionTypes"
import { history } from '../../history';

function listTora(findParams) {
	return dispatch => {
		dispatch(toraActions.listTora(findParams))
	}
}

function paginateTora(parameters, page, projectId) {
	return dispatch => {
		dispatch(toraActions.paginateTora(parameters, page, projectId))
	}
}

function handleChangeFilter(event, target, context) {
	const value = event.target.value
	const prefix = context === 'tora' ? 'TORA' : context === 'toraSaida' ? 'TORA_SAIDA' : ''

	return dispatch => {
		dispatch(setFilter(value, target, prefix))
	}
}

function setFilter(value, target, prefix) { return { type: toraActionTypes[`SET_${prefix}_FILTER`], value, target } }

function handleDateChangeInit(date, context) {
	const prefix = context === 'tora' ? 'TORA' : context === 'toraSaida' ? 'TORA_SAIDA' : ''

	return dispatch => {
		dispatch(handleDateInit(date, prefix))
	}
};


function handleDateChangeEnd(date, context) {
	const prefix = context === 'tora' ? 'TORA' : context === 'toraSaida' ? 'TORA_SAIDA' : ''

	return dispatch => {
		dispatch(handleDateEnd(date, prefix))
	}
};

function handleDateInit(date, prefix) { return { type: toraActionTypes[`SET_${prefix}_FILTER_DATE_INIT`], date } }

function handleDateEnd(date, prefix) { return { type: toraActionTypes[`SET_${prefix}_FILTER_DATE_END`], date } }



function editCard(context, item, callback) {
	if (item) {
		if (callback) { callback(context) }
		return { type: toraActionTypes.EDIT_TORA_CARD, item, context }
	} else {
		return { type: toraActionTypes.UPDATE_TORA_CARD_CANCELED }
	}
}

async function handleInput(myValue, target, context, dispatch, callback, isNumber) {
	let value = target === 'dataMedicao' || target === 'data' || isNumber ? myValue : myValue.target.value

	if (target === 'numeroFicha') {
		value = ("000000000" + value).slice(value.length)
	}

	await dispatch(saveStore(value, target, context));

	if (callback) callback(dispatch)
}

function HandleInputNumber(event, target, context, dispatch, callback) {
	let myValue = event?.target?.value
	let replaced = myValue.replace(/[^\d.-]/g, '')
	handleInput(replaced, target, context, dispatch, callback, true)
}

function saveStore(value, target, context) { return { type: toraActionTypes.HANDLE_TORA_FIELDS, context, value, target } }

function calculateDiametroMedio(diametro1, diametro2, diametro3, comprimento, context, dispatch) {
	const valores = []
	if (diametro1 && (diametro1 !== '' && diametro1 !== '--' && Number(diametro1) !== 0)) valores.push(Number(diametro1))
	if (diametro2 && (diametro2 !== '' && diametro2 !== '--' && Number(diametro2) !== 0)) valores.push(Number(diametro2))
	if (diametro3 && (diametro3 !== '' && diametro3 !== '--' && Number(diametro3) !== 0)) valores.push(Number(diametro3))

	var media = 0

	valores.map(valor => media += valor)

	media /= valores.length
	if ((comprimento && Number(comprimento) > 0)) {
		calculateVolume(comprimento, media, context, dispatch)
	}

	dispatch(saveStore(media, 'diametroMedio', context));
}

function calculateVolume(comprimento, diametroMedio, context, dispatch) {
	let altura = parseFloat(comprimento)
	let raio = parseFloat(diametroMedio / 2)
	let volume = Math.PI * raio * raio * altura

	dispatch(saveStore(volume, 'volume', context));
}

function createTora(parameters, page, callback, projectId, params) {

	const data = {
		numeroFicha: parameters.numeroFicha,
		areaDemarcada: parameters.areaDemarcada,
		comprimento: parameters.comprimento,
		dataMedicao: parameters.dataMedicao,
		diametro1: parameters.diametro1,
		diametro2: parameters.diametro2,
		diametro3: parameters.diametro3,
		diametroMedio: parameters.diametroMedio,
		volume: parameters.volume,
		project: projectId
	}

	return dispatch => {
		dispatch(toraActions.createTora(data, page, callback, projectId, params))
	}
}

function updateTora(parameters, page, callback, projectId, params) {

	const data = {
		_id: parameters._id,
		numeroFicha: parameters.numeroFicha,
		areaDemarcada: parameters.areaDemarcada,
		comprimento: parameters.comprimento,
		dataMedicao: parameters.dataMedicao,
		diametro1: parameters.diametro1,
		diametro2: parameters.diametro2,
		diametro3: parameters.diametro3,
		diametroMedio: parameters.diametroMedio,
		volume: parameters.volume,
		project: projectId ?? parameters.project
	}

	return dispatch => {
		dispatch(toraActions.updateTora(data, page, callback, projectId, params))
	}
}

function deleteTora(parameters, page, projectId, cardId, closeModal, dispatch) {
	dispatch(toraActions.deleteTora(parameters, page, projectId, cardId, closeModal))
}

function exportData(parameters, projectId) {
	return dispatch => {
		dispatch(toraActions.exportTora(parameters, projectId))

	}
}

// Tora Saida

function tora() {
	return dispatch => {
		return history.push(`/flora/tora`)
	}
}

function toraSaidas() {
	return dispatch => {
		return history.push(`tora/saidas`)
	}
}

function paginateToraSaida(parameters, page, projectId) {
	return dispatch => {
		dispatch(toraActions.paginateToraSaida(parameters, page, projectId))
	}
}

async function handleSaidaInput(myValue, target, context, dispatch, callback, isNumber) {
	let value = target === 'dataMedicao' || target === 'data' || isNumber ? myValue : myValue.target.value

	if (target === 'numeroFicha') {
		value = ("000000000" + value).slice(value.length)
	}

	await dispatch(saveSaidaStore(value, target, context));

	if (callback) callback(dispatch)
}

async function handleIndividuos(myValue, IndividuosList, dispatch) {

	let individuos = []

	myValue.map((individuo, index) => {
		let data = {}
		data.numero = individuo.numeroFicha
		data.volume = individuo.volume.replace(' m3', '').replace(' ', '').replace('m³', '')
		data.areaDemarcada = individuo.areaDemarcada
		individuos.push(data)
	})

	await dispatch(saveSaidaStore(individuos, 'individuos', 'toraSaida'));
	calculateSaidaVolume(individuos, 'toraSaida', dispatch)

}

function calculateSaidaVolume(individuos, context, dispatch) {
	let volume = 0

	individuos.map(individuo => volume += Number(individuo.volume.replace(' m3', '').replace(' ', '').replace('m³', '')))

	dispatch(saveSaidaStore(volume, 'volumeTotal', context));
}

function saveSaidaStore(value, target, context) { return { type: toraActionTypes.HANDLE_TORA_SAIDA_FIELDS, context, value, target } }

function createToraSaida(parameters, page, callback, projectId, params) {

	const data = {
		data: parameters.data,
		tipo: parameters.tipo,
		volumeTotal: parameters.volumeTotal,
		individuos: parameters.individuos,
		project: projectId
	}

	return dispatch => {
		dispatch(toraActions.createToraSaida(data, page, callback, projectId, params))
	}
}

function updateToraSaida(parameters, page, callback, projectId, params) {

	const data = {
		_id: parameters._id,
		data: parameters.data,
		tipo: parameters.tipo,
		volumeTotal: parameters.volumeTotal,
		individuos: parameters.individuos,
		project: projectId ?? parameters.project
	}

	return dispatch => {
		dispatch(toraActions.updateToraSaida(data, page, callback, projectId, params))
	}
}

function deleteToraSaida(parameters, page, projectId, cardId, closeModal, dispatch) {
	dispatch(toraActions.deleteToraSaida(parameters, page, projectId, cardId, closeModal))
}

function editSaidaCard(context, item, callback) {
	if (item) {
		if (callback) { callback(context) }
		return { type: toraActionTypes.EDIT_TORA_SAIDA_CARD, item, context }
	} else {
		return { type: toraActionTypes.UPDATE_TORA_SAIDA_CARD_CANCELED }
	}
}

export const toraHelpers = {
	handleChangeFilter,
	handleDateChangeInit,
	handleDateChangeEnd,
	editCard,
	editSaidaCard,
	handleInput,
	HandleInputNumber,
	calculateDiametroMedio,
	calculateVolume,
	listTora,
	paginateTora,
	createTora,
	updateTora,
	deleteTora,
	exportData,
	toraSaidas,
	tora,
	paginateToraSaida,
	handleIndividuos,
	handleSaidaInput,
	createToraSaida,
	updateToraSaida,
	deleteToraSaida,

	// handleObjs,
	// addNewObjs,
	// paginateResgate,
	// listResgate,
	// updateResgate,
	// createResgate,
	// updateProducao,
	// createProducao,
	// exportData,
	// updateQnt,
	// listProducao,
	// handleEspecie
}