import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core"
import { raizesHelpers } from '../../../helpers/flora/raizes/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import moment from 'moment';

class RaizesEdicaoForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formSubmited: false
        }
    }

    submitForm = () => {
        const { raizesReducer, dispatch, closeModal, projectsReducer } = this.props
        const { raizes } = raizesReducer
        const { editItem, filters } = raizes
        const { editedCard } = editItem
        this.setState({ formSubmited: true })

        dispatch(raizesHelpers.updateRaizes(editedCard, 1, closeModal, projectsReducer.selectedProject, filters))

    }

    render() {
        const { dispatch, raizesReducer, projectsReducer, closeModal, placesReducer } = this.props
        const { raizes } = raizesReducer
        const { listPlaces } = placesReducer
        const { editItem, filters, documents } = raizes
        const { editedCard } = editItem
        const { formSubmited } = this.state

        return (
            <>
                <Typography variant="h5" component="h5" className="filterTitle">
                    Detalhes
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="volume"
                                label="Área Demarcada"
                                className="inputDefault"
                                value={editedCard.areaDemarcada}
                                autoComplete='off'
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="volumeTotal"
                                label="Volume Total"
                                className="inputDefault"
                                value={editedCard.volumeTotal}
                                autoComplete='off'
                                disabled={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Divider className="dividerFull" />
                        {editedCard.individuos && editedCard.individuos.map((item, index) => {
                            return (
                                <>
                                    <Grid container spacing={3} className="box-content-primary center blocked-itens" md={11} key={index}>
                                        <Grid item xs={12} md={5}>
                                            <TextField
                                                label="Data"
                                                className="inputDefault"
                                                value={moment(item?.data).format('DD/MM/YYYY')}
                                                autoComplete='off'
                                                // onChange={(event) => palmitoHelpers.handleObjs(editedCard.individuos, index, 'kgColetado', event.target.value, 'coleta', 'matriz', dispatch)}
                                                InputLabelProps={{ shrink: true, }}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <TextField
                                                label="Volume"
                                                className="inputDefault"
                                                value={item.volume}
                                                autoComplete='off'
                                                type="number"
                                                InputLabelProps={{ shrink: true, }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Button
                                                variant="contained"
                                                className="btn-secondary btn-innerTable btn-small"
                                                startIcon={<DeleteForeverIcon />}
                                                onClick={() => dispatch(raizesHelpers.deleteRaizes(item._id, editedCard.individuos, dispatch))}
                                            > Excluir
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                        <Divider className="dividerFull" />
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained"
                                    className="btn-primary"
                                    component="label"
                                    startIcon={<SaveIcon />}
                                    onClick={() => this.submitForm()}> Salvar Ficha </Button>
                            </Box>
                        </Box>
                        {editedCard._id && editedCard._id !== '' &&
                            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                                <Box padding={10}>
                                    <Button variant="contained"
                                        className="btn-secondary"
                                        component="label"
                                        startIcon={<DeleteForeverIcon />}
                                        onClick={(event) => raizesHelpers.deleteRaiz(filters, documents.page, projectsReducer.selectedProject, editedCard._id, closeModal, dispatch)}> Excluir </Button>
                                </Box>
                            </Box>
                        }
                        <Divider className="dividerFull" />
                    </Grid>
                </form>
            </>
        )
    }
}

const mapStateToProps = store => ({
    raizesReducer: store.raizesReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
    placesReducer: store.placesReducer,
});

export default connect(mapStateToProps)(RaizesEdicaoForm);