import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography } from "@material-ui/core"
import { helpers } from '../../helpers/helpers'

class filter extends Component {

    render(){
        const { dispatch, projectsReducer } = this.props
        const { filters } = projectsReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="name" label="Nome" className="inputDefault" value={filters.projectName} onChange={(event) => dispatch(helpers.handleChangeFilter(event, "projectName", "project"))}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="email" label="Sigla" className="inputDefault" value={filters.ProjectNick} onChange={(event) => dispatch(helpers.handleChangeFilter(event, "projectNick", "project"))}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('project', true)}>
                                NOVO PROJETO
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(helpers.paginateProjects(filters, 1))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(filter);