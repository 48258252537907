import Cookies from 'universal-cookie';
import moment from 'moment'
import jwtDecode from "jwt-decode";

import { faunaActions } from "../actions/faunaActions"
import { toraActions } from "../actions/toraActions"
import { raizesActions } from "../actions/raizesActions"
import { lenhaActions } from "../actions/lenhaActions"
import { paramsActions } from "../actions/paramsActions"
import { loginActions } from "../actions/loginActions"
import { usersActions } from "../actions/usersActions"
import { projectsActions } from "../actions/projectsActions"
import { placesActions } from "../actions/placesActions"
import { instListaActions } from "../actions/instListaActions"
import { actionTypes } from "../types/actionTypes"
import { instListaActionTypes } from "../types/instListaActionTypes"
import { especiesActionTypes } from "../types/especiesActionTypes"
import { trapsActionTypes } from "../types/trapsActionTypes"
import { documentsActionTypes } from "../types/documentsActionTypes"
import { faunaActionTypes } from "../types/faunaActionTypes"
import { palmitoActionTypes } from "../types/palmitoActionTypes"
import { history } from './history';
import { especiesActions } from '../actions/especiesActions';
import { alertActions } from '../actions/alertActions';
import { documentsActions } from '../actions/documentsActions';
import { areaPlantioActions } from '../actions/areaPlantioActions';
import { areaMonitoramentoActions } from '../actions/areaMonitoramentoActions';
import { auditActions } from '../actions/auditActions';

export const TOKEN_KEY = "gaia_token";

export const helpers = { 
    login,
	logout,
	setCookie,
	setCurrentProject,
	handleInput,
	handleUserModule,
	saveLocation,
	hasValidCookie,
	ChangePageClick,
	handleDateChangeInit,
	handleDateChangeEnd,
	handleChangeFilter,
	getProjects,
	handleProject,
	paginateUsers,
	editUser,
	updateUser,
	listParams,
	includeParams,
	editParams,
	createUser,
	userVerify,
	moduleVerify,
	paginateProjects,
	editProject,
	listUsers,
	handleProjectModule,
	handleProjectGeoData,
	updateProject,
	createProject,
	projectVerify,
	isAuthenticated,
	getToken,
	setToken,
	removeToken,
	isTokenValid,
	isTokenPresent,
	checkPermissions,
	resetPermissionCookie,
	isSuperUser
}

function listUsers(parameters) {
	return dispatch => {
		dispatch(usersActions.listUsers(parameters))
	}
}

function login(parameters) {
	return dispatch => {
		dispatch(loginActions.login(parameters))
	}
}

function logout() {
	const cookies = new Cookies();
	const date = new Date(moment().subtract(1, "days"))

	
	cookies.set('CurrentProject', '1', { path: '/', expires: date});
	hasValidCookie()
	removeToken();
}

function userVerify(token) {
	return dispatch => {
		try{
			const auth = jwtDecode(token)
			return dispatch({ type: actionTypes.USER_VERIFY_SUCCESS, auth })
		} catch(err) {
			return dispatch({ type: actionTypes.USER_VERIFY_FAILURE })
		}
	}
	
}

function setCookie(auth, dispatch) {
	const cookies = new Cookies();
	const date = new Date(moment().add(7, "days"))

	cookies.set('AuthUser', auth.user, { path: '/', expires: date});
	cookies.set('AuthEmail', auth.email, { path: '/', expires: date});
	cookies.set('CurrentProject', '1', { path: '/', expires: date});

	dispatch(setProject('1'))
}

function handleInput(myValue, target, context, dispatch) {
	let value = target === 'date' || target === 'addDate' || target === 'endDate' || target === 'endDateAlert' || target === 'dataObito' || target === 'dataCadastro' || target === 'dataColeta' ? myValue : myValue.target.value
	switch(context){
		case 'login':
			value = value.trim()
			return dispatch(saveStore(value, target, actionTypes.HANDLE_INPUT));
		case 'editCard':
			return dispatch(saveStore(value, target, actionTypes.HANDLE_INPUT_USERS));
		case 'editProject':
			return dispatch(saveStore(value, target, actionTypes.HANDLE_INPUT_PROJECT));
		case 'editPlace':
			return dispatch(saveStore(value, target, actionTypes.HANDLE_INPUT_PLACE));
		case 'editInstLista':
			return dispatch(saveStore(value, target, instListaActionTypes.HANDLE_INPUT_INSTLISTA));
		case 'editEspecies':
			return dispatch(saveStore(value, target, especiesActionTypes.HANDLE_INPUT_ESPECIES));
		case 'editTraps':
			return dispatch(saveStore(value, target, trapsActionTypes.HANDLE_INPUT_TRAPS));
		case 'editDocuments':
			return dispatch(saveStore(value, target, documentsActionTypes.HANDLE_INPUT_DOCUMENTS));
		default:
			return
	}

	function saveStore(value, target, context) { return { type: context, value, target } }
}

function handleProjectGeoData(target, myValue, dispatch){

	return dispatch(saveGeoDataStore(myValue, target, actionTypes.HANDLE_GEODATA_PROJECT));
	
	function saveGeoDataStore(value, target, context) { return { type: context, value, target } }
}

function handleProjectModule(module, target, myValue, dispatch){
	let newObj = module;
	newObj[target].status = myValue

	return dispatch(saveModuleStore(newObj, actionTypes.HANDLE_MODULES_PROJECT));
}

function handleUserModule(module, target, myValue, dispatch) {

	let newObj = module;

	if(module[target].write === false && myValue === 'write'){
		newObj[target].write = true;
		newObj[target].read = true;
	}else if(module[target].write === true && myValue === 'read'){
		newObj[target].write = false;
		newObj[target].read = false;
	}else{
		newObj[target][myValue] = !module[target][myValue] 
	}

	return dispatch(saveModuleStore(newObj, actionTypes.HANDLE_MODULE_USERS));
}

function saveModuleStore(value, context) { return { type: context, value } }

function saveLocation(location){ 
	return { type: actionTypes.LOCATION, location } 
}

function hasValidCookie(){ 
	const token = getToken();
	const pathName = history.location.pathname;
	
	

	if (!helpers.isTokenValid(token) && pathName !== '/' ) {
		// helpers.removeToken('gaia_token');
		return history.push("/")
	}else if( helpers.isTokenValid(token) && pathName === '/' ) {
		return history.push("/fauna/ficha")
	}

}

function listParams(parameters, page, projectId, route, type){
	return dispatch => {
		dispatch(paramsActions.listParams(parameters, page, projectId, route, type))
	}
}

function includeParams(parameters, page, projectId, route, type){
	if(!!parameters._id){
		return dispatch => {
			dispatch(paramsActions.updateParams(parameters, page, projectId, route))
		}
	}else{
		return dispatch => {
			dispatch(paramsActions.createParams(parameters, page, projectId, route))
		}
	}
}

function ChangePageClick(to, parameters, page, context, projectId, pageContext){

	const nextPage = Number(to.target.outerText)
	switch(context){
		case 'users':
			return dispatch => {
				dispatch(usersActions.paginateUsers(parameters, nextPage))
			}
		case 'fauna':
			return dispatch => {
				dispatch(faunaActions.listFauna(parameters, nextPage, projectId))
			}
		case 'tora':
			return dispatch => {
				dispatch(toraActions.paginateTora(parameters, nextPage, projectId))
			}
		case 'toraSaida':
			return dispatch => {
				dispatch(toraActions.paginateToraSaida(parameters, nextPage, projectId))
			}
		case 'raizes':
			return dispatch => {
				dispatch(raizesActions.paginateRaizes(parameters, nextPage, projectId))
			}
		case 'raizesSaida':
			return dispatch => {
				dispatch(raizesActions.paginateRaizesSaida(nextPage, projectId))
			}
		case 'lenha':
			return dispatch => {
				dispatch(lenhaActions.paginateLenha(parameters, nextPage, projectId))
			}
		case 'lenhaSaida':
			return dispatch => {
				dispatch(lenhaActions.paginateLenhaSaida(nextPage, projectId))
			}
		case 'areaPlantio':
			return dispatch => {
				dispatch(areaPlantioActions.paginateAreaPlantio(parameters, nextPage, projectId))
			}
		case 'areaMonitoramento':
			return dispatch => {
				dispatch(areaMonitoramentoActions.paginateAreaMonitoramento(parameters, nextPage, projectId))
			}
		case 'audit':
			return dispatch => {
				dispatch(auditActions.paginateAudit(parameters, nextPage, projectId))
			}
		case 'parameters':
			return dispatch => {
				dispatch(paramsActions.listParams(parameters, nextPage, projectId, pageContext, "paginate"))
			}
		case 'projects':
			return dispatch => {
				dispatch(projectsActions.paginateProjects(parameters, nextPage))
			}
		case 'places':
			return dispatch => {
				dispatch(placesActions.paginatePlaces(parameters, nextPage))
			}
		case 'instLista':
			return dispatch => {
				dispatch(instListaActions.paginate(parameters, nextPage))
			}
		case 'especies':
			return dispatch => {
				dispatch(especiesActions.paginate(parameters, nextPage))
			}
		case 'documents':
			return dispatch => {
				dispatch(documentsActions.paginate(parameters, nextPage))
			}

		default:
			return
	}
}

function handleDateChangeInit(date, prefix){
	return dispatch => {
		dispatch(handleDateInit(date, prefix))
	}
};

function handleDateChangeEnd(date, prefix){
	return dispatch => {
		dispatch(handleDateEnd(date, prefix))
	}
};

function handleDateInit(date, prefix) {return { type: faunaActionTypes[`SET_${prefix ?? 'FAUNA'}_DATE_INIT`], date }}

function handleDateEnd(date, prefix) {return { type: faunaActionTypes[`SET_${prefix ?? 'FAUNA'}_DATE_END`], date }}

function handleChangeFilter(event, target, context) {
	const value = event.target.value

	return dispatch => {
		if(context === 'fauna'){
			dispatch(setFaunaFilter(value, target))
		}else if(context === 'telemetria'){
			dispatch(setTelemetriaFilter(value, target))
		}else if(context === 'users'){
			dispatch(setUsersFilter(value, target))
		}else if(context === 'parameters'){
			dispatch(setParametersFields(value, target))
		}else if(context === 'project'){
			dispatch(setProjectsFilter(value, target))
		}else if(context === 'places'){
			dispatch(setPlacesFilter(value, target))
		}
	}
}

function setFaunaFilter(value, target) {return { type: faunaActionTypes.SET_FAUNA_FILTER, value, target }}

function setTelemetriaFilter(value, target) {return { type: faunaActionTypes.SET_TELEMETRIA_FILTER, value, target }}

function setProjectsFilter(value, target) {return { type: actionTypes.SET_PROJECTS_FILTER, value, target }}

function setPlacesFilter(value, target) {return { type: actionTypes.SET_PLACES_FILTER, value, target }}

function setUsersFilter(value, target) {return { type: actionTypes.SET_USERS_FILTER, value, target }}

function setParametersFields(value, target) {return { type: actionTypes.SET_PARAMS_FIELD, value, target }}

function getProjects(token) {
	try {
		const user = jwtDecode(token)
		return dispatch => {
			dispatch(projectsActions.getProjects(user._id))
		}

	}catch(e){
		return dispatch => {
			dispatch(alertActions.error(e.message))
		}
	}
}

function setCurrentProject() {
	const cookies = new Cookies();
	const currentProject = cookies.get('CurrentProject');

	return dispatch => {
		dispatch(setProject(currentProject))
	}
}

function handleProject(event) {
	const value = event.target.value

	return dispatch => {
		setProjectCookie(value)
		dispatch(setProject(value))
		// dispatch(getProjects())
		window.location.reload();
	}
}

function setProject(value) {return { type: actionTypes.SET_PROJECT, value }}

function setProjectCookie(selectedProject) {
	const cookies = new Cookies();
	const date = new Date(moment().add(7, "days"))

	cookies.set('CurrentProject', selectedProject, { path: '/', expires: date});
}

function paginateUsers(parameters, page){
	return dispatch => {
		dispatch(usersActions.paginateUsers(parameters, page))
	}
}

function paginateProjects(parameters, page){
	return dispatch => {
		dispatch(projectsActions.paginateProjects(parameters, page))
	}
}

function editUser(user, callback) {
	if(user){
		if(callback){ callback('user') }
		return { type: actionTypes.EDIT_USER, user }
	}else{
		return { type: actionTypes.UPDATE_USER_CANCELED }
	}
}

function editProject(project, callback) {
	if(project){
		if(callback){ callback('project') }
		return { type: actionTypes.EDIT_PROJECT, project }
	}else{
		return { type: actionTypes.UPDATE_PROJECTS_CANCELED }
	}
}

function editParams(row) { return { type: actionTypes.EDIT_PARAMS, row }}

function updateUser(parameters, page, callback){
	return dispatch => {
		dispatch(usersActions.updateUser(parameters, page, callback))
	}
}

function createUser(parameters, page, callback){
	return dispatch => {
		dispatch(usersActions.createUser(parameters, page, callback))
	}
}

function moduleVerify(loginReducer, module){
	if(loginReducer && loginReducer.loggedUser && loginReducer.loggedUser.module){
		return loginReducer.loggedUser.module.find(item => item.description === module)
	}else{
		return false
	}
}

function projectVerify(projectsReducer, module){
	if(projectsReducer && projectsReducer.listProjects && projectsReducer.listProjects.length > 0){
		let myProject = projectsReducer.listProjects.find(item => item.projectId === projectsReducer.selectedProject)
		return myProject && myProject.modules ? myProject.modules.find(project => project.description === module).status : false
	}else{
		return false
	}
}

function isSuperUser(loginReducer){
	const superUser = loginReducer && loginReducer.loggedUser && loginReducer.loggedUser.superUser ? loginReducer.loggedUser.superUser : false 
	return superUser
}

function updateProject(parameters, page, callback){
	return dispatch => {
		dispatch(projectsActions.updateProject(parameters, page, callback))
	}
}

function createProject(parameters, page, callback){
	return dispatch => {
		dispatch(projectsActions.createProject(parameters, page, callback))
	}
}
function isAuthenticated() {
	return localStorage.getItem(TOKEN_KEY) !== null;
}
function getToken() {
	return localStorage.getItem(TOKEN_KEY);
}
function setToken(token) {
	localStorage.setItem(TOKEN_KEY, token);
}
function removeToken() {
	localStorage.removeItem(TOKEN_KEY);
}
export function isTokenPresent(token) {
	if(!token) return false;
	return true;
}
export function isTokenValid(token) {
	if(!token) return false
	const localToken  = jwtDecode(helpers.getToken('gaia_token'));
	const exp = localToken.exp;
	const now = new Date().getTime() / 1000;
	if (exp < now) {
		return false;
	}return true
}
function checkPermissions(){
	const cookies = new Cookies();
	const requireAuth = cookies.get('requireAuth');
	if(requireAuth === 'true'){
		logout()
	}	
}
function resetPermissionCookie() {
	const cookies = new Cookies();
	cookies.set('requireAuth', 'false', { path: '/', expires: new Date(moment().add(7, "days"))});
}