import React, { Component } from "react"
import { Typography } from "@material-ui/core"

class header extends Component {
    render() {
        return (
            <div className="header">
                {/* <img src="images/logo.png" alt="logo"/> */}
                <Typography variant="h4" component="h4" className="headerTitle">
                    {this.props.title}
                </Typography>
            </div>
        );
    };
};

export default header;