import { areaPlantioActionTypes } from "../types/areaPlantioActionTypes";
import moment from "moment"

const initialState = {
  listDocuments: {
    areaPlantio: {},
  },
  areaPlantio: {
    loading: false,
    documents: {
      list: [],
      total: 0,
      limit: 9,
      page: 1,
      pagesTotal: 0,
      qntCounter: 0
    },
    filters: {
      areaDemarcada: '',
    },
    editItem: {
      codigoDaArea: '',
      tipo: 'Arbóreas',
      status: 'Recomposição',
      area: '',
      coord: [
        {
          coordenadaX: "",
          coordenadaY: "",
          EPE: "",
        },
        {
          coordenadaX: "",
          coordenadaY: "",
          EPE: "",
        },
        {
          coordenadaX: "",
          coordenadaY: "",
          EPE: "",
        }
      ],
      individuos: []
    },
    newItem: {
      dataDePlantio: moment().format('YYYY-MM-DD'),
      numeroFicha: '',
      tipo: '',
      areaDemarcada: '',
      nomecientifico: '',
      nomePopular: '',
      numeroDeIndividuos: '',
    }
  },
};

export const areaPlantioReducer = (state = initialState, action) => {
  switch (action.type) {
    case areaPlantioActionTypes.GET_AREA_PLANTIO_CARD_REQUEST:
    case areaPlantioActionTypes.GET_AREA_PLANTIO_CARD_FAILURE:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          areaPlantio: {}
        }
      };
    case areaPlantioActionTypes.GET_AREA_PLANTIO_CARD_SUCCESS:
      return {
        ...state,
        listDocuments: {
          ...state.listDocuments,
          areaPlantio: action.list
        }
      };
    case areaPlantioActionTypes.UPDATE_AREA_PLANTIO_CARD_REQUEST:
    case areaPlantioActionTypes.CREATE_AREA_PLANTIO_CARD_REQUEST:
    case areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_REQUEST:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          loading: true
        }
      };
    case areaPlantioActionTypes.CREATE_AREA_PLANTIO_CARD_SUCCESS:
    case areaPlantioActionTypes.UPDATE_AREA_PLANTIO_CARD_SUCCESS:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          editItem: action.list,
          loading: false
        }
      };
    case areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_SUCCESS:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          documents: {
            list: action.list.docs,
            total: action.list.total,
            limit: action.list.limit,
            page: Number(action.list.page),
            pagesTotal: action.list.pages,
            qntCounter: Number(action.list.qntCounter)
          },
          editItem: initialState.areaPlantio.editItem,
          loading: false
        }
      };
    case areaPlantioActionTypes.UPDATE_AREA_PLANTIO_CARD_FAILURE:
    case areaPlantioActionTypes.CREATE_AREA_PLANTIO_CARD_FAILURE:
    case areaPlantioActionTypes.LIST_AREA_PLANTIO_CARD_FAILURE:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          loading: false
        }
      };

    case areaPlantioActionTypes.SET_AREA_PLANTIO_FILTER:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          filters: { ...state.areaPlantio.filters, [action.target]: action.value }
        }
      };

    case areaPlantioActionTypes.SET_AREA_PLANTIO_FILTER_DATE_INIT:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          filters: {
            ...state.areaPlantio.filters,
            initDate: moment(action.date).format()
          }
        }
      };
    case areaPlantioActionTypes.SET_AREA_PLANTIO_FILTER_DATE_END:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          filters: {
            ...state.areaPlantio.filters,
            endDate: moment(action.date).format()
          }
        }
      };

    case areaPlantioActionTypes.EDIT_AREA_PLANTIO_CARD:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          editItem: action.item
        }
      };
    case areaPlantioActionTypes.UPDATE_AREA_PLANTIO_INDIVIDUOS:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          editItem: {
            ...state.areaPlantio.editItem,
            individuos: action.value
          }
        }
      };

    case areaPlantioActionTypes.UPDATE_AREA_PLANTIO_CARD_CANCELED:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          editItem: initialState.areaPlantio.editItem,
          loading: false
        }
      };
    case areaPlantioActionTypes.HANDLE_AREA_PLANTIO_FIELDS:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          editItem: {
            ...state.areaPlantio.editItem,
            [action.target]: action.value
          }
        }
      };
    case areaPlantioActionTypes.HANDLE_NEW_ITEM_FIELDS:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          newItem: {
            ...state.areaPlantio.newItem,
            [action.target]: action.value
          }
        }
      };
    case areaPlantioActionTypes.EDIT_ITEM:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          newItem: action.value
        }
      };
    case areaPlantioActionTypes.CLEAR_NEW_ITEM_FIELDS:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          newItem: initialState.areaPlantio.newItem
        }
      };
    case areaPlantioActionTypes.HANDLE_AREA_PLANTIO_TOTAL_VOLUME:
      return {
        ...state,
        areaPlantio: {
          ...state.areaPlantio,
          editItem: {
            ...state.areaPlantio.editItem,
            [action.target]: action.value
          }
        }
      };


    default:
      return state;
  }
};