import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"
import { floraEspeciesHelpers } from '../../../helpers/flora/especies/helpers';

class filter extends Component {

    render(){
        const { dispatch, floraEspeciesReducer } = this.props
        const { filters } = floraEspeciesReducer

        return (
            <div className="filter">
                <Typography variant="h5" component="h5" className="filterTitle">
                        Pesquisar
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField id="nomeCientifico" label="Nome Científico" className="inputDefault" value={filters.nomeCientifico} onChange={(event) => dispatch(floraEspeciesHelpers.handleChangeFilter(event, "nomeCientifico"))}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="nomePopular" label="Nome Popular" className="inputDefault" value={filters.nomePopular} onChange={(event) => dispatch(floraEspeciesHelpers.handleChangeFilter(event, "nomePopular"))}/>
                        </Grid>
                        <Divider className="dividerFull"/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-secondary" onClick={() => this.props.handleModal('floraEspecies', true)}>
                                NOVA ESPÉCIE
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}/>
                        <Grid item xs={12} md={3}>
                            <Button variant="contained" className="btn-primary" onClick={() => dispatch(floraEspeciesHelpers.paginate(filters, 1))}>
                                PESQUISAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    floraEspeciesReducer: store.floraEspeciesReducer,
});

export default connect(mapStateToProps)(filter);