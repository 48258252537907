import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableContainer, Table, TableBody, Button, Divider } from '@material-ui/core'
import { Pagination, Alert } from '@material-ui/lab';
import Header from '../../../Components/headerAlter'
import Filter from '../../../Components/flora/palmito/filter'
import CustomModal from '../../../Components/customModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { history } from '../../../helpers/history';
import { helpers } from '../../../helpers/helpers';
import { palmitoHelpers } from '../../../helpers/flora/palmito/helpers';
import { placesHelpers } from '../../../helpers/places/placesHelpers'

class PalmitoSaida extends Component {

  constructor(props){
    super(props);
    this.state = {
        modal: {showModal: false, modalType: undefined}
    }
  }

  componentDidMount(){
    const { dispatch, palmitoReducer, projectsReducer } = this.props
    dispatch(helpers.saveLocation(history.location.pathname));
    dispatch(palmitoHelpers.paginateSaida(undefined, palmitoReducer.documents.page, projectsReducer.selectedProject))
    dispatch(palmitoHelpers.listTalhao());
    dispatch(palmitoHelpers.listMatriz());
    dispatch(placesHelpers.listPlaces(projectsReducer.selectedProject));
  }

  handleModal = (modalType, clear) => {
    const { modal } = this.state
    const { dispatch } = this.props
    this.setState({modal:{ showModal: !modal.showModal, modalType: modalType}})
    if(clear){
        dispatch(palmitoHelpers.editCard('saida', false))
    }
  }

  render(){
    const { palmitoReducer, dispatch, alert } = this.props
    const { modal } = this.state
    const { documents, editItem } = palmitoReducer
    const { page, pagesTotal } = documents
    return (
      <div className='mainContainer'>
        <Header title='SAÍDA DE PALMITO'/>
        <div className='basicContainer'>
          {alert && alert.type === 'alert-danger' && alert.message &&
                  <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
          }
          {alert && alert.type === 'alert-success' && alert.message &&
                  <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
          }
          <Filter context={'saida'} handleModal={this.handleModal}/>
          {documents && documents.list &&
            <Grid container>
              <TableContainer className="customTable paramsTable">
                <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <TableHead className="tableHead">
                    <Grid component={'tr'} container className="parametersTable">
                      <Grid component={'th'} item xs={12} md={3}>Número da saída</Grid>
                      <Grid component={'th'} item xs={12} md={3}>Data de saída</Grid>
                      <Grid component={'th'} item xs={12} md={3}>Quantidade</Grid>
                      <Grid component={'th'} item xs={12} md={2}>Tipo</Grid>
                      <Grid component={'th'} item xs={12} md={1}></Grid>
                    </Grid>
                  </TableHead>
                  <TableBody>
                    {documents.list.map((item, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Grid component={'tr'} container className="parametersTable" key={index} id={labelId}>
                          <Grid component={'td'} item xs={12} md={3}>{item.numeroSaida}</Grid>
                          <Grid component={'td'} item xs={12} md={3}>{item.dataSaida ? moment(item.dataSaida).format('DD/MM/YYYY') : ""}</Grid>
                          <Grid component={'td'} item xs={12} md={3}>{item.quantidade}</Grid>
                          <Grid component={'td'} item xs={12} md={2}>{item.tipo}</Grid>
                          <Grid component={'td'} item xs={12} md={1}>
                            <Button
                              variant="contained"
                              className="btn-secondary btn-innerTable"
                              onClick={() => dispatch(palmitoHelpers.editCard('saida', item, this.handleModal))}
                              disabled={(editItem.matriz && editItem.matriz._id) ? true : false}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider className="dividerFull"/>
              <Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, undefined, page, 'documents'))} />
            </Grid>
          }
        </div>
        {modal.showModal &&
          <CustomModal modalFn={this.handleModal} modalType={modal.modalType}/>
        }
      </div>
    )
  }
}

const mapStateToProps = store => ({
  palmitoReducer: store.palmitoReducer,
  projectsReducer: store.projectsReducer,
  paramsReducer: store.paramsReducer,
  auth: store.loginReducer.auth,
  alert: store.alertReducer
});

export default connect(mapStateToProps)(PalmitoSaida);