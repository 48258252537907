import React, { useState } from 'react';
import { Grid, GridList, GridListTile, Box, Dialog,DialogTitle, makeStyles, Button, ButtonGroup, DialogContent, Typography, Container } from "@material-ui/core"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { blue } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { faunaActions } from '../../actions/faunaActions';


const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",

  },
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
    contaioner: {
        display: 'flex',
        justifyContent: 'center',
    },
    warning: {
        margin: '20px auto',
    },
    gridList: {
      width: '100%',
    },
    gridItem: {
      display: 'flex',
      flexGrow: 1
    }
});
  
function SimpleDialog(props) {
    
    const classes = useStyles();
    const { onClose, selectedValue, open, callBackFn } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Confirmar Exclusão de imagem</DialogTitle>
        <DialogContent>
            <Typography variant='p' component='p' className={classes.warning}>
                Essa ação não poderá ser desfeita.
            </Typography>

            <Container className={classes.container}>
                
                <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                    <Button className='btn-secondary' onClick={handleClose}>Cancelar</Button>
                    <Button className='btn-primary' onClick={callBackFn} variant="contained" color="secondary">Excluir</Button>
                </ButtonGroup>

            </Container>
        </DialogContent>
        
        
      </Dialog>
    );
}
const AccordionCaptura = ({ selectedCard, modalFn, dispatch }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [ url, setUrl ] = useState('');

    const handleDelete = () => {
        if(process.env.REACT_APP_ENVIRONMENT){
          alert('Você esta em ambiente de desenvolvimento consumindo informações de produção, não é possível alterar imagens');
          return
        }
        dispatch(faunaActions.deleteImage(url));
        setOpen(false);
    }

    const handleClickOpen = (url) => {
        setUrl(url)
        setOpen(true);
      };
    
      const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
      };

    return (
        
        <Grid container className="imagesContainer">
            <div className={classes.root}>
              <GridList cellHeight={200} className={classes.gridList} cols={3}>
                {selectedCard.pictures.map((tile,idx,arr) => (
                  <GridListTile key={tile} cols={tile.cols || 1}  className={arr.length === 1 ? classes.gridItem: {}}>
                    <img src={`https://gaia-bucket.nyc3.digitaloceanspaces.com/${tile}`} alt={tile}  onClick={(event) => modalFn('imagem', event.target.src)}/>
                    <Box zIndex="tooltip">
                        <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} callBackFn={handleDelete} />
                        <FontAwesomeIcon icon={faTrash} id="expandButton" onClick={() => handleClickOpen(tile) } />
                      </Box>
                  </GridListTile>
                ))}
              </GridList>          
            </div>  
        </Grid>
    )
}

const mapStateToProps = store => ({
    faunaReducer: store.faunaReducer,
});

export default connect(mapStateToProps)(AccordionCaptura)