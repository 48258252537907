import { instListaActionTypes } from "../types/instListaActionTypes";
import moment from "moment";

const initialState = {
  listPlaces: null,
  loading: false,
  instLista: {
    list: [],
    total: 0,
    limit: 9,
    page: 1,
    pagesTotal: 0
  },
  filters: {
    name: ''
  },
  editItem: {
    name: '',
    nickname: '',
    status: true,
    date: moment().format(),
    classe: [
      {description: '', status: true}
    ],
  }
};

export const instListaEspeciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case instListaActionTypes.GET_INSTLISTA_REQUEST:
      return { ...state, listPlaces: state.listPlaces, loading: true };
    case instListaActionTypes.GET_INSTLISTA_SUCCESS:
      return { ...state, listPlaces: action.places, loading: false };
    case instListaActionTypes.GET_INSTLISTA_FAILURE:
      return { ...state, listPlaces: state.listFauna, loading: false };

    case instListaActionTypes.CREATE_INSTLISTA_REQUEST:
    case instListaActionTypes.UPDATE_INSTLISTA_REQUEST:
    case instListaActionTypes.LIST_INSTLISTA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case instListaActionTypes.CREATE_INSTLISTA_SUCCESS:
    case instListaActionTypes.UPDATE_INSTLISTA_SUCCESS:
    case instListaActionTypes.LIST_INSTLISTA_SUCCESS:
      return {
        ...state,
        instLista: {list: action.listPlaces.docs, total: action.listPlaces.total, limit: action.listPlaces.limit, page: Number(action.listPlaces.page), pagesTotal: action.listPlaces.pages},
        editItem: initialState.editItem,
        loading: false
      };
    case instListaActionTypes.UPDATE_INSTLISTA_CANCELED:
      return {
        ...state,
        editItem: initialState.editItem,
        loading: false
      };
    case instListaActionTypes.CREATE_INSTLISTA_FAILURE:
    case instListaActionTypes.UPDATE_INSTLISTA_FAILURE:
    case instListaActionTypes.LIST_INSTLISTA_FAILURE:
      return {
        ...state,
        loading: false
      };
    case instListaActionTypes.EDIT_INSTLISTA:
      return { ...state, editItem: action.places };
    case instListaActionTypes.HANDLE_INSTLISTA:
    case instListaActionTypes.HANDLE_RADIOS_INSTLISTA:
    case instListaActionTypes.HANDLE_INPUT_INSTLISTA:
      return {
        ...state,
        editItem: {...state.editItem, [action.target]: action.value}
      };
    case instListaActionTypes.SET_INSTLISTA_FILTER:
      return {
        ...state,
        filters: {...state.filters, [action.target]: action.value}
      };
    default:
      return state;
  }
};