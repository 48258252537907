import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider } from "@material-ui/core"

import { raizesHelpers } from '../../../helpers/flora/raizes/helpers';

class filter extends Component {

  render() {
    const { dispatch, raizesReducer, context, projectsReducer } = this.props
    const { raizes } = raizesReducer
    const { filters } = raizes

    return (
      <>
        <div className="filter">
          <Typography variant="h5" component="h5" className="filterTitle">
            Pesquisar
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="areaDemarcada"
                    label="Área demarcada"
                    className="inputDefault"
                    value={filters.areaDemarcada}
                    onChange={(event) => dispatch(raizesHelpers.handleChangeFilter(event, "areaDemarcada", context))}
                  />
                </Grid>
              </>
              <Divider className="dividerFull" />
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => this.props.handleModal(context, true)}
                >
                  ADICIONAR RAIZES
                </Button>
              </Grid>
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-secondary"
                  onClick={() => dispatch(raizesHelpers.raizesSaidas())}
                >
                  SAÍDAS
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={
                    () => {
                      if (context === 'raizes') {
                        dispatch(raizesHelpers.paginateRaizes(filters, 1, projectsReducer.selectedProject))
                      } else {
                        // dispatch(raizesHelpers.paginateProducao(filters, 1))
                      }
                    }
                  }
                >
                  PESQUISAR
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        {/* <div id="qntCounter">
          <p>
            {`Quantidade de exemplares no viveiro: `}
            <span>{documents.qntCounter ? documents.qntCounter : '0' }</span>
          </p>
        </div> */}
      </>
    )
  }
}

const mapStateToProps = store => ({
  raizesReducer: store.raizesReducer,
  paramsReducer: store.paramsReducer,
  projectsReducer: store.projectsReducer
});

export default connect(mapStateToProps)(filter);