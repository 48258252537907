export const toraActionTypes = {

    // TORA

    GET_TORA_CARD_REQUEST: "GET_TORA_CARD_REQUEST",
    GET_TORA_CARD_SUCCESS: "GET_TORA_CARD_SUCCESS",
    GET_TORA_CARD_FAILURE: "GET_TORA_CARD_FAILURE",

    LIST_TORA_CARD_REQUEST: "LIST_TORA_CARD_REQUEST",
    LIST_TORA_CARD_SUCCESS: "LIST_TORA_CARD_SUCCESS",
    LIST_TORA_CARD_FAILURE: "LIST_TORA_CARD_FAILURE",

    SET_TORA_FILTER: "SET_TORA_FILTER",

    SET_TORA_FILTER_DATE_INIT: "SET_TORA_FILTER_DATE_INIT",
    SET_TORA_FILTER_DATE_END: "SET_TORA_FILTER_DATE_END",

    EDIT_TORA_CARD: "EDIT_TORA_CARD",
    UPDATE_TORA_CARD_CANCELED: "UPDATE_TORA_CARD_CANCELED",
    HANDLE_TORA_FIELDS: "HANDLE_TORA_FIELDS",

    CREATE_TORA_CARD_REQUEST: "CREATE_TORA_CARD_REQUEST",
    CREATE_TORA_CARD_SUCCESS: "CREATE_TORA_CARD_SUCCESS",
    CREATE_TORA_CARD_FAILURE: "CREATE_TORA_CARD_FAILURE",

    UPDATE_TORA_CARD_REQUEST: "UPDATE_TORA_CARD_REQUEST",
    UPDATE_TORA_CARD_SUCCESS: "UPDATE_TORA_CARD_SUCCESS",
    UPDATE_TORA_CARD_FAILURE: "UPDATE_TORA_CARD_FAILURE",

    EXPORT_TORA_REQUEST: "EXPORT_TORA_REQUEST",
    EXPORT_TORA_SUCCESS: "EXPORT_TORA_SUCCESS",
    EXPORT_TORA_FAILURE: "EXPORT_TORA_FAILURE",

    // TORA SAIDA

    GET_TORA_SAIDA_CARD_REQUEST: "GET_TORA_SAIDA_CARD_REQUEST",
    GET_TORA_SAIDA_CARD_SUCCESS: "GET_TORA_SAIDA_CARD_SUCCESS",
    GET_TORA_SAIDA_CARD_FAILURE: "GET_TORA_SAIDA_CARD_FAILURE",

    LIST_TORA_SAIDA_CARD_REQUEST: "LIST_TORA_SAIDA_CARD_REQUEST",
    LIST_TORA_SAIDA_CARD_SUCCESS: "LIST_TORA_SAIDA_CARD_SUCCESS",
    LIST_TORA_SAIDA_CARD_FAILURE: "LIST_TORA_SAIDA_CARD_FAILURE",

    CREATE_TORA_SAIDA_CARD_REQUEST: "CREATE_TORA_SAIDA_CARD_REQUEST",
    CREATE_TORA_SAIDA_CARD_SUCCESS: "CREATE_TORA_SAIDA_CARD_SUCCESS",
    CREATE_TORA_SAIDA_CARD_FAILURE: "CREATE_TORA_SAIDA_CARD_FAILURE",

    UPDATE_TORA_SAIDA_CARD_REQUEST: "UPDATE_TORA_SAIDA_CARD_REQUEST",
    UPDATE_TORA_SAIDA_CARD_SUCCESS: "UPDATE_TORA_SAIDA_CARD_SUCCESS",
    UPDATE_TORA_SAIDA_CARD_FAILURE: "UPDATE_TORA_SAIDA_CARD_FAILURE",

    SET_TORA_SAIDA_FILTER: "SET_TORA_SAIDA_FILTER",

    SET_TORA_SAIDA_FILTER_DATE_INIT: "SET_TORA_SAIDA_FILTER_DATE_INIT",
    SET_TORA_SAIDA_FILTER_DATE_END: "SET_TORA_SAIDA_FILTER_DATE_END",

    EDIT_TORA_SAIDA_CARD: "EDIT_TORA_SAIDA_CARD",
    UPDATE_TORA_SAIDA_CARD_CANCELED: "UPDATE_TORA_SAIDA_CARD_CANCELED",
    HANDLE_TORA_SAIDA_FIELDS: "HANDLE_TORA_SAIDA_FIELDS",

};