import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const paramsHandler = { 
	listParams,
	createParams,
	updateParams
};

function listParams(parametersParams, page, projectId, route, type) {
	const $color = parametersParams && parametersParams.cardType ? `&cardType=${parametersParams.cardType}` : ""
	const $status = parametersParams && parametersParams.classType ? `&classType=${parametersParams.classType}` : ""

	const queryParams = `?page=${page ? page : 1}&projectId=${projectId}${$color}${$status}`

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat(`${route}/${type}`).concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createParams(parametersParams, page, projectId, route) {

	const queryParams = "?page=".concat(page)
	.concat(`&projectId=${projectId}`)

	let params = {}

	switch (route) {
		case 'ringColors':
			params.color = parametersParams.color;
			params.status = parametersParams.status;
			break;
		case 'trapType':
			params.trapType = parametersParams.trapType;
			params.status = parametersParams.status;
			break;
		case 'environmentType':
			params.environmentType = parametersParams.environmentType;
			params.status = parametersParams.status;
			break;
		case 'caracFlora':
			params.description = parametersParams.description;
			params.status = parametersParams.status;
			break;
		case 'orgEmissor':
			params.name = parametersParams.name;
			params.status = parametersParams.status;
			break;
		case 'documentsType':
			params.short = parametersParams.short;
			params.modulo = parametersParams.modulo;
			params.description = parametersParams.description;
			params.status = parametersParams.status;
			break;
		case 'faunaClass':
			params.classe = parametersParams.classe;
			params.status = parametersParams.status;
			break;
		default:
			break;
	}


	const requestOptions = { 
		method: "POST",
		headers: defaultHeaders,
		body: JSON.stringify(params),
	};

	return fetch(prefixDefaultApi.concat(`${route}`).concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateParams(parametersParams, page, projectId, route) {
    
	const queryParams = "?page=".concat(page)
	.concat(`&projectId=${projectId}`)

	let params = {}

	switch (route) {
		case 'ringColors':
			params._id = parametersParams._id
			params.color = parametersParams.color;
			params.status = parametersParams.status;
			break;
		case 'trapType':
			params._id = parametersParams._id
			params.trapType = parametersParams.trapType;
			params.status = parametersParams.status;
			break;
		case 'environmentType':
			params._id = parametersParams._id
			params.environmentType = parametersParams.environmentType;
			params.status = parametersParams.status;
			break;
		case 'caracFlora':
			params._id = parametersParams._id
			params.description = parametersParams.description;
			params.status = parametersParams.status;
			break;
		case 'orgEmissor':
			params._id = parametersParams._id
			params.name = parametersParams.name;
			params.status = parametersParams.status;
			break;
		case 'documentsType':
			params._id = parametersParams._id
			params.short = parametersParams.short;
			params.modulo = parametersParams.modulo;
			params.description = parametersParams.description;
			params.status = parametersParams.status;
			break;
		case 'faunaClass':
			params._id = parametersParams._id
			params.classe = parametersParams.classe;
			params.status = parametersParams.status;
			break;
		default:
			break;
	}


	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat(`${route}`).concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}