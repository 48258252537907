import { actionTypes } from '../types/actionTypes';
import { history } from '../helpers/history'

const initialState = {
	location: history.location.pathname
  };

export const locationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOCATION:
			return {
				location: action.location
			};
		default:
			return state
	}
}