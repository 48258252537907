import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import { defaultHeaders } from "../helpers/headerHelper";

export const floraEspeciesHandler = { 
	list, 
	paginate, 
	create,
	update,
};

function list() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/especies/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginate(params, page) {
	const $numeroLote = params && params.numeroLote ? `&numeroLote=${params.numeroLote}` : ""
	const $nomeCientifico = params && params.nomeCientifico ? `&nomeCientifico=${params.nomeCientifico}` : ""
	const $nomePopular = params && params.nomePopular ? `&nomePopular=${params.nomePopular}` : ""
	const $initDate = params && params.initDate !== null ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate !== null ? `&endDate=${params.endDate}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($numeroLote)
	.concat($nomeCientifico)
	.concat($nomePopular)
	.concat($initDate)
	.concat($endDate)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/especies/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function create(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/especies").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function update(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/especies").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}
