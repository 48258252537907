import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactLoading from 'react-loading'

export class Loading extends Component {

    render () {
        const { parameters, login, fauna } = this.props

        return (
            <>
                {(parameters.loading || login.loading || fauna.loading) && 
                    <div className="loadingBg">
                        <ReactLoading type='spinningBubbles' color='#F28C00' height={'3%'} width={'3%'} />
                    </div>
                }
            </>
        );
    }
}


const mapStateToProps = store => ({
    parameters: store.paramsReducer,
    login: store.loginReducer,
    fauna: store.faunaReducer
});
  
export default connect(mapStateToProps)(Loading);