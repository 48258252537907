export const auditActionTypes = {

    // AREA MONITORAMENTO

    GET_AUDIT_CARD_REQUEST: "GET_AUDIT_CARD_REQUEST",
    GET_AUDIT_CARD_SUCCESS: "GET_AUDIT_CARD_SUCCESS",
    GET_AUDIT_CARD_FAILURE: "GET_AUDIT_CARD_FAILURE",

    LIST_AUDIT_CARD_REQUEST: "LIST_AUDIT_CARD_REQUEST",
    LIST_AUDIT_CARD_SUCCESS: "LIST_AUDIT_CARD_SUCCESS",
    LIST_AUDIT_CARD_FAILURE: "LIST_AUDIT_CARD_FAILURE",

    SET_AUDIT_FILTER: "SET_AUDIT_FILTER",

    SET_AUDIT_FILTER_DATE_INIT: "SET_AUDIT_FILTER_DATE_INIT",
    SET_AUDIT_FILTER_DATE_END: "SET_AUDIT_FILTER_DATE_END",

    EDIT_AUDIT_CARD: "EDIT_AUDIT_CARD",
    EDIT_AUDIT_CARD_CANCELED: "EDIT_AUDIT_CARD_CANCELED",
    
    CLEAR_FIELDS: "CLEAR_FIELDS"
};