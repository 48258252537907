import { viveirosHandler } from '../handlers/viveirosHandler';
import { viveirosActionTypes } from '../types/viveirosActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const viveirosActions = {
    listResgate,
    paginateResgate,
    createResgate,
    updateResgate,
    exportResgate,
    listProducao,
    paginateProducao,
    createProducao,
    updateProducao,
    exportProducao,
    upload
  };

function listResgate() {
    return dispatch => {
        dispatch(request());
        viveirosHandler.listResgate()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: viveirosActionTypes.GET_VIVEIROS_RESGATE_REQUEST } }
    function success(places) { return { type: viveirosActionTypes.GET_VIVEIROS_RESGATE_SUCCESS, places } }
    function failure(error) { return { type: viveirosActionTypes.GET_VIVEIROS_RESGATE_FAILURE, error } }
}

function paginateResgate(params, page, project) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.paginateResgate(params, page, project)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.LIST_VIVEIROS_RESGATE_REQUEST } }
    function success(list) { return { type: viveirosActionTypes.LIST_VIVEIROS_RESGATE_SUCCESS, list } }
    function failure(error) { return { type: viveirosActionTypes.LIST_VIVEIROS_RESGATE_FAILURE, error } }
}

function createResgate(params, page, callback, callbackParams, project) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.createResgate(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateResgate(callbackParams, page, project))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.CREATE_VIVEIROS_RESGATE_REQUEST } }
    function success(list) { return { type: viveirosActionTypes.CREATE_VIVEIROS_RESGATE_SUCCESS, list } }
    function failure(error) { return { type: viveirosActionTypes.CREATE_VIVEIROS_RESGATE_FAILURE, error } }
}

function updateResgate(params, page, callback, callbackParams, project) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.updateResgate(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                dispatch(paginateResgate(callbackParams, page, project))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.UPDATE_VIVEIROS_RESGATE_REQUEST } }
    function success(list) { return { type: viveirosActionTypes.UPDATE_VIVEIROS_RESGATE_SUCCESS, list } }
    function failure(error) { return { type: viveirosActionTypes.UPDATE_VIVEIROS_RESGATE_FAILURE, error } }
}

function exportResgate(params, projectId) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.exportResgate(params, projectId)
        .then(response => {
                dispatch(success());
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.EXPORT_VIVEIROS_RESGATE_REQUEST } }
    function success() { return { type: viveirosActionTypes.EXPORT_VIVEIROS_RESGATE_SUCCESS } }
    function failure(error) { return { type: viveirosActionTypes.EXPORT_VIVEIROS_RESGATE_FAILURE, error } }
}

function listProducao() {
    return dispatch => {
        dispatch(request());
        viveirosHandler.listProducao()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: viveirosActionTypes.GET_VIVEIROS_PRODUCAO_REQUEST } }
    function success(places) { return { type: viveirosActionTypes.GET_VIVEIROS_PRODUCAO_SUCCESS, places } }
    function failure(error) { return { type: viveirosActionTypes.GET_VIVEIROS_PRODUCAO_FAILURE, error } }
}

function paginateProducao(params, page, project) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.paginateProducao(params, page, project)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.LIST_VIVEIROS_PRODUCAO_REQUEST } }
    function success(list) { return { type: viveirosActionTypes.LIST_VIVEIROS_PRODUCAO_SUCCESS, list } }
    function failure(error) { return { type: viveirosActionTypes.LIST_VIVEIROS_PRODUCAO_FAILURE, error } }
}

function createProducao(params, page, callback, callbackParams, project) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.createProducao(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginateProducao(callbackParams, page, project))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.CREATE_VIVEIROS_PRODUCAO_REQUEST } }
    function success(list) { return { type: viveirosActionTypes.CREATE_VIVEIROS_PRODUCAO_SUCCESS, list } }
    function failure(error) { return { type: viveirosActionTypes.CREATE_VIVEIROS_PRODUCAO_FAILURE, error } }
}

function updateProducao(params, page, callback, callbackParams, project) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.updateProducao(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                dispatch(paginateProducao(callbackParams, page, project))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.UPDATE_VIVEIROS_PRODUCAO_REQUEST } }
    function success(list) { return { type: viveirosActionTypes.UPDATE_VIVEIROS_PRODUCAO_SUCCESS, list } }
    function failure(error) { return { type: viveirosActionTypes.UPDATE_VIVEIROS_PRODUCAO_FAILURE, error } }
}

function exportProducao(params, projectId) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.exportProducao(params, projectId)
        .then(response => {
                dispatch(success());
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.EXPORT_VIVEIROS_PRODUCAO_REQUEST } }
    function success() { return { type: viveirosActionTypes.EXPORT_VIVEIROS_PRODUCAO_SUCCESS } }
    function failure(error) { return { type: viveirosActionTypes.EXPORT_VIVEIROS_PRODUCAO_FAILURE, error } }
}
function upload(file, fileName, id) {
    return dispatch => {
        dispatch(request());
        viveirosHandler.upload(file, fileName, id)
        .then(response => {
                dispatch(success(response));
                // if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: viveirosActionTypes.UPLOAD_VIVEIROS_REQUEST } }
    function success(list) { return { type: viveirosActionTypes.UPLOAD_VIVEIROS_SUCCESS, list } }
    function failure(error) { return { type: viveirosActionTypes.UPLOAD_VIVEIROS_FAILURE, error } }
}