import { floraEspeciesHandler } from '../handlers/floraEspeciesHandler';
import { floraEspeciesActionTypes } from '../types/floraEspeciesActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const floraEspeciesActions = {
    list,
    paginate,
    create,
    update,
  };

function list() {
    return dispatch => {
        dispatch(request());
        floraEspeciesHandler.list()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: floraEspeciesActionTypes.GET_FLORA_ESPECIES_REQUEST } }
    function success(places) { return { type: floraEspeciesActionTypes.GET_FLORA_ESPECIES_SUCCESS, places } }
    function failure(error) { return { type: floraEspeciesActionTypes.GET_FLORA_ESPECIES_FAILURE, error } }
}

function paginate(params, page) {
    return dispatch => {
        dispatch(request());
        floraEspeciesHandler.paginate(params, page)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: floraEspeciesActionTypes.LIST_FLORA_ESPECIES_REQUEST } }
    function success(list) { return { type: floraEspeciesActionTypes.LIST_FLORA_ESPECIES_SUCCESS, list } }
    function failure(error) { return { type: floraEspeciesActionTypes.LIST_FLORA_ESPECIES_FAILURE, error } }
}

function create(params, page, callback, callbackParams) {
    return dispatch => {
        dispatch(request());
        floraEspeciesHandler.create(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento criado com sucesso'))
                dispatch(paginate(callbackParams, page))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: floraEspeciesActionTypes.CREATE_FLORA_ESPECIES_REQUEST } }
    function success(list) { return { type: floraEspeciesActionTypes.CREATE_FLORA_ESPECIES_SUCCESS, list } }
    function failure(error) { return { type: floraEspeciesActionTypes.CREATE_FLORA_ESPECIES_FAILURE, error } }
}

function update(params, page, callback, callbackParams) {
    return dispatch => {
        dispatch(request());
        floraEspeciesHandler.update(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Documento atualizado com sucesso'))
                dispatch(paginate(callbackParams, page))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: floraEspeciesActionTypes.UPDATE_FLORA_ESPECIES_REQUEST } }
    function success(list) { return { type: floraEspeciesActionTypes.UPDATE_FLORA_ESPECIES_SUCCESS, list } }
    function failure(error) { return { type: floraEspeciesActionTypes.UPDATE_FLORA_ESPECIES_FAILURE, error } }
}