import { faunaActionTypes } from "../types/faunaActionTypes";
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';


const initialState = {
  listFauna: [],
  listTelemetria: [],
  loading: false,
  page: 1,
  pagesTotal: 1,
  filters: {
    initDate: moment().subtract(7, 'days').format(),
    endDate: moment().format(),
    cardType: "todos",
    classType: false,
    numeroFicha: "",
    numeroAnimal: "",
    orderBy: "dataCriacao"
  },
  telemetriaFilters: {
    nomeCientifico: "",
    codigo: "",
  },
  selectOptions: {
    cardType: ["Captura", "Ocorrência", "Recebimento", "Recaptura"],
    cardTypeFilter: ["Captura", "Ocorrência", "Recebimento", "Recaptura", "Captura e Recaptura"],
    classType: ["Mamíferos", "Aves", "Répteis", "Todos", "Anfíbios", "Peixes", "Invertebrados"],
    marcacaoType: ["Microchip", "Anilha", "Sem marcação"],
    ocorrenciaType: ['Visualização', 'Vestígio', 'Som', 'Animal morto'],
    vestigioType: ['pegadas', 'fezes', 'penas/pêlos', 'ninho/toca', 'ovos', 'marca de garras', 'garras', 'regurgito'],
    comportamentoType: ['alimentação', 'sono/descanso', 'vôo/nadando/andando', 'cópula'],
  },
  selectedCard: {
    cardType: "",
    projectId: "",
    pictures: [],
    documents: [],
    numeroFicha: "",
    classe: "",
    ordem: "",
    familia: "",
    subFamilia: "",
    genero: "",
    especie: "",
    especieId: "",
    subEspecie: "",
    nomeCientifico: "",
    nomePopular: "",
    dataObs: "",
    localObs: "",
    ambiente: "",
    manipulador: "",
    pontoObs: {
      coordX: "",
      coordY: "",
      coordProj: "",
    },
    epe: "",
    sexo: "",
    prenhez: "",
    obsAdicionaisObs: "",
    obsOcorrencia: "",
    numeroAnimal: "",
    marcacao: "",
    corAnilha: "",
    dataCaptura: "",
    dataRecebimento: "",
    localCaptura: "",
    localRecebimento: "",
    orgaoResponsavel: "",
    quantidade: "",
    licenca: "",
    armadilha: "",
    pontoCaptura: {
      coordX: "",
      coordY: "",
      coordProj: "",
    },
    epeSoltura: "",
    compFocinhoCloaca: "",
    compFocinhoAnus: "",
    compFocinhoCauda: "",
    compMembroPost: "",
    compCloacaCauda: "",
    compCranio: "",
    compFocinhoCranio: "",
    compTotal: "",
    compCasco: "",
    largCasco: "",
    compplastrao: "",
    largplastrao: "",
    compCauda: "",
    compAsa: "",
    compTarso: "",
    alturaBico: "",
    larguraBico: "",
    compBico: "",
    alturaCernelha: "",
    alturaOrelha: "",
    antebraco: "",
    envergadura: "",
    compPe: "",
    membranaInterfemoral: "",
    scorePeitoral: "",
    coloracaoVentre: "",
    coloracaoDorso: "",
    membranaInterdigitais: "",
    peloCauda: "",
    tipo: "",
    comportamento: "",
    vestigio: "",
    denticao: {
      incisivos: "",
      caninos: "",
      preMolares: "",
      molares: "",
      totalDentes: "",
    },
    peso: "",
    faixaEtaria: "",
    obsAdicionais: "",
    dataSoltura: "",
    localSoltura: "",
    pontoSoltura: {
      coordX: "",
      coordY: "",
      coordProj: "",
    },
    radiotransmissor: "",
    frequencia: "",
    origemSoltura: "",
    documento: "",
    capturadoPor: "",
    manipuladoPor: "",
    dataManipulacao: "",
    amostraNumero: "",
    obsAdicionaisSoltura: "",
    obito: "",
    obitoData: "",
    obitoObs: "",
    ph: "",
    temperatura: "",
    linha_lateral_dividida: false,
    linha_lateral_inteira: false,
    opeculo: false,
    barbilhao_maxilar: false,
    barbilhao_mental: false,
    barbilhao_pos_mental: false,
    boca_terminal: false,
    boca_ventral: false,
    nadadeira_dorsal: false,
    nadadeira_ventral: false,
    nadadeira_peitoral: false,
    nadadeira_anal: false,
    nadadeira_adiposa: false,
    nadadeira_caudal_div: false,
    nadadeira_caudal_int: false,
    escamas: false,
    escamas_tam: "",
    qtd_abertura_branquial: "",
    numDedos: "",
    extrDedos: "",
    pupila: "",
    tamTimpano: "",
    visibTimpano: "",
    supercilio: "",
    cristasCefalicas: "",
    compDedoPost: "",
    qntCapturada: "",
    corMarcacao: "",
    formatedDate: {
      dataObs: null,
      dataCaptura: null,
      dataRecebimento: null,
      dataSoltura: null,
      dataManipulacao: null,
      obitoData: null,
    }
  },
  selectedTelemetriaCard: {
    codigo: null,
    classe: "",
    ordem: "",
    familia: "",
    subFamilia: "",
    genero: "",
    especie: "",
    especieId: "",
    subEspecie: "",
    nomeCientifico: "",
    nomePopular: "",
    radiotransmissor: '',
    frequencia: '',
    monitoramento: []
  },
  telemetriaMonitoramentoCard: {
    _id: uuidv4().split('"')[1],
    dataInit: moment().format(),
    dataEnd: moment().format(),
    coordX: null,
    coordY: null,
    EPE: '',
    coodProj: '',
    pontos: [
      {
        local: '',
        dataRegistro: moment().format(),
        coordX: '',
        coordY: '',
        EPE: '',
        azimut: '',
        sinal: '',
        intensidade: '',
        contato: '',
        observacoes: ''
      }
    ]
  }
};

export const faunaReducer = (state = initialState, action) => {
  switch (action.type) {
    case faunaActionTypes.GET_DOCUMENTS_REQUEST:
    case faunaActionTypes.FAUNA_UPLOAD_DOCUMENTS_REQUEST:
      return { ...state, listDocuments: state.listDocuments, loading: true };
    case faunaActionTypes.GET_DOCUMENTS_SUCCESS:
      return { ...state, listDocuments: action.places, loading: false };
    case faunaActionTypes.GET_DOCUMENTS_FAILURE:
      return { ...state, listDocuments: state.listDocuments, loading: false };

    case faunaActionTypes.UPDATE_FAUNA_CARD_REQUEST:
    case faunaActionTypes.CREATE_FAUNA_CARD_REQUEST:
    case faunaActionTypes.LIST_TELEMETRIA_REQUEST:
      return { ...state, listTelemetria: state.listTelemetria, loading: true };
    case faunaActionTypes.LIST_FAUNA_REQUEST:
    case faunaActionTypes.FAUNA_DELETE_IMAGE_REQUEST:
      return { ...state, listFauna: state.listFauna, loading: true };
    case faunaActionTypes.CREATE_FAUNA_CARD_SUCCESS:
      return { ...state, selectedCard: action.list, loading: false };
    case faunaActionTypes.UPDATE_FAUNA_CARD_SUCCESS:
    case faunaActionTypes.LIST_FAUNA_SUCCESS:
      return { ...state, listFauna: action.list.docs, page: Number(action.list.page), pagesTotal: action.list.pages, loading: false };
    case faunaActionTypes.LIST_TELEMETRIA_SUCCESS:
      return { ...state, listTelemetria: action.list.docs, page: Number(action.list.page), pagesTotal: action.list.pages, loading: false };
    case faunaActionTypes.UPDATE_FAUNA_CARD_FAILURE:
    case faunaActionTypes.CREATE_FAUNA_CARD_FAILURE:
    case faunaActionTypes.FAUNA_UPLOAD_DOCUMENTS_FAILURE:
    case faunaActionTypes.FAUNA_DELETE_IMAGE_FAILURE:
    case faunaActionTypes.LIST_FAUNA_FAILURE:
      return { ...state, listFauna: state.listFauna, loading: false };
    case faunaActionTypes.LIST_TELEMETRIA_FAILURE:
      return { ...state, listTelemetria: state.listTelemetria, loading: false };
    case faunaActionTypes.EXPORT_FAUNA_REQUEST:
      return { ...state, loading: true };
    case faunaActionTypes.EXPORT_FAUNA_SUCCESS:
      return { ...state, loading: false };
    case faunaActionTypes.EXPORT_FAUNA_FAILURE:
      return { ...state, loading: false };
    case faunaActionTypes.SET_FAUNA_DATE_INIT:
      return { ...state, filters: { ...state.filters, initDate: moment(action.date).format() } };
    case faunaActionTypes.SET_FAUNA_DATE_END:
      return { ...state, filters: { ...state.filters, endDate: moment(action.date).format() } };
    case faunaActionTypes.SET_FAUNA_FILTER:
      return { ...state, filters: { ...state.filters, [action.target]: action.value } };
    case faunaActionTypes.SET_TELEMETRIA_DATE_INIT:
      return { ...state, telemetriaFilters: { ...state.telemetriaFilters, initDate: moment(action.date).format() } };
    case faunaActionTypes.SET_TELEMETRIA_DATE_END:
      return { ...state, telemetriaFilters: { ...state.telemetriaFilters, endDate: moment(action.date).format() } };
    case faunaActionTypes.SET_TELEMETRIA_FILTER:
      return { ...state, telemetriaFilters: { ...state.telemetriaFilters, [action.target]: action.value } };
    case faunaActionTypes.SET_TELEMETRIA_CARD:
      return { ...state, selectedTelemetriaCard: action.card ? action.card : initialState.selectedTelemetriaCard };
    case faunaActionTypes.SET_PONTO_MONITORAMENTO:
      return { ...state, telemetriaMonitoramentoCard: { ...state.telemetriaMonitoramentoCard, pontos: action.card } };
    case faunaActionTypes.SET_FAUNA_CARD:
      return { ...state, selectedCard: action.card ? action.card : initialState.selectedCard, loading: false };
    case faunaActionTypes.SET_RECAPTURA_CARD:
      return {
        ...state,
        selectedCard: {
          ...state.selectedCard,
          cardType: action.card.cardType,
          numeroCaptura: action.card.numeroCaptura,
          numeroAnimal: action.card.numeroAnimal,
          projectId: action.card.projectId,
          marcacao: action.card.marcacao,
          classe: action.card.classe,
          ordem: action.card.ordem,
          familia: action.card.familia,
          subFamilia: action.card.subFamilia,
          genero: action.card.genero,
          especie: action.card.especie,
          subEspecie: action.card.subEspecie,
          nomeCientifico: action.card.nomeCientifico,
          nomePopular: action.card.nomePopular,
          radiotransmissor: action.card.radiotransmissor,
          frequencia: action.card.frequencia,
          corAnilha: action.card.corAnilha,
          isRecaptura: true
        }
      };
    case faunaActionTypes.CLEAR_CARD:
      return {
        ...state,
        selectedCard: initialState.selectedCard,
      };
    case faunaActionTypes.HANDLE_INPUT_FIELDS:
      return {
        ...state,
        selectedCard: { ...state.selectedCard, [action.target]: action.value }
      };
    case faunaActionTypes.HANDLE_INPUT_ALLFIELDS:
      return {
        ...state,
        selectedCard: action.value
      };
    case faunaActionTypes.HANDLE_PONTOS_FIELDS:
      return {
        ...state,
        telemetriaMonitoramentoCard: {
          ...state.telemetriaMonitoramentoCard,
          [action.target]: action.value
        }
      };
    case faunaActionTypes.HANDLE_TELEMETRIA_FIELDS:
      return {
        ...state,
        selectedTelemetriaCard: { ...state.selectedTelemetriaCard, [action.target]: action.value }
      };
    case faunaActionTypes.HANDLE_TELEMETRIA_MONITORAMENTO_FIELDS:
      return {
        ...state,
        telemetriaMonitoramentoCard: { ...state.telemetriaMonitoramentoCard, [action.target]: action.value }
      };
    case faunaActionTypes.HANDLE_ESPECIES:
      return { ...state, selectedCard: action.value };
    case faunaActionTypes.HANDLE_TELEMETRIA_ESPECIES:
      return { ...state, selectedTelemetriaCard: action.value };
    case faunaActionTypes.FAUNA_UPLOAD_DOCUMENTS_SUCCESS:
    case faunaActionTypes.FAUNA_DELETE_IMAGE_SUCCESS:
    case faunaActionTypes.FAUNA_DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        selectedCard: action.list,
        loading: false
      }
    case faunaActionTypes.SAVE_MONITORAMENTO:
      return {
        ...state,
        selectedTelemetriaCard: { ...state.selectedTelemetriaCard, monitoramento: action.monitoramento }
      }
    case faunaActionTypes.EDIT_MONITORAMENTO:
      return {
        ...state,
        telemetriaMonitoramentoCard: action.monitoramento
      }
    case faunaActionTypes.RESET_TELEMETRIA_CARD:
      return {
        ...state,
        selectedTelemetriaCard: initialState.selectedTelemetriaCard,
      }
    case faunaActionTypes.RESET_MONITORAMENTO:
      return {
        ...state,
        telemetriaMonitoramentoCard: {
          _id: uuidv4().split('"')[1],
          dataInit: moment().format(),
          dataEnd: moment().format(),
          coordX: null,
          coordY: null,
          pontos: [
            {
              local: '',
              dataRegistro: moment().format(),
              coordX: '',
              coordY: '',
              EPE: '',
              azimut: '',
              projecao: '',
              sinal: '',
              intensidade: '',
              contato: '',
              observacoes: ''
            }
          ]
        }
      }
    case faunaActionTypes.DELETE_MONITORAMENTO:
      return {
        ...state,
        selectedTelemetriaCard: { ...state.selectedTelemetriaCard, monitoramento: action.monitoramentosObject }
      }
    case faunaActionTypes.DELETE_FAUNA_CARD_REQUEST:
      return { ...state, loading: true };
    case faunaActionTypes.DELETE_FAUNA_CARD_SUCCESS:
      return { ...state, selectedCard: initialState.selectedCard, loading: false };
    case faunaActionTypes.DELETE_FAUNA_CARD_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};