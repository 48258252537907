import { paramsHandler } from '../handlers/paramsHandler';
import { actionTypes } from '../types/actionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const paramsActions = {
    listParams,
    createParams,
    updateParams
  };
  
function listParams(params, page, projectId, route, type) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        paramsHandler.listParams(params, page, projectId, route, type)
        .then(response => {
                if(type === "paginate") {
                    dispatch(paginateSuccess(response));
                }else{
                    dispatch(listSuccess(response, route));
                }
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function listSuccess(list, route) { return { type: actionTypes.LIST_OPTIONS_PARAMS_SUCCESS, list, route } }
    function request() { return { type: actionTypes.LIST_PARAMS_REQUEST } }
    function paginateSuccess(list) { return { type: actionTypes.LIST_PARAMS_SUCCESS, list } }
    function failure(error) { return { type: actionTypes.LIST_PARAMS_FAILURE, error } }
}

function createParams(params, page, projectId, route) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        paramsHandler.createParams(params, page, projectId, route)
        .then(response => {
                if(response.error){
                    dispatch(failure(response));
                    dispatch(alertActions.error(response.errorMsg));
                }else{
                    dispatch(success(response));
                    dispatch(alertActions.success("Parâmetro adicionado com sucesso!"));
                }
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.CREATE_PARAMS_REQUEST } }
    function success(list) { return { type: actionTypes.CREATE_PARAMS_SUCCESS, list } }
    function failure(error) { return { type: actionTypes.CREATE_PARAMS_FAILURE, error } }
}

function updateParams(params, page, projectId, route) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        paramsHandler.updateParams(params, page, projectId, route)
        .then(response => {
                if(response.error){
                    dispatch(failure(response));
                    dispatch(alertActions.error(response.errorMsg));
                }else{
                    dispatch(success(response));
                    dispatch(alertActions.success("Parâmetro atualizado com sucesso!"));
                }
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(failure(error));
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: actionTypes.UPDATE_PARAMS_REQUEST } }
    function success(list) { return { type: actionTypes.UPDATE_PARAMS_SUCCESS, list } }
    function failure(error) { return { type: actionTypes.UPDATE_PARAMS_FAILURE, error } }
}