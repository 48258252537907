
import React, { Component, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Grid, Typography, Divider, InputAdornment, FormControl, InputLabel, Select, MenuItem, Card, CardHeader, InputBase, IconButton, CardActions } from "@material-ui/core"
import { toraHelpers } from '../../../helpers/flora/tora/helpers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { debounce, set } from 'lodash';




const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    modal: {
        padding: theme.spacing(3),
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    paper: {
        width: 400,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    input: {
        marginLeft: theme.spacing(1),
        padding: theme.spacing(1),
        flex: 1,
        flexGrow: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    listCard: {
        boxShadow: 'none',
    }
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function ToraSaidaForm(props) {

    const [formSubmited, setFormSubmited] = useState(false)

    const { dispatch, toraReducer, projectsReducer, closeModal } = props
    const { toraSaida, listDocuments } = toraReducer
    const { editItem, filters, documents } = toraSaida


    const classes = useStyles();
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState(listDocuments.tora);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const numberOfChecked = (items) => intersection(checked, items).length;


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];


        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        toraHelpers.handleIndividuos(right.concat(leftChecked), editItem.individuos, dispatch)
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        toraHelpers.handleIndividuos(not(right, rightChecked), editItem.individuos, dispatch)
        setChecked(not(checked, rightChecked));
    };
    const handleSearch = (event) => {
        const value = event.target.value
        if (value.length > 0) dispatch(toraHelpers.listTora({ numeroFicha: value }, 1))

    }
    const handleDebounceSearch = useCallback(
        debounce(handleSearch, 500)
        , [])

    useEffect(() => {
        if (editItem?.individuos?.length > 0){
            const individuos = []
            const lista = listDocuments.tora
            editItem.individuos.map((item) => {
                const data = {}
                data.numeroFicha = item.numero
                data.areaDemarcada = item.areaDemarcada
                data.volume = item.volume
                data._id = item._id
                individuos.push(data)
                lista.find((element, index) => {
                    if(element.numeroFicha === item.numero){
                        lista.splice(index, 1)
                        return element
                    }
                })
            })

            setLeft(lista)
            setRight(individuos)
        }
        else{
            setLeft(listDocuments.tora)
            setRight([])
        }
    }, [dispatch, handleDebounceSearch, listDocuments])


    const customList = (items) => (
        <Paper className={`${classes.paper}`}>
            <Card className={`${classes.listCard}`}>

                <CardActions>
                    {numberOfChecked(items) > 0 && (
                        <Typography color="textSecondary">{numberOfChecked(items)}/{items.length} selecionados</Typography>
                    )}
                </CardActions>




                <List dense component="div" role="list">
                    {items.map((value, index) => {
                        const labelId = `transfer-list-item-${value.numeroFicha}-label`;
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleToggle(value)} >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.numeroFicha} | ${value.areaDemarcada}`} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Card>
        </Paper>
    );

    const formValidator = () => {

        const { toraReducer } = props
        const { toraSaida } = toraReducer
        const { editItem } = toraSaida
        const { data, tipo, volumeTotal } = editItem

        if (data !== null && tipo !== false && tipo !== '' && volumeTotal !== 0 && volumeTotal !== '' && volumeTotal !== '0') {
            return true
        } else {
            return false
        }
    }

    const submitForm = () => {

        const { dispatch, toraReducer} = props
        const { toraSaida } = toraReducer
        const { editItem, filters } = toraSaida
        setFormSubmited({ formSubmited: true })

        if (formValidator() === false) return

        if (editItem._id && editItem._id !== '') {
            dispatch(toraHelpers.updateToraSaida(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        } else {
            dispatch(toraHelpers.createToraSaida(editItem, 1, closeModal, projectsReducer.selectedProject, filters))
        }
    }

    return (
        <Paper elevation={1} className={classes.modal}>
            <Typography variant="h5" component="h5" className="filterTitle">
                {editItem._id && editItem._id !== '' ? 'Editar' : 'Adicionar novo'}
            </Typography>
            <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} md={4}>
                            <KeyboardDatePicker
                                className="inputDefault dateInput"
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Data"
                                value={editItem.data}
                                onChange={(event) => toraHelpers.handleSaidaInput(event, 'data', 'toraSaida', dispatch)}
                                KeyboardButtonProps={{
                                    'aria-label': 'Alterar data',
                                }}
                                invalidDateMessage={'Data inválida'}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={12} md={4}>
                        <FormControl className="inputDefault" required error={formSubmited && (editItem.tipo === '' || editItem.tipo === false)}>
                            <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                value={editItem.tipo}
                                onChange={(event) => toraHelpers.handleSaidaInput(event, "tipo", 'toraSaida', dispatch)}
                            >
                                <MenuItem key='0' value={false}>{'--'}</MenuItem>
                                <MenuItem key='1' value={'Uso interno'}>{'Uso interno'}</MenuItem>
                                <MenuItem key='2' value={'Desmembramento'}>{'Desmembramento'}</MenuItem>
                                <MenuItem key='3' value={'Deterioração'}>{'Deterioração'}</MenuItem>
                                <MenuItem key='4' value={'Saída'}>{'Saída'}</MenuItem>
                                {/* {fauna.selectOptions.classType.map((type, index) => {
                          if (type === "Todos") {
                            return <MenuItem key='0' value={false}>{type}</MenuItem>
                          } else {
                            return <MenuItem key={index} value={type}>{type}</MenuItem>
                          }
                        })} */}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="volume"
                            label="Volume total"
                            className="inputDefault"
                            value={editItem.volumeTotal}
                            autoComplete='off'
                            disabled={true}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                            }}
                            error={formSubmited && (editItem.volumeTotal == 0 || editItem.volumeTotal == '' || editItem.volumeTotal == '0')}
                            helperText={formSubmited && (editItem.volumeTotal == 0 || editItem.volumeTotal == '' || editItem.volumeTotal == '0') ? 'O Volume deve ser maior que 0' : ''}
                        />
                    </Grid>
                </Grid>

                <Grid className='listaIndividuos' container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>{customList(left)}</Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleCheckedRight}
                                // disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>{customList(right)}</Grid>
                </Grid>

                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}
                >
                    <Divider className="dividerFull" />
                    <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                        <Box padding={10}>
                            <Button variant="contained"
                                className="btn-primary"
                                component="label"
                                startIcon={<SaveIcon />}
                                onClick={() => submitForm()}> Salvar Ficha </Button>
                        </Box>
                    </Box>
                    {editItem._id && editItem._id !== '' &&
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Box padding={10}>
                                <Button variant="contained"
                                    className="btn-secondary"
                                    component="label"
                                    startIcon={<DeleteForeverIcon />}
                                    onClick={(event) => toraHelpers.deleteToraSaida(filters, documents.page, projectsReducer.selectedProject, editItem._id, closeModal, dispatch)}> Excluir </Button>
                            </Box>
                        </Box>
                    }
                </Grid>



            </form>
        </Paper>
    )
}

const mapStateToProps = store => ({
    toraReducer: store.toraReducer,
    paramsReducer: store.paramsReducer,
    projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(ToraSaidaForm);