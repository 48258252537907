import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Grid, TableHead, TableContainer, Table, TableBody, Button, Divider } from '@material-ui/core'
import { Pagination, Alert } from '@material-ui/lab';
import Header from '../Components/headerAlter'
import Filter from '../Components/places/filter'
import CustomModal from '../Components/customModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { history } from '../helpers/history'
import { helpers } from '../helpers/helpers'
import { placesHelpers } from '../helpers/places/placesHelpers'


class Places extends Component {

  constructor(props){
    super(props);
    this.state = {
        modal: {showModal: false, modalType: undefined}
    }
  }

  componentDidMount(){
    const { dispatch, projectsReducer } = this.props
    dispatch(helpers.saveLocation(history.location.pathname));
    dispatch(placesHelpers.paginatePlaces(undefined, this.props.placesReducer.places.page))
    dispatch(helpers.listParams(null, null, projectsReducer.selectedProject, 'environmenttype', "list" ));
  }

  handleModal = (modalType, clear) => {
    const { modal } = this.state
    const { dispatch } = this.props
    this.setState({modal:{ showModal: !modal.showModal, modalType: modalType}})
    if(clear){
        dispatch(placesHelpers.editPlace(false))
    }
  }

  render(){
    const { placesReducer, dispatch, alert } = this.props
    const { modal } = this.state
    const { places, editPlace } = placesReducer
    const { page, pagesTotal } = places
    return (
      <div className='mainContainer'>
        <Header title={`LOCAIS & ÁREAS`}/>
        <div className='basicContainer'>
          {alert && alert.type === 'alert-danger' && alert.message &&
                  <Alert className="alert alert-error" severity="error">{alert.message}</Alert>
          }
          {alert && alert.type === 'alert-success' && alert.message &&
                  <Alert className="alert alert-success" severity="success">{alert.message}</Alert>
          }
          <Filter handleModal={this.handleModal}/>
          {places && places.list &&
            <Grid container>
              <TableContainer className="customTable paramsTable">
                <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <TableHead className="tableHead">
                    <Grid component={'tr'} container className="parametersTable">
                      <Grid component={'th'} item xs={12} md={5}>Código</Grid>
                      <Grid component={'th'} item xs={12} md={3}>Status</Grid>
                      <Grid component={'th'} item xs={12} md={3}>Área (m2)</Grid>
                      <Grid component={'th'} item xs={12} md={1}></Grid>
                    </Grid>
                  </TableHead>
                  <TableBody>
                    {places.list.map((place, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Grid component={'tr'} container className="parametersTable" key={index} id={labelId}>
                          <Grid component={'td'} item xs={12} md={5}>{place.name}</Grid>
                          <Grid component={'td'} item xs={12} md={3}>{place.status ? "Ativo" : "Inativo"}</Grid>
                          <Grid component={'td'} item xs={12} md={3}>{place.area ?? "--"}</Grid>
                          <Grid component={'td'} item xs={12} md={1}>
                            <Button
                              variant="contained"
                              className="btn-secondary btn-innerTable"
                              onClick={() => dispatch(placesHelpers.editPlace(place, this.handleModal))}
                              disabled={(editPlace._id) ? true : false}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider className="dividerFull"/>
              <Pagination count={pagesTotal} page={page} onChange={(event) => dispatch(helpers.ChangePageClick(event, undefined, page, 'places'))} />
            </Grid>
          }
        </div>
        {modal.showModal &&
          <CustomModal modalFn={this.handleModal} modalType={modal.modalType}/>
        }
      </div>
    )
  }
}

const mapStateToProps = store => ({
  placesReducer: store.placesReducer,
  projectsReducer: store.projectsReducer,
  auth: store.loginReducer.auth,
  alert: store.alertReducer
});

export default connect(mapStateToProps)(Places);