import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Typography, Divider } from "@material-ui/core"
import { faunaHelpers } from '../../helpers/fauna/faunaHelpers';
import Box from "@material-ui/core/Box";
import SaveIcon from '@material-ui/icons/Save';


class ExclusaoModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formSubmited: false
    }
  }

  submitForm = () => {
    const { dispatch, numeroFicha } = this.props

    dispatch(faunaHelpers.deleteCard(numeroFicha))
    this.overrideCloseModal()
    window.scrollTo(0, 0)
  }

  overrideCloseModal = () => {
    const { closeModal } = this.props
    closeModal()
  }

  render() {
    const { dispatch} = this.props

    return (
      <>
        <Grid item xs={12} md={12} >
          <Typography variant="h6" component="h6" className="filterTitle">Confirmação de ação</Typography>
          <Typography variant="p" component="p" className="modalSmallSize">Esta ação é irreversível, tem certeza que deseja excluir esta ficha?</Typography>
          <Divider className="dividerFull" />
          <Divider className="dividerFull" />
          <Grid container spacing={3}>
            <Grid item xs={12} md={2} />
            <Grid item xs={6} md={2}>
              <Button variant="contained"
                className="btn-primary"
                component="label"
                onClick={() => this.submitForm()}>
                SIM
              </Button>
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={6} md={2}>
              <Button variant="contained"
                className="btn-secondary"
                component="label"
                onClick={() => dispatch(this.overrideCloseModal)}>
                NÃO
              </Button>
            </Grid>
            <Grid item xs={12} md={2}/>
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = store => ({
  viveirosReducer: store.viveirosReducer,
  placesReducer: store.placesReducer,
  faunaReducer: store.faunaReducer,
  projectsReducer: store.projectsReducer,
});

export default connect(mapStateToProps)(ExclusaoModal);