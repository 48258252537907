import { instListaHandler } from '../handlers/instListaHandler';
import { instListaActionTypes } from '../types/instListaActionTypes';
import { apiHelpers } from '../helpers/apiHelpers'
import { alertActions } from './alertActions'

export const instListaActions = {
    list,
    paginate,
    create,
    update
  };
  
function list() {
    return dispatch => {
        dispatch(request());
        instListaHandler.list()
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Não foi possível se conectar, Tente Novamente."));
        });
    };
    function request() { return { type: instListaActionTypes.GET_INSTLISTA_REQUEST } }
    function success(places) { return { type: instListaActionTypes.GET_INSTLISTA_SUCCESS, places } }
    function failure(error) { return { type: instListaActionTypes.GET_INSTLISTA_FAILURE, error } }
}

function paginate(params, page) {
    return dispatch => {
        dispatch(request());
        instListaHandler.paginate(params, page)
        .then(response => {
                dispatch(success(response));
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: instListaActionTypes.LIST_INSTLISTA_REQUEST } }
    function success(listPlaces) { return { type: instListaActionTypes.LIST_INSTLISTA_SUCCESS, listPlaces } }
    function failure(error) { return { type: instListaActionTypes.LIST_INSTLISTA_FAILURE, error } }
}

function create(params, page, callback) {
    return dispatch => {
        dispatch(request());
        instListaHandler.create(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Projeto criado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: instListaActionTypes.CREATE_INSTLISTA_REQUEST } }
    function success(listPlaces) { return { type: instListaActionTypes.CREATE_INSTLISTA_SUCCESS, listPlaces } }
    function failure(error) { return { type: instListaActionTypes.CREATE_INSTLISTA_FAILURE, error } }
}

function update(params, page, callback) {
    return dispatch => {
        dispatch(request());
        instListaHandler.update(params, page)
        .then(response => {
                dispatch(success(response));
                dispatch(alertActions.success('Projeto atualizado com sucesso'))
                if(callback){ callback('project', true)}
            },
            error => {
                apiHelpers.handleError(
                    dispatch,
                    error,
                    failure(error),
                    true
                );
                dispatch(alertActions.error(error.errorMsg));
            }
        ).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error.errorMsg));
        });
    };
    function request() { return { type: instListaActionTypes.UPDATE_INSTLISTA_REQUEST } }
    function success(listPlaces) { return { type: instListaActionTypes.UPDATE_INSTLISTA_SUCCESS, listPlaces } }
    function failure(error) { return { type: instListaActionTypes.UPDATE_INSTLISTA_FAILURE, error } }
}