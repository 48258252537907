import { prefixDefaultApi } from "../helpers/prefixApi";
import { apiHelpers } from "../helpers/apiHelpers";
import moment from 'moment';
import fileDownload from 'js-file-download';
import { defaultHeaders } from "../helpers/headerHelper";

export const viveirosHandler = { 
	listResgate, 
	paginateResgate, 
	createResgate,
	updateResgate,
	exportResgate,
	listProducao, 
	paginateProducao, 
	createProducao,
	updateProducao,
	exportProducao,
	upload
};

function listResgate() {

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/viveiros/resgate/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateResgate(params, page, project) {
	const $numeroLote = params && params.numeroLote ? `&numeroLote=${params.numeroLote}` : ""
	const $nomeCientifico = params && params.nomeCientifico ? `&nomeCientifico=${params.nomeCientifico}` : ""
	const $nomePopular = params && params.nomePopular ? `&nomePopular=${params.nomePopular}` : ""
	const $initDate = params && params.initDate !== null ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate !== null ? `&endDate=${params.endDate}` : ""
	const $project = project ? `&project=${project}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($numeroLote)
	.concat($nomeCientifico)
	.concat($nomePopular)
	.concat($initDate)
	.concat($endDate)
	.concat($project)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/viveiros/resgate/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createResgate(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/viveiros/resgate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateResgate(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/viveiros/resgate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function exportResgate(params, projectId) {

	const $projectId = projectId ? `&projectId=${projectId}` : "" 
	const $numeroLote = params && params.numeroLote ? `&numeroLote=${params.numeroLote}` : ""
	const $nomeCientifico = params && params.nomeCientifico ? `&nomeCientifico=${params.nomeCientifico}` : ""
	const $nomePopular = params && params.nomePopular ? `&nomePopular=${params.nomePopular}` : ""
	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	
	const queryParams = "?"
	.concat($projectId)
	.concat($numeroLote)
	.concat($nomeCientifico)
	.concat($nomePopular)
	.concat($initDate)
	.concat($endDate)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/viveiros/resgate/export").concat(queryParams), requestOptions)
	.then( res => {
		apiHelpers.handleStatus(res)
		return res.blob()
	})
	.then( blob => {
	  fileDownload(blob, `${moment().format('YYYY-MM-DD-hh-mm-ss')}_export_viveiro_resgate.xlsx`);
	})
}

function listProducao() {

	const requestOptions = { method: "GET",	headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/viveiros/producao/list"), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function paginateProducao(params, page, project) {
	const $numeroLote = params && params.numeroLote ? `&numeroLote=${params.numeroLote}` : ""
	const $nomeCientifico = params && params.nomeCientifico ? `&nomeCientifico=${params.nomeCientifico}` : ""
	const $nomePopular = params && params.nomePopular ? `&nomePopular=${params.nomePopular}` : ""
	const $initDate = params && params.initDate !== null ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate !== null ? `&endDate=${params.endDate}` : ""
	const $project = project ? `&project=${project}` : ""
    
	const queryParams = "?page=".concat(page)
	.concat($numeroLote)
	.concat($nomeCientifico)
	.concat($nomePopular)
	.concat($initDate)
	.concat($endDate)
	.concat($project)

	const requestOptions = { method: "GET", headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/viveiros/producao/paginate").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function createProducao(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "POST",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/viveiros/producao").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}

function updateProducao(params, page) {
    
	const queryParams = "?page=".concat(page)

	const requestOptions = { 
		method: "PUT",
		body: JSON.stringify(params),
		headers: defaultHeaders
	};
	return fetch(prefixDefaultApi.concat("flora/viveiros/producao").concat(queryParams), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}


function exportProducao(params, projectId) {

	const $projectId = projectId ? `&projectId=${projectId}` : "" 
	const $numeroLote = params && params.numeroLote ? `&numeroLote=${params.numeroLote}` : ""
	const $nomeCientifico = params && params.nomeCientifico ? `&nomeCientifico=${params.nomeCientifico}` : ""
	const $nomePopular = params && params.nomePopular ? `&nomePopular=${params.nomePopular}` : ""
	const $initDate = params && params.initDate ? `&initDate=${params.initDate}` : ""
	const $endDate = params && params.endDate ? `&endDate=${params.endDate}` : ""
	
	const queryParams = "?"
	.concat($projectId)
	.concat($numeroLote)
	.concat($nomeCientifico)
	.concat($nomePopular)
	.concat($initDate)
	.concat($endDate)

	const requestOptions = { method: "GET" , headers: defaultHeaders };
	return fetch(prefixDefaultApi.concat("flora/viveiros/producao/export").concat(queryParams), requestOptions)
	.then( res => {
		apiHelpers.handleStatus(res)
		return res.blob()
	})
	.then( blob => {
	  fileDownload(blob, `${moment().format('YYYY-MM-DD-hh-mm-ss')}_export_viveiro_producao.xlsx`);
	})
}
function upload(file, fileName, ids) {
	const {id, idMonitoramento} = ids;
	const formdata = new FormData()
	formdata.append("documentFile", file, fileName);

	const requestOptions = { 
		method: "POST",
		headers: defaultHeaders,
		body: formdata,
	};
	return fetch(prefixDefaultApi.concat(`flora/viveiros/producao/upload?id=${id}&idMonitoramento=${idMonitoramento}`), requestOptions)
		.then(apiHelpers.handleStatus)
		.then(apiHelpers.handleResponse);
}